<template lang="html">
  <PageHeader />

  <ComingSoon />
  <PageFooter />
</template>

<script lang="js">

import ComingSoon from '@/components/partials/ComingSoon.vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Terms and Conditions',
  props: [],
  components: {
    ComingSoon,
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
