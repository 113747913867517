<template lang="html">
  <PageHeader />
  <section class="marketplace mt-stickybar">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-lg-4">
                <div class="markerplace-nav">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link tellers active"
                        id="pills-story-tellers-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-story-tellers"
                        type="button"
                        role="tab"
                        aria-controls="pills-story-tellers"
                        aria-selected="true"
                      >
                        Story Tellers
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link story"
                        id="pills-story-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-story"
                        type="button"
                        role="tab"
                        aria-controls="pills-story"
                        aria-selected="false"
                      >
                        Story
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-5 col-lg-5">
                <MarketplaceSearch />
              </div>
              <div class="col-md-3 col-lg-3">
                <MarketplaceIcons />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-story-tellers"
          role="tabpanel"
          aria-labelledby="pills-story-tellers-tab"
        >
          <MarketplaceStoryTellers />
        </div>
        <div
          class="tab-pane fade"
          id="pills-story"
          role="tabpanel"
          aria-labelledby="pills-story-tab"
        >
          <MarketplaceStory />
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import MarketplaceStoryTellers from '@/components/partials/MarketplaceStoryTellers.vue';
import MarketplaceStory from '@/components/partials/MarketplaceStory.vue';
import MarketplaceSearch from '@/components/partials/MarketplaceSearch.vue';
import MarketplaceIcons from '@/components/partials/MarketplaceIcons.vue';

export default {
  name: 'marketplace',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    MarketplaceStoryTellers,
    MarketplaceStory,
    MarketplaceSearch,
    MarketplaceIcons,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
