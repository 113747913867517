<template lang="html">
  <PageHeader />
  <section class="contact-us mt-stickybar home">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 m-auto">
            <div class="contact-left-block mb-4">
              <h1 class="text-center mb-3">{{ $t('getInTouch') }}</h1>
              <p>
                {{ $t('getInTouchMessage') }}
              </p>
              <img src="@/assets/images/contact-us.png" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="contact-right-block general-form-block">
              <div class="form-group">
                <label>{{ $t('name') }}</label>
                <input type="text" v-model="contactUs.name" class="form-control" />
                <span class="text-danger pl-3" v-if="v$.name.$error">
                  <!-- {{ v$.name.$errors[0].$message }}* -->
                  {{ $t('errorMessage', { text: $t('name') }) }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('email') }}</label>
                <input type="email" v-model="contactUs.email" class="form-control" />
                <span class="text-danger pl-3" v-if="v$.email.$error">
                  <!-- {{ v$.email.$errors[0].$message }}* -->
                  {{ $t('errorMessage', { text: $t('email') }) }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('contactNumber') }}</label>
                <input type="text" v-model="contactUs.phoneNumber" class="form-control" />
                <span class="text-danger pl-3" v-if="v$.phoneNumber.$error">
                  <!-- {{ v$.phoneNumber.$errors[0].$message }}* -->
                  {{ $t('errorMessage', { text: $t('contactNumber') }) }}*
                </span>
              </div>

              <div class="form-group">
                <label>{{ $t('wehelp') }}</label>
                <div class="drop-select dropdown w-100 d-flex justify-content-between">
                  <span v-if="query.selected" class="mt-2 mx-2">{{ query.selected }}</span>
                  <span v-else class="mt-2 mx-3">{{ $t('select') }}</span>
                  <button
                    class="btn dropdown-toggle"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul class="dropdown-menu w-100">
                    <li class="dropdown-item" @click="onlineOrders($t('onlineOrders'))">
                      {{ $t('onlineOrders') }}
                    </li>
                    <li
                      class="dropdown-item"
                      value="StoryPhones and other Shields"
                      @click="spShields($t('spAndShields'))"
                    >
                      {{ $t('spAndShields') }}
                    </li>
                    <li class="dropdown-item" @click="handleClicks($t('ContentSuggestion'))">
                      {{ $t('ContentSuggestion') }}
                    </li>
                    <li class="dropdown-item" @click="handleClicks($t('productFeedback'))">
                      {{ $t('productFeedback') }}
                    </li>
                    <li class="dropdown-item" @click="handleClicks($t('Others'))">
                      {{ $t('Others') }}
                    </li>
                  </ul>
                </div>
                <span class="text-danger pl-3" v-if="v$$.selected.$error">
                  {{ $t('selectMsg') }}*
                </span>
              </div>
              <div v-show="isShow" class="form-group">
                <label>{{ $t('whatIsIt') }}</label>
                <select class="form-select" name="" id="" v-model="query.whatIsIt">
                  <option value="">{{ $t('select') }}</option>
                  <option
                    v-for="(item, index) in weHelpResults.details"
                    :key="index"
                    :value="item.description"
                  >
                    {{ item.description }}
                  </option>
                </select>

                <span class="text-danger pl-3" v-if="v$$.whatIsIt.$error">
                  {{ $t('selectMsg') }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('orderNumber') }} </label>
                <input type="text" v-model="contactUs.orderNumber" class="form-control" />
                <span class="text-danger pl-3" v-if="v$.orderNumber.$error">
                  <!-- {{ v$.orderNumber.$errors[0].$message }}* -->
                  {{ $t('errorMessage', { text: $t('orderNumber') }) }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('problemDetails') }}</label>
                <textarea
                  :placeholder="$t('contactUsReason')"
                  class="form-control"
                  v-model="contactUs.description"
                  rows="5"
                ></textarea>
                <span class="text-danger pl-3" v-if="v$.description.$error">
                  <!-- {{ v$.description.$errors[0].$message }}* -->
                  {{ $t('contactUsReason') }}*
                </span>
              </div>

              <div class="wave-btn-block text-right">
                <button
                  type="submit"
                  class="blob-btn fill-bg btn-lg submit-btn mt-2"
                  @click="submitContactUs()"
                >
                  {{ $t('submit') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>

              <div
                class="success-submit-box modal fade mt-3 d-none"
                :class="{ addsuccess: isAdded, show: isAdded }"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content border-0 p-3">
                    <div class="modal-body">
                      <div class="popup-msg-box">
                        <p>{{ $t('contactUsMessage') }}!</p>
                      </div>
                    </div>
                    <div class="text-center">
                      <router-link to="/" class="btn btn-primary btn-lg submit-btn mb-3">
                        {{ $t('ok') }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';

import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, helpers, alpha,
} from '@vuelidate/validators';
import {
  ref, computed, reactive,
} from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';

const storyphoneService = new StoryphoneService();

export default {
  name: 'Contact Us',
  props: [],
  components: {
    SvgButton,
    PageHeader,
    PageFooter,
  },

  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');
    const isAdded = ref(false);
    const isShow = ref(false);
    const query = reactive({
      selected: '',
      whatIsIt: '',
    });
    const selected = ref('');
    const whatIsIt = ref('');

    const contactUs = reactive({
      name: '',
      email: '',
      // subject: '',
      description: '',
      phoneNumber: '',
      orderNumber: '',
    });

    const rules = {
      name: { required: helpers.withMessage('You must enter your name', required) },
      email: { required: helpers.withMessage('You must enter your email', required), email },
      description: { required: helpers.withMessage('Please explain as to how we can be of assistance', required) },
      phoneNumber: { required: helpers.withMessage('You must enter your phone number', required) },
      orderNumber: { required: helpers.withMessage('You must enter your Order Number', required) },

    };
    const rules2 = {
      selected: { required: helpers.withMessage('You must select ', required) },
      whatIsIt: { required: helpers.withMessage('You must select  ', required) },

    };

    const v$ = useVuelidate(
      rules,
      contactUs,
      query,
    );
    const v$$ = useVuelidate(
      rules2,
      query,
    );
    const onlineOrders = (value) => {
      query.selected = value;
      query.whatIsIt = '';
      isShow.value = true;
      const Data = {
        code: langCode,
        slug: `contact_us_online_orders_${langCode}`,
      };
      store.dispatch('fetchContactUs', Data);
    };

    const spShields = (value) => {
      query.selected = value;
      query.whatIsIt = '';
      isShow.value = true;
      const Data = {
        code: langCode,
        slug: `contact_us_sp_and_shields_${langCode}`,
      };
      store.dispatch('fetchContactUs', Data);
    };

    const handleClicks = (value) => {
      query.selected = value;
      query.whatIsIt = '';
      isShow.value = false;
    };

    const submitContactUs = async () => {
      const result = await v$.value.$validate();
      const result2 = await v$$.value.selected.$validate();
      const result3 = await v$$.value.whatIsIt.$validate();
      if (isShow.value === true && result && result2 && result3) {
        const data = {
          name: contactUs.name,
          email: contactUs.email,
          phone: contactUs.phoneNumber,
          description: contactUs.description,
          issue_category: query.selected,
          issue_subcategory: query.whatIsIt,
          order_number: contactUs.orderNumber,
        };
        storyphoneService.contactUsEmail(data)
          .then((res) => {
            isAdded.value = true;
          })
          .catch(() => {

          });
      } else if
      (isShow.value === false && result && result2 && !result3) {
        const data = {
          name: contactUs.name,
          email: contactUs.email,
          phone: contactUs.phoneNumber,
          description: contactUs.description,
          issue_category: query.selected,
          // issue_subcategory: query.whatIsIt,
          order_number: contactUs.orderNumber,
        };
        storyphoneService.contactUsEmail(data)
          .then((res) => {
            isAdded.value = true;
          })
          .catch(() => {

          });
      }
    };
    const weHelpResults = computed(() => store.getters.getContactUs);

    return {
      isAdded,
      contactUs,
      v$,
      v$$,
      submitContactUs,
      store,
      langCode,
      onlineOrders,
      spShields,
      weHelpResults,
      handleClicks,
      selected,
      isShow,
      whatIsIt,
      query,
    };
  },

};
</script>

<style scoped lang="scss"></style>
