<template lang="html">
  <PageHeader />
  <section class="marketplace-my-playlist mt-stickybar">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 col-lg-7">
                <MarketplaceSearch />
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="wave-btn-block text-end">
                  <button class="blob-btn fill-bg">
                    Record a Story
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>

            <div class="marketplace-profile-outerblock">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <MarketplaceAccountSidebar />
                </div>
                <div class="col-md-8 col-lg-8">
                  <div class="profile-content-block">
                    <div class="profiile-single-content">
                      <div class="star-heading-block">
                        <h3>Naughty Luttappi</h3>
                        <div class="stars-list mb-2">
                          <ul class="list-unstyled">
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li><i class="icon-star" aria-hidden="true"></i></li>
                          </ul>
                        </div>
                        <div class="top-btn-block d-flex">
                          <button class="shadow-btn border-0 me-2">
                            <i class="icon-delete" aria-hidden="true"></i>
                          </button>
                          <button class="shadow-btn border-0">
                            <i class="icon-share" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>

                      <p>
                        Luttāppi, is a cute, innocent red coloured little imp who works as an aide
                        of Küttoosen. One of the primary sources of humor in the comic, Luttāppi is
                        noted for his cheekiness and cowardice. His stupidity and over excitedness
                        often lands everyone in trouble...
                        <a href="/marketplace-story-details" class="read-more">Read More</a>
                      </p>
                      <div class="single-video-outer d-block my-3">
                        <div class="single-video">
                          <img src="@/assets/images/story-video.png" alt="story-video" />
                        </div>
                      </div>

                      <div class="btn-block pt-3">
                        <div class="row">
                          <div class="col-md-6 col-lg-6">
                            <span class="profile-like-dislike">
                              <button class="like">
                                <i class="icon-thumb-up" aria-hidden="true"></i>74
                              </button>
                              <button href="#" class="dislike">
                                <i class="icon-thumb-down" aria-hidden="true"></i>10
                              </button>
                            </span>
                            <span class="wave-btn-block">
                              <button
                                type="button"
                                class="blob-btn btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#eviews-popup"
                              >
                                Reviews
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </span>
                          </div>

                          <div class="col-md-6 col-lg-6">
                            <div class="wave-btn-block text-end">
                              <button class="blob-btn fill-bg btn-sm me-2">
                                Add to cart
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <button class="blob-btn fill-bg btn-sm">
                                Add To Wishlist
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="profiile-single-content">
                      <div class="star-heading-block">
                        <h3>Naughty Luttappi</h3>
                        <div class="stars-list mb-2">
                          <ul class="list-unstyled">
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li><i class="icon-star" aria-hidden="true"></i></li>
                          </ul>
                        </div>
                        <div class="top-btn-block d-flex">
                          <button class="shadow-btn border-0 me-2">
                            <i class="icon-delete" aria-hidden="true"></i>
                          </button>
                          <button class="shadow-btn border-0">
                            <i class="icon-share" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>

                      <p>
                        Luttāppi, is a cute, innocent red coloured little imp who works as an aide
                        of Küttoosen. One of the primary sources of humor in the comic, Luttāppi is
                        noted for his cheekiness and cowardice. His stupidity and over excitedness
                        often lands everyone in trouble...
                        <a href="/marketplace-story-details" class="read-more">Read More</a>
                      </p>
                      <div class="single-video-outer d-block my-3">
                        <div class="single-video">
                          <img src="@/assets/images/story-video.png" alt="story-video" />
                        </div>
                      </div>

                      <div class="btn-block pt-3">
                        <div class="row">
                          <div class="col-md-6 col-lg-6">
                            <span class="profile-like-dislike">
                              <button class="like">
                                <i class="icon-thumb-up" aria-hidden="true"></i>74
                              </button>
                              <button href="#" class="dislike">
                                <i class="icon-thumb-down" aria-hidden="true"></i>10
                              </button>
                            </span>
                            <span class="wave-btn-block">
                              <button
                                type="button"
                                class="blob-btn btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#eviews-popup"
                              >
                                Reviews
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </span>
                          </div>

                          <div class="col-md-6 col-lg-6">
                            <div class="wave-btn-block text-end">
                              <button class="blob-btn fill-bg btn-sm me-2">
                                Add to cart
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <button class="blob-btn fill-bg btn-sm">
                                Add To Wishlist
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="profiile-single-content">
                      <div class="star-heading-block">
                        <h3>Naughty Luttappi</h3>
                        <div class="stars-list mb-2">
                          <ul class="list-unstyled">
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li><i class="icon-star" aria-hidden="true"></i></li>
                          </ul>
                        </div>
                        <div class="top-btn-block d-flex">
                          <button class="shadow-btn border-0 me-2">
                            <i class="icon-delete" aria-hidden="true"></i>
                          </button>
                          <button class="shadow-btn border-0">
                            <i class="icon-share" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>

                      <p>
                        Luttāppi, is a cute, innocent red coloured little imp who works as an aide
                        of Küttoosen. One of the primary sources of humor in the comic, Luttāppi is
                        noted for his cheekiness and cowardice. His stupidity and over excitedness
                        often lands everyone in trouble...
                        <a href="/marketplace-story-details" class="read-more">Read More</a>
                      </p>
                      <div class="single-video-outer d-block my-3">
                        <div class="single-video">
                          <img src="@/assets/images/story-video.png" alt="story-video" />
                        </div>
                      </div>

                      <div class="btn-block pt-3">
                        <div class="row">
                          <div class="col-md-6 col-lg-6">
                            <span class="profile-like-dislike">
                              <button class="like">
                                <i class="icon-thumb-up" aria-hidden="true"></i>74
                              </button>
                              <button href="#" class="dislike">
                                <i class="icon-thumb-down" aria-hidden="true"></i>10
                              </button>
                            </span>
                            <span class="wave-btn-block">
                              <button
                                type="button"
                                class="blob-btn btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#eviews-popup"
                              >
                                Reviews
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </span>
                          </div>

                          <div class="col-md-6 col-lg-6">
                            <div class="wave-btn-block text-end">
                              <button class="blob-btn fill-bg btn-sm me-2">
                                Add to cart
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <button class="blob-btn fill-bg btn-sm">
                                Add To Wishlist
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- marketplace-profile-outerblock -->

            <!-- Modal -->
            <div
              class="modal fade"
              id="eviews-popup"
              tabindex="-1"
              aria-labelledby="eviewsPopupLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                  <button
                    type="button"
                    class="btn-close close-blue-btn ms-auto 45"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="icon-close" aria-hidden="true"></i>
                  </button>
                  <div class="modal-body">
                    <h3 class="text-center">Reviews</h3>
                    <div class="marketplace-review-listing">
                      <ul class="row">
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import MarketplaceSearch from '@/components/partials/MarketplaceSearch.vue';
import MarketplaceAccountSidebar from '@/components/commons/MarketplaceAccountSidebar.vue';

export default {
  name: 'marketplace-my-playlist',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    SvgButton,
    MarketplaceSearch,
    MarketplaceAccountSidebar,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
