export const SET_STORYSHIELDS_LIST = 'SET_STORYSHIELDS_LIST';
export const SET_STORYPHONE_LIST = 'SET_STORYPHONE_LIST';
export const SET_PLAYSHIELD_LIST = 'SET_PLAYSHIELD_LIST';
export const SET_ZENSHIELD_LIST = 'SET_ZENSHIELD_LIST';
export const SET_PLAYLISTS_LIST = 'SET_PLAYLISTS_LIST';
export const SET_STORY_LIST = 'SET_STORY_LIST';
export const SET_STORY_DETAILS = 'SET_STORY_DETAILS';
export const SET_PLAYLISTS_DETAILS = 'SET_PLAYLISTS_DETAILS';
export const SET_SELECTED_SHIELD_DETAILS = 'SET_SELECTED_SHIELD_DETAILS';
export const GET_SUCCESS_MESSAGE = 'GET_SUCCESS_MESSAGE';
export const GET_ERROR_MESSAGE = 'GET_ERROR_MESSAGE';
export const SET_SELECTED_SHIELD_SKU = 'SET_SELECTED_SHIELD_SKU';
export const SET_SHIELD_PRICE = 'SET_SHIELD_PRICE';
export const GET_WISHLIST = 'GET_WISHLIST';
export const SET_CARTITEM_DETAILS = 'SET_CARTITEM_DETAILS';
export const SET_CARTITEM_PRICE = 'SET_CARTITEM_PRICE';
export const SELECTED_STORYPHONE = 'SELECTED_STORYPHONE';
export const SELECTED_PLAYSHIELD = 'SELECTED_PLAYSHIELD';
export const GET_USER_WISHLIST = 'GET_USER_WISHLIST';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_PLAYLIST_DETAILS = 'GET_PLAYLIST_DETAILS';
export const GET_GENRES_DETAILS = 'GET_GENRES_DETAILS';
export const ONANOFF_STORYPHONES = 'ONANOFF_STORYPHONES';
export const DISNEY_STORYPHONES = 'DISNEY_STORYPHONES';
export const LINGOKIDS_STORYPHONES = 'LINGOKIDS_STORYPHONES';
export const PLAY_SHEILD_PLUS = 'PLAY_SHEILD_PLUS';
