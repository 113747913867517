<template lang="html">
  <PageHeader />
  <section class="checkout mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="inner-page-content pt-4 pb-5">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb px-0">
                <li class="breadcrumb-item">
                  <a href="/update-cart">{{ $t('cart') }}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('checkOut') }}</li>
              </ol>
            </nav>
            <div class="checkout-wizard text-center">
              <ul id="wizard-progressbar">
                <li id="shield-title" class="active"><span>1</span>{{ $t('shipAds') }}</li>
                <li id="recording" :class="{ active: firstStep }">
                  <span>2</span> {{ $t('billAds') }}
                </li>
                <li id="recording" :class="{ active: secondStep }">
                  <span>3</span>{{ $t('orderPre') }}
                </li>
                <li id="artwork" :class="{ active: thirdStep }">
                  <span>4</span>{{ $t('payment') }}
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="checkout-main">
                  <h3>{{ $t('checkout') }}</h3>
                  <hr />
                  <ShippingDetails />
                  <BillingDetails />
                  <OrderPreview />
                  <PayNow />
                </div>
              </div>
              <div class="col-md-4">
                <OrderSummery />
                <div class="order-item mt-5">
                  <div class="text-center">
                    <h6>{{ $t('urCart') }}</h6>
                    <hr />
                  </div>
                  <div v-if="cartSummary">
                    <ul class="list-cart-view list-unstyled">
                      <li v-for="cartItem in cartSummary" :key="cartItem.id">
                        <div class="cart-item-single row">
                          <div class="col-md-3 cart-image">
                            <img :src="cartItem.image_url" alt="cart image" />
                          </div>
                          <div class="col-md-9">
                            <h6>{{ cartItem.name }}</h6>
                            <div class="form-group mt-1 mb-1">
                              <label class="d-inline"
                                >{{ $t('quantity') }} : {{ cartItem.quantity }}</label
                              >
                            </div>
                            <div class="form-group mt-1 mb-1">
                              <label class="d-inline">
                                {{ $t('Price') }} : {{ price(cartItem.currency_code,cartItem.unit_amount_float) }}
                                <!-- {{ $t('Price') }} : {{ cartItem.formatted_unit_amount.slice(0, 1) }}
                                {{ cartItem.unit_amount_float }} -->
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </li>
                    </ul>
                  </div>
                  <div class="text-center mb-2 mt-4" @click="editCartClick()">
                    <button
                      class="blob-btn fill-bg btn-lg edit-cart-btn"
                      :class="{ btnhide: isButtonHide }"
                    >
                      {{ $t('editCart') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                    </button>
                    <SvgButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  ref, computed, reactive, onUnmounted,
} from 'vue';
import { useCookies } from 'vue3-cookies';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import OrderSummery from '@/components/partials/OrderSummery.vue';
import ShippingDetails from '@/components/partials/ShippingDetails.vue';
import BillingDetails from '@/components/partials/BillingDetails.vue';
import OrderPreview from '@/components/partials/OrderPreview.vue';
import PayNow from '@/components/partials/PayNow.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const { cookies } = useCookies({ secure: true });

export default {
  name: 'checkout',
  props: [],
  components: {
    OrderSummery,
    ShippingDetails,
    BillingDetails,
    OrderPreview,
    PayNow,
    PageHeader,
    PageFooter,
    SvgButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { orderId } = cookies.get('orderIdCookie');
    store.dispatch('fetchCartItems', orderId);
    store.dispatch('hideCartButton', true);
    const cartSummary = computed(() => store.getters.getLineItems);
    const cartItems = computed(() => store.getters.getCartItems);
    const isButtonHide = computed(() => store.getters.isButtonHide);

    const firstStep = computed(() => store.getters.isFirstStep);
    const secondStep = computed(() => store.getters.isSecondStep);
    const thirdStep = computed(() => store.getters.isThirdStep);

    const price = (currencyCode, value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      });
      return formatter.format(value);
    };

    const editCartClick = () => {
      router.push('/update-cart');
    };
    onUnmounted(() => {
      store.dispatch('hideCartButton', false);
      store.dispatch('isHideShipping', false);
      store.dispatch('isShowBilling', false);
      store.dispatch('buttonHide', false);
      store.dispatch('isShowPreview', false);
      store.dispatch('isShowPaynow', false);
      store.dispatch('inFirstStep', false);
      store.dispatch('inSecondStep', false);
      store.dispatch('inThirdStep', false);
      store.dispatch('inFourthStep', false);
    });

    return {
      cartSummary, cartItems, isButtonHide, editCartClick, firstStep, secondStep, thirdStep, price,
    };
  },
};
</script>

<style scoped lang="scss"></style>
