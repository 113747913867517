<template>
  <PageHeader />
  <section class="blog">
    <div class="container">
      <div class="row">
        <div class="col-md-10 m-auto">
          <div class="container text-center">
            <img src="@/assets/images/blog-4-top-image.jpeg" alt="lingokids-StoryPhones" />
          </div>
          <h5 class="mt-4 mb-3">
            ONANOFF Secures Silver Honor at 2023 EU Product Safety Awards for Revolutionary StoryPhones.
          </h5>
          <!-- <p class="blog-p">
            In today&#39;s digital age, it&#39;s no secret that screens play a significant role in
            children&#39;s lives. However, striking a balance between screen time and other forms of
            engagement is crucial for their holistic development. That is why StoryPhones have
            teamed up with leading early learning platform
            <a href="https://lingokids.com/" class="">Lingokids</a> to offer captivating
            learning experience for young children in the form of new StoryShields. This partnership
            combines the power of technology with the magic of music and sing-a-longs, promising an
            educational journey like no other.
          </p>
          <p class="blog-p">
            We’re very excited to unveil not just one, but six Lingokids shields exclusively on the
            StoryPhones platform throughout 2023! That’s right, we’re taking our mission of making
            learning fun to a whole new level. Two StoryShields from Lingokids are already
            available:
          </p> -->
          <!-- <ul class="ps-5 mb-3">
            <li>
              Learn to Read with Elliot –Elliot is a playful panda that uses music and sing-a-longs
              to introduce kids to the world of reading.
            </li>
            <li>
              Learn Math and Science with Billy –Billy is a little bird with big brain power that
              guides kids through math and science with catchy tunes that make learning an
              adventure.
            </li>
          </ul> -->
          <h5 class="mt-4 mb-3">Embracing a Screen-Free Realm of Creativity and Security</h5>
          <p class="blog-p">
            In a celebration of ingenuity and unwavering dedication to consumer safety, ONANOFF stands tall as a beacon of excellence at the 2023 EU Product Safety Awards, clinching the esteemed Silver Award in the category dedicated to Protecting the Safety of Vulnerable Consumer Groups. This accolade is a testament to the company&#39;s commitment to surpassing regulatory requirements, establishing a heightened standard for product safety.
          </p>
          <h5 class="mt-4 mb-3">StoryPhones: A Stage for Unleashing Imagination</h5>
          <p class="blog-p">
            The spotlight shines on ONANOFF&#39;s ground-breaking initiative, StoryPhones, acclaimed for providing a healthier alternative to screen-centric entertainment. This independent device operates seamlessly without external connections, fostering a screen-free haven for children&#39;s creativity. With the capacity to store and play audio content at any time, StoryPhones encourages a return to the timeless tradition of storytelling.
          </p>
          <h5 class="mt-4 mb-3">Revitalizing Entertainment, Diminishing Screen Dependency</h5>
          <p class="blog-p">
            In an era where our digital landscape evolves continuously, raising children amid pervasive screens poses unique challenges. ONANOFF&#39;s StoryPhones addresses this concern by championing a balanced approach to entertainment. More than a mere device, it signifies a commitment to nurturing creativity and reducing screen time, offering a safer and healthier alternative.
          </p>
          <h5 class="mt-4 mb-3">EU Product Safety Awards: A Recognition of Distinction</h5>
          <p class="blog-p">
            Organized biennially by the European Commission, the EU Product Safety Awards spotlight companies and research projects prioritizing consumer safety. In its third edition, the awards expanded their recognition to include researchers contributing to consumer product safety. ONANOFF&#39;s receipt of the Silver Award in the Protecting the Safety of Vulnerable Consumer Groups category reinforces the company&#39;s dedication to both product safety and innovative solutions.
          </p>
          <div class="container text-center mt-5">
            <img src="@/assets/images/blog-4-bottom-1-image.jpeg" alt="lingokids-StoryPhones" />
          </div>
          <h5 class="mt-4 mb-3">Innovation, Impact, and Inspiration: ONANOFF&#39;s Successful Equation</h5>
          <p class="blog-p">
            The evaluation criteria for the EU Product Safety Awards encompassed innovation, impact, inspiration potential, and the efficacy of a company&#39;s product safety processes. ONANOFF excelled in all these aspects, with StoryPhones emerging as an embodiment of creativity and responsibility.
          </p>
          <h5 class="mt-4 mb-3">A Pledge to Safeguard Hearing in a Dynamic Landscape</h5>
          <p class="blog-p">
            Beyond the acclaim, ONANOFF remains unwavering in its mission to provide the most robust sound solutions, safeguarding children&#39;s hearing in an ever-changing technological landscape. In a world where technology and children&#39;s needs constantly evolve, ONANOFF persists in being at the forefront, ensuring that quality headphones prioritize the well-being of young listeners.
          </p>
          <h5 class="mt-4 mb-3">Advocating for Safer Listening Practices</h5>
          <p class="blog-p">
            The Silver Award from the EU Product Safety Awards is not merely a recognition; it is a reaffirmation of ONANOFF&#39;s commitment to advocating for safer listening habits. From heightening awareness about Noise-Induced Hearing Loss and the effects of too much screentime to pioneering innovative solutions like StoryPhones, ONANOFF remains at the forefront of establishing a safer and more responsible consumer product environment.
          </p>
          <h5 class="mt-4 mb-3">A Secure Tomorrow with StoryPhones</h5>
          <p class="blog-p">
            As ONANOFF revels in the glow of the 2023 EU Product Safety Awards, the journey is far from over. The Silver Award serves as a tangible testament to the company&#39;s unwavering dedication to innovation, impact, and social responsibility. With StoryPhones leading the way, carving a path toward a safer, screen-free world where imagination reigns supreme, and the safety of children takes precedence.
          </p>
          <div class="container text-center mt-5">
            <img src="@/assets/images/blog-4-bottom-2-image.jpeg" alt="lingokids-StoryPhones" />
          </div>
          <!-- <div class="container">
            <ul class="" style="list-style: none;">
              <li class="mt-4 mb-4">
                <a href="https://www.storyphones.com/products/lingoKids"
                  >StoryPhones + 2 Lingokids Shields Bundle</a
                >
              </li>
              <li class="">
                <a href="https://www.storyphones.com/shields-details/4897111742990"
                  >Lingokids Billy &amp; Elliot StoryShield Bundle</a
                >
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
    <div class="container-fluid text-center mt-4 mb-5">
      <div class="wave-btn-block">
        <router-link to="/blog" class="blob-btn fill-bg btn-lg">
          Back to Blog
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Blog',

  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style></style>
