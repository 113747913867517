/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getStoryPhoneList = (state) => isObj(state, 'storyPhoneList', []);

export const getOnanOffStoryPhone = (state) => {
  const headphones = isObj(state, 'storyPhoneList.items', []);
  const storyPhones = [];
  if (headphones) {
    headphones.forEach((element) => {
      if (element.fields.brand === 'ONANOFF') {
        storyPhones.push(element);
      }
    });
  }
  return storyPhones;
};

export const getDisneyStoryPhone = (state) => {
  const headphones = isObj(state, 'storyPhoneList.items', []);
  const disneyPhones = [];
  if (headphones) {
    headphones.forEach((element) => {
      if (element.fields.brand === 'DISNEY') {
        disneyPhones.push(element);
      }
    });
  }
  return disneyPhones;
};

export const getStoryShieldsList = (state) => isObj(state, 'storyShieldsList.items', []);

export const getStoryShieldsImage = () => ((storyShields) => {
  isObj(storyShields, 'image.fields.file.url', '');
});

export const getStoryShieldList = (state) => isObj(state, 'storyShieldsList.items', []);

export const getStoryShieldPrice = (state) => isObj(state, 'storyShieldPrice', []);

export const getPlayShieldList = (state) => isObj(state, 'playShieldList.items', []);

export const getZenShieldList = (state) => isObj(state, 'zenShieldList.items[0]', []);

export const getStoryShieldImage = (state) => (Playlists) => isObj(Playlists, 'image.fields.file.url', '');

export const getPlaylists = (state) => isObj(state, 'playlists.items', []);

export const getPlaylistsImage = (state) => (Playlists) => isObj(Playlists, 'image.fields.file.url', '');

export const getStoryList = (state) => isObj(state, 'storyList.items', []);

export const getStoryListImage = (state) => (storyList) => isObj(storyList, 'image.fields.file.url', '');

export const getStoryDetails = (state) => isObj(state, 'storyDetails.items[0]', '');

export const getPlaylistsDetails = (state) => isObj(state, 'playlistsDetails.items[0]', '');

export const getSelectedShieldDetails = (state) => isObj(state, 'selectedShieldDetails', {});

export const getSelectedShieldSku = (state) => isObj(state, 'selectedShieldSku', {});

export const getSuccessMessage = (state) => isObj(state, 'successMessage', {});

export const getErrorMessage = (state) => isObj(state, 'errorMessage', {});

export const getWishlist = (state) => isObj(state, 'wishlist.data.skus', {});

export const getUserWishlist = (state) => isObj(state, 'userWishlist', {});

export const cartItemDetails = (state) => isObj(state, 'cartItemDetails', {});

export const cartItemPrice = (state) => isObj(state, 'cartItemPrice', {});

export const selectedStoryPhone = (state) => isObj(state, 'selectedStoryPhone', {});

export const selectedPlayShield = (state) => isObj(state, 'selectedPlayShield', {});

export const searchedItems = (state) => isObj(state, 'searchedItems', []);

export const getPlaylistDetails = (state) => isObj(state, 'playlistDetails', []);

export const getGenresDetails = (state) => isObj(state, 'genresDetails', []);

export const getOnanoffStoryPhones = (state) => isObj(state, 'onanoffStoryPhones', []);

export const getDisneyStoryPhones = (state) => isObj(state, 'disneyStoryPhones', []);

export const getLingoKidsStoryPhones = (state) => isObj(state, 'lingoKidsStoryPhones', []);

export const getPlayShieldPlus = (state) => isObj(state, 'playShieldPlus', []);
