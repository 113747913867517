<template lang="html">
  <PageHeader />
  <section class="custom-playlist nav-top-fixed mb-5 home">
    <div class="inner-page-banner full-width text-center">
      <img src="@/assets/images/banner-inner-page.jpg" alt="" />
    </div>

    <div class="inner-page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav aria-label="breadcrumb" class="mt-3">
              <ol class="breadcrumb px-0">
                <li class="breadcrumb-item">
                  <router-link to="/my-account">{{ $t('myAccount') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('myPlaylist') }}</li>
              </ol>
            </nav>
            <div class="text-center">
              <h2>{{ $t('myPlaylist') }}</h2>
              <router-link to="/playshield-plus" class="btn btn-primary add-new-recording">
                <i class="icon-plus-circle" aria-hidden="true"></i>
                <h4>{{ $t('addRecording') }}</h4>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'custom-playlist',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
