<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="popup-play-shield">
    <div
      class="modal fade"
      id="popupplayshield"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="btn-close close popup"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>
            <div class="row m-0 align-items-center">
              <div class="col-md-6 p-0">
                <div class="shield-popup-image text-center">
                  <img :src="selectedPlayShield?.image" alt="" />
                </div>
              </div>
              <div class="col-md-6 p-0">
                <div class="shield-popup-content">
                  <div class="shield-plus">
                    <h2>{{ selectedPlayShield.name }}</h2>
                    <h4 class="highlight-heading" v-if="priceDetails[0]?.prices[0]">
                      {{ price(priceDetails[0]?.prices[0]) }}
                    </h4>
                    <p>{{ selectedPlayShield?.tagLine }}</p>
                    <hr />
                    <h5>{{ $t('introduction') }}:</h5>
                    <p>
                      {{ selectedPlayShield?.description }}
                    </p>
                  </div>

                  <div class="mt-4 pb-3">
                    <h5>Type: {{ selectedPlayShield?.name }}</h5>
                    <button
                      v-for="(shield, index) in playShieldList"
                      :key="index"
                      class="btn p-0 border-0"
                    >
                      <div
                        class="product-gray"
                        @click="selectedItem(shield, index)"
                        :class="{ active: currentIndex === index }"
                      >
                        <span class="product-color"></span>
                      </div>
                    </button>
                  </div>
                  <div v-if="selectedPlayShield?.name == 'PlayShield'">
                    <div v-if="quantity > 0" class="wave-btn-block">
                      <button
                        class="add-to-cart blob-btn fill-bg"
                        :class="{ added: isAdded }"
                        @click="addToCart(selectedPlayShield)"
                      >
                        <div class="default">
                          <i class="icon-cart" aria-hidden="true"></i> {{ $t('AddToCart') }}
                        </div>
                        <div class="success">{{ $t('added') }}</div>
                        <div class="cart">
                          <div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <div class="dots"></div>
                        <span class="blob-btn__inner">
                          <span class="blob-btn__blobs">
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                          </span>
                        </span>
                      </button>
                      <SvgButton />
                    </div>
                    <div v-else>
                      <button class="add-to-cart disabled" disabled>
                        <span class="default"
                          ><i class="icon-cart-close me-1" aria-hidden="true"></i> Out of
                          Stock</span
                        >
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div class="wave-btn-block mt-3">
                      <a href="/custom-playlist" class="blob-btn fill-bg add-to-cart"
                        ><i class="icon-cart" aria-hidden="true"></i> Customize now
                        <span class="blob-btn__inner">
                          <span class="blob-btn__blobs">
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                          </span>
                        </span>
                      </a>
                      <SvgButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';

import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';
import Loader from '@/components/commons/Loader.vue';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'popup-play-shield',
  props: [],
  components: {
    SvgButton,
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isActive = ref(false);
    const isActiveTwo = ref(true);
    const isAdded = ref(false);
    const show = ref(null);
    const currentIndex = ref(0);
    const priceDetails = ref({});
    const quantity = ref(0);
    const isLoading = ref(false);
    const langCode = localStorage.getItem('LangCode');

    // store.dispatch('fetchPlayShields');
    // isLoading.value = true;

    // newContentfulService.fetchPlayShieldPlus().then((res) => { console.log(res); });

    const getPrice = (sku) => {
      cLayerService.getShieldPrice(sku)
        .then((response) => {
          priceDetails.value = response;
          quantity.value = response[0]?.stock_items[0]?.quantity;
        })
        .catch((error) => {
        });
    };
    const selectedPlayShield = computed(() => store.getters.selectedPlayShield);
    const playShieldList = computed(() => store.getters.getPlayShieldList);

    const func = async () => {
      const lang = langCode;
      await store.dispatch('fetchPlayShields', lang)
        .then(async () => {
          await store.dispatch('selectPlayShield', playShieldList.value[0])
            .then(() => {
              isLoading.value = false;
            });

          getPrice(selectedPlayShield.value.sku);
        });
    };

    func();

    const selectedItem = (shield, index) => {
      currentIndex.value = index;
      store.dispatch('selectPlayShield', shield);
      getPrice(shield.sku);
    };

    const addToCart = (lineItem) => {
      const data = {
        itemDetails: {
          sku: lineItem.sku,
        },
      };
      isAdded.value = true;
      store.dispatch('updateLineItem', data)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        });
    };
    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    return {
      isActive,
      isActiveTwo,
      isAdded,
      show,
      playShieldList,
      selectedItem,
      currentIndex,
      priceDetails,
      quantity,
      addToCart,
      price,
      selectedPlayShield,
      isLoading,
    };
  },
};
</script>

<style scoped lang="scss"></style>
