<template lang="html">
  <section class="story-bg">
    <div class="footer-star-left">
      <div class="footer-star-left2">
        <div class="footer-left-top">
          <div class="footer-right-top">
            <!-- <div class="stroke-left-bottom"> -->
            <!-- -->
            <!-- <div class="img-top">
          <img src="@/assets/images/disney.png" alt="Disney logo" width="130" />
        </div> -->
            <!-- <div class="disney-details">
          <h6>
            ©Disney <br />
            ©Disney/Pixar
          </h6>
        </div> -->
            <div class="container">
              <div class="row py-5">
                <div class="col-md-10 text-center m-auto">
                  <h1 class="mb-4 footer-h1">SHARING FROM OUR STORYTELLER</h1>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-6"><MarketPlaceFooterCarousel /></div>
                <div class="col-md-6">
                  <div class="text-start">
                    <img src="@/assets/images/quotes1.png" alt="quotes1" />
                  </div>
                  <h4 class="mt-4 ms-5 ps-5">The easiest way for me to tell a story for KIDS.</h4>
                  <div class="text-end mt-4 mb-4">
                    <img src="@/assets/images/quotes2.png" alt="quotes2" />
                  </div>
                  <p>
                    Absolutely love this platform for me to create a story with the easiest way for
                    kids. I also tell the story to my kids and I really want to do the same to other
                    kids! I recommend this channel to everyone who want to tell their story! :)
                    &lt;3 Spread LOVE with StoryPhones!
                  </p>
                  <div class="mt-3 mb-3">
                    <img src="@/assets/images/MpFooter.png" alt="MpFooter" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import MarketPlaceFooterCarousel from '@/components/partials/MarketPlaceFooterCarousel.vue';
// import Sample from '@/components/partials/sample.vue';

export default {
  name: 'MarketPlaceFooter',
  props: [],
  components: {
    MarketPlaceFooterCarousel,
    // Sample,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    return {
    //   magicalWorld, langCode,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
