<template lang="html">
  <PageHeader />
  <section class="marketplace-my-playlist mt-stickybar">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 col-lg-7">
                <MarketplaceSearch />
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="wave-btn-block text-end">
                  <button class="blob-btn fill-bg">
                    Add Account
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>

            <div class="marketplace-profile-outerblock">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <MarketplaceAccountSidebar />
                </div>
                <div class="col-md-8 col-lg-8">
                  <h3>Payments</h3>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Story Name</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Payment Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Naughty Luttappi</td>
                        <td>abcxyz@gmail.com</td>
                        <td>10 $</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Naughty Luttappi</td>
                        <td>abcxyz@gmail.com</td>
                        <td>10 $</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Naughty Luttappi</td>
                        <td>abcxyz@gmail.com</td>
                        <td>10 $</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Naughty Luttappi</td>
                        <td>abcxyz@gmail.com</td>
                        <td>10 $</td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>Naughty Luttappi</td>
                        <td>abcxyz@gmail.com</td>
                        <td>10 $</td>
                      </tr>
                      <tr>
                        <td colspan="3"><h4 class="text-end m-0 py-2 me-3">Total</h4></td>
                        <td><h4 class="m-0 py-2">50 $</h4></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- marketplace-profile-outerblock -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import MarketplaceSearch from '@/components/partials/MarketplaceSearch.vue';
import MarketplaceAccountSidebar from '@/components/commons/MarketplaceAccountSidebar.vue';

export default {
  name: 'marketplace-payments',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    SvgButton,
    MarketplaceSearch,
    MarketplaceAccountSidebar,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
