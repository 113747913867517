<template lang="html">
  <div>
    <section class="library-storyshields" aria-label="library-storyshields">
      <h4>StoryShields</h4>
      <div v-if="storyShields" class="library-story-listing">
        <div
          v-if="storyShields?.items?.length > 0"
          class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4"
        >
          <div v-for="(story, index) in storyShields?.items" :key="index" class="col">
            <div class="card h-100 text-center">
              <div class="card-body p-0">
                <img :src="story?.playlist?.image" class="card-img-top" alt="" />
              </div>
              <div class="card-footer border-0 p-0">
                <h5 class="card-title m-0">{{ story?.playlist?.title }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert empty-cart" role="alert">
          <img src="@/assets/images/empty-cart.png" alt="Empty image" />
          <h2>
            {{ $t('oopsMessage', { text: 'StoryShields' }) }}
          </h2>
          <p>
            {{ $t('attachMessage', { text: 'StoryShield' }) }}
          </p>
          <div class="wave-btn-block">
            <button
              type="submit"
              class="blob-btn fill-bg btn-lg mt-3"
              @click="library"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {{ $t('shopNow') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <SvgButton />
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="product-details-popup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close close-blue-btn ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>

            <PopupUserShields
              v-if="selectedShieldDetails?.items?.length > 0 && this.selectedShieldSku"
              :shield-data="selectedShieldDetails?.items"
              :shield-sku="this.selectedShieldSku"
            />
            <div v-else><Loader /></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import PopupUserShields from '@/components/partials/PopupUserShields.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'LibraryStoryShields',
  props: [],
  components: {
    PopupUserShields,
    SvgButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    store.dispatch('fetchUserStoryShields');

    const storyShields = computed(() => store.getters.getUserStoryShields);

    const library = () => {
      router.push('/shields');
    };

    return {
      storyShields,
      library,
    };
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
