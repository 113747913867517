<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="cart-details mt-stickybar home pt-5">
    <div v-if="productDetails" class="container">
      <div class="row align-items-center">
        <div class="col-md-6 m-auto">
          <div v-if="productDetails.brand" class="text-center px-5">
            <img :src="getImage(productDetails)" alt="" class="w-100" />
            <div v-if="productDetails?.brand?.name" class="col-md-12 mt-4">
            <img :src="productDetails?.brand?.image" alt="" width="200" />
        </div>
          </div>
        </div>
        <div class="col-md-6">
          <h1>{{ productDetails.title }}</h1>
          <h4 v-if="priceDetails[0]?.prices[0]">
            {{ price(priceDetails[0]?.prices[0]) }}
            <!-- {{ priceDetails[0]?.prices[0]?.formatted_amount.slice(0, 1) }}
            {{ priceDetails[0]?.prices[0]?.amount_float }} -->
          </h4>
          <div v-if="productDetails.title">
            <p v-if="productName(productDetails?.title)" class="mt-3">
              {{ productDetails.description }}
            </p>
            <p v-else class="mt-3">Product Details</p>
          </div>

          <ol v-if="productDetails?.informations" class="pl-3">
            <li
              class="w-100 p-0 m-0"
              v-for="storyList in productDetails?.informations"
              :key="storyList.id"
            >
              {{ storyList }}
            </li>
          </ol>
          <div v-if="productDetails?.age">
            <p>&copy;{{productDetails?.brand?.name}}</p>
        </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 px-4 details-carousel mt-5">
      <h4>{{ $t('umaylike') }}</h4>
      <ShieldCarouselListing />
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import 'vue3-carousel/dist/carousel.css';
import { useCookies } from 'vue3-cookies';
import { ref, computed, reactive } from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import ShieldCarouselListing from '@/components/partials/ShieldCarouselListing.vue';
import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';

const { cookies } = useCookies({ secure: true });
const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();

export default {
  name: 'CartDetails',
  props: [],
  components: {
    ShieldCarouselListing,
    PageHeader,
    PageFooter,
    Loader,
  },

  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');
    const route = useRoute();
    const isAdded = ref(false);
    const sku = ref('');
    const isLoading = ref(false);

    sku.value = route.params.id;
    const Data = {
      sku: sku.value,
      locale: langCode,
    };
    isLoading.value = true;
    store.dispatch('fetchCartItemDetails', Data).then(() => {
      isLoading.value = false;
    });
    store.dispatch('fetchCartItemPrice', sku.value);

    const productDetails = computed(() => store.getters.cartItemDetails);
    const priceDetails = computed(() => store.getters.cartItemPrice);

    const getImage = (productDetail) => productDetail?.image;

    const productName = (name) => {
      if (name.includes('StoryPhones')) {
        return false;
      }
      return true;
    };
    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    return {
      productDetails, priceDetails, price, getImage, productName, isLoading,
    };
  },

};
</script>

<style scoped lang="scss"></style>
