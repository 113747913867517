/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getCMS = (state) => isObj(state, 'CMS', []);

export const getCMS1 = (state) => isObj(state, 'CMS1', []);

export const getCMS2 = (state) => isObj(state, 'CMS2', []);

export const getCMS3 = (state) => isObj(state, 'CMS3', []);

export const getCMS4 = (state) => isObj(state, 'CMS4', []);

export const getCMS5 = (state) => isObj(state, 'CMS5', []);

export const getCMS6 = (state) => isObj(state, 'CMS6', []);

export const getCMS7 = (state) => isObj(state, 'CMS7', []);

export const getCMS8 = (state) => isObj(state, 'CMS8', []);

export const getCMS9 = (state) => isObj(state, 'CMS9', []);

export const getCMS10 = (state) => isObj(state, 'CMS10', []);

export const getLangCode = (state) => isObj(state, 'LangCode', []);

export const getContactUs = (state) => isObj(state, 'contactUs', []);

export const getUserManualIos = (state) => isObj(state, 'userManualIos', []);

export const getUserManualAndroid = (state) => isObj(state, 'userManualAndroid', []);

export const getApp = (state) => isObj(state, 'app', []);
