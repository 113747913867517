import { useCookies } from 'vue3-cookies';
import CLayerBaseService from '@/services/CLayerBaseService';

const axios = require('axios');

const cLayerBaseService = new CLayerBaseService();

const { cookies } = useCookies({ secure: true });

export default class BaseService {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL;
    this.http = axios;
    this.guestToken = process.env.VUE_APP_GUEST_API_TOKEN;

    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== 'v1/auth/jwt/create/' && err.response) {
          if (
            err.response.status === 401
            && err.response.data.type === 'InvalidToken'
            // eslint-disable-next-line no-underscore-dangle
            && !originalConfig._retry
          ) {
            // eslint-disable-next-line no-underscore-dangle
            originalConfig._retry = true;
            try {
              const refreshToken = cookies.get('storyPhoneUserRefreshCookie');
              const rs = await axios.post(`${this.baseURL}v1/auth/jwt-verify/`, {
                refresh: refreshToken.refresh_token,
              });

              if (rs) {
                cLayerBaseService.refreshToken();
              }
              const accessToken = rs.data.access;
              cookies.set('storyPhoneUserAuthCookie', {
                access_token: accessToken,
              });
              cookies.set('storyPhoneUserRefreshCookie', {
                refresh_token: rs.data.refresh,
              });
              originalConfig.headers.Authorization = `Bearer ${this.getUserToken()}`;
              return axios.request(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      },
    );
  }

  refresh(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/jwt-verify/`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getUserToken = () => {
    const token = cookies.get('storyPhoneUserAuthCookie');
    return token.access_token;
  };
}
