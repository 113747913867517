import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';
import * as types from './mutation-types';

const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export const fetchUserDetails = ({ commit }) => new Promise(
  (resolve, reject) => {
    storyphoneService
      .getUserDetails()
      .then((response) => {
        commit(types.SET_USER_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserAddress = ({ commit }) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getAddress()
      .then((response) => {
        commit(types.SET_USER_ADDRESS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchArtWork = ({ commit }) => new Promise(
  (resolve, reject) => {
    storyphoneService
      .getArtWork()
      .then((response) => {
        commit(types.GET_ART_WORKS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchBackgroundColor = ({ commit }) => new Promise(
  (resolve, reject) => {
    newContentfulService
      .fetchBackgroundColours()
      .then((response) => {
        commit(types.GET_BACKGROUND_COLORS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchRecordingList = ({ commit }, item) => {
  commit(types.GET_RECORDING_LIST, item);
};

export const setArtWork = ({ commit }, item) => {
  commit(types.SET_ART_WORKS, item);
};

export const deleteArtWork = ({ commit }, item) => {
  commit(types.REMOVE_ART_WORKS, item);
};

export const setBackgroundColor = ({ commit }, item) => {
  commit(types.SET_BACKGROUND_COLORS, item);
};

export const deleteBackgroundColor = ({ commit }, item) => {
  commit(types.REMOVE_BACKGROUND_COLORS, item);
};

export const setvideoSrc = ({ commit }, src) => {
  commit(types.SET_VIDEO_SRC, src);
};

export const removeVideoSrc = ({ commit }, src) => {
  commit(types.REMOVE_VIDEO_SRC, src);
};

export const deleteRecording = ({ commit }, item) => {
  commit(types.DELETE_RECORDING, item);
};

export const searchTerm = ({ commit }, item) => {
  commit(types.SEARCH_TERM, item);
};

export const fetchCreativeStories = ({ commit }, value) => new Promise(
  (resolve, reject) => {
    storyphoneService
      .getCreativeStories(value.offset, value.term)
      .then((response) => {
        commit(types.GET_CREATIVE_STORIES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserStoryShields = ({ commit }) => new Promise(
  (resolve, reject) => {
    storyphoneService
      .getUserStoryShields()
      .then((response) => {
        commit(types.GET_USER_STORYSHIELDS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserPlayShields = ({ commit }) => new Promise(
  (resolve, reject) => {
    storyphoneService
      .getUserPlayShields()
      .then((response) => {
        commit(types.GET_USER_PLAYSHIELDS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchMarkets = ({ commit }) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getMarkets()
      .then((response) => {
        commit(types.GET_USER_MARKETS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const selectMarket = ({ commit }, market) => {
  commit(types.GET_SELECTED_MARKETS, market);
};

export const fetchLanguages = ({ commit }, values) => {
  commit(types.GET_LANGUAGES, values);
};

export const selectedRecordings = ({ commit }, values) => {
  commit(types.SELECTED_RECORDINGS, values);
};
