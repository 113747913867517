import * as types from './mutation-types';

const mutations = {
  [types.FIRST_STEP](state, value) {
    state.firstStep = value;
  },
  [types.SECOND_STEP](state, value) {
    state.secondStep = value;
  },
  [types.THIRD_STEP](state, value) {
    state.thirdStep = value;
  },
  [types.FOURTH_STEP](state, value) {
    state.fourthStep = value;
  },
  [types.FIFTH_STEP](state, value) {
    state.fifthStep = value;
  },
  [types.SIXTH_STEP](state, value) {
    state.sixthStep = value;
  },
};

export default mutations;
