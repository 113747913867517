<template lang="html">
  <section class="important-of-storytellig py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center heading-with-highlight" v-html="Details"></div>

          <!-- start storytelling-slider -->
          <div class="storytelling-slider mt-5 mb-4">
            <Carousel
              :settings="settings"
              :autoplay="2000"
              :wrap-around="true"
              :breakpoints="breakpoints"
              v-for="(widgetDetails, index) in homeCarousel.widgets"
              :key="index"
            >
              <Slide :key="index" v-for="(meta, index) in widgetDetails.details">
                <div
                  v-if="!meta.image"
                  class="carousel__item card h-100 slide-item pink-bg rounded-0"
                >
                  <div class="card-body">
                    <div class="quotation-mark-top text-start pb-4">
                      <img src="@/assets/images/quotation-mark-top.png" alt="" />
                    </div>
                    <div class="mb-3" v-html="domSanitize(meta.title)"></div>
                    <div v-html="domSanitize(meta.description)"></div>
                    <div class="quotation-mark-top text-end pt-4">
                      <img src="@/assets/images/quotation-mark-btm.png" alt="" />
                    </div>
                  </div>
                </div>
                <div v-else class="carousel__item card h-100 slide-item rounded-0">
                  <div class="card-body p-0">
                    <div class="pic">
                      <img :src="meta.image" alt="" />
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
          <!-- End of storytelling-slider -->
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import {
  Carousel, Navigation, Slide,
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import DOMPurify from 'dompurify';

export default {
  name: 'ImportantOfStorytelling',
  props: [],
  name2: 'Breakpoints',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_carousel_${langCode}`,
      // slug: 'homecarouselen',
    };
    store.dispatch('fetchCMS10', Data);

    const homeCarousel = computed(() => store.getters.getCMS10);
    const Details = computed(() => DOMPurify.sanitize(homeCarousel.value.html_description));
    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      homeCarousel, langCode, Details, domSanitize,
    };
  },
  mounted() {
  },
  data() {
    return {
      storyTellingImportant: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        pauseAutoplayOnHover: true,
      },
      breakpoints: {
      // 700px and up
        768: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        992: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  methods: {
  },
  computed: {
  },
};
</script>
<style scoped lang="scss"></style>
