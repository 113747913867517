/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const isHideShipping = (state) => isObj(state, 'hideShipping', true);

export const isShowBilling = (state) => isObj(state, 'showBilling', true);

export const isShowPreview = (state) => isObj(state, 'showPreview', true);

export const isShowPaynow = (state) => isObj(state, 'showPaynow', true);

export const isButtonHide = (state) => isObj(state, 'hideButton', true);

export const getShippingAddress = (state) => isObj(state, 'shippingAddress', {});

export const getBillingAddress = (state) => isObj(state, 'billingAddress', {});

export const getPaymentSource = (state) => isObj(state, 'paymentSource', {});

export const hideButton = (state) => isObj(state, 'hideCartButton', {});

export const shippingMessage = (state) => isObj(state, 'successMessage', '');
