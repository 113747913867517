import * as types from './mutation-types';

const mutations = {
  [types.SET_SC_CHANNEL_TOKEN](state, scChannelToken) {
    state.scChannelToken = scChannelToken;
  },
  [types.SET_PRODUCT_DETAILS](state, productDetails) {
    state.productDetails = productDetails;
  },

  [types.GET_CART_ITEMS](state, cartItems) {
    state.cartItems = { ...state.cartItems, cartItems };
  },
  [types.SET_SHIPPING_METHOD](state, shippingMethod) {
    state.shippingMethods = { ...state.shippingMethods, shippingMethod };
  },
  [types.DELIVERY_LEAD_TIME](state, deliveryLeadTime) {
    state.deliveryLeadTime = { ...state.deliveryLeadTime, deliveryLeadTime };
  },
  [types.GET_COUNTRIES](state, countryList) {
    state.countryList = { ...state.countryList, countryList };
  },
  [types.GET_STATES](state, stateList) {
    state.stateList = { ...state.stateList, stateList };
  },
  [types.GET_ORDERS](state, orders) {
    state.orders = { ...state.orders, orders };
  },
  [types.GET_OUT_OF_STOCK_MESSAGE](state, value) {
    state.outOfStockMessage = value;
    setTimeout(() => {
      state.outOfStockMessage = '';
    }, 3000);
  },
};

export default mutations;
