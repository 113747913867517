<template>
  <div v-if="getProductCategory(cartData.product_category)" class="order-pic">
    <a @click="shieldClick(cartData)" class="pic">
      <img :src="getImage(cartData)" alt="" />
    </a>
  </div>
  <div v-else class="order-pic">
    <img :src="getImage(cartData)" alt="" />
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import CLayerService from '@/services/CLayerService';

export default {
  name: 'WishlistImage',
  props: [
    'cartData',
  ],
  setup(props) {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const router = useRouter();
    const productDetails = ref({});
    const category = ref(['storyshield']);
    // contentfulService.getEntityList('products', 100, props.cartData.sku)
    //   .then((response) => {
    //     productDetails.value = response.items[0].fields;
    //   })
    //   .catch((error) => {
    //     console.log('error');
    //   });

    const getImage = (details) => details?.image_url;

    const getProductCategory = (name) => {
      // eslint-disable-next-line no-constant-condition
      if (category.value.includes(name)) {
        return true;
      }
      return false;
    };

    const shieldClick = (data) => {
      const Data = {
        id: data.sku,
        locale: langCode,
      };
      store.dispatch('fetchCartItemDetails', Data);
      store.dispatch('fetchCartItemPrice', data.sku);
      router.push({ name: 'cart-details', params: { id: data.sku } });
    };

    return {
      productDetails, getImage, getProductCategory, shieldClick,
    };
  },
};
</script>
