<template>
  <div class="d-block">
    <div v-if="this.orderDetails" class="row align-items-center">
      <div class="col-md-3 col-lg-2">
        <div class="order-pic">
          <img :src="getImage(this.orderDetails)" alt="" />
        </div>
      </div>
      <div class="col-md-9 ol-lg-10 m-auto">
        <div class="row mb-3">
          <div class="col-8">
            <h6>{{ this.orderDetails.name }}</h6>
          </div>
          <div class="col-4">
            <h5 class="text-end">
              <span>
                {{ this.orderDetails.formatted_total_amount.slice(0, 1) }}
                {{ this.orderDetails.total_amount_float }}
              </span>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-8"></div>
          <div class="col-8"></div>
          <div class="col-4">
            <p class="text-end">{{ $t('quantity') }}: {{ this.orderDetails.quantity }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">

import Loader from '@/components/commons/Loader.vue';

export default {
  name: 'OrderHistoryItem',
  props: ['orderDetails'],
  components: {
  },
  setup() {
    const getImage = (order) => order.image_url;
    return { getImage };
  },
};
</script>

<style scoped lang="scss"></style>
