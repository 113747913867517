<template lang="html">
  <PageHeader />
  <ProductOfDisney />
  <section class="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="disney-logo-outer">
            <div class="disney-logo right-logo">
              <img src="@/assets/images/disney-white.png" alt="Disney logo" />
            </div>
            <div class="disney-details right-text">
              <h6>
                ©Disney <br />
                ©Disney/Pixar
              </h6>
            </div>
            <img src="@/assets/images/disney-img.png" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row justify-content-center align-items-center mt-s-3">
        <div class="col-md-6" v-for="(image, index) in screenFree.images" :key="index">
          <!-- <img src="@/assets/images/audio-img.jpg" alt="" /> -->
          <img :src="image.image_url" alt="" />
        </div>
        <div class="col-md-6 py-sm-8">
          <div class="row">
            <div class="col-md-11">
              <!-- <h2 class="mb-4 font-400">{{ screenFree.title }}</h2>
              <p>{{ screenFree.description }}</p> -->
              <div v-html="domSanitize(screenFree.html_description)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-secondary">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 py-sm-8 order-2 order-lg-1 order-sm-1">
          <div class="row">
            <div class="col-md-11">
              <!-- <h1 class="mb-4 font-400">
                {{ generations.title }}
              </h1>
              <p>
                {{ generations.description }}
              </p> -->
              <div v-html="domSanitize(generations.html_description)"></div>

            </div>
          </div>
        </div>
        <div
          class="col-md-6 text-right order-1 order-lg-2 order-sm-2"
          v-for="(item, index) in generations.images"
          :key="index"
        >
          <!-- <img src="@/assets/images/image2.jpg" alt="" /> -->
          <img :src="item.image_url" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row justify-content-center align-items-center mt-s-3">
        <div class="col-md-6" v-for="(item, index) in extremelyUse.images" :key="index">
          <!-- <img src="@/assets/images/image1.jpg" alt="" /> -->
          <img :src="item.image_url" alt="" />
        </div>
        <div class="col-md-6 py-sm-8">
          <div class="row">
            <div class="col-md-11">
              <!-- <h2 class="mb-4 font-400">
                {{ extremelyUse.title }}
              </h2>
              <p>
                {{ extremelyUse.description }}
              </p> -->
              <div v-html="domSanitize(extremelyUse.html_description)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <StoriesCarousel />
  <!-- <FAQuestions class="py-5" /> -->
  <section>
    <div class="disney-logo-outer">
      <div class="disney-logo">
        <img src="@/assets/images/disney.png" alt="Disney logo" />
      </div>
      <div class="disney-details">
        <h6>
          ©Disney <br />
          ©Disney/Pixar
        </h6>
      </div>
      <img src="@/assets/images/headphones-shields-home-large.png" alt="" class="w-100" />
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import StoriesCarousel from '@/components/partials/StoriesCarousel.vue';
import ProductOfDisney from '@/components/partials/ProductOfDisney.vue';
// import FAQuestions from '@/components/partials/FAQuestions.vue';

export default {
  name: 'Disney',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    StoriesCarousel,
    // FAQuestions,
    ProductOfDisney,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_dis_screen_free_${langCode}`,
    };
    store.dispatch('fetchCMS', Data);

    const Data1 = {
      code: langCode,
      slug: `home_dis_sp_story_gen_${langCode}`,
    };
    store.dispatch('fetchCMS1', Data1);

    const Data2 = {
      code: langCode,
      slug: `home_dis_sp_extremely_use_${langCode}`,
    };
    store.dispatch('fetchCMS2', Data2);

    const screenFree = computed(() => store.getters.getCMS);
    const generations = computed(() => store.getters.getCMS1);
    const extremelyUse = computed(() => store.getters.getCMS2);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      screenFree, langCode, generations, extremelyUse, domSanitize,
    };
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
