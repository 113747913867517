<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="recording mt-stickybar home" aria-label="recording">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="grey-outer-block">
              <div class="recording-block">
                <div class="col-md-8 m-auto">
                  <div class="text-center">
                    <h5>{{ $t('pressRecord') }}</h5>
                    <h4>“{{ $t('onceUpOn') }}”</h4>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('enterStory')"
                      v-model="storyName"
                      class="form-control"
                    />
                    <span class="text-danger pl-3" v-if="v$.storyName.$error">
                      {{ v$.storyName.$errors[0].$message }}*
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="recording-outer text-center white-box w-100 mt-3">
                      <button
                        class="record-btn p-0 border-0 bg-white record-only"
                        :class="{ hiderecord: recordHide, showrecord: hideStop }"
                        @click="recordAudio()"
                      >
                        <span><i class="icon-microphone" aria-hidden="true"></i></span>
                        {{ $t('record') }}
                      </button>
                      <button
                        class="record-btn p-0 border-0 bg-white pause-btn"
                        :class="{
                          showpause: recordHide,
                          hidepause: pauseHide,
                        }"
                        @click="pauseRecording()"
                      >
                        <span
                          ><i
                            class="icon-pause2 animate__animated animate__jackInTheBox"
                            aria-hidden="true"
                          ></i></span
                        >{{ $t('pause') }}
                      </button>
                      <button
                        class="record-btn p-0 border-0 bg-white resume-btn"
                        :class="{ showresume: pauseHide, hideresume: hideStop }"
                        @click="resumeRecording()"
                      >
                        <span
                          ><i
                            class="icon-microphone animate__animated animate__jackInTheBox"
                            aria-hidden="true"
                          ></i></span
                        >{{ $t('resume') }}
                      </button>
                      <div class="audio-main d-flex justify-content-center mt-4">
                        <div v-show="timer">
                          <span class="audioTimer d-none" ref="audioTimer"></span>
                        </div>
                        <div class="time-box">
                          <span>{{ hours }}</span> <span>{{ minutes }}</span>
                          <span>{{ seconds }}</span>
                        </div>
                        <button
                          @click="stopRecordAudio()"
                          class="stop-btn border-0 d-none"
                          :class="{ showstop: recordHide, hidestop: hideStop }"
                        ></button>
                        <div
                          v-show="audioFile && !recordAudioState"
                          ref="audio"
                          class="audio audioStyle"
                        >
                          <audio controls="controls" class="audioStyle"></audio>
                        </div>
                      </div>
                      <div
                        class="cart-success-block text-center mt-4 d-none"
                        :class="{ saveactive: showSave }"
                      >
                        <button
                          class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight"
                          :class="{ hidesave: isAdded }"
                          @click="saveAudio()"
                        >
                          {{ $t('save') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                          <SvgButton />
                        </button>

                        <button
                          class="blob-btn fill-bg btn-lg ms-2 animate__animated animate__fadeInRight"
                          :class="{ hidecancel: isAdded }"
                          @click="cancelAudio()"
                        >
                          {{ $t('cancel') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                          <SvgButton />
                        </button>
                        <button
                          class="btn btn-primary btn-lg d-none animate__animated animate__fadeInRight"
                          :class="{ addsuccess: isAdded }"
                        >
                          <i class="icon-checklist" aria-hidden="true"></i>
                          {{ $t('saved') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import {
  required, minLength, helpers, sameAs,
} from '@vuelidate/validators';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const storyphoneService = new StoryphoneService();

export default {
  name: 'guest-recording',
  props: {
    timer: {
      type: Boolean,
      default: true,
    },
    timerColor: {
      type: String,
      default: '#000',
    },
    timerFontSize: {
      type: Number,
      default: 1,
    },
    timerBackground: {
      type: String,
      default: '#ccc',
    },
    audioWidth: {
      type: Number,
      default: 400,
    },
  },
  components: {
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },

  data() {
    return {
      v$: useVuelidate(),
      permissionStatus: null,
      recordHide: false,
      pauseHide: false,
      resumeHide: false,
      recorder: null,
      audioIsPlaying: false,
      audioFile: null,
      audioType: 'audio/x-wav',
      audioFileName: 'audio.wav',
      uploadedAudioFile: null,
      animateFrame: 0,
      nowTime: 0,
      diffTime: 0,
      startTime: 0,
      isRunning: false,
      hideStop: false,
      storyName: '',
      isAdded: false,
      showSave: false,
      isLoading: false,
      SvgButton,
      isVerified: false,
      saved: false,
      token: '',
    };
  },
  methods: {
    async checkPermission() {
      const status = await navigator.permissions.query({ name: 'microphone' });
      this.permissionStatus = status.state;
    },
    clearAudio() {
      const TIMER = this.$refs.audioTimer;
      this.initialTime = Date.now();
      TIMER.innerText = '';
    },
    checkAudioTime() {
      const TIMER = this.$refs.audioTimer;
      const timeDifference = Date.now() - this.initialTime;
      const formatted = this.convertAudioTime(timeDifference);
      TIMER.innerHTML = `${formatted}`;
    },
    convertAudioTime(miliseconds) {
      const totalSeconds = Math.floor(miliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds - minutes * 60;
      const sec = seconds < 10 ? `0${seconds}` : seconds;
      return `${minutes}:${sec}`;
    },
    recordAudio() {
      this.v$.storyName.$touch();
      if (!this.v$.storyName.$invalid) {
        this.showSave = false;
        this.clearAudio();
        try {
          const device = navigator.mediaDevices.getUserMedia({ audio: true });
          const items = [];
          device
            .then((stream) => {
              this.recorder = new MediaRecorder(stream);
              this.recorder.ondataavailable = (e) => {
                items.push(e.data);
                if (this.recorder.state === 'inactive' || this.recorder.state === 'paused') {
                  const blob = new Blob(items, { type: this.audioType });
                  this.audioFile = URL.createObjectURL(blob);
                  this.uploadedAudioFile = blob;
                  const { audio } = this.$refs;
                  audio.innerHTML = '';
                  const mainaudio = document.createElement('audio');
                  mainaudio.setAttribute('controls', 'controls');
                  mainaudio.setAttribute('style', `width: ${this.audioWidth}px`);
                  audio.appendChild(mainaudio);
                  // eslint-disable-next-line max-len
                  mainaudio.innerHTML = `<source src="${this.audioFile}" type="${this.audioType}" />`;
                  mainaudio.onplay = () => {
                    this.audioIsPlaying = true;
                  };
                  mainaudio.onpause = () => {
                    this.audioIsPlaying = false;
                  };
                  mainaudio.onended = () => {
                    this.audioIsPlaying = false;
                  };
                }
              };
              this.recordAudioState = true;
              this.audioInterval = setInterval(this.checkAudioTime, 500);
              this.recordHide = true;
              this.hideStop = false;
              this.pauseHide = false;
              this.recorder.start();
              this.startTimer();
            })
            .catch((err) => {
            // eslint-disable-next-line no-alert
              alert(err);
              // eslint-disable-next-line no-console
            });
        } catch (err) {
        // eslint-disable-next-line no-alert
          alert(`Audio error:  ${err}`);
          // eslint-disable-next-line no-console
          console.error('Audio error: ', err);
        }
      } else {
        console.log('');
      }
    },

    pauseRecording() {
      this.pauseHide = true;
      this.resumeHide = false;
      this.recorder.requestData();
      this.recorder.pause();
      this.stopTimer();
      this.recordAudioState = false;
    },

    resumeRecording() {
      this.resumeHide = true;
      this.pauseHide = false;
      this.recorder.resume();
      this.startTimer();
      this.recordAudioState = true;
    },
    stopRecordAudio() {
      clearInterval(this.audioInterval);
      this.recorder.stop();
      this.clearAll();
      this.recordAudioState = false;
      this.hideStop = true;
      this.pauseHide = true;
      this.showSave = true;
    },
    deleteAudioFile() {
      if (this.recordAudioState === false) {
        this.audioFile = null;
        this.uploadedAudioFile = null;
      }
    },
    setSubtractStartTime(times) {
      const time = typeof times !== 'undefined' ? times : 0;
      this.startTime = Math.floor(performance.now() - time);
    },
    startTimer() {
      const vm = this;
      vm.setSubtractStartTime(vm.diffTime);
      (function loop() {
        vm.nowTime = Math.floor(performance.now());
        vm.diffTime = vm.nowTime - vm.startTime;
        vm.animateFrame = requestAnimationFrame(loop);
      }());
      vm.isRunning = true;
    },
    stopTimer() {
      this.isRunning = false;
      cancelAnimationFrame(this.animateFrame);
    },
    saveAudio() {
      this.isLoading = true;
      this.token = this.$route.params.token;
      const file = new File([this.uploadedAudioFile], `${this.storyName}.wav`);
      const formData = new FormData();
      formData.append('title', this.storyName);
      formData.append('file', file);
      formData.append('type', 'playshield-story');
      this.saved = true;
      this.isAdded = true;
      storyphoneService.storyUpload(formData, this.token).then((story) => {
        this.isLoading = false;
        setTimeout(() => {
          this.isAdded = false;
          this.showSave = false;
          this.storyName = '';
          this.audioFile = null;
          this.v$.$reset();
        }, 2000);
      });
    },

    cancelAudio() {
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
    },
    clearAll() {
      this.startTime = 0;
      this.nowTime = 0;
      this.diffTime = 0;
      this.times = [];
      this.stopTimer();
      this.animateFrame = 0;
    },
    gotToList() {
      if (this.audioFile) {
        if (this.saved) {
          this.recordAudioState = true;
          this.storyName = '';
          this.v$.$reset();
          this.showSave = false;
          this.audioFile = null;
          this.isShowRecordingAction(false);
          this.isShowFilesAction(true);
          this.inSecondStepAction(true);
          this.saved = false;
        } else {
          this.isVerified = true;
        }
      } else {
        this.isShowRecordingAction(false);
        this.isShowFilesAction(true);
        this.saved = false;
        this.inSecondStepAction(true);
      }
    },
    recordingBack() {
      this.recordAudioState = true;
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
      this.isShowRecordingAction(false);
      this.isShowUploadRecordAction(true);
    },
    toTime(seconds) {
      const date = new Date(null);
      date.setSeconds(7200 - seconds);
      return date.toISOString().substr(11, 8);
    },
    closeVerfied() {
      this.isVerified = false;
    },
    ...mapActions({
      recordingListAction: 'fetchRecordingList',
      isShowRecordingAction: 'isShowRecording',
      isShowUploadRecordAction: 'isShowUploadRecord',
      isShowFilesAction: 'isShowFiles',
      inSecondStepAction: 'inSecondStep',
    }),
  },

  validations() {
    return {
      storyName: {
        required: helpers.withMessage('Enter A Story Name', required),
      },
    };
  },

  computed: {
    ...mapGetters({
      duration: 'listDuration',
      showRecording: 'isShowRecording',
    }),
    hours() {
      return Math.floor(this.diffTime / 1000 / 60 / 60);
    },

    minutes() {
      return Math.floor(this.diffTime / 1000 / 60) % 60;
    },
    seconds() {
      return Math.floor(this.diffTime / 1000) % 60;
    },
    milliSeconds() {
      return Math.floor(this.diffTime % 1000);
    },
  },
};
</script>

<style scoped lang="scss"></style>
