<template lang="html">
  <PageHeader />
  <section class="account-settings order-history mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('orderHistory') }}</h3>
              <div v-if="orderHistory.length > 0" class="inner-box">
                <div v-for="order in orderHistory" :key="order.id" class="order-single-block">
                  <OrderHistoryList :orderDetails="order" />
                </div>
              </div>
              <div v-else class="alert empty-cart" role="alert">
                <img src="@/assets/images/empty-cart.png" alt="Empty image" />
                <!-- <h2>Oops! No Orders Yet!</h2> -->
                <h2>{{ $t('oopsNo') }}</h2>
                <div class="wave-btn-block">
                  <button type="submit" class="blob-btn fill-bg btn-lg" @click="library()">
                    {{ $t('shopNow') }}
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';
import OrderHistoryList from '@/components/partials/OrderHistoryList.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const { cookies } = useCookies({ secure: true });

export default {
  name: 'order-history',
  props: [],
  components: {
    AccountSidebar,
    OrderHistoryList,
    SvgButton,
    PageHeader,
    PageFooter,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const id = cookies.get('clUserOwnerId');
    // store.dispatch('fetchOrderHistory', id.owner_id);
    const library = () => {
      router.push('/shields');
    };

    const orderHistory = computed(() => store.getters.getOrderHistory);

    return { library, orderHistory };
  },

};
</script>

<style scoped lang="scss"></style>
