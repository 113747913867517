/* eslint-disable import/extensions */
import { createStore } from 'vuex';
import product from './product';
import authentication from './authentication';
import cart from './cart';
import user from './user';
import checkout from './checkout';
import playshieldPlus from './playshieldPlus';
import wizard from './wizard';
import cms from './cms';
import worldMap from './worldMap';

const store = createStore({
  modules: {
    product,
    authentication,
    cart,
    user,
    checkout,
    playshieldPlus,
    wizard,
    cms,
    worldMap,
  },
});

export default store;
