<template lang="html">
  <section class="marketplace-icons-component">
    <div class="marketplace-icons d-flex justify-content-end">
      <button
        class="marketplace-btn border-0"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Tooltip on top"
      >
        <i class="icon-like" aria-hidden="true"></i>
      </button>
      <button class="marketplace-btn border-0 mx-3">
        <i class="icon-filter2" aria-hidden="true"></i>
      </button>
      <a href="/marketplace-login" class="marketplace-btn border-0">
        <i class="icon-content-creator" aria-hidden="true"></i>
      </a>
    </div>
  </section>
</template>

<script lang="js">
import { Tooltip } from 'bootstrap';
// import $ from 'jquery';

export default {
  name: 'marketplace-icons',
  props: [],
  mounted() {
    // $('[data-bs-toggle="tooltip"]').tooltip();
  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
