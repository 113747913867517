import * as types from './mutation-types';

const mutations = {
  [types.SET_USER_DETAILS](state, userDetails) {
    state.userDetails = userDetails;
  },
  [types.SET_USER_ADDRESS](state, userAddress) {
    state.userAddress = userAddress;
  },
  [types.GET_ART_WORKS](state, arkWorks) {
    state.arkWorks = arkWorks;
  },
  [types.GET_BACKGROUND_COLORS](state, backgroundColors) {
    state.backgroundColors = backgroundColors;
  },
  [types.GET_RECORDING_LIST](state, recordingList) {
    state.recordingList = [...state.recordingList, recordingList];
  },
  [types.DELETE_RECORDING](state, deleteRecording) {
    state.recordingList.forEach((element, index) => {
      if (element.name === deleteRecording) {
        state.recordingList.splice(index, 1);
      }
    });
  },
  [types.SET_ART_WORKS](state, selectedArtwork) {
    state.selectedArtwork = selectedArtwork;
  },
  [types.SET_BACKGROUND_COLORS](state, selectedColor) {
    state.selectedColor = selectedColor;
  },
  [types.REMOVE_ART_WORKS](state, selectedArtwork) {
    state.selectedArtwork = '';
  },
  [types.REMOVE_BACKGROUND_COLORS](state, selectedColor) {
    state.selectedColor = '';
  },
  [types.SET_VIDEO_SRC](state, videoSrc) {
    state.videoSrc = videoSrc;
  },
  [types.REMOVE_VIDEO_SRC](state, videoSrc) {
    state.videoSrc = '';
  },
  [types.SEARCH_TERM](state, term) {
    state.searchVariable = term;
  },
  [types.GET_CREATIVE_STORIES](state, value) {
    state.creativeStories = value;
  },
  [types.GET_USER_STORYSHIELDS](state, value) {
    state.userStoryShields = value;
  },
  [types.GET_USER_PLAYSHIELDS](state, value) {
    state.userPlayShields = value;
  },
  [types.GET_USER_MARKETS](state, value) {
    state.userMarkets = value;
  },
  [types.GET_SELECTED_MARKETS](state, value) {
    state.selectedMarket = value;
  },
  [types.GET_LANGUAGES](state, value) {
    state.languages = value;
  },
};

export default mutations;
