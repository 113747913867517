export const CONTENT_MANAGEMENT_SYSTEM = 'CONTENT_MANAGEMENT_SYSTEM';
export const CONTENT_MANAGEMENT_SYSTEM1 = 'CONTENT_MANAGEMENT_SYSTEM1';
export const CONTENT_MANAGEMENT_SYSTEM2 = 'CONTENT_MANAGEMENT_SYSTEM2';
export const CONTENT_MANAGEMENT_SYSTEM3 = 'CONTENT_MANAGEMENT_SYSTEM3';
export const CONTENT_MANAGEMENT_SYSTEM4 = 'CONTENT_MANAGEMENT_SYSTEM4';
export const CONTENT_MANAGEMENT_SYSTEM5 = 'CONTENT_MANAGEMENT_SYSTEM5';
export const CONTENT_MANAGEMENT_SYSTEM6 = 'CONTENT_MANAGEMENT_SYSTEM6';
export const CONTENT_MANAGEMENT_SYSTEM7 = 'CONTENT_MANAGEMENT_SYSTEM7';
export const CONTENT_MANAGEMENT_SYSTEM8 = 'CONTENT_MANAGEMENT_SYSTEM8';
export const CONTENT_MANAGEMENT_SYSTEM9 = 'CONTENT_MANAGEMENT_SYSTEM9';
export const CONTENT_MANAGEMENT_SYSTEM10 = 'CONTENT_MANAGEMENT_SYSTEM10';
export const LANG_CODE = 'LANG_CODE';
export const CONTACT_US = 'CONTACT_US';
export const USER_MANUAL_IOS = 'USER_MANUAL_IOS';
export const USER_MANUAL_ANDROID = 'USER_MANUAL_ANDROID';
export const APP = 'APP';
