<template lang="html">
  <section id="card-container">
    <div class="container">
      <ul class="grid-container list-unstyled text-center">
        <li class="list1" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/amari.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="amari-img"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/white-Star.svg" alt="white Star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Amari and the Night Brothers</h6>
            <h6 class="text-dark text-center">By: <span class="colour">B.B Alston</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>

        <li class="list2" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/lastfallenmoon.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="fallenmoon"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">The Last Fallen Moon</h6>
            <h6 class="text-dark text-center">By: <span class="colour">B. B. Alston</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list3" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/lionofmars.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="lionofmars"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">The Lion of Mars</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Jennifer L. Holm</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list4" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/stella.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="stella"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/white-Star.svg" alt="white Star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Stella</h6>
            <h6 class="text-dark text-center">By: <span class="colour">McCall Hoyle</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list5" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/cecerids.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="cecerids-img"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Story title</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Storyteller name</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list6" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/john.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="SecretGlendunny"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">The Secret of Glendunny</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Kathryn Lasky</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list7" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/bettylem.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="bettylem"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/white-Star.svg" alt="white star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">A World of Curiosities</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Louise Penny</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list8" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>

          <img
            src="@/assets/images/marketplace/david.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="WrinkleTime"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/white-Star.svg" alt="white Star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">A Wrinkle in Time</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Madeleine L'Engle</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list9" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/dragon.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="dragon-img"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow Star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">The Dragon's Toothache</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Annie Besant</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list10" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/bedtimestory.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="bedtimestory"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/white-Star.svg" alt="white Star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Bedtime Stories for Kids</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Mamalla Noble</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list11" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/meditation.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="lionofmars"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Bedtime Meditation Stories for Kids</h6>
            <h6 class="text-dark text-center">By: <span class="colour">Daisy Relaxing</span></h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>
        <li class="list12" data-bs-toggle="modal" data-bs-target="#product-toggle-popup">
          <div class="round-add-button">
            <button class="button bg-info float-end me-3">
              <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
            </button>
          </div>
          <img
            src="@/assets/images/marketplace/kidssleep.png"
            class="rounded-circle mx-auto d-block mt-5 mb-2"
            alt="kidssleep"
          />
          <div class="card-body">
            <ul class="d-flex justify-content-center mb-2 list-unstyled">
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
              <li>
                <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
              </li>
            </ul>
            <h6 class="text-dark text-center">Kids Sleep Meditations</h6>
            <h6 class="text-dark text-center">
              By: <span class="colour">Mindfulness Habits Team</span>
            </h6>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <div class="round-add-button">
                <button class="button p-0 me-3">
                  <span><i class="icon-cart" aria-hidden="true"></i></span>
                  <div class="cart">
                    <svg viewBox="0 0 36 26">
                      <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                      <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                    </svg>
                  </div>
                </button>
              </div>
              <p class="text-dark mt-2">€14,99</p>
            </div>
          </div>
        </li>

        <!--  -->
      </ul>
    </div>
  </section>

  <section id="arrow-container">
    <div class="container" id="prev">
      <div class="carousel__track">
        <div class="carousel-viewport mx-auto d-block">
          <button type="button" class="btn btn-primary rounded-circle m-1">
            <img src="@/assets/images/marketplace/left-arrow.svg" alt="leftarrow-carousel" />
          </button>
          <button type="button" class="btn btn-primary rounded-circle m-1">
            <img src="@/assets/images/marketplace/right-arrow.svg" alt="rightarrow-carousel" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'AuthorCards',

};
</script>
