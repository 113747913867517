<template>
  <section class="marketplace-search">
    <div class="container">
      <div class="marketplace-items d-flex px-4">
        <button class="btn">
          <i class="icon-search" aria-hidden="true"></i>
        </button>
        <input placeholder="Search Story or Storyteller here" type="text" name="" id="" />
        <a href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button">
          <img src="@/assets/images/MpFilter.png" alt="" />
        </a>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade d-end"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="modal-dialog float-end">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title d-block mx-auto" id="staticBackdropLabel">Filters</h3>
            <button
              type="button"
              class="btn filter-btn rounded-circle"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <a class="dropdown-item" href="#">
                <div class="row">
                  <div class="col-md-8 mt-1 px-4"><h6>Applied Filters</h6></div>
                  <div class="col-md-2 float-end">
                    <p href="#" class="text-danger px-4">Clear all</p>
                  </div>
                </div>

                <button type="button" class="btn bh rounded-5 mx-2">
                  <img class="mx-2" src="@/assets/images/marketplace/mark.svg" alt="mark" />
                  Fairy Tales, Folk Tales & Myths
                </button></a
              >
            </div>

            <!--  -->
            <div class="row">
              <div class="accordion accordion-flush w-100 product-accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingone">
                    <button
                      class="accordion-button collapsed px-3 sh"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseone"
                      aria-expanded="false"
                      aria-controls="collapseone"
                    >
                      Sorting
                    </button>
                  </h2>
                  <div
                    id="collapseone"
                    class="accordion-collapse collapse sh"
                    aria-labelledby="headingone"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body px-3">
                      <ul class="ms-4 list-unstyled">
                        <li>Featured</li>
                        <li>Newest</li>
                        <li>Best Selling</li>
                        <li>Audio Length: Shortest to longest</li>
                        <li>Audio Length: Longest to shortest</li>
                      </ul>
                    </div>
                  </div>
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed px-3 sh"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Age
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse sh"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body px-3">
                      <ul class="ms-4 list-unstyled">
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1">0-5</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1">5-8</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1">8-12+</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed px-3 sh"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      CATEGORIES
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse sh"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body px-3">
                      <ul class="ms-4 list-unstyled">
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1"
                              >Action & Adventure</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme2"
                              checked
                            />
                            <label class="form-check-label" for="Checkme2"
                              >Education & Learning</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Fairy Tales, Folk Tales & Myths</label
                            >
                          </div>
                        </li>

                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme4"
                              checked
                            />
                            <label class="form-check-label" for="Checkme4"
                              >Geography & Cultures</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Growing Up & Facts of Life</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3">History</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Holidays & Celebrations</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Literature & Fiction</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Music & Performing Arts</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Mystery & Suspense</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Science & Technology</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme3"
                            />
                            <label class="form-check-label" for="Checkme3"
                              >Science Fiction & Fantasy</label
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed px-3 sh"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      CONTENT
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse sh"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body px-3">
                      <ul class="ms-4 list-unstyled">
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1">Story</label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="Checkme1"
                            />
                            <label class="form-check-label" for="Checkme1">Storyteller</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <div
              class="cart-slide offcanvas offcanvas-end w-25"
              tabindex="-1"
              id="filter-popup"
              aria-labelledby="offcanvasRightLabel"
            >
              <ul>
                <button
                  type="button"
                  class="btn-close close-blue-btn ms-auto float-end"
                  data-bs-dismiss="filter-popup"
                  aria-label="Close"
                >
                  <i class="icon-close" aria-hidden="true"></i>
                </button>
                <h2 class="text-center pt-5">Filters</h2>

              </ul>

            </div> -->
            <button type="submit" class="blob-btn fill-bg mx-auto">
              <b>Apply Filters</b>
              <span class="blob-btn__inner"
                ><span class="blob-btn__blobs"
                  ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                  ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span></span
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </section>
</template>

<script>
export default {
  name: 'marketplace-search',
};
</script>

<style></style>
