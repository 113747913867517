<template lang="html">
  <PageHeader />

  <div v-if="isLoading"><Loader /></div>
  <section class="account-settings add-new-address mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('personalInformation') }}</h3>
              <div class="inner-box">
                <div class="form-group">
                  <label>{{ $t('addressNickname') }}</label>
                  <input type="text" v-model="userAddress.title" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.title.$error">
                    <!-- {{ v$.title.$errors[0].$message }} -->
                    {{ $t('errorMessage', { text: $t('title') }) }}*
                  </span>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label>{{ $t('firstName') }} </label>
                    <input type="text" v-model="userAddress.firstName" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.firstName.$error">
                      <!-- {{ v$.firstName.$errors[0].$message }} -->
                      {{ $t('errorMessage', { text: $t('firstName') }) }}*
                    </span>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ $t('lastName') }}</label>
                    <input type="text" v-model="userAddress.lastName" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.lastName.$error">
                      <!-- {{ v$.lastName.$errors[0].$message }} -->
                      {{ $t('errorMessage', { text: $t('lastName') }) }}*
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ $t('address') }} 1 </label>
                  <input type="text" v-model="userAddress.address1" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.address1.$error">
                    <!-- {{ v$.address1.$errors[0].$message }} -->
                    {{ $t('errorMessage', { text: $t('address') }) }}*
                  </span>
                </div>
                <div class="form-group">
                  <label>{{ $t('address') }} 2 </label>
                  <input type="text" v-model="userAddress.address2" class="form-control" />
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>{{ $t('selectCountry') }}</label>
                    <select
                      class="form-select"
                      v-model="userAddress.country"
                      @change="countrySelected()"
                    >
                      <option
                        v-for="(country, index) in countryList?.countryList?.data.items"
                        :key="index"
                        :value="country.country_code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                    <span class="text-danger pl-3" v-if="v$.country.$error">
                      <!-- {{ v$.country.$errors[0].$message }} -->
                      {{ $t('selectMessage', { text: $t('country') }) }}*
                    </span>
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ $t('selectState') }}</label>
                    <select class="form-select" v-model="userAddress.state">
                      <option
                        v-for="state in stateList"
                        :key="state.code"
                        :value="state.state_code"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                    <span class="text-danger pl-3" v-if="v$.state.$error">
                      <!-- {{ v$.state.$errors[0].$message }} -->
                      {{ $t('selectMessage', { text: $t('state') }) }}*
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label>{{ $t('city') }} </label>
                    <input type="text" v-model="userAddress.city" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.city.$error">
                      <!-- {{ v$.city.$errors[0].$message }} -->
                      {{ $t('errorMessage', { text: $t('city') }) }}*
                    </span>
                  </div>
                  <div class="form-group col-6">
                    <label> {{ $t('zipCode') }} </label>
                    <input type="text" v-model="userAddress.zipCode" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.zipCode.$error">
                      <!-- {{ v$.zipCode.$errors[0].$message }} -->
                      {{ $t('errorMessage', { text: $t('zipCode') }) }}*
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label> {{ $t('phoneNumber') }} </label>
                  <input type="text" v-model="userAddress.phoneNumber" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.phoneNumber.$error">
                    <!-- {{ v$.phoneNumber.$errors[0].$message }} -->
                    {{ $t('errorMessage', { text: $t('phoneNumber') }) }}*
                  </span>
                </div>
                <div class="text-center">
                  <div class="wave-btn-block">
                    <button
                      type="submit"
                      class="blob-btn fill-bg btn-lg submit-btn mt-2"
                      @click="addAddress()"
                    >
                      {{ $t('save') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                    </button>
                    <SvgButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, helpers, alpha,
} from '@vuelidate/validators';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';
import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';

const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();

export default {
  name: 'add-new-address',
  props: [],
  components: {
    AccountSidebar,
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch('fetchAvailableCountries');
    const isLoading = ref(false);
    const userAddress = reactive({
      title: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      phoneNumber: '',
    });

    const stateList = ref({});

    const rules = {
      title: { required: helpers.withMessage('You must enter a title', required) },
      firstName: { required: helpers.withMessage('You must enter your first name', required) },
      lastName: { required: helpers.withMessage('You must enter your last name', required) },
      address1: { required: helpers.withMessage('You must enter your address', required) },
      city: { required: helpers.withMessage('You must enter your city', required) },
      state: { required: helpers.withMessage('You must select your state', required) },
      country: { required: helpers.withMessage('You must select your country', required) },
      zipCode: { required: helpers.withMessage('You must enter your zipCode', required) },
      phoneNumber: { required: helpers.withMessage('You must enter your phone number', required) },
    };

    const v$ = useVuelidate(rules, userAddress);

    const countrySelected = () => {
      storyphoneService.fetchAvailableStates(userAddress.country)
        .then((state) => {
          stateList.value = state.data.items;
        }).catch((stateError) => {
          stateList.value = {};
        });
    };

    const countryList = computed(() => store.getters.getCountryList);

    const addAddress = async () => {
      const userAddressParam = {
        notes: userAddress.title,
        first_name: userAddress.firstName,
        last_name: userAddress.lastName,
        line_1: userAddress.address1,
        line_2: userAddress.address2,
        city: userAddress.city,
        country_code: userAddress.country,
        state_code: userAddress.state,
        zip_code: userAddress.zipCode,
        phone: userAddress.phoneNumber,
      };
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        cLayerService.createAddress(userAddressParam)
          .then((addressCreated) => {
            cLayerService.associateAddress(addressCreated.id)
              .then((res) => {
                isLoading.value = false;
                store.dispatch('fetchUserAddress');
                router.push('/my-account');
              })
              .catch((err) => {
                isLoading.value = false;
              });
          })
          .catch((err) => {
            isLoading.value = false;
          })
          .finally(() => {
          });
      } else {
        console.log(v$);
      }
    };

    return {
      v$, isLoading, userAddress, stateList, countryList, addAddress, countrySelected,
    };
  },
};
</script>

<style scoped lang="scss"></style>
