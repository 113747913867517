<template lang="html">
  <section class="account-sidebar-menu">
    <div class="accounts-sidebar">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link :to="{ name: 'marketplace-my-playlist' }">My Playlist</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'marketplace-payments' }">My Payments</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'marketplace-creater-profile' }">My Profile</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'marketplace-account-sidebar',
  props: [],
  components: {

  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
