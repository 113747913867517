<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="recording-and-upload">
    <div
      class="recording-and-upload-block d-none"
      :class="{
        showuploadrecording: showuploadrecording,
      }"
    >
      <h4 class="text-center">{{ $t('recordMessage') }}</h4>
      <h6 v-if="duration" class="text-center">
        {{ $t('recordTimeDuration', { time: toTime(duration) }) }}
      </h6>
      <h6 v-else class="text-center">
        {{ $t('recordTimeDuration', { time: '120' }) }}
      </h6>
      <div class="row">
        <div class="col-md-6">
          <div class="upload-block">
            <input
              type="file"
              class="form-control-file"
              id="real-file"
              accept="audio/*"
              hidden="hidden"
            />
            <button
              type="button"
              id="custom-button"
              class="white-box record-btn w-100 mt-4 d-block"
              @click="openFile()"
            >
              <span><i class="icon-upload" aria-hidden="true"></i></span>
              <div id="custom-text">{{ $t('uploadAudio') }}</div>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <button class="white-box record-btn w-100 mt-4 d-block" @click="recordButton()">
            <span><i class="icon-microphone" aria-hidden="true"></i></span>{{ $t('record') }}
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg" @click="secondBackBtn()">
            {{ $t('back') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { ref, computed, reactive } from 'vue';
import { mapActions, useStore } from 'vuex';

import SvgButton from '@/components/partials/SvgButton.vue';
import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';

const storyphoneService = new StoryphoneService();
let realFileBtn;

export default {
  name: 'recording-and-upload',
  props: [],
  components: {
    SvgButton,
    Loader,
  },
  setup() {
    const store = useStore();

    const duration = computed(() => store.getters.listDuration);

    const showuploadrecording = computed(() => store.getters.isShowUploadRecord);

    const recordButton = () => {
      store.dispatch('isShowRecording', true);
      store.dispatch('isShowUploadRecord', false);
    };
    const secondBackBtn = () => {
      store.dispatch('isHideTitle', false);
      store.dispatch('isShowUploadRecord', false);
      store.dispatch('inFirstStep', false);
    };
    const toTime = (seconds) => {
      const date = new Date(null);
      date.setSeconds(7200 - seconds);
      return date.toISOString().substr(11, 8);
    };

    const showLibrary = () => {
      store.dispatch('isShowLibrary', true);
      store.dispatch('isShowUploadRecord', false);
    };

    return {
      duration, toTime, recordButton, secondBackBtn, showuploadrecording, showLibrary,
    };
  },
  mounted() {
    realFileBtn = document.getElementById('real-file');
  },
  data() {
    return {
      isLoading: false,
      base64: '',
    };
  },
  methods: {
    convertingTobase(audio) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64 = event.target.result;
        // const data = newline.replace('data:audio/x-wav;base64,', ' ');
        // this.base64 = data;
      };
      reader.readAsDataURL(audio);
    },
    openFile() {
      realFileBtn.click();
      realFileBtn.addEventListener('change', this.listener);
    },
    listener(evt) {
      if (realFileBtn.value) {
        this.isLoading = true;
        const file = realFileBtn.files[0];
        const formData = new FormData();
        formData.append('title', realFileBtn.files[0].name);
        formData.append('file', realFileBtn.files[0]);
        formData.append('type', 'playshield-story');

        // this.convertingTobase(realFileBtn.files[0]);
        // setTimeout(() => {
        //   const Data = {
        //     title: realFileBtn.files[0].name,
        //     file: this.base64,
        //     type: 'playshield-story',
        //   };
        storyphoneService.storyUpload(formData).then((story) => {
          const audioFile = {
            file: URL.createObjectURL(realFileBtn.files[0]),
            name: realFileBtn.files[0].name,
            id: story.data.id,
            duration: story.data.duration_in_seconds,
          };
          this.recordingListAction(audioFile);
          this.isLoading = false;
        });
        // }, 1000);
        this.isShowFilesAction(true);
        this.inSecondStepAction(true);
        this.isShowUploadRecordAction(false);
      } else {
        console.log('no file selected');
      }
    },
    ...mapActions({
      recordingListAction: 'fetchRecordingList',
      isShowFilesAction: 'isShowFiles',
      isShowUploadRecordAction: 'isShowUploadRecord',
      inSecondStepAction: 'inSecondStep',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
