<template>
  <div class="col market-footer-carousel">
    <div class="market-carousel">
      <div class="market-carousel-inner">
        <div class="market-carousel-card active">
          <div class="market-card-img">
            <img src="@/assets/images/author1.png" class="rounded-circle" alt="..." />
            <div class="card-body d-flex">
              <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />
              <p class="card-text">B. B. Alston</p>
              <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
            </div>
            <div class="mt-1">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
          </div>
        </div>
        <div class="market-carousel-card">
          <div class="market-card-img">
            <img src="@/assets/images/author1.png" class="rounded-circle" alt="..." />
            <div class="card-body d-flex">
              <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />
              <p class="card-text">B. B. Alston</p>
              <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
            </div>
            <div class="mt-1">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
          </div>
        </div>
        <div class="market-carousel-card">
          <div class="market-card-img">
            <img src="@/assets/images/author1.png" class="rounded-circle" alt="..." />
            <div class="card-body d-flex">
              <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />
              <p class="card-text">B. B. Alston</p>
              <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
            </div>
            <div class="mt-1">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
          </div>
        </div>
        <div class="market-carousel-card">
          <div class="market-card-img">
            <img src="@/assets/images/author1.png" class="rounded-circle" alt="..." />
            <div class="card-body d-flex">
              <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />
              <p class="card-text">B. B. Alston</p>
              <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
            </div>
            <div class="mt-1">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
          </div>
        </div>
        <div class="market-carousel-card">
          <div class="market-card-img">
            <img src="@/assets/images/author1.png" class="rounded-circle" alt="..." />
            <div class="card-body d-flex">
              <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />
              <p class="card-text">B. B. Alston</p>
              <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
            </div>
            <div class="mt-1">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
          </div>
        </div>
      </div>
      <button class="prev-btn"><i class="fa fa-chevron-left"></i></button>
      <button class="next-btn"><i class="fa fa-chevron-right"></i></button>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import {
  ref, computed, reactive, onMounted,
} from 'vue';
import 'vue3-carousel/dist/carousel.css';

import SvgButton from '@/components/partials/SvgButton.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import ShieldCarouselItem from '@/components/partials/ShieldCarouselItem.vue';
import Loader from '@/components/commons/Loader.vue';
import WishListSvgButton from '@/components/partials/WishListSvgButton.vue';

// document.addEventListener('DOMContentLoaded', () => {

// });

export default {
  name: 'MarketPlaceFooterCarousel',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    showCount: {
      type: Number,
      default: 3,
    },
  },
  components: {
    // Carousel,
    // Slide,
    // Navigation,
    // ShieldCarouselItem,
    // DetailsOfCategory,
    // Loader,
    // SvgButton,
  },

  setup(props) {
    const store = useStore();
    const storyShieldList = ref({});
    const shieldSku = ref('');
    const settings = ref({
      itemsToShow: 1,
      snapAlign: 'center',
      pauseAutoplayOnHover: true,
    });
    const breakpoints = ref({
      // 700px and up
      768: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      992: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    });

    const story = ref([]);
    story.value = [
      {
        name: 'Amari and the Night Brothers',
        author: 'B.B Alston',
        image: 'Amari',
      },
      {
        name: 'The Last Fallen Moon',
        author: 'B.B Alston',
        image: 'stella',

      },
      {
        name: 'The Lion of Mars',
        author: 'B.B Alston',
        image: 'lastfallen',

      },
      {
        name: 'Stella',
        author: 'B.B Alston',
        image: 'lionmars',

      },

    ];
    onMounted(() => {
      const carouselInner = document.querySelector('.market-carousel-inner');
      const cards = document.querySelectorAll('.market-carousel-card');
      const prevBtn = document.querySelector('.prev-btn');
      const nextBtn = document.querySelector('.next-btn');

      let currentCard = 1;

      prevBtn.addEventListener('click', () => {
        // eslint-disable-next-line no-plusplus
        currentCard--;
        if (currentCard < 1) {
          currentCard = cards.length;
        }
        // eslint-disable-next-line no-use-before-define
        updateCarousel();
      });

      nextBtn.addEventListener('click', () => {
        // eslint-disable-next-line no-plusplus
        currentCard++;
        if (currentCard > cards.length) {
          currentCard = 1;
        }
        // eslint-disable-next-line no-use-before-define
        updateCarousel();
      });

      function updateCarousel() {
        cards.forEach((card) => {
          card.classList.remove('active');
          // eslint-disable-next-line no-param-reassign
          card.style.transform = 'translate(-50%, -50%) rotateY(0deg)';
        });

        cards[currentCard - 1].classList.add('active');

        if (currentCard === 1) {
          cards[4].style.transform = 'translate(-130%, -50%) rotateY(0deg)';
          cards[0].style.transform = 'translate(-50%, -50%) rotateY(0deg) scale(1.2)';
          cards[1].style.transform = 'translate(30%, -50%) rotateY(0deg)';
          cards[2].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
          cards[3].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
        } else if (currentCard === 2) {
          cards[0].style.transform = 'translate(-130%, -50%) rotateY(0deg)';
          cards[1].style.transform = 'translate(-50%, -50%) rotateY(0deg) scale(1.2)';
          cards[2].style.transform = 'translate(30%, -50%) rotateY(0deg)';
          cards[3].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
          cards[4].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
        } else if (currentCard === 3) {
          cards[1].style.transform = 'translate(-130%, -50%) rotateY(0deg)';
          cards[2].style.transform = 'translate(-50%, -50%) rotateY(0deg) scale(1.2)';
          cards[3].style.transform = 'translate(30%, -50%) rotateY(0deg)';
          cards[4].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
          cards[0].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
        } else if (currentCard === 4) {
          cards[2].style.transform = 'translate(-130%, -50%) rotateY(0deg)';
          cards[3].style.transform = 'translate(-50%, -50%) rotateY(0deg) scale(1.2)';
          cards[4].style.transform = 'translate(30%, -50%) rotateY(0deg)';
          cards[0].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
          cards[1].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
        } else if (currentCard === 5) {
          cards[3].style.transform = 'translate(-130%, -50%) rotateY(0deg)';
          cards[4].style.transform = 'translate(-50%, -50%) rotateY(0deg) scale(1.2)';
          cards[0].style.transform = 'translate(30%, -50%) rotateY(0deg)';
          cards[1].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
          cards[2].style.transform = 'translate(-50%, -50%) rotateY(90deg)';
        }
      }

      updateCarousel();
    });
    // const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    // contentfulService.getStoryShieldList(props.categoryId, 20).then((response) => {
    //   storyShieldList.value = response.items;
    // });

    // const shieldClick = (sku) => {
    //   shieldSku.value = sku;
    // };

    // const getProductDetails = (id, type = 'storyShields') => {
    //   store.dispatch('fetchSelectedShieldDetails', { id, type });
    // };

    return {
      storyShieldList,
      shieldSku,
      settings,
      breakpoints,
      //   selectedShieldDetails,
      //   shieldClick,
      //   getProductDetails,
      story,
    };
  },
};
</script>

<style scoped lang="scss">
// .market-carousel {
//   position: relative;
//   width: 100%;
//   height: 400px;
//   margin: 0 auto;
//   perspective: 1000px;
// }

// .market-carousel-inner {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   transform-style: preserve-3d;
//   transition: transform 1s;
// }

// .market-carousel-card {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 200px;
//   height: 250px;
//   padding: 20px;
//   transform-style: preserve-3d;
//   background-color: #fff;
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
//   border-radius: 10px;
//   text-align: center;
//   transform: translate(-50%, -50%) rotateY(0deg);
//   transition: transform 1s;
// }

// .market-carousel-card.active {
//   z-index: 1;
//   transform: translate(-50%, -50%) rotateY(0deg) scale(1.2);
// }

// .market-carousel-card:not(.active) {
//   pointer-events: none;
//   opacity: 0.5;
// }

// .market-carousel-card:nth-child(2) {
//   transform: translate(-50%, -50%) rotateY(60deg);
// }

// .market-carousel-card:nth-child(3) {
//   transform: translate(-50%, -50%) rotateY(120deg);
// }

// .market-carousel-card:nth-child(4) {
//   transform: translate(-50%, -50%) rotateY(180deg);
// }

// .market-carousel-card:nth-child(5) {
//   transform: translate(-50%, -50%) rotateY(240deg);
// }

// .prev-btn,
// .next-btn {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 50px;
//   height: 50px;
//   border: none;
//   background-color: #fff;
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
//   border-radius: 50%;
// }
// .prev-btn {
//   left: 10px;
// }

// .next-btn {
//   right: 10px;
// }

// .prev-btn:hover,
// .next-btn:hover {
//   cursor: pointer;
// }

// .fa {
//   font-size: 24px;
// }

// .fa-chevron-left:before {
//   content: '\f053';
// }

// .fa-chevron-right:before {
//   content: '\f054';
// }
</style>
