import AuthenticationService from '@/services/AuthenticationService';

import * as types from './mutation-types';

const authenticationService = new AuthenticationService();

export const userActivation = ({ commit }, userActiveData) => new Promise((resolve, reject) => {
  authenticationService
    .userActivation(userActiveData)
    .then((success) => {
      commit(types.SET_USER_ACTIVATION_SUCCESS, success.data.status);
      resolve(success);
    })
    .catch((error) => {
      commit(types.SET_USER_ACTIVATION_ERRORS, error.response.data);
      reject(error);
    });
});

export default userActivation;
