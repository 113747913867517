import { useCookies } from 'vue3-cookies';
import CLayerAuth from '@commercelayer/js-auth';
import CommerceLayer from '@commercelayer/sdk';

export default class CLayerBaseService {
  constructor() {
    this.clEntity = null;
    this.clAmdmin = null;
  }

  createToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    // localStorage.setItem('marketNumber', '11948');
    const market = localStorage.getItem('marketNumber');
    if (!cookies.isKey('clAuthCookie')) {
      CLayerAuth.getSalesChannelToken({
        clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
        endpoint: process.env.VUE_APP_COMMERCE_LAYER_SC_ENDPOINT,
        scope: `market:${market}`,
      })
        .then((response) => {
          cookies.set('clAuthCookie', {
            access_token: response.accessToken,
          });
          this.clEntity = CommerceLayer({
            organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
            accessToken: response.accessToken,
          });
          resolve(this.clEntity);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: cookies.get('clAuthCookie').access_token,
      });
      resolve(this.clEntity);
    }
  });

  createAdminToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    CLayerAuth.getIntegrationToken({
      grant_type: 'client_credentials',
      clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_ID,
      clientSecret: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_SECRET,
      endpoint: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ENDPOINT,
    })
      .then((response) => {
        cookies.set('clUserAuthAdminCookie', {
          access_token: response.data.access_token,
        });
        this.clAmdmin = CommerceLayer({
          organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ORG,
          accessToken: response.data.access_token,
        });
        resolve(this.clAmdmin);
      })
      .catch((error) => {
        reject(error);
      });
  });

  // eslint-disable-next-line class-methods-use-this
  createUserToken = (credentials) => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    // localStorage.setItem('marketNumber', '11948');
    const market = localStorage.getItem('marketNumber');
    if (!cookies.isKey('clUserAuthCookie')) {
      CLayerAuth.getSalesChannelToken(
        {
          clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
          endpoint: process.env.VUE_APP_COMMERCE_LAYER_SC_ENDPOINT,
          scope: `market:${market}`,
        },
        { username: credentials.email, password: credentials.password },
      )
        .then((response) => {
          cookies.set('clUserAuthCookie', {
            access_token: response.accessToken,
          });
          cookies.set('clUserAuthRefreshCookie', {
            refresh_token: response.refreshToken,
          });
          cookies.set('clUserOwnerId', {
            owner_id: response.data.owner_id,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

  refreshToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    const refresh = cookies.get('clUserAuthRefreshCookie');
    const market = localStorage.getItem('marketNumber');
    CLayerAuth.getRefreshToken({
      clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
      endpoint: process.env.VUE_APP_COMMERCE_LAYER_SC_ENDPOINT,
      scope: `market:${market}`,
      refreshToken: refresh.refresh_token,
    })
      .then((response) => {
        cookies.set('clUserAuthCookie', {
          access_token: response.accessToken,
        });
        cookies.set('clUserAuthRefreshCookie', {
          refresh_token: response.refreshToken,
        });
        cookies.set('clUserOwnerId', {
          owner_id: response.data.owner_id,
        });
        this.clEntity = CommerceLayer({
          organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
          accessToken: response.accessToken,
        });
        resolve(this.clEntity);
      })
      .catch((error) => {
        reject(error);
      });
  });

  getToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    if (cookies.isKey('clUserAuthCookie')) {
      const token = cookies.get('clUserAuthCookie');
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: token.access_token,
      });
      this.clEntity.addResponseInterceptor(undefined, (err) => {
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
          this.refreshToken().then(() => {
            window.location.reload();
          });
        }
      });
      resolve(this.clEntity);
    } else {
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: cookies.get('clAuthCookie').access_token,
      });
      this.clEntity.addResponseInterceptor(undefined, (err) => {
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
          cookies.remove('clAuthCookie');
          this.createToken().then(() => {
            window.location.reload();
          });
        }
      });
      resolve(this.clEntity);
    }
  });
}
