import * as types from './mutation-types';

export const isHideShipping = ({ commit }, value) => {
  commit(types.HIDE_SHIPPING, value);
};

export const isShowBilling = ({ commit }, value) => {
  commit(types.SHOW_BILLING, value);
};

export const isShowPreview = ({ commit }, value) => {
  commit(types.SHOW_PREVIEW, value);
  if (value === true) {
    commit(types.GET_SUCCESS_MESSAGE, 'Shipping cost has been calculated.');
    setTimeout(() => {
      commit(types.GET_SUCCESS_MESSAGE, '');
    }, 3000);
  }
};

export const isShowPaynow = ({ commit }, value) => {
  commit(types.SHOW_PAYNOW, value);
  if (value === false) {
    commit(types.GET_SUCCESS_MESSAGE, '');
  }
};

export const buttonHide = ({ commit }, value) => {
  commit(types.HIDE_BUTTON, value);
};

export const fetchShippingAddress = ({ commit }, value) => {
  commit(types.SHIPPING_ADDRESS, value);
};

export const fetchBillingAddress = ({ commit }, value) => {
  commit(types.BILLING_ADDRESS, value);
};

export const fetchPaymentSource = ({ commit }, value) => {
  commit(types.PAYMENT_SOURCE, value);
};

export const hideCartButton = ({ commit }, value) => {
  commit(types.HIDE_CARTBUTTONS, value);
};
