import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class WidgetService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',

    };
  }

  getAboutMakersContent() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/widgets/991a525a-2ac4-477c-92f0-0dd5152a868e`;
      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
