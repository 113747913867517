import { useCookies } from 'vue3-cookies';
import CLayerService from '@/services/CLayerService';
import AuthenticationService from '@/services/AuthenticationService';
import StoryphoneService from '@/services/StoryphoneService';
import * as types from './mutation-types';

const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();

export const fetchSCChannelToken = (
  { commit },
) => new Promise((resolve, reject) => {
  cLayerService
    .fetchSCChannelToken()
    .then((response) => {
      commit(types.SET_SC_CHANNEL_TOKEN, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const updateLineItem = ({ dispatch, commit }, cartItem) => new Promise((resolve, reject) => {
  const { cookies } = useCookies({ secure: true });
  if (!cookies.isKey('orderIdCookie')) {
    cLayerService.createOrderService()
      .then((createdOrder) => {
        cookies.set('orderIdCookie', {
          orderId: createdOrder.id,
        }, createdOrder.expires);
        cLayerService.addLineItem(cartItem.itemDetails.sku, cookies.get('orderIdCookie').orderId)
          .then((addedIineItem) => {
            resolve(addedIineItem);
            dispatch('fetchCartItems', createdOrder.id);
          })
          .catch((lineItemError) => {
            commit(types.GET_OUT_OF_STOCK_MESSAGE, 'The product is currently out of stock.');
            reject(lineItemError);
          });
      })
      .catch(() => {
      });
  }
  if (cookies.isKey('orderIdCookie')) {
    cLayerService.addLineItem(cartItem.itemDetails.sku, cookies.get('orderIdCookie').orderId)
      .then((addedIineItem) => {
        resolve(addedIineItem);
        dispatch('fetchCartItems', cookies.get('orderIdCookie').orderId);
      })
      .catch((lineItemError) => {
        commit(types.GET_OUT_OF_STOCK_MESSAGE, 'The product is currently out of stock.');
        reject(lineItemError);
      });
  } else {
    console.log();
  }
});

export const fetchShippingMethods = ({ commit }, orderID) => new Promise((resolve, reject) => {
  cLayerService
    .fetchShippingMethods(orderID)
    .then((response) => {
      commit(types.SET_SHIPPING_METHOD, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchDeliveryLeadTime = (
  { commit },
) => new Promise((resolve, reject) => {
  cLayerService
    .fetchDeliveryLeadTime()
    .then((response) => {
      commit(types.DELIVERY_LEAD_TIME, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchAvailableCountries = (
  { commit },
) => new Promise((resolve, reject) => {
  storyphoneService
    .fetchAvailableCountries()
    .then((response) => {
      commit(types.GET_COUNTRIES, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchCartItems = ({ commit }, orderId) => new Promise((resolve, reject) => {
  cLayerService
    .fetchCartItems(orderId)
    .then((response) => {
      commit(types.GET_CART_ITEMS, response);
      resolve(response);
    })
    .catch((error) => {
      commit(types.GET_CART_ITEMS, 0);
      reject(error);
    });
});

export const fetchOrderHistory = ({ commit }, customerId) => new Promise((resolve, reject) => {
  cLayerService
    .getOrderHistory(customerId)
    .then((response) => {
      commit(types.GET_ORDERS, response);
      resolve(response);
    })
    .catch((error) => {
      commit(types.GET_ORDERS, 0);
      reject(error);
    });
});

export const setOutOfStockMessage = ({ commit }, value = 'The product is currently out of stock.') => {
  commit(types.GET_OUT_OF_STOCK_MESSAGE, value);
};
