<template>
  <div class="col">
    <!-- :autoplay="2000" -->

    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      class="py-5 stories-carousel w-100"
      aria-label="playlist carousel"
      :wrap-around="true"
    >
      <Slide v-for="(item, index) in story" :key="index">
        <div class="card h-100 w-100 market-card border-0">
          <div class="card-body p-0">
            <a class="p-0" data-bs-toggle="modal" data-bs-target="#product-details-popup">
              <div style="cursor: pointer">
                <div class="text-end">
                  <button class="btn market-btn rounded-circle">
                    <img src="@/assets/images/MpPlay.png" alt="play" class="" />
                  </button>
                </div>
                <div class="market-image">
                  <img src="@/assets/images/Amari.png" alt="customer like also" class="w-45" />
                </div>
                <div class="mt-2">
                  <img src="@/assets/images/Star31.png" alt="star" class="" />
                  <img src="@/assets/images/Star31.png" alt="star" class="" />
                  <img src="@/assets/images/Star31.png" alt="star" class="" />
                  <img src="@/assets/images/Star31.png" alt="star" class="" />
                  <img src="@/assets/images/Star35.png" alt="star" class="" />
                </div>
                <h5 class="mt-4 carousel-title">{{ item.name }}</h5>
                <p>By:{{ item.author }}</p>
              </div>
            </a>
          </div>
          <div class="card-footer border-0 me-5">
            <button class="btn btn-primary rounded-circle mx-2">
              <i class="icon-cart" aria-hidden="true"></i>
            </button>
            €14,99
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
    <!-- <div class="col-md-12 text-center mt-5">
      <div class="wave-btn-block">
        <router-link to="/shields" class="blob-btn fill-bg btn-lg">
          {{ $t('explore') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </router-link>
        <SvgButton />
      </div>
    </div> -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="product-details-popup"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close close-blue-btn ms-auto 45"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <MarketplaceDetails />
          <!-- <div v-else><Loader /></div> -->
          <!-- <div class="modal-body"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { ref, computed, reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';

import SvgButton from '@/components/partials/SvgButton.vue';
import MarketplaceDetails from '@/components/partials/MarketplaceDetails.vue';
import ShieldCarouselItem from '@/components/partials/ShieldCarouselItem.vue';
import Loader from '@/components/commons/Loader.vue';

export default {
  name: 'MarketPlaceCarouselListing',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    showCount: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    MarketplaceDetails,
    // ShieldCarouselItem,
    // DetailsOfCategory,
    // Loader,
    // SvgButton,
  },

  setup(props) {
    const store = useStore();
    const storyShieldList = ref({});
    const shieldSku = ref('');
    const settings = ref({
      itemsToShow: 1,
      snapAlign: 'center',
      pauseAutoplayOnHover: true,
    });
    const breakpoints = ref({
      // 700px and up
      768: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      992: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    });

    const story = ref([]);
    story.value = [
      {
        name: 'Amari and the Night Brothers',
        author: 'B.B Alston',
        image: 'Amari',
      },
      {
        name: 'The Last Fallen Moon',
        author: 'B.B Alston',
        image: 'stella',

      },
      {
        name: 'The Lion of Mars',
        author: 'B.B Alston',
        image: 'lastfallen',

      },
      {
        name: 'Stella',
        author: 'B.B Alston',
        image: 'lionmars',

      },
    ];
    // const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    // contentfulService.getStoryShieldList(props.categoryId, 20).then((response) => {
    //   storyShieldList.value = response.items;
    // });

    // const shieldClick = (sku) => {
    //   shieldSku.value = sku;
    // };

    // const getProductDetails = (id, type = 'storyShields') => {
    //   store.dispatch('fetchSelectedShieldDetails', { id, type });
    // };

    return {
      storyShieldList,
      shieldSku,
      settings,
      breakpoints,
      //   selectedShieldDetails,
      //   shieldClick,
      //   getProductDetails,
      story,
    };
  },
};
</script>

<style scoped lang="scss"></style>
