<template lang="html">
  <PageHeader />
  <section v-if="videoSrc" class="home mt-stickybar">
    <section class="banner-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="video-block">
              <button class="video-play-btn d-none" @click="playBannerVideo"></button>
              <video id="bannerVideo" class="w-100" width="100%" loop autoplay muted preload="auto" playsinline="">
                <source src="@/assets/videos/banner.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="popup-video-block">
      <PopupVideo v-if="videoSrc" />
      <div class="video-btn">
        <button type="button" class="p-0" data-bs-toggle="modal" data-bs-target="#popupvideo"
          @click="setVideoSrc"></button>
      </div>
    </div> -->
  </section>

  <section class="py-8 pb-0 ces-award">
    <div class="container">
      <div class="row">
        <div class="col-md-9 m-auto text-center">
          <span v-for="(item, index) in homeAwards.images" :key="index">
            <img :src="item.image_url" alt="" width="" class="mb-4 me-3" />
          </span>
          <div id="home-awards" v-html="domSanitize(homeAwards.html_description)"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="multi-storeyphones my-5">
    <div class="container">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div class="card h-100 border-0 shadow-20 img-zoom">
            <div class="img-card-gray text-center m-4" v-for="(item, index) in spHome.images" :key="index">
              <img class="p-4" :src="item.image_url" alt="Card image cap" />
            </div>
            <div class="card-body">
              <div class="home-headphones" v-html="domSanitize(spHome.html_description)"></div>
            </div>
            <div class="card-footer border-0 pb-4">
              <div class="wave-btn-block">
                <a href="/products/storyphones" class="blob-btn fill-bg">
                  <!-- Buy now -->
                  {{ spHome.excerpt }}

                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 border-0 shadow-20 img-zoom">
            <div class="img-card-blue text-center m-4" v-for="(item, index) in disHome.images" :key="index">
              <img class="p-4" :src="item.image_url" alt="Card image cap" />
            </div>
            <div class="card-body">
              <div class="home-headphones" v-html="domSanitize(disHome.html_description)"></div>
            </div>
            <div v-if="langCode !== 'zh'" class="card-footer border-0 pb-4">
              <div class="wave-btn-block">
                <a href="/products/disney" class="blob-btn fill-bg">
                  <!-- Buy now -->
                  {{ disHome.excerpt }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="home-playshield">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 py-sm-8 order-2 order-lg-1 order-sm-1">
          <div class="home-playShield-content">
            <div class="plyashield-zenshield" v-html="domSanitize(generations.html_description)"></div>
            <button v-if="langCode !== 'zh'" type="button" class="btn btn-primary mt-3 cart-btn p-0"
              data-bs-toggle="modal" data-bs-target="#popupplayshield">
              <i class="icon-cart" aria-hidden="true"></i>
            </button>
            <PopupPlayShield />
          </div>
        </div>
        <div class="col-md-6 text-end order-1 order-lg-2 order-sm-2">
          <div class="home-playshield-pic">
            <img src="@/assets/images/playshield-home1.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="home-zenshield mb-4 mt-4">
    <div class="container">
      <div class="row justify-content-center align-items-center mt-s-3">
        <div class="col-md-6">
          <div class="home-zenshield-pic">
            <img src="@/assets/images/zenshield-home.jpg" alt="" />
          </div>
        </div>
        <div class="col-md-6 py-sm-8">
          <div class="home-zenshield-content">
            <div class="plyashield-zenshield" v-html="domSanitize(relax.html_description)"></div>
            <button v-if="langCode !== 'zh'" type="button" class="btn btn-primary mt-3 cart-btn p-0"
              data-bs-toggle="modal" data-bs-target="#popupzenshield">
              <i class="icon-cart" aria-hidden="true"></i>
            </button>

            <PopupZenShield />
          </div>
        </div>
      </div>
    </div>
  </section>

  <StoriesCarousel />
  <StoryphonesAdventure />
  <ScreenFreeEntertainment />

  <section>
    <img src="@/assets/images/home-footer-kids-large.png" alt="" class="w-100" />
  </section>

  <ImportantOfStorytelling />

  <section class="mt-3">
    <div class="disney-logo-outer">
      <div class="disney-logo">
        <img src="@/assets/images/disney.png" alt="Disney logo" />
      </div>
      <div class="disney-details">
        <h6>
          ©Disney <br />
          ©Disney/Pixar
        </h6>
      </div>
      <img src="@/assets/images/headphones-shields-home-large.png" alt="" class="w-100" />
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import DOMPurify from 'dompurify';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import PopupVideo from '@/components/partials/PopupVideo.vue';
import ImportantOfStorytelling from '@/components/partials/ImportantOfStorytelling.vue';
import ScreenFreeEntertainment from '@/components/partials/ScreenFreeEntertainment.vue';
import StoryphonesAdventure from '@/components/partials/StoryphonesAdventure.vue';
import StoriesCarousel from '@/components/partials/StoriesCarousel.vue';
import PopupPlayShield from '@/components/partials/PopupPlayShield.vue';
import PopupZenShield from '@/components/partials/PopupZenShield.vue';

let bannerPlayVideo;

export default {
  name: 'Home',
  title: 'StoryPhones | Let imagination be limitless, with StoryPhones by ONANOFF.',
  props: [],
  components: {
    StoriesCarousel,
    ImportantOfStorytelling,
    StoryphonesAdventure,
    ScreenFreeEntertainment,
    SvgButton,
    // PopupVideo,
    PopupPlayShield,
    PopupZenShield,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');
    const update = localStorage.getItem('update');

    // onMounted(() => {
    //   if (!update) {
    //     const cookieContainer = document.querySelector('#staticBackdrop');
    //     cookieContainer.style.display = 'block';
    //     cookieContainer.classList.add('show');
    //   }
    // });

    bannerPlayVideo = document.getElementById('bannerVideo');
    store.dispatch('setvideoSrc', 'https://www.youtube.com/embed/DbEWOVrbd5k?rel=0');
    // store.dispatch('fetchPlayShields');
    const setVideoSrc = () => {
      store.dispatch('setvideoSrc', 'https://www.youtube.com/embed/DbEWOVrbd5k?rel=0');
    };
    const playBannerVideo = () => {
      bannerPlayVideo.play();
    };
    const playShieldList = computed(() => store.getters.getPlayShieldList);
    const func = async () => {
      await store.dispatch('fetchPlayShields')
        .then(async () => {
          await store.dispatch('selectPlayShield', playShieldList?.value[0]?.fields);
        });
    };

    func();

    const Data = {
      code: langCode,
      slug: `home_awards_${langCode}`,
      // slug: 'homeawardsen',

    };
    store.dispatch('fetchCMS', Data);

    const Data1 = {
      code: langCode,
      slug: `home_storyphones_${langCode}`,
      // slug: 'homestoryphonesen',
    };
    store.dispatch('fetchCMS1', Data1);

    const Data2 = {
      code: langCode,
      slug: `home_disney_${langCode}`,
      // slug: 'homedisneyen',
    };
    store.dispatch('fetchCMS2', Data2);

    const Data3 = {
      code: langCode,
      slug: `home_dis_sp_story_gen_${langCode}`,
      // slug: 'homedisspstorygenen',
    };
    store.dispatch('fetchCMS3', Data3);

    const Data4 = {
      code: langCode,
      slug: `home_sp_relax_${langCode}`,
      // slug: 'homesprelaxen',
    };
    store.dispatch('fetchCMS5', Data4);

    const homeAwards = computed(() => store.getters.getCMS);
    const spHome = computed(() => store.getters.getCMS1);
    const disHome = computed(() => store.getters.getCMS2);
    const generations = computed(() => store.getters.getCMS3);
    const relax = computed(() => store.getters.getCMS5);

    const videoSrc = computed(() => store.getters.getvideoSrc);

    // const Details = computed(() => DOMPurify.sanitize(homeAwards.value.html_description));
    // const Details2 = computed(() => DOMPurify.sanitize(spHome.value.html_description));
    // const Details3 = computed(() => DOMPurify.sanitize(disHome.value.html_description));
    // const Details4 = computed(() => DOMPurify.sanitize(generations.value.html_description));
    // const Details5 = computed(() => DOMPurify.sanitize(relax.value.html_description));

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      bannerPlayVideo,
      setVideoSrc,
      playBannerVideo,
      videoSrc,
      langCode,
      homeAwards,
      spHome,
      disHome,
      generations,
      relax,
      update,
      domSanitize,
      // Details,
      // Details2,
      // Details3,
      // Details4,
      // Details5,
    };
  },

};
</script>

<style scoped lang="scss"></style>
