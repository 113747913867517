<template>
  <div class="modal-dialog modal-dialog-centered modal-xl marketPlace-modal">
    <div class="modal-content mp-login-bg">
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="p-5 text-center">
              <img class="m-3" src="@/assets/images/marketplace/magicbk.png" alt="magicbk" />
              <h3 class="m-3 text-white">Become our Storyteller now</h3>
              <p class="text-white">
                From the Masters of Entertainment, a Superb Collection of Exciting Stories for Kids.
              </p>
              <div class="irow">
                <a href="#">
                  <span class="dot p-1 mx-1 d-inline-block rounded-circle bg-light"></span>
                  <span class="dot p-1 px-2 mx-1 d-inline-block rounded-5 bg-light"></span>
                  <span class="dot p-1 mx-1 d-inline-block rounded-circle bg-light"></span>
                  <span class="dot p-1 mx-1 d-inline-block rounded-circle bg-light"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-end">
              <button
                type="button"
                class="btn filter-btn rounded-circle"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="icon-close" aria-hidden="true"></i>
              </button>
            </div>
            <!-- d-flex mp-modal-logo  -->
            <div class="ms-4 mp-modal-header">
              <div class="">
                <img src="@/assets/images/SpBlack.png" alt="logo" class="mp-logo" />
              </div>
              <div class=""><h5>MARKETPLACE</h5></div>
            </div>
            <MarketPlaceSignUpSection />
            <MarketPlaceLogInSection />
            <MarketPlaceConfirmSection />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import MarketPlaceSignUpSection from '@/components/partials/MarketPlaceSignUpSection.vue';
import MarketPlaceLogInSection from '@/components/partials/MarketPlaceLogInSection.vue';
import MarketPlaceConfirmSection from '@/components/partials/MarketPlaceConfirmSection.vue';

import {
  ref, computed, reactive,
} from 'vue';

export default {
  name: 'marketPlaceModal',
  props: [],
  components: {
    MarketPlaceSignUpSection,
    // MarketPlaceLogInSection,
    // MarketPlaceConfirmSection,
  },
  setup() {
    return { };
  },
};
</script>

<style></style>
