<template lang="html">
  <div class="col">
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      v-if="storyShieldList.length > 0"
      class="py-5 stories-carousel w-100"
      aria-label="playlist carousel"
      :autoplay="2000"
      :wrap-around="true"
    >
      <Slide v-for="(shield,index) in storyShieldList" :key="shield.id">

        <div class="card bg-transparent border-0 h-100">
          <div class="card-body p-0">
            <a
              class="p-0"
              data-bs-toggle="modal"
              data-bs-target="#product-details-popup"
              @click="shieldClick(shield)"
            >
              <div @click="getProductDetails(shield)" style="cursor: pointer">
                <div class="carousel__item">
                  <img
                    :src="shield.image"
                    alt="customer like also"
                    class="w-45"
                  />
                  <h6 class="mt-4 carousel-title">{{ shield.title }}</h6>
                </div>
              </div>
            </a>
          </div>
          <div class="card-footer bg-transparent p-0 border-0">
            <ShieldCarouselItem
            v-if="priceDetails[index]"
            :shieldData="shield" :shieldSku="priceDetails[index]" />
            <div v-else><Loader /></div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
    <div class="col-md-12 text-center mt-5">
      <div class="wave-btn-block">
        <router-link to="/shields" class="blob-btn fill-bg btn-lg">
          {{ $t('explore') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </router-link>
        <SvgButton />
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="product-details-popup"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close close-blue-btn ms-auto 45"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <DetailsOfCategory
            v-if="selectedShieldDetails"
            :shield-data="selectedShieldDetails"
            :shield-sku="this.shieldSku"
          />
          <div v-else><Loader /></div>
          <!-- <div class="modal-body"></div> -->
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="shield-play-popup"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @focusout="stopAudio()">
          <button
            type="button"
            class="btn-close close popup"
            data-bs-target="#product-details-popup"
            data-bs-toggle="modal"
            @click="stopAudio()"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <div v-if="selectedShieldDetails" class="modal-body text-center py-5">
            <h5>{{ selectedShieldDetails?.title }}</h5>
            <div class="audio audioStyle">
              <audio
                id="audio"
                controls="controls"
                class="audioStyle"
                ref="audio"
                :src="selectedShieldDetails?.sample_audio"
              ></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { ref, computed } from 'vue';
import 'vue3-carousel/dist/carousel.css';

import SvgButton from '@/components/partials/SvgButton.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import ShieldCarouselItem from '@/components/partials/ShieldCarouselItem.vue';
import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'ShieldCarouselListing',
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    showCount: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    ShieldCarouselItem,
    DetailsOfCategory,
    Loader,
    SvgButton,
  },

  setup(props) {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const storyShieldList = ref({});
    const shieldSku = ref('');
    const storyShieldSkuList = ref([]);
    const priceDetails = ref([]);
    const settings = ref({
      itemsToShow: 1,
      snapAlign: 'center',
      pauseAutoplayOnHover: true,
    });
    const breakpoints = ref({
      // 700px and up
      768: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      992: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    });
    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);
    const getGenresDetail = computed(() => store.getters.getGenresDetails);

    // contentfulService.getStoryShieldList(props.categoryId, 10).then((response) => {
    //   storyShieldSkuList.value = [];
    //   priceDetails.value = [];
    //   storyShieldList.value = response.items;
    //   storyShieldList.value.forEach((element) => {
    //     storyShieldSkuList.value.push(element.fields.sku);
    //   });

    //   cLayerService.getShieldPriceList(storyShieldSkuList.value)
    //     .then((prices) => {
    //       storyShieldList.value.forEach((shields) => {
    //         prices.forEach((price) => {
    //           if (shields.fields.sku === price.code) {
    //             priceDetails.value.push(price);
    //           }
    //         });
    //       });
    //     })
    //     .catch((error) => {
    //       console.log('error');
    //     });
    // });

    newContentfulService.fetchStoryshields(langCode).then((response) => {
      storyShieldSkuList.value = [];
      priceDetails.value = [];
      storyShieldList.value = response.items;
      storyShieldList.value.forEach((element) => {
        storyShieldSkuList.value.push(element.sku);
      });

      cLayerService.getShieldPriceList(storyShieldSkuList.value)
        .then((prices) => {
          storyShieldList.value.forEach((shields) => {
            prices.forEach((price) => {
              if (shields.sku === price.code) {
                priceDetails.value.push(price);
              }
            });
          });
        })
        .catch((error) => {
          console.log('error');
        });
    });

    const shieldClick = (Data) => {
      shieldSku.value = Data.sku;
      store.dispatch('fetchPlaylistDetail', Data.playlist);
      const details = {
        id: Data.genre,
        locale: langCode,
      };
      store.dispatch('fetchGenresDetail', details);
    };

    const getProductDetails = (Data, type = 'storyShields') => {
      // newContentfulService.fetchStoryshieldsDetails(id).then((response) => {
      //   console.log(response);
      // });
      // store.dispatch('fetchSelectedShieldDetails', { data, type });
      const details = {
        id: Data.id,
        locale: langCode,
      };
      store.dispatch('fetchSelectedShieldDetails', details);
    };

    return {
      storyShieldList,
      shieldSku,
      settings,
      breakpoints,
      selectedShieldDetails,
      shieldClick,
      getProductDetails,
      storyShieldSkuList,
      priceDetails,
      getGenresDetail,
      langCode,
    };
  },
};
</script>

<style scoped lang="scss"></style>
