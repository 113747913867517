<template lang="html">
  <section class="account-sidebar">
    <div class="accounts-left-block d-none d-md-block">
      <h2>{{ $t('welcomeBack') }}!</h2>
      <AccountSidebarMenu />
    </div>

    <div class="accounts-left-block mobile-sidebar d-md-none">
      <a
        class="btn-profile-settings collapsed"
        data-bs-toggle="collapse"
        href="#collapseExample"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Profile settings
      </a>

      <div class="collapse" id="collapseExample">
        <AccountSidebarMenu />
      </div>
    </div>
  </section>
</template>

<script lang="js">
import AccountSidebarMenu from '@/components/commons/AccountSidebarMenu.vue';

export default {
  name: 'account-sidebar',
  props: [],
  components: {
    AccountSidebarMenu,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
