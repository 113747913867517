import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      userDetails: {},
      userAddress: {},
      arkWorks: {},
      backgroundColors: {},
      recordingList: [],
      selectedArtwork: '',
      selectedColor: '',
      videoSrc: '',
      deleteRecording: '',
      removeSrc: '',
      searchVariable: '',
      creativeStories: [],
      userStoryShields: [],
      userPlayShields: [],
      userMarkets: [],
      selectedMarket: [],
      languages: [],
      recordings: [],
    };
  },
  mutations,
  actions,
  getters,
};
