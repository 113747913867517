<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="forget-password account-section mt-stickybar mb-5 home">
    <div class="account-banner text-center"><img src="@/assets/images/signup.png" alt="" /></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block mt-5">
            <h2 class="text-center mb-3">{{ $t('forgotPasswordQuestion') }}?</h2>
            <p class="text-center mb-4">{{ $t('emailResetMessage') }}.</p>
            <div class="form-group">
              <label>{{ $t('email') }}</label>
              <input type="input" v-model="resetEmail.email" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.email.$error">
                {{ $t('errorMessage', { text: $t('mail') }) }}*
              </span>
            </div>

            <div class="text-center">
              <div class="wave-btn-block">
                <button type="submit" class="blob-btn fill-bg btn-lg submit-btn" @click="reset()">
                  {{ $t('send') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import {
  ref, computed, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, minLength, helpers,
} from '@vuelidate/validators';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import StoryphoneService from '@/services/StoryphoneService';

const storyphoneService = new StoryphoneService();

export default {
  name: 'forget-password',
  props: [],
  components: {
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const resetEmail = reactive({
      email: '',
    });
    const isLoading = ref(false);
    const router = useRouter();

    const rules = {
      email: { required: helpers.withMessage('You must enter your email', required), email },

    };

    const v$ = useVuelidate(rules, resetEmail);

    const reset = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const data = { email: resetEmail.email };
        storyphoneService.resetPasswordEmail(data)
          .then(() => {
            isLoading.value = false;
            router.push('/reset-password-email');
          })
          .catch((error) => {
            isLoading.value = false;
          });
      }
    };

    return {
      isLoading, reset, v$, resetEmail,
    };
  },
};
</script>

<style scoped lang="scss"></style>
