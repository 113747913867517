export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const GET_ART_WORKS = 'GET_ART_WORKS';
export const GET_BACKGROUND_COLORS = 'GET_BACKGROUND_COLORS';
export const GET_RECORDING_LIST = 'GET_RECORDING_LIST';
export const DELETE_RECORDING = 'DELETE_RECORDING';
export const SET_ART_WORKS = 'SET_ART_WORKS';
export const SET_BACKGROUND_COLORS = 'SET_BACKGROUND_COLORS';
export const SET_VIDEO_SRC = 'SET_VIDEO_SRC';
export const REMOVE_VIDEO_SRC = 'REMOVE_VIDEO_SRC';
export const REMOVE_ART_WORKS = 'REMOVE_ART_WORKS';
export const REMOVE_BACKGROUND_COLORS = 'REMOVE_BACKGROUND_COLORS';
export const SEARCH_TERM = 'SEARCH_TERM';
export const GET_CREATIVE_STORIES = 'GET_CREATIVE_STORIES';
export const GET_USER_STORYSHIELDS = 'GET_USER_STORYSHIELDS';
export const GET_USER_PLAYSHIELDS = 'GET_USER_PLAYSHIELDS';
export const GET_USER_MARKETS = 'GET_USER_MARKETS';
export const GET_SELECTED_MARKETS = 'GET_SELECTED_MARKETS';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SELECTED_RECORDINGS = 'SELECTED_RECORDINGS';
