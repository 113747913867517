<template lang="html">
  <PageHeader />

  <div v-if="isLoading"><Loader /></div>
  <section class="cloud-bg signup account-section mt-stickybar mb-5 pad">
    <LoginAnimation />
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block mt-5">
            <h2 class="text-center mb-4">{{ $t('createAnAccount') }}</h2>
            <div class="form-group">
              <label>{{ $t('firstName') }}</label>
              <input type="input" v-model="userSignup.firstName" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.firstName.$error">
                <!-- {{ v$.firstName.$errors[0].$message }} -->
                {{ $t('errorMessage', { text: $t('firstName') }) }}*
              </span>
            </div>
            <div class="form-group">
              <label>{{ $t('lastName') }}</label>
              <input type="input" v-model="userSignup.lastName" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.lastName.$error">
                <!-- {{ v$.lastName.$errors[0].$message }}* -->
                {{ $t('errorMessage', { text: $t('lastName') }) }}*
              </span>
            </div>
            <div class="form-group">
              <label>{{ $t('email') }}</label>
              <input type="input" v-model="userSignup.email" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.email.$error">
                {{ $t('errorMessage', { text: $t('mail') }) }}*
              </span>
            </div>
            <div class="form-group">
              <label>{{ $t('password') }}</label>
              <input
                type="password"
                id="password"
                v-model="userSignup.password"
                class="form-control"
              />
              <div
                class="eye-pwd"
                style="cursor: pointer; text-align: right; margin-top: -30px"
                @click="toggleShowPassword()"
              >
                <div v-if="showPassword">
                  <i class="icon-eye-Open" aria-hidden="true"></i>
                </div>
                <div v-if="!showPassword">
                  <i class="icon-eye-closed" aria-hidden="true"></i>
                </div>
              </div>
              <!-- {{ v$.password }} -->
              <span class="text-danger pl-3" v-if="v$.password.$error">
                <!-- {{ v$.password }}* -->

                <span v-if="v$.password.required.$invalid">
                  {{ $t('errorMessage', { text: $t('password') }) }}*
                </span>
                <span v-if="v$.password.min.$invalid"> {{ $t('minLength') }}*</span>
                <span
                  v-if="
                    v$.password.containsPasswordRequirement.$invalid &&
                    !v$.password.required.$invalid &&
                    !v$.password.min.$invalid
                  "
                >
                  {{ $t('passwordRequirement') }}*
                </span>
              </span>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" v-model="terms" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1"
                >{{ $t('agreement') }}
                <router-link to="/licence-agreement" class="text-link">{{
                  $t('licenceAgreement')
                }}</router-link
                >.
              </label>
            </div>

            <span class="text-danger pl-3" v-if="error">
              {{ error }}
            </span>
            <span class="text-danger pl-3" v-if="policy"> {{ $t('licenceError') }}</span>
            <div class="text-center">
              <div class="wave-btn-block">
                <button type="submit" class="blob-btn fill-bg btn-lg submit-btn" @click="signUp">
                  {{ $t('signUp') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
            <p class="small-text text-center mt-4">
              {{ $t('logInQuestion') }}
              <router-link to="/login" class="txt-bdr-link">{{ $t('logIn') }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import {
  ref, computed, reactive,
} from 'vue';
import { useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, minLength, helpers, sameAsPassword,
} from '@vuelidate/validators';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import CLayerBaseService from '@/services/CLayerBaseService';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import LoginAnimation from '@/components/partials/LoginAnimation.vue';

const cLayerBaseService = new CLayerBaseService();
const storyphoneService = new StoryphoneService();

export default {
  name: 'signup',
  props: [],
  components: {
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
    LoginAnimation,
  },
  setup() {
    const router = useRouter();
    const showPassword = ref(false);
    const terms = ref(false);
    const policy = ref(false);
    const error = ref('');
    const isLoading = ref(false);
    const userSignup = reactive({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    });

    const rules = {
      firstName: {
        required: helpers.withMessage('You must enter your first name', required),
        name_validation: {
          $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
        },
      },
      lastName: { required: helpers.withMessage('You must enter your last name', required) },
      email: { required: helpers.withMessage('You must enter your email', required), email },
      password: {
        required: helpers.withMessage('You must enter a password', required),
        min: minLength(8),
        containsPasswordRequirement: helpers.withMessage(
          () => 'The password requires an uppercase, lowercase, number',
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
        ),
      },
    };

    const v$ = useVuelidate(rules, userSignup);

    const toggleShowPassword = () => {
      const password = document.querySelector('#password');
      if (showPassword.value) {
        showPassword.value = false;
        password.setAttribute('type', 'password');
      } else {
        showPassword.value = true;
        password.setAttribute('type', 'text');
      }
    };

    const signUp = async () => {
      const result = await v$.value.$validate();
      if (result) {
        if (terms.value === false) {
          policy.value = true;
        } else {
          policy.value = false;
          isLoading.value = true;
          const credentials = {
            email: userSignup.email,
            password: userSignup.password,

          };
          storyphoneService.signUp(credentials)
            .then((signup) => {
              isLoading.value = false;
              router.push('/confirm-email');
            })
            .catch((err) => {
              console.log(err.response);
              isLoading.value = false;
              error.value = err?.response?.data?.message;
              // console.log(err.response.data.errors[0].msg);
            });
        }
      } else {
        console.log(v$);
      }
    };

    return {
      showPassword,
      terms,
      policy,
      error,
      isLoading,
      userSignup,
      v$,
      toggleShowPassword,
      signUp,
    };
  },
};
</script>

<style scoped lang="scss">
.signup {
}
</style>
