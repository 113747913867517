<template lang="html">
  <section class="py-8 pb-5 adv-storyphone">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="mini-video-block">
            <button class="video-play-btn" @click="playEntertainmentVideo()"></button>
            <video id="entertainment" class="w-100" width="100%" autoplay="autoplay" loop="loop">
              <source src="@/assets/videos/s3.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="video-small-pic ms-auto">
            <img src="@/assets/images/mmuntitled.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="entertainment-block">
            <div class="row">
              <div class="col-md-9 m-auto">
                <div class="heading-with-highlight" v-html="Details"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

let entertainmentVideo;

export default {
  name: 'StoryphonesAdventure',
  components: {},
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_screen_free_${langCode}`,
    };
    store.dispatch('fetchCMS8', Data);

    const screenFree = computed(() => store.getters.getCMS8);
    const Details = computed(() => DOMPurify.sanitize(screenFree.value.html_description));

    return {
      screenFree,
      langCode,
      Details,
    };
  },
  mounted() {
    entertainmentVideo = document.getElementById('entertainment');
  },
  data() {
    return {};
  },
  methods: {
    playEntertainmentVideo() {
      entertainmentVideo.play();
    },
  },
  computed: {},
};
</script>
<style></style>
