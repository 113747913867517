/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getUserDetails = (state) => isObj(state, 'userDetails', {});

export const getUserAddress = (state) => {
    const address = isObj(state, 'userAddress', false);
    return address;
};

export const getArtWork = (state) => isObj(state, 'arkWorks', {});

export const setArtWork = (state) => isObj(state, 'selectedArtwork', '');

export const getBackgroundColors = (state) => isObj(state, 'backgroundColors', {});

export const setBackgroundColor = (state) => isObj(state, 'selectedColor', '');

export const getvideoSrc = (state) => isObj(state, 'videoSrc', '');

export const getRecordingList = (state) => {
    const item = isObj(state, 'recordingList', []);
    return item;
};

export const listDuration = (state) => {
    let time = 0;
    const list = isObj(state, 'recordingList', []);
    if (list) {
        list.forEach((item) => {
            time += item.duration;
        });
    }
    return time;
};

export const getsearchTerm = (state) => isObj(state, 'searchVariable', '');

export const getCreativeStories = (state) => isObj(state, 'creativeStories', '');

export const getUserStoryShields = (state) => isObj(state, 'userStoryShields', '');

export const getUserPlayShields = (state) => isObj(state, 'userPlayShields', '');

export const getUserMarkets = (state) => isObj(state, 'userMarkets', '');

export const getSelectedMarket = (state) => isObj(state, 'selectedMarket', []);

export const getSelectedLanguages = (state) => isObj(state, 'languages', []);

export const getSelectedRecordings = (state) => isObj(state, 'recordings', []);
