<template>
  <div class="loading-overlay">
    <div id="loading" class="loading text-center">
      <img src="../../assets/images/loader.gif" alt="" />
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'Loader',
};
</script>
<style scoped lang="scss">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  .loading {
    img {
      width: 40px;
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
    }
  }
}
</style>
