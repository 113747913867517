import BaseService from './BaseService';

export default class AuthenticationService extends BaseService {
  userActivation(userActiveData) {
    return new Promise((resolve, reject) => {
      const apiEndpoint = `${this.baseURL}v1/auth/activate/`;
      this.http
        .post(apiEndpoint, userActiveData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    };
  }
}
