export const SET_SC_CHANNEL_TOKEN = 'SET_SC_CHANNEL_TOKEN';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const UPDATE_LINE_ITEMS = 'UPDATE_LINE_ITEM';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const DELIVERY_LEAD_TIME = 'DELIVERY_LEAD_TIME';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_OUT_OF_STOCK_MESSAGE = 'GET_OUT_OF_STOCK_MESSAGE';
