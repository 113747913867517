<template lang="html">
  <PageHeader />
  <section class="faq mt-stickybar home">
    <div class="faq-banner text-center pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="text-end">
              <Language />
            </div> -->
            <img src="@/assets/images/faq.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="text-center">
            {{ $t('frequently') }}
              <!-- Frequently asked questions -->
            </h1>
            <div class="faq-list mt-5">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="faq-link active"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    <!-- Troubleshoot StoryPhones Headphones -->
                    <!-- {{ faq.title }} -->
                    <div class="faq-p" v-html="domSanitize(faq.html_description)"></div>
                  </button>
                </li>
                <!-- <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    StoryShields
                  </button>
                </li> -->
                <li class="nav-item" role="presentation">
                  <button
                    class="faq-link"
                    id="pills-storyphones-app-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-app"
                    type="button"
                    role="tab"
                    aria-controls="pills-app"
                    aria-selected="false"
                  >
                    <!-- Troubleshoot StoryPhones App -->
                    <!-- {{ app.title }} -->
                    <div class="faq-p" v-html="domSanitize(app.html_description)"></div>

                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="faq-link"
                    id="pills-playshield-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-playshield"
                    type="button"
                    role="tab"
                    aria-controls="pills-playshield"
                    aria-selected="false"
                  >
                    <!-- Troubleshoot Storyphones Wifi Setup -->
                    <!-- {{ wifi.title }} -->
                    <div class="faq-p" v-html="domSanitize(wifi.html_description)"></div>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="faq-link"
                    id="pills-account-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-account"
                    type="button"
                    role="tab"
                    aria-controls="pills-account"
                    aria-selected="false"
                  >
                    <!-- Account -->
                    <!-- {{ account.title }} -->
                    <div class="faq-p" v-html="domSanitize(account.html_description)"></div>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="faq-link"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="false"
                  >
                    <!-- {{ $t('D&R') }} -->
                    <!-- Deliveries -->
                    <!-- {{ deliveries.title }} -->
                    <div class="faq-p" v-html="domSanitize(deliveries.html_description)"></div>
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div class="accordion accordion-flush" id="accordionStoryphone">
                    <div
                      class="accordion-item"
                      v-for="(storyphoneQuestion, index) in faq.widgets"
                      :key="index"
                    >
                      <span v-for="(data, index1) in storyphoneQuestion.details" :key="index1">
                        <h2 class="accordion-header m-0 p-0" id="flush-headingOne2">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#flush-collapseOne2' + index1"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne2"
                          >
                            <!-- {{ data.title }} -->
                            <div v-html="domSanitize(data.title)"></div>

                          </button>
                        </h2>
                        <div
                          :id="'flush-collapseOne2' + index1"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne2"
                          data-bs-parent="#accordionStoryphone"
                        >
                          <div class="accordion-body px-0 py-2">
                            <!-- <p>{{ data.description }}</p> -->
                            <div v-html="domSanitize(data.description)"></div>

                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-app"
                  role="tabpanel"
                  aria-labelledby="pills-storyphones-app-tab"
                >
                  <div class="accordion accordion-flush" id="accordionstoryphonesApp">
                    <div
                      class="accordion-item"
                      v-for="(appQuestion, index) in app.widgets"
                      :key="index"
                    >
                      <span v-for="(data, index1) in appQuestion.details" :key="index1">
                        <h2 class="accordion-header m-0 p-0" id="flush-headingOne4">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#flush-collapseOne4' + index1"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne4"
                          >
                          <div v-html="domSanitize(data.title)"></div>
                          </button>
                        </h2>
                        <div
                          :id="'flush-collapseOne4' + index1"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne4"
                          data-bs-parent="#accordionstoryphonesApp"
                        >
                          <div class="accordion-body px-0 py-2">
                            <!-- <p>{{ data.description }}</p> -->
                            <div v-html="domSanitize(data.description)"></div>

                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-playshield"
                  role="tabpanel"
                  aria-labelledby="pills-playshield-tab"
                >
                  <div class="accordion accordion-flush" id="accordionPlayshield">
                    <div
                      class="accordion-item"
                      v-for="(wifiQuestion, index) in wifi.widgets"
                      :key="index"
                    >
                      <span v-for="(wifi, index1) in wifiQuestion.details" :key="index1">
                        <h2 class="accordion-header m-0 p-0" id="flush-headingOne5">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#flush-collapseOne5' + index1"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne5"
                          >
                            <!-- {{ wifi.title }} -->
                            <div v-html="domSanitize(wifi.title)"></div>

                          </button>
                        </h2>
                        <div
                          :id="'flush-collapseOne5' + index1"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne5"
                          data-bs-parent="#accordionPlayshield"
                        >
                          <div class="accordion-body px-0 py-2">
                            <!-- <p>{{ wifi.description }}</p> -->
                            <div v-html="domSanitize(wifi.description)"></div>

                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-account"
                  role="tabpanel"
                  aria-labelledby="pills-account-tab"
                >
                  <div class="accordion accordion-flush" id="accordionAccount">
                    <div
                      class="accordion-item"
                      v-for="(accountQuestion, index) in account.widgets"
                      :key="index"
                    >
                      <span v-for="(account, index1) in accountQuestion.details" :key="index1">
                        <h2 class="accordion-header m-0 p-0" id="flush-headingOne6">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#flush-collapseOne6' + index1"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne6"
                          >
                            <!-- {{ account.title }} -->
                            <div v-html="domSanitize(account.title)"></div>

                          </button>
                        </h2>
                        <div
                          :id="'flush-collapseOne6' + index1"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne6"
                          data-bs-parent="#accordionAccount"
                        >
                          <div class="accordion-body px-0 py-2">
                            <!-- <p>{{ account.description }}</p> -->
                            <div v-html="domSanitize(account.description)"></div>

                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div
                      class="accordion-item"
                      v-for="(returnsQuestion, index) in deliveries.widgets"
                      :key="index"
                    >
                      <span v-for="(deliveries, index1) in returnsQuestion.details" :key="index1">
                        <h2 class="accordion-header m-0 p-0" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#flush-collapseOne' + index1"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <!-- {{ deliveries.title }} -->
                            <div v-html="domSanitize(deliveries.title)"></div>

                          </button>
                        </h2>
                        <div
                          :id="'flush-collapseOne' + index1"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body px-0 py-2">
                            <!-- <p>{{ deliveries.description }}</p> -->
                            <div v-html="domSanitize(deliveries.description)"></div>

                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div class="accordion accordion-flush" id="accordionShields">
                    <div
                      class="accordion-item"
                      v-for="(shieldsQuestion, index) in shieldsQuestions"
                      :key="shieldsQuestion.id"
                    >
                      <h2 class="accordion-header m-0 p-0" id="flush-headingOne3">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#flush-collapseOne3' + index"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne3"
                        >
                          <!-- {{ shieldsQuestion.title }} -->
                          <div v-html="domSanitize(shieldsQuestion.title)"></div>

                        </button>
                      </h2>
                      <div
                        :id="'flush-collapseOne3' + index"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne3"
                        data-bs-parent="#accordionShields"
                      >
                        <div class="accordion-body px-0 py-2">
                          <!-- <p>{{ shieldsQuestion.description }}</p> -->
                          <div v-html="domSanitize(shieldsQuestion.description)"></div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mt-5 faq">
                {{ wifi.excerpt }}
                <a href="/contact-us" class="txt-bdr-link" aria-label="contact us"
                  ><span>{{ $t('contactUs') }}</span></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import Language from '@/components/commons/Language.vue';
import DOMPurify from 'dompurify';

export default {
  name: 'faq',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    // Language,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `faq_troubleshoot_headphones_${langCode}`,
    };
    store.dispatch('fetchCMS', Data);

    const Data1 = {
      code: langCode,
      slug: `faq_app_${langCode}`,
    };
    store.dispatch('fetchCMS2', Data1);

    const Data2 = {
      code: langCode,
      slug: `faq_wifi_${langCode}`,
    };
    store.dispatch('fetchCMS3', Data2);

    const Data3 = {
      code: langCode,
      slug: `faq_account_${langCode}`,
    };
    store.dispatch('fetchCMS4', Data3);

    const Data4 = {
      code: langCode,
      slug: `faq_deliveries_${langCode}`,
    };
    store.dispatch('fetchCMS5', Data4);

    const faq = computed(() => store.getters.getCMS);
    const app = computed(() => store.getters.getCMS2);
    const wifi = computed(() => store.getters.getCMS3);
    const account = computed(() => store.getters.getCMS4);
    const deliveries = computed(() => store.getters.getCMS5);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      faq, langCode, app, wifi, account, deliveries, domSanitize,
    };
  },
  mounted() {

  },
  data() {
    return {
      returnsQuestions: [
        {
          id: 1,
          title: 'How long will delivery take?',
          description: '',
        },
        {
          id: 2,
          title: 'Do you deliver worldwide?',
          description: '',
        },
        {
          id: 3,
          title: 'Where do you ship from?',
          description: 'We ship from warehouses in the US, Europe, and Asia.',
        },
        // {
        //   id: 4,
        //   title: 'Returns',
        //   description: '',
        // },
      ],
      storyphoneQuestions: [
        // {
        //   id: 1,
        //   title: 'What is StoryPhones?',
        //   description: 'StoryPhones is a magical pair of headphones that open up a whole world of interactive audio fun for listeners of all ages. StoryPhones are award winning SafeAudio®, smart headphones for kids that play audio content offline, offering the ultimate screen-free entertainment experience. It‘s all about bringing kids back to the basics, allowing them to enjoy the oldest way of entertainment, storytelling. A fun way to boost creativity and decrease screen time.',
        // },
        // {
        //   id: 2,
        //   title: 'Where can I buy StoryPhones?',
        //   description: 'You can buy it online with StoryPhones.com or through StoryPhones App. You can also buy it in our store which is located in…',
        // },
        {
          id: 3,
          title: 'Is StoryPhones safe with my kid?',
          description: 'Yes. StoryPhones are award winning SafeAudio® headphones for kids. They have received two CES innovation awards in no less than two categories Headphones & Personal Audio, and Health & Wellness.  Having StoryPhones selected as a winner of two CES Awards, amidst a great number of submitted designs, is truly an honor and a brilliant acknowledgment.',
        },
        {
          id: 4,
          title: 'What will be included if I buy a StoryPhones package?',
          description: 'For the onanoff StoryPhones package, you will have the StoryPhones, 1 ZenShield, 1 PlayShield, USB-C charging cable, Audio cable and Carry Bag. For the Disney StoryPhones, you will have 2 StoryShields, USB-C charging cable, Audio cable and Carry Bag.',
        },
        {
          id: 5,
          title: 'The StoryPhones does not switch on ?',
          description: 'Storyphones use batteries. Make sure the headphones have been charged for at least one hour through the provided USB-C charging cable. You can connect the cable to any USB charger available in your home. Once charged press the switch on/off button for 5 seconds. If no light is visible and no voice prompt ("power on") is heard, please kindly send an email to "Defective@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',

        },
        {
          id: 6,
          title: 'The shields got detached! Is the initial download continuing?',
          description: 'After attaching a StoryShield the first time, as long as the StoryPhones are connected to the WiFi, the download will start and continue till completed. Even if the StoryShield gets detached the download will continue.',
        },
        {
          id: 7,
          title: 'After how much time do my StoryPhones switch off automatically?',
          description: 'Your StoryPhones will be idle for 15 minutes before switching off. If a StoryShield is playing, the headphones will wait until its end and switch off only after 15min.',
        },

        {
          id: 8,
          title: ' How do I use StoryPhones as BT headphones?',
          description: 'StoryPhones automatically switch on the BT connection anytime it is switched on and anytime a StoryShield is not connected/disconnected A voice prompt "BT mode" will confirm this. You can also activate the BT mode manually by pressing the center button of the headphones controls for a few seconds till the voice prompt "BT mode" will be heard. ',
        },
        {
          id: 9,
          title: 'How do I use StoryPhones as wired headphones?',
          description: 'Connect the provided 3.5mm jack audio cable on one side to the headphones and the other side to your device. You can now use your StoryPhones as wired headphones. ',
        },
        {
          id: 10,
          title: 'Are StoryPhones volume limited?',
          description: 'Yes, all StoryPhones are volume limited. You can select between 75dB - recommended for toddlers, 85dB - recommended for all children and 94dB - when heavy background noises make it challenging to listen to your content (such as in airplanes, trains, and cars).  ',
        },
        {
          id: 11,
          title: 'How do I change my StoryPhones volume limitation?',
          description: 'You can change the volume limitation through the StoryPhones app. Go to the StoryPhones app and select the settings button on the StoryPhones image. Scroll down to the audio setting, activate safe audio, and click on update max volume. Select your safe volume and save. 75dB - recommended for toddlers, 85dB - recommended for all children, and 94dB - when heavy background noises make it challenging to listen to your content (such as in airplanes, trains, and cars).  ',
        },
        {
          id: 12,
          title: 'How do I listen to a new StoryShield?',
          description: 'Switch on the StoryPhones, check the WiFi is connected (see FAQ on how to connect your WiFi), and carefully attach the StoryShield to the side of the headphones (there should be no visible space between the shield and the headphone). The download should initiate automatically: the LEDs should be blinking yellow and a "high tone" prompt can be heard. The LEDs will continue to blink till the download is completed. The download will take between 2 to 7 minutes. Your new StoryShield will start playing after the download is completed.',
        },
        {
          id: 13,
          title: 'How do I browse through the chapters in my StoryShields?',
          description: 'You can browse through the chapters and stories by simply rotating the StoryShield. NOTE: there should be no visible space between the shield and the headphone. Rotate slowly till all groves are aligned. You can also use the control buttons on the side of the headphones to browse through the chapters.',
        },
        {
          id: 14,
          title: 'After attaching the StoryShields the LEDs are blinking red (or no LEDs are blinking).',
          description: 'Make sure the StoryPhones is connected to a WiFi network. You can check if your headphones are connected to the WiFi in your StoryPhones app. If you need more support to connect to the WiFi check further our FAQs. Make sure the StoryPhones were connected to the power supply for at least one hour after connecting to the WiFi. If after attaching the StoryShield you see the LED flashing red, or you hear a low-tone prompt, please send an email to "Defective@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
        {
          id: 15,
          title: 'After attaching the StoryShields the LEDs continue to blink for over 20 minutes.',
          description: 'If the LED continues to blink for over 20 mins without stopping, please send an email to "Defective@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
      ],
      shieldsQuestions: [
        {
          id: 1,
          title: 'What is StoryShields?',
          description: 'Discover our library full of carefully curated content, suitable for children aged 3 and up, that entertains and educates with audio stories, music and podcasts catering to different tastes. This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
        },
        {
          id: 2,
          title: 'What is ZenShields?',
          description: 'StoryPhones can help with sleep and relaxation. ZenShield offers high fidelity soothing sounds, to help overstimulated kids calm down, relax and even sleep with 6 relaxing sound profiles such as Forest, Classical Piano, Waves, White Noise, Soft Rainfall and RoRo Lullaby. The exclusive RóRó sound profile features soothing sounds of real-life heartbeat and breathing inspired by scientific research on closeness, sleep and wellbeing with the help of doctors, nurses, psychologists, parents and babies.',
        },
        {
          id: 3,
          title: 'What is PlayShields?',
          description: 'StoryPhones offer the option of recording your own stories, in your own voice with PlayShield. Together with StoryPhones and PlayShield it has never been easier to share stories from one generation to the next. Building your own PlayShield by reading a story in your own voice is also a brilliant way to stay connected with loved ones far away. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice. And parents can soothe their child with a lullaby sung in their own voice, when they need to be away from the child. We also offer a custom Playshield that allows you to draw or print your own artwork on the shield to create your own fully personalized shield.',
        },
      ],
      appQuestions: [
        {
          id: 1,
          title: 'What is StoryPhones App for?',
          description: 'With the StoryPhones App, you can record your own story and create your own collection. It is also a parental-controlled device for you to manage your kids headphones with SafeAudio volume. You can also purchase your favorite StoryShields and StoryPhones within the App.',
        },
        {
          id: 2,
          title: ' How do I record my own story with the StoryPhones App?',
          description: 'Our app walks you through a step-by-step process of recording your own audio. Once you enter our app, there will be a record button at the middle of the interface. That is where your journey begins.',
        },
        {
          id: 3,
          title: 'How do I pair my StoryPhones to my phone?',
          description: 'You can find the “Add new StoryPhones” option in the device section within the StoryPhones App. Once you click it, there will be a step-by-step process to guide you through the set up.',
        },
        {
          id: 4,
          title: 'Can I manage my headphones through the App?',
          description: 'Yes. You can see the status of your headphones like battery, volume level, SafeAudio etc.',
        },
        {
          id: 5,
          title: 'What languages do StoryPhones APP support?',
          description: 'We now have English, French, Spanish, German and Italian.',
        },
        {
          id: 6,
          title: 'The StoryPhones App does not open.',
          description: 'Please uninstall the app and re-install the app on your phone. Make sure the app is updated to the latest version. If the problem persists, please check the list of compatible devices and operating systems in our FAQs',
        },
        {
          id: 7,
          title: 'StoryPhones App compatibility list.',
          description: 'iPhones with iOS Requires iOS 12.0 or later.  Android with OS Requires 7.0 and up.',
        },
        {
          id: 8,
          title: 'How do I upgrade my StoryPhone app?',
          description: 'Open your App Store or Google Play store and look for the StoryPhones app. Check if any updates are available. Update the app to the latest version.',
        },
        {
          id: 9,
          title: 'Where do I see the version of my StoryPhones app?',
          description: 'Open your App Store or Google Play store and look for the StoryPhones app. In the description, you will see the latest StoryPhones app version available. If your downloaded app is the same version, well done. If your downloaded app is of a different version, please update the app for secure and higher performance.',
        },
        {
          id: 10,
          title: 'I lost my StoryShield, can I still listen to the story?',
          description: 'Unfortunately once the StoryShield is lost the stories cannot be played any longer. Treasure and collect all your StoryShields in a safe place so you can listen to them anytime anywhere for generations to come. Your application is not working well. If your application is not performing. please check if the app is the latest version available. If problems continue, please check the app compatibility list, uninstall the app, and install it once more.',
        },
        {
          id: 11,
          title: 'I am experiencing an issue with my StoryPhones.',
          description: 'Please review the FAQ on "StoryPhones.com" or send an email to "support@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
      ],
      // wifi troubleshoot Question
      playshieldQuestions: [
        // {
        //   id: 1,
        //   title: 'What is PlayShield+?',
        //   description: 'A custom PlayShield allows you to customize your own PlayShield with your artwork. The idea is to bridge the generations and you can tell stories with your loved ones even if you are far away from them!',
        // },
        // {
        //   id: 2,
        //   title: 'Are there any differences between PlayShield and PlayShield+?',
        //   description: 'Yes. Apart from the recording function, PlayShield+ allows you to select your own artwork and print it on the shield. It will be the one and only shield for you and your beloved ones. While PlayShield can’t customize your artwork but you can record on your own also!',
        // },
        // {
        //   id: 3,
        //   title: 'How can I customize my own PlayShield?',
        //   description: 'You can order your PlayShield+ on the StoryPhones App and follow the guideline to finish your purchase. After finishing, your PlayShield+ will be delivered to your home.',
        // },
        {
          id: 1,
          title: 'How do I change the WiFi network or password?',
          description: 'Go to the StoryPhones app and select the settings button on the StoryPhones image. Scroll to WiFi settings and click on change WiFi. Select the correct WiFi and follow the steps to complete the connection to the new WiFi network. During this process, you can enter a new WiFi password if needed.',
        },
        {
          id: 2,
          title: 'How do I connect to the WiFi?',
          description: 'Download the StoryPhones app on your smartphone and ensure it is the latest version available. Open the StoryPhones app and follow the instruction you will see on the screen or here below to set up your StoryPhones. Set up or log in to your account. Click add a new device Switch on the StoryPhones. Link the app with the StoryPhones. Connect to the WiFi network - 2.4Ghz compatible only (consult your router or internet provider, if necessary, no special parental controls or VPN should be active) and enter your password, Name your StoryPhones. You are now connected to the WiFi. We RECOMMEND leaving the StoryPhones connected to a charger for at least one hour after connecting to the WiFi the first time to upgrade its firmware. If the problem is not resolved please see further our FAQs or send an email to "WiFi@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
        {
          id: 3,
          title: 'Where do I check the firmware version of my StoryPhones?',
          description: 'Go to the StoryPhones app and select the settings button on the StoryPhones image. Scroll to StoryPhones and check your headphone\'s firmware version.',
        },
        {
          id: 4,
          title: 'How do I know if my firmware is up to date?',
          description: 'Check at the beginning of these FAQs to see the latest version of the firmware. Go to the StoryPhones app and select the settings button on the StoryPhones image. Scroll to StoryPhones and check your headphone\'s firmware version. If the two versions are the same your firmware is up to date.',
        },
        {
          id: 5,
          title: 'How do I update the firmware of my StoryPhones?',
          description: 'Switch on your headphones and make sure they are connected to the WiFi (you can verify this on your StoryPhones app) and let them charge through the provided USB-C charger for at least one hour. The firmware will update automatically. The network should be 2.4Gh (consult your router or internet provider, if necessary, no special parental controls or VPN should be active).',
        },
        {
          id: 6,
          title: 'My StoryPhones is not able to connect to the WiFi.',
          description: 'Before starting any WiFi troubleshooting, please check if your Bluetooth connection is in good order. To do so please disconnect any StoryShield from the StoryPhones, ensure the "BT mode" is active and then open the BT setting in your device and pair it with the StoryPhones. Just in case, switch off and switch on the StoryPhones if the headphones are not discovered within a few seconds. Once connected, please try and listen to something. If your BT connection does not work (please repeat the instructions above once more if possible) then send an email to "Defective@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon. If your BT connection works as expected continue reading. To check your WiFi connection reset your StoryPhones to factory setting by pressing the power button and the volume up button for 10 seconds (a voice prompt will confirm the unit is resetting together with some blue flashing lights). The headphones will automatically switch off and restart. Set up your StoryPhones once more by following the steps explained in the app or here below.Open StoryPhones app. Set up or log in to your account. Click add a new device. Switch on the StoryPhones. Link the app with the StoryPhone. Connect to the wifi network - 2.4Ghz compatible only (consult your router or internet provider, if necessary, no special parental controls or VPN should be active) and enter your password, Name to your StoryPhones If the problem is not resolved please send an email to "WiFi@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon. NOTE: StoryPhones may not work properly if connected to a hotspot made by a mobile phone or tablet.',
        },
        {
          id: 7,
          title: 'The BT mode does not work.',
          description: 'Please disconnect any StoryShield from the StoryPhones, ensure the "BT mode" is active and then open the BT setting in your device and pair it with the StoryPhones. Just in case, switch off and switch on the StoryPhones if the headphones are not discovered within a few seconds. Once connected, please try and listen to something. If your BT connection does not work (please repeat the instructions above once more if possible) then send an email to "Defective@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
        {
          id: 8,
          title: 'I cannot upgrade my Firmware.',
          description: 'Please check "How do I update the firmware of my StoryPhones?" in our FAQs. If after trying this the firmware does not update please send an email to "WiFi@StoryPhones.com" stating the issue in the subject, describing your experience, and providing your details (full name, full address, and telephone number). Our support team will reach out with a solution soon.',
        },
      ],
      accountQuestions: [
        {
          id: 1,
          title: 'Is my StoryPhones account sync up together with my phone and website?',
          description: 'Yes. All the content will be sync up through the website and app if you created your account with StoryPhones.',
        },
        {
          id: 2,
          title: 'What if I forgot my password?',
          description: 'When you try to login to your account and forget your password, there is an option “Forgot your password?” which will direct you to reset your password. You can check with your email and finish the steps!',
        },
      ],
      germanReturnsQuestions: [
        {
          id: 1,
          title: 'How long will delivery take?',
          description: 'German',
        },
        {
          id: 2,
          title: 'Do you deliver worldwide?',
          description: '',
        },
        {
          id: 3,
          title: 'Where do you ship from?',
          description: 'We ship from warehouses in the US, Europe, and Asia.',
        },
        {
          id: 4,
          title: 'Returns',
          description: '',
        },
      ],
      germanStoryphoneQuestions: [
        {
          id: 1,
          title: 'What is German?',
          description: 'StoryPhones is a magical pair of headphones that open up a whole world of interactive audio fun for listeners of all ages. StoryPhones are award winning SafeAudio®, smart headphones for kids that play audio content offline, offering the ultimate screen-free entertainment experience. It‘s all about bringing kids back to the basics, allowing them to enjoy the oldest way of entertainment, storytelling. A fun way to boost creativity and decrease screen time.',
        },
        {
          id: 2,
          title: 'Where can I buy StoryPhones?',
          description: 'You can buy it online with StoryPhones.com or through StoryPhones App. You can also buy it in our store which is located in…',
        },
        {
          id: 3,
          title: 'Is StoryPhones safe with my kid?',
          description: 'Yes. StoryPhones are award winning SafeAudio® headphones for kids. They have received two CES innovation awards in no less than two categories; Headphones & Personal Audio, and Health & Wellness. Having StoryPhones selected as a winner of two CES Awards, amidst a great number of submitted designs, is truly an honor and a brilliant acknowledgment.',
        },
        {
          id: 4,
          title: 'What will be included if I buy a StoryPhones package?',
          description: 'For the onanoff StoryPhones package, you will have the StoryPhones, 1 ZenShield, 1 PlayShield, USB-C charging cable, Audio cable and Carry Bag. For the Disney StoryPhones, you will have 2 StoryShields, USB-C charging cable, Audio cable and Carry Bag.',
        },
      ],
      germanShieldsQuestions: [
        {
          id: 1,
          title: 'What is German?',
          description: 'Discover our library full of carefully curated content, suitable for children aged 3 and up, that entertains and educates with audio stories, music and podcasts catering to different tastes. This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
        },
        {
          id: 2,
          title: 'What is ZenShields?',
          description: 'StoryPhones can help with sleep and relaxation. ZenShield offers high fidelity soothing sounds, to help overstimulated kids calm down, relax and even sleep with 6 relaxing sound profiles such as Forest, Classical Piano, Waves, White Noise, Soft Rainfall and RoRo Lullaby. The exclusive RóRó sound profile features soothing sounds of real-life heartbeat and breathing inspired by scientific research on closeness, sleep and wellbeing with the help of doctors, nurses, psychologists, parents and babies.',
        },
        {
          id: 3,
          title: 'What is PlayShields?',
          description: 'StoryPhones offer the option of recording your own stories, in your own voice with PlayShield. Together with StoryPhones and PlayShield it has never been easier to share stories from one generation to the next. Building your own PlayShield by reading a story in your own voice is also a brilliant way to stay connected with loved ones far away. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice. And parents can soothe their child with a lullaby sung in their own voice, when they need to be away from the child. We also offer a custom Playshield that allows you to draw or print your own artwork on the shield to create your own fully personalized shield.',
        },
      ],
      germanAppQuestions: [
        {
          id: 1,
          title: 'What is German App for?',
          description: 'With the StoryPhones App, you can record your own story and create your own collection. It is also a parental-controlled device for you to manage your kids headphones with SafeAudio volume. You can also purchase your favorite StoryShields and StoryPhones within the App.',
        },
        {
          id: 2,
          title: ' How do I record my own story with the StoryPhones App?',
          description: 'Our app walks you through a step-by-step process of recording your own audio. Once you enter our app, there will be a record button at the middle of the interface. That is where your journey begins.',
        },
        {
          id: 3,
          title: 'How do I pair my StoryPhones to my phone?',
          description: 'You can find the “Add new StoryPhones” option in the device section within the StoryPhones App. Once you click it, there will be a step-by-step process to guide you through the set up.',
        },
        {
          id: 4,
          title: 'Can I manage my headphones through the App?',
          description: 'Yes. You can see the status of your headphones like battery, volume level, SafeAudio etc.',
        },
        {
          id: 5,
          title: 'What languages do StoryPhones APP support?',
          description: 'We now have English, French, Spanish, German and Italian.',
        },
      ],
      germanPlayshieldQuestions: [
        {
          id: 1,
          title: 'What is German+?',
          description: 'A custom PlayShield allows you to customize your own PlayShield with your artwork. The idea is to bridge the generations and you can tell stories with your loved ones even if you are far away from them!',
        },
        {
          id: 2,
          title: 'Are there any differences between PlayShield and PlayShield+?',
          description: 'Yes. Apart from the recording function, PlayShield+ allows you to select your own artwork and print it on the shield. It will be the one and only shield for you and your beloved ones. While PlayShield can’t customize your artwork but you can record on your own also!',
        },
        {
          id: 3,
          title: 'How can I customize my own PlayShield?',
          description: 'You can order your PlayShield+ on the StoryPhones App and follow the guideline to finish your purchase. After finishing, your PlayShield+ will be delivered to your home.',
        },
      ],
      germanAccountQuestions: [
        {
          id: 1,
          title: 'Is my German account sync up together with my phone and website?',
          description: 'Yes. All the content will be sync up through the website and app if you created your account with StoryPhones.',
        },
        {
          id: 2,
          title: 'What if I forgot my password?',
          description: 'When you try to login to your account and forget your password, there is an option “Forgot your password?” which will direct you to reset your password. You can check with your email and finish the steps!',
        },
      ],
      spanishReturnsQuestions: [
        {
          id: 1,
          title: 'How long will delivery take?',
          description: 'Spanish',
        },
        {
          id: 2,
          title: 'Do you deliver worldwide?',
          description: '',
        },
        {
          id: 3,
          title: 'Where do you ship from?',
          description: 'We ship from warehouses in the US, Europe, and Asia.',
        },
        {
          id: 4,
          title: 'Returns',
          description: '',
        },
      ],
      spanishStoryphoneQuestions: [
        {
          id: 1,
          title: 'What is Spanish?',
          description: 'StoryPhones is a magical pair of headphones that open up a whole world of interactive audio fun for listeners of all ages. StoryPhones are award winning SafeAudio®, smart headphones for kids that play audio content offline, offering the ultimate screen-free entertainment experience. It‘s all about bringing kids back to the basics, allowing them to enjoy the oldest way of entertainment, storytelling. A fun way to boost creativity and decrease screen time.',
        },
        {
          id: 2,
          title: 'Where can I buy Spanish?',
          description: 'You can buy it online with StoryPhones.com or through StoryPhones App. You can also buy it in our store which is located in…',
        },
        {
          id: 3,
          title: 'Is StoryPhones safe with my kid?',
          description: 'Yes. StoryPhones are award winning SafeAudio® headphones for kids. They have received two CES innovation awards in no less than two categories; Headphones & Personal Audio, and Health & Wellness. Having StoryPhones selected as a winner of two CES Awards, amidst a great number of submitted designs, is truly an honor and a brilliant acknowledgment.',
        },
        {
          id: 4,
          title: 'What will be included if I buy a StoryPhones package?',
          description: 'For the onanoff StoryPhones package, you will have the StoryPhones, 1 ZenShield, 1 PlayShield, USB-C charging cable, Audio cable and Carry Bag. For the Disney StoryPhones, you will have 2 StoryShields, USB-C charging cable, Audio cable and Carry Bag.',
        },
      ],
      spanishShieldsQuestions: [
        {
          id: 1,
          title: 'What is Spanish?',
          description: 'Discover our library full of carefully curated content, suitable for children aged 3 and up, that entertains and educates with audio stories, music and podcasts catering to different tastes. This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
        },
        {
          id: 2,
          title: 'What is ZenShields?',
          description: 'StoryPhones can help with sleep and relaxation. ZenShield offers high fidelity soothing sounds, to help overstimulated kids calm down, relax and even sleep with 6 relaxing sound profiles such as Forest, Classical Piano, Waves, White Noise, Soft Rainfall and RoRo Lullaby. The exclusive RóRó sound profile features soothing sounds of real-life heartbeat and breathing inspired by scientific research on closeness, sleep and wellbeing with the help of doctors, nurses, psychologists, parents and babies.',
        },
        {
          id: 3,
          title: 'What is PlayShields?',
          description: 'StoryPhones offer the option of recording your own stories, in your own voice with PlayShield. Together with StoryPhones and PlayShield it has never been easier to share stories from one generation to the next. Building your own PlayShield by reading a story in your own voice is also a brilliant way to stay connected with loved ones far away. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice. And parents can soothe their child with a lullaby sung in their own voice, when they need to be away from the child. We also offer a custom Playshield that allows you to draw or print your own artwork on the shield to create your own fully personalized shield.',
        },
      ],
      spanishAppQuestions: [
        {
          id: 1,
          title: 'What is Spanish App for?',
          description: 'With the StoryPhones App, you can record your own story and create your own collection. It is also a parental-controlled device for you to manage your kids headphones with SafeAudio volume. You can also purchase your favorite StoryShields and StoryPhones within the App.',
        },
        {
          id: 2,
          title: ' How do I record my own story with the StoryPhones App?',
          description: 'Our app walks you through a step-by-step process of recording your own audio. Once you enter our app, there will be a record button at the middle of the interface. That is where your journey begins.',
        },
        {
          id: 3,
          title: 'How do I pair my StoryPhones to my phone?',
          description: 'You can find the “Add new StoryPhones” option in the device section within the StoryPhones App. Once you click it, there will be a step-by-step process to guide you through the set up.',
        },
        {
          id: 4,
          title: 'Can I manage my headphones through the App?',
          description: 'Yes. You can see the status of your headphones like battery, volume level, SafeAudio etc.',
        },
        {
          id: 5,
          title: 'What languages do StoryPhones APP support?',
          description: 'We now have English, French, Spanish, German and Italian.',
        },
      ],
      spanishPlayshieldQuestions: [
        {
          id: 1,
          title: 'What is Spanish+?',
          description: 'A custom PlayShield allows you to customize your own PlayShield with your artwork. The idea is to bridge the generations and you can tell stories with your loved ones even if you are far away from them!',
        },
        {
          id: 2,
          title: 'Are there any differences between PlayShield and PlayShield+?',
          description: 'Yes. Apart from the recording function, PlayShield+ allows you to select your own artwork and print it on the shield. It will be the one and only shield for you and your beloved ones. While PlayShield can’t customize your artwork but you can record on your own also!',
        },
        {
          id: 3,
          title: 'How can I customize my own PlayShield?',
          description: 'You can order your PlayShield+ on the StoryPhones App and follow the guideline to finish your purchase. After finishing, your PlayShield+ will be delivered to your home.',
        },
      ],
      spanishAccountQuestions: [
        {
          id: 1,
          title: 'Is my Spanish account sync up together with my phone and website?',
          description: 'Yes. All the content will be sync up through the website and app if you created your account with StoryPhones.',
        },
        {
          id: 2,
          title: 'What if I forgot my password?',
          description: 'When you try to login to your account and forget your password, there is an option “Forgot your password?” which will direct you to reset your password. You can check with your email and finish the steps!',
        },
      ],
      frenchReturnsQuestions: [
        {
          id: 1,
          title: 'How long will delivery take?',
          description: 'French',
        },
        {
          id: 2,
          title: 'Do you deliver worldwide?',
          description: '',
        },
        {
          id: 3,
          title: 'Where do you ship from?',
          description: 'We ship from warehouses in the US, Europe, and Asia.',
        },
        {
          id: 4,
          title: 'Returns',
          description: '',
        },
      ],
      frenchStoryphoneQuestions: [
        {
          id: 1,
          title: 'What is French?',
          description: 'StoryPhones is a magical pair of headphones that open up a whole world of interactive audio fun for listeners of all ages. StoryPhones are award winning SafeAudio®, smart headphones for kids that play audio content offline, offering the ultimate screen-free entertainment experience. It‘s all about bringing kids back to the basics, allowing them to enjoy the oldest way of entertainment, storytelling. A fun way to boost creativity and decrease screen time.',
        },
        {
          id: 2,
          title: 'Where can I buy StoryPhones?',
          description: 'You can buy it online with StoryPhones.com or through StoryPhones App. You can also buy it in our store which is located in…',
        },
        {
          id: 3,
          title: 'Is StoryPhones safe with my kid?',
          description: 'Yes. StoryPhones are award winning SafeAudio® headphones for kids. They have received two CES innovation awards in no less than two categories; Headphones & Personal Audio, and Health & Wellness. Having StoryPhones selected as a winner of two CES Awards, amidst a great number of submitted designs, is truly an honor and a brilliant acknowledgment.',
        },
        {
          id: 4,
          title: 'What will be included if I buy a StoryPhones package?',
          description: 'For the onanoff StoryPhones package, you will have the StoryPhones, 1 ZenShield, 1 PlayShield, USB-C charging cable, Audio cable and Carry Bag. For the Disney StoryPhones, you will have 2 StoryShields, USB-C charging cable, Audio cable and Carry Bag.',
        },
      ],
      frenchShieldsQuestions: [
        {
          id: 1,
          title: 'What is French?',
          description: 'Discover our library full of carefully curated content, suitable for children aged 3 and up, that entertains and educates with audio stories, music and podcasts catering to different tastes. This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
        },
        {
          id: 2,
          title: 'What is ZenShields?',
          description: 'StoryPhones can help with sleep and relaxation. ZenShield offers high fidelity soothing sounds, to help overstimulated kids calm down, relax and even sleep with 6 relaxing sound profiles such as Forest, Classical Piano, Waves, White Noise, Soft Rainfall and RoRo Lullaby. The exclusive RóRó sound profile features soothing sounds of real-life heartbeat and breathing inspired by scientific research on closeness, sleep and wellbeing with the help of doctors, nurses, psychologists, parents and babies.',
        },
        {
          id: 3,
          title: 'What is PlayShields?',
          description: 'StoryPhones offer the option of recording your own stories, in your own voice with PlayShield. Together with StoryPhones and PlayShield it has never been easier to share stories from one generation to the next. Building your own PlayShield by reading a story in your own voice is also a brilliant way to stay connected with loved ones far away. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice. And parents can soothe their child with a lullaby sung in their own voice, when they need to be away from the child. We also offer a custom Playshield that allows you to draw or print your own artwork on the shield to create your own fully personalized shield.',
        },
      ],
      frenchAppQuestions: [
        {
          id: 1,
          title: 'What is French App for?',
          description: 'With the StoryPhones App, you can record your own story and create your own collection. It is also a parental-controlled device for you to manage your kids headphones with SafeAudio volume. You can also purchase your favorite StoryShields and StoryPhones within the App.',
        },
        {
          id: 2,
          title: ' How do I record my own story with the StoryPhones App?',
          description: 'Our app walks you through a step-by-step process of recording your own audio. Once you enter our app, there will be a record button at the middle of the interface. That is where your journey begins.',
        },
        {
          id: 3,
          title: 'How do I pair my StoryPhones to my phone?',
          description: 'You can find the “Add new StoryPhones” option in the device section within the StoryPhones App. Once you click it, there will be a step-by-step process to guide you through the set up.',
        },
        {
          id: 4,
          title: 'Can I manage my headphones through the App?',
          description: 'Yes. You can see the status of your headphones like battery, volume level, SafeAudio etc.',
        },
        {
          id: 5,
          title: 'What languages do StoryPhones APP support?',
          description: 'We now have English, French, Spanish, German and Italian.',
        },
      ],
      frenchPlayshieldQuestions: [
        {
          id: 1,
          title: 'What is French+?',
          description: 'A custom PlayShield allows you to customize your own PlayShield with your artwork. The idea is to bridge the generations and you can tell stories with your loved ones even if you are far away from them!',
        },
        {
          id: 2,
          title: 'Are there any differences between PlayShield and PlayShield+?',
          description: 'Yes. Apart from the recording function, PlayShield+ allows you to select your own artwork and print it on the shield. It will be the one and only shield for you and your beloved ones. While PlayShield can’t customize your artwork but you can record on your own also!',
        },
        {
          id: 3,
          title: 'How can I customize my own PlayShield?',
          description: 'You can order your PlayShield+ on the StoryPhones App and follow the guideline to finish your purchase. After finishing, your PlayShield+ will be delivered to your home.',
        },
      ],
      frenchAccountQuestions: [
        {
          id: 1,
          title: 'Is my French account sync up together with my phone and website?',
          description: 'Yes. All the content will be sync up through the website and app if you created your account with StoryPhones.',
        },
        {
          id: 2,
          title: 'What if I forgot my password?',
          description: 'When you try to login to your account and forget your password, there is an option “Forgot your password?” which will direct you to reset your password. You can check with your email and finish the steps!',
        },
      ],
      italianReturnsQuestions: [
        {
          id: 1,
          title: 'How long will delivery take?',
          description: 'Italian',
        },
        {
          id: 2,
          title: 'Do you deliver worldwide?',
          description: '',
        },
        {
          id: 3,
          title: 'Where do you ship from?',
          description: 'We ship from warehouses in the US, Europe, and Asia.',
        },
        {
          id: 4,
          title: 'Returns',
          description: '',
        },
      ],
      italianStoryphoneQuestions: [
        {
          id: 1,
          title: 'What is Italian?',
          description: 'StoryPhones is a magical pair of headphones that open up a whole world of interactive audio fun for listeners of all ages. StoryPhones are award winning SafeAudio®, smart headphones for kids that play audio content offline, offering the ultimate screen-free entertainment experience. It‘s all about bringing kids back to the basics, allowing them to enjoy the oldest way of entertainment, storytelling. A fun way to boost creativity and decrease screen time.',
        },
        {
          id: 2,
          title: 'Where can I buy StoryPhones?',
          description: 'You can buy it online with StoryPhones.com or through StoryPhones App. You can also buy it in our store which is located in…',
        },
        {
          id: 3,
          title: 'Is StoryPhones safe with my kid?',
          description: 'Yes. StoryPhones are award winning SafeAudio® headphones for kids. They have received two CES innovation awards in no less than two categories; Headphones & Personal Audio, and Health & Wellness. Having StoryPhones selected as a winner of two CES Awards, amidst a great number of submitted designs, is truly an honor and a brilliant acknowledgment.',
        },
        {
          id: 4,
          title: 'What will be included if I buy a StoryPhones package?',
          description: 'For the onanoff StoryPhones package, you will have the StoryPhones, 1 ZenShield, 1 PlayShield, USB-C charging cable, Audio cable and Carry Bag. For the Disney StoryPhones, you will have 2 StoryShields, USB-C charging cable, Audio cable and Carry Bag.',
        },
      ],
      italinaShieldsQuestions: [
        {
          id: 1,
          title: 'What is Italian?',
          description: 'Discover our library full of carefully curated content, suitable for children aged 3 and up, that entertains and educates with audio stories, music and podcasts catering to different tastes. This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
        },
        {
          id: 2,
          title: 'What is ZenShields?',
          description: 'StoryPhones can help with sleep and relaxation. ZenShield offers high fidelity soothing sounds, to help overstimulated kids calm down, relax and even sleep with 6 relaxing sound profiles such as Forest, Classical Piano, Waves, White Noise, Soft Rainfall and RoRo Lullaby. The exclusive RóRó sound profile features soothing sounds of real-life heartbeat and breathing inspired by scientific research on closeness, sleep and wellbeing with the help of doctors, nurses, psychologists, parents and babies.',
        },
        {
          id: 3,
          title: 'What is PlayShields?',
          description: 'StoryPhones offer the option of recording your own stories, in your own voice with PlayShield. Together with StoryPhones and PlayShield it has never been easier to share stories from one generation to the next. Building your own PlayShield by reading a story in your own voice is also a brilliant way to stay connected with loved ones far away. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice. And parents can soothe their child with a lullaby sung in their own voice, when they need to be away from the child. We also offer a custom Playshield that allows you to draw or print your own artwork on the shield to create your own fully personalized shield.',
        },
      ],
      italinaAppQuestions: [
        {
          id: 1,
          title: 'What is Italian App for?',
          description: 'With the StoryPhones App, you can record your own story and create your own collection. It is also a parental-controlled device for you to manage your kids headphones with SafeAudio volume. You can also purchase your favorite StoryShields and StoryPhones within the App.',
        },
        {
          id: 2,
          title: ' How do I record my own story with the StoryPhones App?',
          description: 'Our app walks you through a step-by-step process of recording your own audio. Once you enter our app, there will be a record button at the middle of the interface. That is where your journey begins.',
        },
        {
          id: 3,
          title: 'How do I pair my StoryPhones to my phone?',
          description: 'You can find the “Add new StoryPhones” option in the device section within the StoryPhones App. Once you click it, there will be a step-by-step process to guide you through the set up.',
        },
        {
          id: 4,
          title: 'Can I manage my headphones through the App?',
          description: 'Yes. You can see the status of your headphones like battery, volume level, SafeAudio etc.',
        },
        {
          id: 5,
          title: 'What languages do StoryPhones APP support?',
          description: 'We now have English, French, Spanish, German and Italian.',
        },
      ],
      italianPlayshieldQuestions: [
        {
          id: 1,
          title: 'What is Italian+?',
          description: 'A custom PlayShield allows you to customize your own PlayShield with your artwork. The idea is to bridge the generations and you can tell stories with your loved ones even if you are far away from them!',
        },
        {
          id: 2,
          title: 'Are there any differences between PlayShield and PlayShield+?',
          description: 'Yes. Apart from the recording function, PlayShield+ allows you to select your own artwork and print it on the shield. It will be the one and only shield for you and your beloved ones. While PlayShield can’t customize your artwork but you can record on your own also!',
        },
        {
          id: 3,
          title: 'How can I customize my own PlayShield?',
          description: 'You can order your PlayShield+ on the StoryPhones App and follow the guideline to finish your purchase. After finishing, your PlayShield+ will be delivered to your home.',
        },
      ],
      italianAccountQuestions: [
        {
          id: 1,
          title: 'Is my Italian account sync up together with my phone and website?',
          description: 'Yes. All the content will be sync up through the website and app if you created your account with StoryPhones.',
        },
        {
          id: 2,
          title: 'What if I forgot my password?',
          description: 'When you try to login to your account and forget your password, there is an option “Forgot your password?” which will direct you to reset your password. You can check with your email and finish the steps!',
        },
      ],
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
</style>
