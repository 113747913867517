<template lang="html">
  <div>
    <div v-if="isLoading"><Loader /></div>
    <section class="library-recording-list" aria-label="library-recording-list">
      <div class="d-none" :class="{ showrecordingBlock: showRecordBlock }">
        <div class="row">
          <div class="col-md-5">
            <div class="product-search library-search">
              <input
                class="search-filed form-control"
                v-model="searchField.search"
                type="search"
                :placeholder="$t('search')"
                aria-label="Search"
                v-on:keyup.enter="onSubmit()"
              />
              <span class="text-danger pl-3" v-if="v$$.search.$error">
                {{ v$$.search.$errors[0].$message }}
              </span>
              <button class="btn product-search-btn border-0" type="submit" @click="onSubmit()">
                <i class="icon-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="col-md-7">
            <div class="d-flex justify-content-end">
              <div class="add-recording wave-btn-block me-2">
                <router-link :to="{ name: 'recording' }" class="blob-btn fill-bg btn-lg submit-btn"
                  >{{ $t('addRecording') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </router-link>
                <SvgButton />
              </div>
              <!-- <div class="btn-group invite">
                <button
                  type="button"
                  class="blob-btn fill-bg btn-lg submit-btn"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Invite
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end animate__animated animate__fadeInUp">
                  <li>
                    <a class="dropdown-item" href="mailto:nithin@richkenmedia.com">
                      <span class="icon-invite"><i class="icon-email" aria-hidden="true"></i></span
                      >Email
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#" @click="copyURL()">
                      <span class="icon-invite"><i class="icon-link" aria-hidden="true"></i></span
                      >Copy Link
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="creativeStories" class="library-record-listing mt-5">
          <ul v-if="creativeStories?.items?.length > 0" class="row list-unstyled">
            <li v-for="(story, index) in creativeStories.items" :key="index" class="col-md-6">
              <button
                type="button"
                class="ibrary-listing-btn"
                data-bs-toggle="modal"
                data-bs-target="#library-recording-popup"
                @click="getAudioDetails(story)"
              >
                <span class="border-0 round-btn blue play me-2">
                  <i
                    class="icon-play3 ms-1 animate__animated animate__jackInTheBox"
                    aria-hidden="true"
                  ></i>
                </span>
                {{ story.title }}
              </button>
            </li>
          </ul>
        </div>

        <div v-else class="alert empty-cart" role="alert">
          <img src="@/assets/images/empty-cart.png" alt="Empty image" />
          <h2>{{ $t('oopsRecord') }}</h2>
          <div class="wave-btn-block">
            <router-link :to="{ name: 'recording' }" class="blob-btn fill-bg btn-lg submit-btn">
              {{ $t('recordNow') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-center mt-4">
              <VuejsPaginateNext
                :page-count="pageCount"
                :click-handler="pageClick"
                :margin-pages="2"
                :page-range="5"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
              ></VuejsPaginateNext>
            </div>
          </div>
        </div>
      </div>

      <div class="recording-container">
        <section
          class="edition white-box w-100 d-none"
          :class="{ showEditionBlock: showEditionBlock }"
        >
          <h3>{{ $t('play') }}</h3>
          <div id="playbackControls" class="playback-controls">
            <button id="playFullClip" class="blob-btn btn-lg play-full-clip mb-1">
              {{ $t('fullClip') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
              <SvgButton />
            </button>
            <button id="playEditedPreview" class="blob-btn btn-lg mx-2 play-edited-preview">
              {{ $t('selectedRegion') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
              <SvgButton />
            </button>
            <div id="tipsContainer" class="tips-container">
              <span id="tip" class="tip"></span>
            </div>
          </div>

          <div id="editionBar" class="edition-bar"></div>

          <div class="edition-controls text-center mt-4">
            <button
              id="clearRegions"
              class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight clear-regions mb-1"
            >
              {{ $t('clearRegion') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
              <SvgButton />
            </button>
            <button
              id="cutAudio"
              class="blob-btn fill-bg btn-lg mx-2 animate__animated animate__fadeInRight cut-audio"
            >
              {{ $t('trim') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
              <SvgButton />
            </button>
            <button
              id="cancelEditing"
              class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight cancel-editing"
              @click="cancelTrim()"
            >
              {{ $t('cancel') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
              <SvgButton />
            </button>
          </div>
        </section>

        <div class="main-controls white-box w-100 d-none" :class="{ showFinalBlock: finalBlock }">
          <section class="sound-clips"></section>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="library-recording-popup"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" @focusout="closePopup()">
            <button
              type="button"
              class="btn-close close popup"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closePopup()"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>

            <div v-if="selectedFile" class="modal-body text-center py-5">
              <h5>{{ selectedFile.title }}</h5>
              <div v-show="selectedFile" class="my-4">
                <div class="audio audioStyle">
                  <audio
                    id="audioPlayer"
                    controls="controls"
                    class="audioStyle"
                    ref="audioPlayer"
                    :src="this.selectedFile.file"
                  ></audio>
                </div>
              </div>
              <div class="wave-btn-block mt-4">
                <button
                  class="blob-btn fill-bg btn-lg submit-btn me-2"
                  data-bs-dismiss="modal"
                  @click="editSaved()"
                >
                  {{ $t('edit') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <button
                  class="blob-btn fill-bg btn-lg submit-btn"
                  data-bs-target="#library-recording-delete"
                  data-bs-toggle="modal"
                >
                  {{ $t('delete') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Toggle modals Second -->
      <div
        class="modal fade"
        id="library-recording-delete"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close close popup"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>

            <div class="modal-body text-center py-5">
              {{ $t('ruSure?') }}
              <div class="wave-btn-block mt-4">
                <button
                  class="blob-btn fill-bg btn-lg submit-btn me-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="popupYesClick(selectedFile)"
                >
                  {{ $t('yes') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <button
                  class="blob-btn fill-bg btn-lg submit-btn"
                  data-bs-target="#library-recording-popup"
                  data-bs-toggle="modal"
                >
                  {{ $t('no') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<!-- eslint-disable no-mixed-operators -->
<!-- eslint-disable no-plusplus -->
<!-- eslint-disable prefer-destructuring -->
<!-- eslint-disable no-nested-ternary -->
<!-- eslint-disable indent -->
<!-- eslint-disable comma-dangle -->
<!-- eslint-disable operator-linebreak -->

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useCookies } from 'vue3-cookies';
import WaveSurfer from 'wavesurfer.js';
import Region from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import SvgButton from '@/components/partials/SvgButton.vue';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });
const audioEncoder = require('audio-encoder');

let record;
let stop;

let playFullClip;
let playEditedPreview;
let tip;

// let removeSelection;
let clearRegions;
let cutAudio;
let cancelEditing;
let editionWave;

const isNeg = function (number) {
  return number === 0 && 1 / number === -Infinity;
};

const nidx = function negIdx(idx, length) {
  return idx === null
    ? 0
    : isNeg(idx)
    ? length
    : idx <= -length
    ? 0
    : idx < 0
    ? length + (idx % length)
    : Math.min(length, idx);
};

const generatedClips = [];
let appStatus = 'inactive';
let currentEditionClipID = null;
const editionMode = 'remove-selection';

export default {
  name: 'LibraryRecordingList',
  props: [],

  components: {
    SvgButton,
    Loader,
    VuejsPaginateNext,
  },

  mounted() {
    record = document.querySelector('.button-record');
    stop = document.querySelector('.button-stop');

    playFullClip = document.querySelector('.play-full-clip');
    playEditedPreview = document.querySelector('.play-edited-preview');
    tip = document.querySelector('.tip');

    // removeSelection = document.querySelector('.cutting-mode-remove');
    // Botones de selección
    clearRegions = document.querySelector('.clear-regions');
    // Botonos de Recortar/Salir
    cutAudio = document.querySelector('.cut-audio');
    cancelEditing = document.querySelector('.cancel-editing');
  },
  created() {
    record = document.querySelector('.button-record');
    stop = document.querySelector('.button-stop');

    playFullClip = document.querySelector('.play-full-clip');
    playEditedPreview = document.querySelector('.play-edited-preview');
    tip = document.querySelector('.tip');

    // Botones de selección
    clearRegions = document.querySelector('.clear-regions');
    // Botonos de Recortar/Salir
    cutAudio = document.querySelector('.cut-audio');
    cancelEditing = document.querySelector('.cancel-editing');
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isAdded = ref(false);
    const isLoading = ref(false);
    const search = ref('');
    const perPage = ref(10);
    const pageCount = ref(0);
    const currentOffset = ref(0);
    const searchField = reactive({
      search: '',
    });
    const value = {
      offset: currentOffset.value,
      term: searchField.search,
    };

    isLoading.value = true;

    const creativeStories = computed(() => store.getters.getCreativeStories);

    store.dispatch('fetchCreativeStories', value).then((stories) => {
      pageCount.value = Math.ceil(stories.data.total / perPage.value);
      isLoading.value = false;
    });

    const deleteStory = () => {
      isAdded.value = true;
    };

    const rules = {
      search: {
        required: helpers.withMessage('You must enter', required),
      },
      // selectedMarket: { required: helpers.withMessage('You must select a currrency', required) },
    };

    const v$$ = useVuelidate(rules, searchField);

    // const copyURL = async () => {
    //   try {
    //     const user = cookies.get('storyPhoneUserAuthCookie');
    //     await navigator.clipboard.writeText(
    //       `http://localhost:8080/guest-recording/${user.access_token}`
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const onSubmit = async () => {
      const result = await v$$.value.$validate();
      if (result) {
        isLoading.value = true;
      const data = {
        offset: 0,
        term: searchField.search,
      };
      store.dispatch('fetchCreativeStories', data).then((stories) => {
        pageCount.value = Math.ceil(stories.data.total / perPage.value);

        isLoading.value = false;
        v$$.value.$reset();
      });
      }
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = (pageNum - 1) * 10;
      const data = {
        offset: currentOffset.value,
        term: searchField.search,
      };
      store.dispatch('fetchCreativeStories', data).then(() => {
        isLoading.value = false;
      });
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const popupYesClick = (data) => {
      isLoading.value = true;
      storyphoneService.storyDelete(data.id).then(() => {
        const datas = {
          offset: 0,
          term: searchField.search,
        };
        store.dispatch('fetchCreativeStories', datas).then(() => {
          isLoading.value = false;
          router.push({ path: '/library' }).then(() => { router.go(); });
        });
      });
    };

    const popupNoClick = () => {
      isAdded.value = false;
    };

    const closePopup = () => {
      document.getElementById('audioPlayer').pause();
    };

    return {
      creativeStories,
      closePopup,
      deleteStory,
      popupYesClick,
      popupNoClick,
      isLoading,
      isAdded,
      pageCount,
      onSubmit,
      pageClick,
      search,
      // copyURL,
      searchField,
      v$$
    };
  },

  data() {
    return {
      selectedFile: {},
      audioIsPlaying: false,
      v$: useVuelidate(),
      permissionStatus: null,
      recordHide: false,
      pauseHide: false,
      resumeHide: false,
      recorder: null,
      audioFile: null,
      audioType: 'audio/wav',
      audioFileName: 'audio.wav',
      uploadedAudioFile: null,
      animateFrame: 0,
      nowTime: 0,
      diffTime: 0,
      startTime: 0,
      isRunning: false,
      hideStop: false,
      storyName: '',
      showSave: false,
      SvgButton,
      isVerified: false,
      saved: false,
      user: '',
      showRecordBlock: true,
      showEditionBlock: false,
      finalBlock: false,
      tempClipId: '',
      tempSource: '',
      isSaved: false,
      base64: '',
    };
  },

  methods: {
    async getAudioDetails(story) {
      this.selectedFile = story;
      this.storyName = this.selectedFile.title;
      const raw = fetch(this.selectedFile.file).then(async (res) => {
        const blob = await res.blob();
        const audioURL = window.URL.createObjectURL(blob);
        const clipID = this.generateSoundClipID();
        this.generateWaveContainerID(clipID);
        const sourceType = 'blob';
        this.saveSoundClip(clipID, blob, sourceType);
        this.tempClipId = clipID;
        this.tempSource = audioURL;
      });
    },

    editSaved() {
      this.showRecordBlock = false;
      this.showEditionBlock = true;
      this.enterEditionMode();
      // this.setEditionMode(editionMode);
      this.editClip(this.tempClipId, this.tempSource);
      tip.textContent = 'You can drag the cursor inside the wave to select a region';
    },

    // TRIM

    // async copyURL() {
    //   try {
    //     const user = cookies.get('storyPhoneUserAuthCookie');
    //     await navigator.clipboard.writeText(
    //       // eslint-disable-next-line comma-dangle
    //       `http://localhost:8080/guest-recording/${user.access_token}`
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    saveSoundClip(clipID, audioData, sourceType) {
      generatedClips.push({ id: clipID, data: audioData, sourceType });
    },

    generateSoundClipID() {
      return `sc${Math.random().toString(36).slice(2)}`;
    },

    generateWaveContainerID(id) {
      return `#${id}`;
    },
    convertingTobase(audio) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64 = event.target.result;
        // const data = newline.replace('data:audio/x-wav;base64,', ' ');
        // this.base64 = data;
      };
      reader.readAsDataURL(audio);
    },

    async generateClip(clipID, waveContainerID, audioSource, defaultName) {
      const clipContainer = document.createElement('article');
      const clipLabel = document.createElement('h2');
      const ButtonsContainer = document.createElement('div');
      const playButton = document.createElement('button');
      const editButton = document.createElement('button');
      const saveButton = document.createElement('button');
      const seekbarContainer = document.createElement('div');
      const deleteButton = document.createElement('button');

      clipContainer.classList.add('clip');
      ButtonsContainer.classList.add('buttons-container');
      seekbarContainer.classList.add('seekbar-container');
      seekbarContainer.id = clipID;
      playButton.textContent = 'Play';
      playButton.className = 'play-button';
      editButton.textContent = 'Edit';
      editButton.className = 'edit-button';
      saveButton.textContent = 'Save';
      saveButton.className = 'save-button';
      deleteButton.textContent = 'Cancel';
      deleteButton.className = 'delete-button';

      clipLabel.textContent = this.storyName;

      clipContainer.appendChild(clipLabel);
      ButtonsContainer.appendChild(playButton);
      if (typeof audioSource === 'string') {
        ButtonsContainer.appendChild(editButton);
      }
      ButtonsContainer.appendChild(saveButton);
      ButtonsContainer.appendChild(deleteButton);
      clipContainer.appendChild(seekbarContainer);
      clipContainer.appendChild(ButtonsContainer);
      const soundClips = await document.querySelector('.sound-clips');

      soundClips.appendChild(clipContainer);

      const seekbar = this.generateClipWave(waveContainerID);
      this.loadWave(seekbar, audioSource);

      playButton.onclick = () => {
        seekbar.playPause();
      };

      saveButton.onclick = () => {
        this.isLoading = true;
        // const file = new File([this.uploadedAudioFile], `${this.storyName}.wav`);
        // const formData = new FormData();
        // formData.append('title', this.storyName);
        // formData.append('file', file);
        this.convertingTobase(this.uploadedAudioFile);
        setTimeout(() => {
          const Data = {
            title: this.storyName,
            file: this.base64,
            type: 'playshield-story',
          };
          this.saved = true;
          this.isAdded = true;
          seekbar.playPause();
          this.isLoading = false;
          storyphoneService.storyUpdate(this.selectedFile.id, Data).then((story) => {
            window.location.reload();
            this.isSaved = true;
          });
        }, 1000);
      };

      editButton.onclick = () => {
        this.enterEditionMode();
        this.setEditionMode(editionMode);
        this.editClip(clipID, audioSource);
        tip.textContent = 'You can drag the cursor inside the wave to select a region';
      };

      deleteButton.onclick = () => {
        this.storyName = '';
        this.v$.$reset();
        this.showSave = false;
        this.audioFile = null;
        this.showRecordBlock = true;
        this.showEditionBlock = false;
        this.uploadedAudioFile = null;
        this.finalBlock = false;
        this.exitEditionMode();
        editionWave.destroy();
        seekbar.playPause();
      };
    },

    cancelTrim() {
      this.showEditionBlock = false;
      this.showRecordBlock = true;
    },

    disableEditionControls() {
      // removeSelection.disabled = true;
      clearRegions.disabled = true;
      cutAudio.disabled = true;
      cancelEditing.disabled = true;
      playFullClip.disabled = true;
      playEditedPreview.disabled = true;
    },

    enableEditionControls() {
      // removeSelection.disabled = false;
      cancelEditing.disabled = false;
      playFullClip.disabled = false;
    },

    setTipOnNoRegions() {
      tip.textContent = 'You can drag the cursor inside the wave to select a region';
    },

    setTipOnRegionCreated() {
      // tip.textContent = 'You can delete a region by double clicking on it';
    },

    clearTip() {
      tip.textContent = '';
    },

    enableEditionOnCreation() {
      cutAudio.disabled = false;
      this.setTipOnRegionCreated();
      playEditedPreview.disabled = false;
      clearRegions.disabled = false;
    },

    disableEditionOnClear() {
      clearRegions.disabled = true;
      cutAudio.disabled = true;
      this.setTipOnNoRegions();
      playEditedPreview.disabled = true;
    },

    disableRecordingControls() {
      record.disabled = true;
      stop.disabled = true;
    },

    enableRecordingControls() {
      record.disabled = false;
      stop.disabled = false;
    },

    enterEditionMode() {
      // this.disableRecordingControls();
      this.enableEditionControls();
      this.setTipOnNoRegions();
    },

    exitEditionMode() {
      // this.enableRecordingControls();
      this.disableEditionControls();
      this.clearTip();
    },

    disableEditionModeToggle() {
      // removeSelection.style.background = '';
    },

    generateClipWave(waveContainerID) {
      const clipWave = WaveSurfer.create({
        container: waveContainerID,
        waveColor: '#605dc7',
        progressColor: '#605dc7',
        cursorColor: 'red',
        cursorWidth: 2,
        barWidth: 2,
        barGap: 1,
        barHeight: 5,
        height: 60,
        hideScrollbar: true,
      });
      return clipWave;
    },

    loadWave(wave, source) {
      if (typeof source === 'string') {
        wave.load(source);
        this.isLoading = false;
      } else {
        wave.loadDecodedBuffer(source.data);
        this.isLoading = false;
      }
    },

    getSoundClip(clipID) {
      return generatedClips.find((clip) => clip.id === clipID);
    },

    slice(originalSource, regionsIntervals) {
      const audioContext = new AudioContext();
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const myArrayBuffer = fileReader.result;
        audioContext.decodeAudioData(myArrayBuffer, async (buffer) => {
          const sampleRate = buffer.sampleRate;
          const numberChannels = 1;
          let start;
          let end;
          const data = [0];
          const regionsData = [];

          for (let channel = 0; channel < 1; channel++) {
            const channelData = buffer.getChannelData(channel);
            for (let i = 0; i < 1; i++) {
              start = regionsIntervals[i][0] * sampleRate;
              end = regionsIntervals[i][1] * sampleRate;
              const bufferSlice = channelData.slice(start, end);
              regionsData.push(bufferSlice);
              for (let pos = 0; pos < regionsData[i].length; pos++) {
                data.push(regionsData[i][pos]);
              }
            }
          }

          const cutClipID = this.generateSoundClipID();
          const cutWaveContainerID = this.generateWaveContainerID(cutClipID);
          const cutWaveAudioBuffer = audioContext.createBuffer(
            numberChannels,
            data.length,
            sampleRate
          );

          if (data) {
            let l;
            let c;
            for (c = 0, l = 1; c < l; c++) {
              cutWaveAudioBuffer.getChannelData(c).set(data);
            }
          }
          const sourceType = 'audioBuffer';

          this.saveSoundClip(cutClipID, cutWaveAudioBuffer, sourceType);

          audioEncoder(cutWaveAudioBuffer, 128, null, (blob) => {
            this.uploadedAudioFile = blob;
          });

          this.generateClip(
            cutClipID,
            cutWaveContainerID,
            this.getSoundClip(cutClipID),
            'Clip de Audio Editado'
          );
        });
      };
      fileReader.readAsArrayBuffer(originalSource.data);
    },

    editClip(clipID, audioSource) {
      currentEditionClipID = clipID;
      let clipDuration;
      let selectedRegionID;

      editionWave = WaveSurfer.create({
        container: '#editionBar',
        waveColor: '#605dc7',
        progressColor: '#605dc7',
        cursorColor: 'red',
        cursorWidth: 2,
        barWidth: 2,
        barGap: 1,
        barHeight: 5,
        height: 96,
        // hideScrollbar: true,
        plugins: [
          Region.create({
            regions: [
              {
                start: 0,
                end: 2,
                loop: false,
              },
            ],
            dragSelection: {},
          }),
        ],
      });

      editionWave.on('region-created', (region) => {
        editionWave.regions.clear();
      });

      if (appStatus === 'editing') {
        editionWave.destroy();
      }
      this.loadWave(editionWave, audioSource);
      // appStatus = 'editing';

      let selectedRegion = null;

      editionWave.on('ready', () => {
        editionWave.enableDragSelection({
          color: 'rgba(0, 92, 200, 0.3)',
        });
        clipDuration = editionWave.getDuration();
      });

      // PROBANDO ACCESO A PARAMETROS DE LAS REGIONES
      editionWave.on('region-created', (region, event) => {
        this.enableEditionOnCreation();
      });

      editionWave.on('region-click', (region, event) => {
        selectedRegionID = region.id;

        if (selectedRegion == null) {
          selectedRegion = region;
          selectedRegion.update({
            color: 'rgba(21, 209, 52, 0.3)',
          });
        } else {
          selectedRegion.update({
            color: 'rgba(0, 92, 200, 0.3)',
          });
          selectedRegion = region;
          selectedRegion.update({
            color: 'rgba(21, 209, 52, 0.3)',
          });
        }
      });

      editionWave.on('region-dblclick', (region, event) => {
        region.remove();
        if (Object.keys(editionWave.regions.list).length === 0) {
          this.disableEditionOnClear();
        }
      });

      editionWave.on('region-update-end', (region) => {
        editionWave.drawer.un('click');

        if (selectedRegion != null) {
          selectedRegion.update({
            color: 'rgba(0, 92, 200, 0.3)',
          });
        }

        selectedRegion = region;
      });

      // Zoom slider
      editionWave.zoom(-1); // Resetting zoom

      playFullClip.onclick = () => {
        editionWave.seekTo(0);
        editionWave.playPause();
      };

      playEditedPreview.onclick = () => {
        const regionsIDs = Object.keys(editionWave.regions.list);
        for (let i = 0; i < regionsIDs.length; i++) {
          editionWave.regions.list[regionsIDs[i]].play();
        }
      };

      cutAudio.onclick = () => {
        this.isLoading = true;
        const regionsIDs = Object.keys(editionWave.regions.list);
        const regionsCount = regionsIDs.length;

        const originalRegionIntervals = [];
        let selectedRegionsIntervals = [];
        const nonSelectedRegionsIntervals = [];

        for (let idPosition = 0; idPosition < regionsCount; idPosition++) {
          originalRegionIntervals.push([
            editionWave.regions.list[regionsIDs[idPosition]].start,
            editionWave.regions.list[regionsIDs[idPosition]].end,
          ]);
        }

        // Ordenando a partir de los tiempos iniciales de cada región
        originalRegionIntervals.sort((a, b) => a[0] - b[0]);

        // Creando regiones finales uniendo regiones solapadas
        if (regionsCount > 1) {
          // Inicializando posiciones
          let leftStart = originalRegionIntervals[0][0];
          let leftEnd = originalRegionIntervals[0][1];
          let newStart = leftStart;
          let newEnd = leftEnd;
          let rightStart;
          let rightEnd;

          for (let leftRegion = 0, rightRegion = 1; rightRegion < regionsCount; rightRegion++) {
            // eslint-disable-next-line prefer-destructuring
            rightStart = originalRegionIntervals[rightRegion][0];
            // eslint-disable-next-line prefer-destructuring
            rightEnd = originalRegionIntervals[rightRegion][1];

            if (leftEnd > rightStart) {
              newEnd = rightEnd;
            } else {
              selectedRegionsIntervals.push([newStart, newEnd]);
              leftRegion = rightRegion;
              // eslint-disable-next-line prefer-destructuring
              leftStart = originalRegionIntervals[leftRegion][0];
              // eslint-disable-next-line prefer-destructuring
              leftEnd = originalRegionIntervals[leftRegion][1];
              newStart = leftStart;
              newEnd = leftEnd;
            }
          }
          // Insertar última región
          selectedRegionsIntervals.push([newStart, newEnd]);
        } else {
          selectedRegionsIntervals = originalRegionIntervals;
        }

        // Determinando intervalos de regiones no seleccionadas
        for (
          let currentRegion = 0, start = 0, end = selectedRegionsIntervals[0][0];
          currentRegion < selectedRegionsIntervals.length;
          currentRegion++
        ) {
          if (selectedRegionsIntervals[currentRegion][0] > start) {
            end = selectedRegionsIntervals[currentRegion][0];
            nonSelectedRegionsIntervals.push([start, end]);
            start = selectedRegionsIntervals[currentRegion][1];
          } else {
            start = selectedRegionsIntervals[currentRegion][1];
          }

          // Insertar última región
          if (
            currentRegion === selectedRegionsIntervals.length - 1 &&
            selectedRegionsIntervals[currentRegion][1] < clipDuration
          ) {
            nonSelectedRegionsIntervals.push([
              selectedRegionsIntervals[currentRegion][1],
              clipDuration,
            ]);
          }
        }

        const audioBlob = generatedClips[0].data;

        this.slice(this.getSoundClip(clipID), nonSelectedRegionsIntervals);
        this.showEditionBlock = false;
        this.finalBlock = true;
        appStatus = 'inactive';
      };

      clearRegions.onclick = () => {
        editionWave.clearRegions();
        this.disableEditionOnClear();
      };

      cancelEditing.onclick = () => {
        this.exitEditionMode();
        this.disableEditionModeToggle();
        editionWave.destroy();
        appStatus = 'inactive';
      };
    },
  },

  computed: {},
};
</script>

<style></style>
