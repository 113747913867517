<template lang="html">
  <div class="cart-header-outer">
    <div class="cart-header bg-white cart">
      <button
        class="btn btn-cart py-0 border-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <span> {{ cartItemCount }} </span>
        <i class="icon-cart" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div
    class="cart-slide offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header cart-head d-flex justify-content-between">
      <h3>{{ $t('cart') }}</h3>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div v-if="lineItems.length > 0">
        <ul class="cart-product-list">
          <li v-for="lineItem in lineItems" :key="lineItem.id">
            <div class="row">
              <CartItemImage :cartData="lineItem" />
              <div class="col-7">
                <div class="description">
                  <CartName :cartData="lineItem" />
                  <CartItemButton :cartData="lineItem" />
                  <div class="price">
                    {{ price(lineItem.currency_code,lineItem.total_amount_float) }}
                    <!-- {{ lineItem.formatted_unit_amount.slice(0, 1) }}
                    {{ lineItem.unit_amount_float }} -->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-6">
            <h5>{{ $t('Subtotal') }}</h5>
          </div>
          <div class="col-6">
            <div class="text-right">
              <!-- {{ subtotal }} -->
              <h5>{{ price(subtotal.currency_code,subtotal.total_amount_float) }}</h5>
              <!-- <h5>{{ subtotal }}</h5> -->
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="wave-btn-block">
            <button
              type="submit"
              class="blob-btn fill-bg btn-lg mt-5"
              @click="checkout()"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {{ $t('checkOut') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <SvgButton />
          </div>
        </div>
      </div>
      <div v-else class="alert empty-cart" role="alert">
        <img src="@/assets/images/empty-cart.png" alt="Empty image" />
        <h2>{{ $t('oopsCart') }}</h2>
        <p>{{ $t('oopsCartMeg') }}</p>
        <div class="wave-btn-block">
          <button
            type="submit"
            class="blob-btn fill-bg btn-lg mt-3"
            @click="library"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            {{ $t('shopNow') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useCookies } from 'vue3-cookies';

import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';
import CartItemButton from '@/components/partials/CartItemButton.vue';
import CartName from '@/components/partials/CartName.vue';
import CartItemImage from '@/components/partials/CartItemImage.vue';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'Cart',
  components: {
    SvgButton,
    CartItemButton,
    CartItemImage,
    CartName,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const langCode = localStorage.getItem('LangCode');
    store.dispatch('fetchAvailableCountries');

    const lineItems = computed(() => store.getters.getLineItems);

    const cartItemCount = computed(() => store.getters.getCartItemCount);

    const subtotal = computed(() => store.getters.getSubtotalPrice);

    if (cookies.isKey('clUserAuthCookie')) {
      // store.dispatch('fetchUserAddress');
    }

    const price = (currencyCode, value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      });
      return formatter.format(value);
    };

    const shieldClick = (sku) => {
      const Data = {
        id: sku,
        locale: langCode,
      };
      store.dispatch('fetchCartItemDetails', Data);
      store.dispatch('fetchCartItemPrice', sku);
      router.push({ name: 'cart-details', params: { id: sku } });
    };

    // const shieldName = (sku) => {

    //   contentfulService.getEntityList('products', 100, sku)
    //     .then((res) => res?.items[0]?.fields?.name);
    // };

    const clearCart = () => {
      if (cookies.isKey('orderIdCookie')) {
        const { orderId } = cookies.get('orderIdCookie');
        cLayerService.clearCartItems(orderId);
      }
    };

    const checkout = () => {
      router.push('/checkout');
    };

    const library = () => {
      router.push('/shields');
    };

    return {
      lineItems, cartItemCount, subtotal, shieldClick, checkout, library, price,
    };
  },
};
</script>

<style scoped lang="scss"></style>
