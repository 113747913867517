<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="account-settings my-account mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>

        <div v-if="userDetails" class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('personalInformation') }}</h3>
              <div class="inner-box">
                <div class="form-group">
                  <label>{{ $t('firstName') }} </label>
                  <input type="text" class="form-control" v-model="fname" :placeholder="fname" />
                </div>
                <div class="form-group">
                  <label>{{ $t('email') }} </label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    :placeholder="userDetails.email"
                  />
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>{{ $t('password') }} </label>
                    <button
                      type="button"
                      class="btn change-pwd p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#changePassword"
                    >
                      {{ $t('changePassword') }}
                      <i class="icon-Right-arrow-small ms-1" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div v-if="userDetails.provider === null">
                  <PopupChangePassword />
                  </div>

                  <input type="password" readonly class="form-control" placeholder="********" />
                </div>
                <div class="wave-btn-block">
                  <button
                    type="submit"
                    class="blob-btn fill-bg btn-lg submit-btn mt-2"
                    @click="updateUserData()"
                  >
                    {{ $t('savechanges') }}
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>
            <div v-if="userAddress" class="address-block mt-5">
              <div class="border-block">
                <h3>{{ $t('addressBook') }}</h3>
                <div class="inner-box">
                  <div class="address-inner" v-for="address in userAddress" :key="address.id">
                    <div class="address-link">
                      <a @click="editAddress(address.address.id)">
                        <span class="address-icon"
                          ><i class="icon-location" aria-hidden="true"></i
                        ></span>
                        <span class="right-icon edit-icon"
                          ><i class="icon-edit" aria-hidden="true"></i
                        ></span>
                        <p>
                          {{ address.address.notes }}<br />
                          {{ address.address.line_1 }} <br />
                          {{ address.address.city }} {{ address.address.state_code }} ({{
                            address.address.country_code
                          }})<br />
                          {{ address.address.phone }}
                        </p>
                      </a>
                      <div class="text-end right-icon delete-icon">
                        <button
                          type="button"
                          class="border-0 delete"
                          @click="deleteAddress(address.id)"
                          aria-label="Remove"
                        >
                          <i class="icon-delete" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <router-link to="/add-new-address" class="btn addnew-btn px-0 mt-2 border-0"
                    >+ {{ $t('addNewAddress') }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { useCookies } from 'vue3-cookies';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';
import PopupChangePassword from '@/components/partials/PopupChangePassword.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';

const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();

const { cookies } = useCookies({ secure: true });

export default {
  name: 'my-account',
  props: [],
  components: {
    AccountSidebar,
    PopupChangePassword,
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fname = ref('');
    const isLoading = ref(false);
    // store.dispatch('fetchWishList');
    store.dispatch('fetchUserAddress');

    const load = async () => {
      const details = await store.dispatch('fetchUserDetails');
      fname.value = await details.data.name;
    };
    load();

    const updateUserData = () => {
      isLoading.value = true;
      const data = { name: fname.value };
      storyphoneService.updateUserDetails(data)
        .then(() => {
          isLoading.value = false;
          store.dispatch('fetchUserDetails');
        })
        .catch((error) => {
          isLoading.value = false;
        });
    };

    const editAddress = (addressId) => {
      router.push({ name: 'edit-address', params: { id: addressId } });
    };

    const deleteAddress = (id) => {
      isLoading.value = true;
      cLayerService.deleteAddress(id)
        .then(() => {
          store.dispatch('fetchUserAddress')
            .then(() => {
              isLoading.value = false;
            });
        });
    };
    const userDetails = computed(() => store.getters.getUserDetails);
    const userAddress = computed(() => store.getters.getUserAddress);

    return {
      fname, isLoading, updateUserData, editAddress, userDetails, userAddress, deleteAddress,
    };
  },
};
</script>

<style scoped lang="scss"></style>
