<template lang="html">
  <PageHeader />

  <section class="mt-stickybar licence-agreement home" aria-label="licence-agreement">
    <div class="inner-page-content py-5">
      <div class="container">
        <!-- <div v-html="Details"></div> -->

        <div class="row">
          <!-- {{ licenseAgreementTermination }} -->
          <div class="col-md-12">
            <h2>StoryPhones隐私政策</h2>
            <p>
              StoryPhones的所有者——OnanOff公司（以下简称“我们”）致力于保护你的隐私。本隐私政策简要说明了在您使用StoryPhones App（以下简称 “App”）时，我们如何收集、使用、披露和保护您的个人信息。</p>

            <h5>1.信息收集</h5>
            <ul class="ms-4 mb-2" style="list-style-type: lower-alpha;">
              <li>个人资料图像：出于个人资料管理目的，App可能会收集并存储用户上传的图片。</li>
              <li>音频文件：App可能收集和存储用户提供的音频文件，用于创建播放列表。</li>
            </ul>
            <h5>2.信息使用</h5>
            <ul class="ms-4 mb-2" style="list-style-type: lower-alpha;">
              <li>个人资料图像：我们使用收集到的个人资料图像来个性化用户在App中的使用体验，并增强个人资料管理功能。</li>
              <li>音频文件：收集的音频文件仅用于在App内创建播放列表，供耳机使用。</li>
            </ul>
            <h5>3.数据安全</h5>
            <ul class="ms-4 mb-2">
              <li>我们采取合理的安全措施来保护所收集信息的保密性和完整性。但是，在互联网环境下，没有任何一种互联网传输或电子存储方式是完全安全的，我们无法保证信息100%的安全。</li>
            </ul>
            <h5>4.信息共享</h5>
            <ul class="ms-4 mb-2">
              <li>我们不会向第三方出售、交易或以其他方式转让您的个人信息。但是，我们可能会与协助我们运营App、开展业务或为您提供服务的可靠第三方共享您的信息。</li>
            </ul>
            <h5>5.许可同意</h5>
            <ul class="ms-4 mb-2">
              <li>使用App即表示您同意本隐私政策的条款，并同意按本政策所述收集和使用您的信息。</li>
            </ul>
            <h5>6. 隐私政策的更新</h5>
            <ul class="ms-4 mb-2">
              <li>我们可能会适时更新或修改本隐私政策，恕不另行通知。如变更本隐私政策，我们会将更 新后的隐私政策在App上公布并即时生效。您应定期查看本隐私政策。</li>
            </ul>
            <h5>7.联系信息</h5>
            <ul class="ms-4 mb-2">
              <li>如果您对本隐私政策有任何问题或疑问，请通过<a href="mailto:help@storyphones.com" class="text-link">help@storyphones.com</a>联系我们，谢谢。</li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import PageService from '@/services/PageService';

const pageService = new PageService();
const ent = require('ent');

export default {
  name: 'privacy-policy',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const responseContainer = ref(null);

    const LangCode = localStorage.getItem('LangCode');
    // const data = {
    //   code: LangCode,
    //   slug: `license_agreement_${LangCode}`,
    // };

    // store.dispatch('fetchCMS', data);

    // const data1 = {
    //   code: LangCode,
    //   slug: `license_agreement_general_${LangCode}`,
    // };

    // store.dispatch('fetchCMS1', data1);

    // const data2 = {
    //   code: LangCode,
    //   slug: `license_agreement_email_${LangCode}`,
    // };

    // store.dispatch('fetchCMS2', data2);

    // const data3 = {
    //   code: LangCode,
    //   slug: `license_agreement_termination_${LangCode}`,
    // };

    // store.dispatch('fetchCMS3', data3);

    // const data4 = {
    //   code: LangCode,
    //   slug: `license_agreement_transferability_${LangCode}`,
    // };

    // store.dispatch('fetchCMS4', data4);

    // const data5 = {
    //   code: LangCode,
    //   slug: `license_agreement_services_${LangCode}`,
    // };

    // store.dispatch('fetchCMS5', data5);

    // const data6 = {
    //   code: LangCode,
    //   slug: `license_agreement_disclaimer_${LangCode}`,
    // };

    // store.dispatch('fetchCMS6', data6);

    // const data7 = {
    //   code: LangCode,
    //   slug: `license_agreement_indemnification_${LangCode}`,
    // };

    // store.dispatch('fetchCMS7', data7);

    // const decode = async (value) => {
    //   const text = await ent.decode(value);
    //   return text.value;
    // };

    // const licenseAgreement = computed(() => store.getters.getCMS);
    // const Details = computed(() => DOMPurify.sanitize(licenseAgreement.value.html_description));

    // console.log(Details);

    // const licenseAgreementGeneral = computed(() => store.getters.getCMS1);

    // const licenseAgreementEmail = computed(() => store.getters.getCMS2);

    // const licenseAgreementTermination = computed(() => store.getters.getCMS3);

    // const licenseAgreementTransferability = computed(() => store.getters.getCMS4);

    // const licenseAgreementServices = computed(() => store.getters.getCMS5);

    // const licenseAgreementDisclaimer = computed(() => store.getters.getCMS6);

    // const licenseAgreementIndemnification = computed(() => store.getters.getCMS7);

    // const decodeHtml = (html) => {
    //   const parser = new DOMParser();
    //   const decoded = parser.parseFromString(html, 'text/html').body.textContent;
    //   return decoded;
    // };
    return {
      // licenseAgreement,
      // licenseAgreementGeneral,
      // licenseAgreementEmail,
      // licenseAgreementTermination,
      // licenseAgreementTransferability,
      // licenseAgreementServices,
      // licenseAgreementDisclaimer,
      // licenseAgreementIndemnification,
      // decode,
      // decodeHtml,
      responseContainer,
      // Details,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
.text-link {
  color: blue;
  text-decoration: underline;
}
</style>
