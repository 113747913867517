<template lang="html">
  <PageHeader />
  <section class="cloud-bg signup account-section mt-stickybar mb-5">
    <LoginAnimation />
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-6 m-auto">
          <div class="account-form-block mt-5">
            <h2 class="text-center mb-4">Update Profile</h2>
            <div class="upload-photo form-group">
              <div class="photo-box">
                <span class="icon-box">
                  <i class="icon-camera" aria-hidden="true"></i>
                </span>
                <i class="icon-User" aria-hidden="true"></i>
              </div>
              <div class="wave-btn-block text-center">
                <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                  Upload
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
            <div class="form-group">
              <label>First Name</label>
              <input type="input" class="form-control" />
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="input" class="form-control" />
            </div>
            <div class="form-group">
              <label>DOB</label>
              <div class="row">
                <div class="col-4">
                  <select class="form-select" aria-label="Default select example">
                    <option value="">Day</option>
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4</option>
                    <option value="">5</option>
                    <option value="">6</option>
                    <option value="">7</option>
                    <option value="">8</option>
                    <option value="">9</option>
                    <option value="">10</option>
                    <option value="">11</option>
                    <option value="">12</option>
                    <option value="">13</option>
                    <option value="">14</option>
                    <option value="">15</option>
                    <option value="">16</option>
                    <option value="">17</option>
                    <option value="">18</option>
                    <option value="">19</option>
                    <option value="">20</option>
                    <option value="">21</option>
                    <option value="">22</option>
                    <option value="">24</option>
                    <option value="">25</option>
                    <option value="">26</option>
                    <option value="">27</option>
                    <option value="">28</option>
                    <option value="">29</option>
                    <option value="">30</option>
                    <option value="">31</option>
                  </select>
                </div>
                <div class="col-4">
                  <select class="form-select" aria-label="Default select example">
                    <option value="">Month</option>
                    <option value="">January</option>
                    <option value="">February</option>
                    <option value="">March</option>
                    <option value="">April</option>
                    <option value="">May</option>
                    <option value="">June</option>
                    <option value="">July</option>
                    <option value="">August</option>
                    <option value="">September</option>
                    <option value="">October</option>
                    <option value="">November</option>
                    <option value="">December</option>
                  </select>
                </div>
                <div class="col-4">
                  <select class="form-select" aria-label="Default select example">
                    <option value="">Year</option>
                    <option value="">2022</option>
                    <option value="">2021</option>
                    <option value="">2020</option>
                    <option value="">2019</option>
                    <option value="">2018</option>
                    <option value="">2017</option>
                    <option value="">2016</option>
                    <option value="">2015</option>
                    <option value="">2014</option>
                    <option value="">2013</option>
                    <option value="">2012</option>
                    <option value="">2011</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group custom-radio">
              <label class="d-block">Gender</label>
              <div class="form-check form-check-inline ps-0">
                <input
                  type="radio"
                  class="form-check-input"
                  id="chat1"
                  name="radio-group"
                  checked=""
                /><label for="chat1">Male</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="chat2" name="radio-group" /><label
                  for="chat2"
                  >Female</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="chat3" name="radio-group" /><label
                  for="chat3"
                  >Others</label
                >
              </div>
            </div>
            <div class="form-group">
              <label>Nationality</label>
              <select class="form-select" aria-label="Default select example">
                <option value="">Indian</option>
                <option value="">Japaneese</option>
                <option value="">German</option>
              </select>
            </div>
            <div class="form-group">
              <label>Language proficiency</label>
              <input type="input" class="form-control" />
              <div class="marketplace-language-listing mt-3">
                <ul class="list-unstyled">
                  <li>
                    English
                    <button class="close-language p-0 border-0">
                      <i class="icon-close" aria-hidden="true"></i>
                    </button>
                  </li>
                  <li>
                    Japaneese
                    <button class="close-language p-0 border-0">
                      <i class="icon-close" aria-hidden="true"></i>
                    </button>
                  </li>
                  <li>
                    German
                    <button class="close-language p-0 border-0">
                      <i class="icon-close" aria-hidden="true"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>Bio</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
            </div>
            <div class="wave-btn-block text-center">
              <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                Save
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </button>
              <SvgButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

import SvgButton from '@/components/partials/SvgButton.vue';
import LoginAnimation from '@/components/partials/LoginAnimation.vue';

export default {
  name: 'marketplace-update-profile',
  props: [],
  components: {

    SvgButton,
    PageHeader,
    PageFooter,
    LoginAnimation,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
