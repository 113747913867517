<!-- eslint-disable vue/no-parsing-error -->
<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="search">
    <div class="header-search-popup">
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content" @focusout="clear()">
            <div class="modal-body py-4">
              <div class="search-form">
                <button
                  class="btn search-btn border-0"
                  type="submit"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="onSubmit()"
                >
                  <i class="icon-search" aria-hidden="true"></i>
                </button>
                <input
                  class="search-filed form-control"
                  v-model="searchText"
                  type="search"
                  :placeholder="$t('Search')"
                  aria-label="Search"
                  v-on:keyup.enter="onSubmit()"
                />
                <button
                  id="close"
                  type="button"
                  class="btn-close close-blue-btn search-close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="clear()"
                >
                  <i class="icon-close" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  watch, ref, computed, reactive,
} from 'vue';

import Loader from '@/components/commons/Loader.vue';

export default {
  name: 'search',
  props: [],
  components: {
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const searchText = ref('');

    const onSubmit = () => {
      isLoading.value = true;

      store.dispatch('searchTerm', searchText.value)
        .then(() => {
          isLoading.value = false;
          router.push('/search-result');
        });
      document.getElementById('close').click();
    };
    const clear = () => {
      searchText.value = '';
    };

    return {
      onSubmit, searchText, isLoading, clear,
    };
  },
};
</script>

<style scoped lang="scss"></style>
