<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="recording-page mt-stickybar home" aria-label="recording">
    <div class="inner-page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-8 col-sm-9">
                <nav aria-label="breadcrumb" class="mt-3">
                  <ol class="breadcrumb px-0">
                    <li class="breadcrumb-item">
                      <router-link to="/my-account">
                        {{ $t('myAccount') }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{ $t('recording') }}
                    </li>
                  </ol>
                </nav>
              </div>
              <!-- <div class="col-4 col-sm-3">
                <div class="btn-group invite record d-flex justify-content-end my-3">
                  <button
                    type="button"
                    class="blob-btn fill-bg btn-lg submit-btn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ $t('invite') }}
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end animate__animated animate__fadeInUp">
                    <li>
                      <a class="dropdown-item" href="#">
                        <span class="icon-invite"
                          ><i class="icon-email" aria-hidden="true"></i></span
                        >{{ $t('mail') }}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="copyURL()">
                        <span class="icon-invite"
                          ><i class="icon-link" aria-hidden="true"></i
                        ></span>
                        {{ $t('copyLink') }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>

            <div class="grey-outer-block">
              <div class="recording-block d-none" :class="{ showrecordingBlock: showRecordBlock }">
                <div class="col-md-8 m-auto">
                  <div class="text-center">
                    <h5>{{ $t('pressRecord') }}</h5>
                    <h4>“{{ $t('onceUpOn') }}”</h4>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('enterStory')"
                      v-model="storyName"
                      class="form-control"
                    />
                    <span class="text-danger pl-3" v-if="v$.storyName.$error">
                      <!-- {{ v$.storyName.$errors[0].$message }} -->
                      {{ $t('enterStory') }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="recording-outer text-center white-box w-100 mt-3">
                      <button
                        class="record-btn p-0 border-0 bg-white record-only"
                        :class="{ hiderecord: recordHide, showrecord: hideStop }"
                        @click="recordAudio()"
                      >
                        <span><i class="icon-microphone" aria-hidden="true"></i></span>
                        {{ $t('record') }}
                      </button>
                      <button
                        class="record-btn p-0 border-0 bg-white pause-btn"
                        :class="{
                          showpause: recordHide,
                          hidepause: pauseHide,
                        }"
                        @click="pauseRecording()"
                      >
                        <span
                          ><i
                            class="icon-pause2 animate__animated animate__jackInTheBox"
                            aria-hidden="true"
                          ></i></span
                        >{{ $t('pause') }}
                      </button>
                      <button
                        class="record-btn p-0 border-0 bg-white resume-btn"
                        :class="{ showresume: pauseHide, hideresume: hideStop }"
                        @click="resumeRecording()"
                      >
                        <span
                          ><i
                            class="icon-microphone animate__animated animate__jackInTheBox"
                            aria-hidden="true"
                          ></i></span
                        >{{ $t('resume') }}
                      </button>
                      <div class="audio-main d-flex justify-content-center mt-4">
                        <div v-show="timer">
                          <span class="audioTimer d-none" ref="audioTimer"></span>
                        </div>
                        <div class="time-box">
                          <span>{{ hours }}</span> <span>{{ minutes }}</span>
                          <span>{{ seconds }}</span>
                        </div>
                        <button
                          @click="stopRecordAudio()"
                          class="stop-btn border-0 d-none"
                          :class="{ showstop: recordHide, hidestop: hideStop }"
                        ></button>
                        <div
                          v-show="audioFile && !recordAudioState"
                          ref="audio"
                          class="audio audioStyle"
                        >
                          <audio id="audioElement" controls="controls" class="audioStyle"></audio>
                        </div>
                      </div>
                      <div
                        class="cart-success-block text-center mt-4 d-none"
                        :class="{ saveactive: showSave }"
                      >
                        <button
                          class="blob-btn fill-bg btn-lg mx-2 animate__animated animate__fadeInRight edit-button"
                          :class="{ hidecancel: isAdded }"
                          @click="editAudio()"
                        >
                          {{ $t('edit') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                          <SvgButton />
                        </button>

                        <button
                          class="blob-btn fill-bg btn-lg mx-2 animate__animated animate__fadeInRight"
                          :class="{ hidesave: isAdded }"
                          @click="saveAudio()"
                        >
                          {{ $t('save') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                          <SvgButton />
                        </button>

                        <button
                          class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight"
                          :class="{ hidecancel: isAdded }"
                          @click="cancelAudio()"
                        >
                          {{ $t('cancel') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                          <SvgButton />
                        </button>

                        <button
                          class="btn btn-primary btn-lg d-none animate__animated animate__fadeInRight"
                          :class="{ addsuccess: isAdded }"
                        >
                          <i class="icon-checklist" aria-hidden="true"></i>
                          {{ $t('saved') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="recording-container">
                <section
                  class="edition white-box w-100 d-none"
                  :class="{ showEditionBlock: showEditionBlock }"
                >
                  <h3>{{ $t('play') }}</h3>
                  <div id="playbackControls" class="playback-controls">
                    <button id="playFullClip" class="blob-btn btn-lg play-full-clip mb-1">
                      {{ $t('fullClip') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                      <SvgButton />
                    </button>
                    <button id="playEditedPreview" class="blob-btn btn-lg mx-2 play-edited-preview">
                      {{ $t('selectedRegion') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                      <SvgButton />
                    </button>
                    <div id="tipsContainer" class="tips-container">
                      <span id="tip" class="tip"></span>
                    </div>
                  </div>

                  <div id="editionBar" class="edition-bar"></div>

                  <div class="edition-controls text-center mt-4">
                    <button
                      id="clearRegions"
                      class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight clear-regions mb-1"
                    >
                      {{ $t('clearRegion') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                      <SvgButton />
                    </button>
                    <button
                      id="cutAudio"
                      class="blob-btn fill-bg btn-lg mx-2 animate__animated animate__fadeInRight cut-audio"
                    >
                      {{ $t('trim') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                      <SvgButton />
                    </button>
                    <button
                      id="cancelEditing"
                      class="blob-btn fill-bg btn-lg animate__animated animate__fadeInRight cancel-editing"
                      @click="cancelTrim()"
                    >
                      {{ $t('cancel') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                      <SvgButton />
                    </button>
                  </div>
                </section>

                <div
                  class="main-controls white-box w-100 d-none"
                  :class="{ showFinalBlock: finalBlock }"
                >
                  <section class="sound-clips"></section>
                </div>
              </div>
              <div
                class="success-submit-box modal fade mt-3 d-none"
                :class="{ addsuccess: isSaved, show: isSaved }"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content border-0 p-3">
                    <div class="modal-body">
                      <div class="popup-msg-box">
                        <p>{{ $t('recordSaved') }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button
                        class="btn btn-primary btn-lg submit-btn mb-3 me-3"
                        data-dismiss="modal"
                        @click="popupYesClick()"
                      >
                        {{ $t('ok') }}
                      </button>
                      <button
                        class="btn btn-primary btn-lg submit-btn mb-3"
                        data-dismiss="modal"
                        @click="popupNoClick()"
                      >
                        {{ $t('goToLibrary') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>
<!-- eslint-disable no-mixed-operators -->
<!-- eslint-disable no-plusplus -->
<!-- eslint-disable prefer-destructuring -->
<!-- eslint-disable no-nested-ternary -->

<script lang="js" defer>

import { useCookies } from 'vue3-cookies';
import WaveSurfer from 'wavesurfer.js';
import Region from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import {
  required, minLength, helpers, sameAs,
} from '@vuelidate/validators';
import { onUnmounted } from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import RecorderTimer from '@/mixins/Timer';

const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });
const audioEncoder = require('audio-encoder');

let timeout;

let record;
let stop;

let playFullClip;
let playEditedPreview;
let tip;

let clearRegions;
let cutAudio;
let cancelEditing;
let mainaudio;
let editionWave;

const isNeg = function (number) {
  return number === 0 && 1 / number === -Infinity;
};

const nidx = function negIdx(idx, length) {
  return idx === null
    ? 0
    : isNeg(idx)
      ? length
      : idx <= -length
        ? 0
        : idx < 0
          ? length + (idx % length)
          : Math.min(length, idx);
};

const generatedClips = [];
let appStatus = 'inactive';
let currentEditionClipID = null;
const editionMode = 'remove-selection';

export default {
  name: 'recording',
  props: {
    timer: {
      type: Boolean,
      default: true,
    },
    timerColor: {
      type: String,
      default: '#000',
    },
    timerFontSize: {
      type: Number,
      default: 1,
    },
    timerBackground: {
      type: String,
      default: '#ccc',
    },
    audioWidth: {
      type: Number,
      default: 400,
    },
  },

  mounted() {
    record = document.querySelector('.button-record');
    stop = document.querySelector('.button-stop');

    playFullClip = document.querySelector('.play-full-clip');
    playEditedPreview = document.querySelector('.play-edited-preview');
    tip = document.querySelector('.tip');

    // removeSelection = document.querySelector('.cutting-mode-remove');
    // Botones de selección
    clearRegions = document.querySelector('.clear-regions');
    // Botonos de Recortar/Salir
    cutAudio = document.querySelector('.cut-audio');
    cancelEditing = document.querySelector('.cancel-editing');
  },
  onUnmounted() {
    this.exitEditionMode();
    editionWave.destroy();
  },
  created() {
    record = document.querySelector('.button-record');
    stop = document.querySelector('.button-stop');

    playFullClip = document.querySelector('.play-full-clip');
    playEditedPreview = document.querySelector('.play-edited-preview');
    tip = document.querySelector('.tip');

    // removeSelection = document.querySelector('.cutting-mode-remove');
    // Botones de selección
    clearRegions = document.querySelector('.clear-regions');
    // Botonos de Recortar/Salir
    cutAudio = document.querySelector('.cut-audio');
    cancelEditing = document.querySelector('.cancel-editing');
  },

  components: {
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },

  data() {
    return {
      v$: useVuelidate(),
      permissionStatus: null,
      recordHide: false,
      pauseHide: false,
      resumeHide: false,
      recorder: null,
      audioIsPlaying: false,
      audioFile: null,
      audioType: 'audio/x-wav',
      audioFileName: 'audio.wav',
      uploadedAudioFile: null,
      animateFrame: 0,
      nowTime: 0,
      diffTime: 0,
      startTime: 0,
      isRunning: false,
      hideStop: false,
      storyName: '',
      isAdded: false,
      showSave: false,
      isLoading: false,
      SvgButton,
      isVerified: false,
      saved: false,
      user: '',
      showRecordBlock: true,
      showEditionBlock: false,
      finalBlock: false,
      tempClipId: '',
      tempSource: '',
      isSaved: false,
    };
  },

  methods: {
    async checkPermission() {
      const status = await navigator.permissions.query({ name: 'microphone' });
      this.permissionStatus = status.state;
    },
    clearAudio() {
      const TIMER = this.$refs.audioTimer;
      this.initialTime = Date.now();
      TIMER.innerText = '';
    },

    async copyURL() {
      try {
        const user = cookies.get('storyPhoneUserAuthCookie');
        await navigator.clipboard.writeText(
          `http://localhost:8080/guest-recording/${user.access_token}`,
        );
      } catch (error) {
        console.log(error);
      }
    },

    checkAudioTime() {
      const TIMER = this.$refs.audioTimer;
      const timeDifference = Date.now() - this.initialTime;
      const formatted = this.convertAudioTime(timeDifference);
      TIMER.innerHTML = `${formatted}`;
    },

    convertAudioTime(miliseconds) {
      const totalSeconds = Math.floor(miliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds - minutes * 60;
      const sec = seconds < 10 ? `0${seconds}` : seconds;
      return `${minutes}:${sec}`;
    },

    saveSoundClip(clipID, audioData, sourceType) {
      generatedClips.push({ id: clipID, data: audioData, sourceType });
    },

    generateSoundClipID() {
      return `sc${Math.random().toString(36).slice(2)}`;
    },

    generateWaveContainerID(id) {
      return `#${id}`;
    },

    async generateClip(clipID, waveContainerID, audioSource, defaultName) {
      const clipContainer = document.createElement('article');
      const clipLabel = document.createElement('h2');
      const ButtonsContainer = document.createElement('div');
      const playButton = document.createElement('button');
      const editButton = document.createElement('button');
      const saveButton = document.createElement('button');
      const seekbarContainer = document.createElement('div');
      const deleteButton = document.createElement('button');

      clipContainer.classList.add('clip');
      ButtonsContainer.classList.add('buttons-container');
      seekbarContainer.classList.add('seekbar-container');
      seekbarContainer.id = clipID;
      playButton.textContent = 'Play';
      playButton.className = 'play-button';
      editButton.textContent = 'Edit';
      editButton.className = 'edit-button';
      saveButton.textContent = 'Save';
      saveButton.className = 'save-button';
      deleteButton.textContent = 'Delete';
      deleteButton.className = 'delete-button';

      clipLabel.textContent = this.storyName;

      clipContainer.appendChild(clipLabel);
      ButtonsContainer.appendChild(playButton);
      if (typeof audioSource === 'string') {
        ButtonsContainer.appendChild(editButton);
      }
      ButtonsContainer.appendChild(saveButton);
      ButtonsContainer.appendChild(deleteButton);
      clipContainer.appendChild(seekbarContainer);
      clipContainer.appendChild(ButtonsContainer);
      const soundClips = await document.querySelector('.sound-clips');

      soundClips.appendChild(clipContainer);

      const seekbar = this.generateClipWave(waveContainerID);
      this.loadWave(seekbar, audioSource);

      playButton.onclick = () => {
        seekbar.playPause();
      };

      saveButton.onclick = () => {
        this.isLoading = true;
        const file = new File([this.uploadedAudioFile], `${this.storyName}.wav`);
        const formData = new FormData();
        formData.append('title', this.storyName);
        formData.append('file', file);
        formData.append('type', 'playshield-story');
        seekbar.playPause();
        storyphoneService.storyUpload(formData).then((story) => {
          this.saved = true;
          this.isAdded = true;
          this.isLoading = false;
          setTimeout(() => {
            this.isAdded = false;
            this.showSave = false;
            this.storyName = '';
            this.audioFile = null;
            this.v$.$reset();
          }, 2000);
        });
      };

      editButton.onclick = () => {
        this.enterEditionMode();
        this.setEditionMode(editionMode);
        this.editClip(clipID, audioSource);
        tip.textContent = 'You can drag the cursor inside the wave to select a region';
      };

      deleteButton.onclick = () => {
        this.storyName = '';
        this.v$.$reset();
        this.showSave = false;
        this.audioFile = null;
        this.showRecordBlock = true;
        this.showEditionBlock = false;
        this.uploadedAudioFile = null;
        this.finalBlock = false;
        this.exitEditionMode();
        editionWave.destroy();
        seekbar.playPause();
      };
    },

    popupYesClick() {
      this.isSaved = false;
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
      this.showRecordBlock = true;
      this.showEditionBlock = false;
      this.uploadedAudioFile = null;
      this.finalBlock = false;
    },

    popupNoClick() {
      this.$router.push({ name: 'library' });
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
      this.uploadedAudioFile = null;
    },

    saveAudio() {
      const file = new File([this.uploadedAudioFile], `${this.storyName}.wav`);
      const formData = new FormData();
      formData.append('title', this.storyName);
      formData.append('file', file);
      formData.append('type', 'playshield-story');
      this.isLoading = true;
      storyphoneService.storyUpload(formData).then((story) => {
        this.saved = true;
        this.isAdded = true;
        this.isLoading = false;
        setTimeout(() => {
          this.isAdded = false;
          this.showSave = false;
          this.storyName = '';
          this.audioFile = null;
          this.v$.$reset();
        }, 2000);
      });
    },

    cancelTrim() {
      this.showEditionBlock = false;
      this.showRecordBlock = true;
    },

    editAudio() {
      mainaudio.pause();
      this.showRecordBlock = false;
      this.showEditionBlock = true;
      this.enterEditionMode();
      this.editClip(this.tempClipId, this.tempSource);
      tip.textContent = 'You can drag the cursor inside the wave to select a region';
    },

    recordAudio() {
      this.v$.storyName.$touch();
      const constraints = { audio: true };
      const chunks = [];
      if (!this.v$.storyName.$invalid) {
        this.showSave = false;
        this.clearAudio();
        try {
          const device = navigator.mediaDevices.getUserMedia({ audio: true });
          const items = [];
          device
            .then((stream) => {
              this.recorder = new MediaRecorder(stream);
              this.recorder.ondataavailable = (e) => {
                items.push(e.data);
                if (this.recorder.state === 'inactive' || this.recorder.state === 'paused') {
                  const blob = new Blob(items, { type: this.audioType });
                  this.audioFile = URL.createObjectURL(blob);
                  this.uploadedAudioFile = blob;
                  const { audio } = this.$refs;
                  audio.innerHTML = '';
                  mainaudio = document.createElement('audio');
                  mainaudio.setAttribute('controls', 'controls');
                  mainaudio.setAttribute('style', `width: ${this.audioWidth}px`);
                  audio.appendChild(mainaudio);
                  // eslint-disable-next-line max-len
                  mainaudio.innerHTML = `<source src="${this.audioFile}" preload="auto" type="${this.audioType}" />`;
                  mainaudio.onplay = () => {
                    this.audioIsPlaying = true;
                  };
                  mainaudio.onpause = () => {
                    this.audioIsPlaying = false;
                  };
                  mainaudio.onended = () => {
                    this.audioIsPlaying = false;
                  };
                }

                this.recorder.onstop = () => {
                  const blob = new Blob(items, { type: this.audioType });
                  const audioURL = window.URL.createObjectURL(blob);
                  const clipID = this.generateSoundClipID();
                  const sourceType = 'blob';
                  this.saveSoundClip(clipID, blob, sourceType);
                  this.tempClipId = clipID;
                  this.tempSource = audioURL;
                };
              };
              this.recordAudioState = true;
              this.audioInterval = setInterval(this.checkAudioTime, 500);
              this.recordHide = true;
              this.hideStop = false;
              this.pauseHide = false;
              this.recorder.start();
              this.startTimer();
              timeout = new RecorderTimer((() => {
                this.stopRecordAudio();
              }), 90 * 60 * 1000);
            })
            .catch((err) => {
            // eslint-disable-next-line no-alert
              alert(err);
              // eslint-disable-next-line no-console
            });
        } catch (err) {
        // eslint-disable-next-line no-alert
          alert(`Audio error:  ${err}`);
          // eslint-disable-next-line no-console
          console.error('Audio error: ', err);
        }
      } else {
        console.log('');
      }
    },

    pauseRecording() {
      this.pauseHide = true;
      this.resumeHide = false;
      this.recorder.requestData();
      this.recorder.pause();
      this.stopTimer();
      this.recordAudioState = false;
      timeout.pause();
    },

    resumeRecording() {
      this.resumeHide = true;
      this.pauseHide = false;
      this.recorder.resume();
      this.startTimer();
      this.recordAudioState = true;
      timeout.resume();
    },
    stopRecordAudio() {
      clearInterval(this.audioInterval);
      this.recorder.stop();
      this.clearAll();
      this.recordAudioState = false;
      this.hideStop = true;
      this.pauseHide = true;
      this.showSave = true;
      timeout.cancel();
    },
    deleteAudioFile() {
      if (this.recordAudioState === false) {
        this.audioFile = null;
        this.uploadedAudioFile = null;
      }
    },
    setSubtractStartTime(times) {
      const time = typeof times !== 'undefined' ? times : 0;
      this.startTime = Math.floor(performance.now() - time);
    },
    startTimer() {
      const vm = this;
      vm.setSubtractStartTime(vm.diffTime);
      (function loop() {
        vm.nowTime = Math.floor(performance.now());
        vm.diffTime = vm.nowTime - vm.startTime;
        vm.animateFrame = requestAnimationFrame(loop);
      }());
      vm.isRunning = true;
    },
    stopTimer() {
      this.isRunning = false;
      cancelAnimationFrame(this.animateFrame);
    },

    cancelAudio() {
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
    },

    clearAll() {
      this.startTime = 0;
      this.nowTime = 0;
      this.diffTime = 0;
      this.times = [];
      this.stopTimer();
      this.animateFrame = 0;
    },
    gotToList() {
      if (this.audioFile) {
        if (this.saved) {
          this.recordAudioState = true;
          this.storyName = '';
          this.v$.$reset();
          this.showSave = false;
          this.audioFile = null;
          this.isShowRecordingAction(false);
          this.isShowFilesAction(true);
          this.inSecondStepAction(true);
          this.saved = false;
        } else {
          this.isVerified = true;
        }
      } else {
        this.isShowRecordingAction(false);
        this.isShowFilesAction(true);
        this.saved = false;
        this.inSecondStepAction(true);
      }
    },
    recordingBack() {
      this.recordAudioState = true;
      this.storyName = '';
      this.v$.$reset();
      this.showSave = false;
      this.audioFile = null;
      this.isShowRecordingAction(false);
      this.isShowUploadRecordAction(true);
    },
    toTime(seconds) {
      const date = new Date(null);
      date.setSeconds(7200 - seconds);
      return date.toISOString().substr(11, 8);
    },
    closeVerfied() {
      this.isVerified = false;
    },
    // this

    disableEditionControls() {
      // removeSelection.disabled = true;
      clearRegions.disabled = true;
      cutAudio.disabled = true;
      cancelEditing.disabled = true;
      playFullClip.disabled = true;
      playEditedPreview.disabled = true;
    },

    enableEditionControls() {
      // removeSelection.disabled = false;
      cancelEditing.disabled = false;
      playFullClip.disabled = false;
    },

    setTipOnNoRegions() {
      tip.textContent = 'You can drag the cursor inside the wave to select a region';
    },

    setTipOnRegionCreated() {
      // tip.textContent = 'You can delete a region by double clicking on it';
    },

    clearTip() {
      tip.textContent = '';
    },

    enableEditionOnCreation() {
      cutAudio.disabled = false;
      this.setTipOnRegionCreated();
      playEditedPreview.disabled = false;
      clearRegions.disabled = false;
    },

    disableEditionOnClear() {
      clearRegions.disabled = true;
      cutAudio.disabled = true;
      this.setTipOnNoRegions();
      playEditedPreview.disabled = true;
    },

    disableRecordingControls() {
      record.disabled = true;
      stop.disabled = true;
    },

    enableRecordingControls() {
      record.disabled = false;
      stop.disabled = false;
    },

    enterEditionMode() {
      // this.disableRecordingControls();
      this.enableEditionControls();
      this.setTipOnNoRegions();
    },

    exitEditionMode() {
      // this.enableRecordingControls();
      this.disableEditionControls();
      this.clearTip();
    },

    disableEditionModeToggle() {
      // removeSelection.style.background = '';
    },

    generateClipWave(waveContainerID) {
      const clipWave = WaveSurfer.create({
        container: waveContainerID,
        waveColor: '#605dc7',
        progressColor: '#605dc7',
        cursorColor: 'red',
        cursorWidth: 2,
        barWidth: 2,
        barGap: 1,
        barHeight: 5,
        height: 60,
        hideScrollbar: true,
      });
      return clipWave;
    },

    loadWave(wave, source) {
      if (typeof source === 'string') {
        wave.load(source);
        this.isLoading = false;
      } else {
        wave.loadDecodedBuffer(source.data);
        this.isLoading = false;
      }
    },

    // deleteSoundClip(clipID) {
    //   const clipIndex = storedClips.findIndex(
    //     (storedClips) => storedClips.id === clipID,
    //   );
    //   generatedClips.splice(clipIndex, 1);
    // },

    getSoundClip(clipID) {
      return generatedClips.find((clip) => clip.id === clipID);
    },

    slice(originalSource, regionsIntervals) {
      const audioContext = new AudioContext();
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const myArrayBuffer = fileReader.result;
        audioContext.decodeAudioData(myArrayBuffer, async (buffer) => {
          const sampleRate = buffer.sampleRate;
          const numberChannels = buffer.numberOfChannels;
          let start;
          let end;
          const data = [0];
          const regionsData = [];

          for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
            const channelData = buffer.getChannelData(channel);

            for (let i = 0; i < regionsIntervals.length; i++) {
              start = regionsIntervals[i][0] * sampleRate;
              end = regionsIntervals[i][1] * sampleRate;
              const bufferSlice = channelData.slice(start, end);
              regionsData.push(bufferSlice);
              for (let pos = 0; pos < regionsData[i].length; pos++) {
                data.push(regionsData[i][pos]);
              }
            }
          }

          const cutClipID = this.generateSoundClipID();
          const cutWaveContainerID = this.generateWaveContainerID(cutClipID);
          const cutWaveAudioBuffer = audioContext.createBuffer(
            numberChannels,
            data.length,
            sampleRate,
          );

          if (data) {
            let l;
            let c;
            for (c = 0, l = 1; c < l; c++) {
              cutWaveAudioBuffer.getChannelData(c).set(data);
            }
          }

          const sourceType = 'audioBuffer';

          this.saveSoundClip(cutClipID, cutWaveAudioBuffer, sourceType);

          audioEncoder(cutWaveAudioBuffer, 128, null, (blob) => {
            this.uploadedAudioFile = blob;
          });
          this.generateClip(
            cutClipID,
            cutWaveContainerID,
            this.getSoundClip(cutClipID),
            'Clip de Audio Editado',
          );
        });
      };
      fileReader.readAsArrayBuffer(originalSource.data);
    },

    editClip(clipID, audioSource) {
      currentEditionClipID = clipID;
      let clipDuration;
      let selectedRegionID;
      editionWave = WaveSurfer.create({
        container: '#editionBar',
        waveColor: '#605dc7',
        progressColor: '#605dc7',
        cursorColor: 'red',
        cursorWidth: 2,
        barWidth: 2,
        barGap: 1,
        barHeight: 5,
        height: 96,
        // hideScrollbar: true,
        plugins: [
          Region.create({
            regions: [
              {
                start: 0,
                end: 2,
                loop: false,
              },
            ],
            dragSelection: {},
          }),
        ],
      });

      editionWave.on('region-created', (region) => {
        editionWave.regions.clear();
      });

      if (appStatus === 'editing') {
        editionWave.destroy();
      }

      this.loadWave(editionWave, audioSource);
      appStatus = 'editing';

      let selectedRegion = null;

      editionWave.on('ready', () => {
        editionWave.enableDragSelection({
          color: 'rgba(0, 92, 200, 0.3)',
        });
        clipDuration = editionWave.getDuration();
      });

      // PROBANDO ACCESO A PARAMETROS DE LAS REGIONES
      editionWave.on('region-created', (region, event) => {
        this.enableEditionOnCreation();
      });

      editionWave.on('region-click', (region, event) => {
        selectedRegionID = region.id;

        if (selectedRegion == null) {
          selectedRegion = region;
          selectedRegion.update({
            color: 'rgba(21, 209, 52, 0.3)',
          });
        } else {
          selectedRegion.update({
            color: 'rgba(0, 92, 200, 0.3)',
          });
          selectedRegion = region;
          selectedRegion.update({
            color: 'rgba(21, 209, 52, 0.3)',
          });
        }
      });

      editionWave.on('region-dblclick', (region, event) => {
        region.remove();
        if (Object.keys(editionWave.regions.list).length === 0) {
          this.disableEditionOnClear();
        }
      });

      editionWave.on('region-update-end', (region) => {
        editionWave.drawer.un('click');

        if (selectedRegion != null) {
          selectedRegion.update({
            color: 'rgba(0, 92, 200, 0.3)',
          });
        }

        selectedRegion = region;
      });

      // Zoom slider
      editionWave.zoom(-1); // Resetting zoom

      playFullClip.onclick = () => {
        editionWave.seekTo(0);
        editionWave.playPause();
      };

      playEditedPreview.onclick = () => {
        const regionsIDs = Object.keys(editionWave.regions.list);
        for (let i = 0; i < regionsIDs.length; i++) {
          editionWave.regions.list[regionsIDs[i]].play();
        }
      };

      cutAudio.onclick = () => {
        editionWave.playPause();
        this.isLoading = true;
        const regionsIDs = Object.keys(editionWave.regions.list);
        const regionsCount = regionsIDs.length;

        const originalRegionIntervals = [];
        let selectedRegionsIntervals = [];
        const nonSelectedRegionsIntervals = [];

        for (let idPosition = 0; idPosition < regionsCount; idPosition++) {
          originalRegionIntervals.push([
            editionWave.regions.list[regionsIDs[idPosition]].start,
            editionWave.regions.list[regionsIDs[idPosition]].end,
          ]);
        }

        // Ordenando a partir de los tiempos iniciales de cada región
        originalRegionIntervals.sort((a, b) => a[0] - b[0]);

        // Creando regiones finales uniendo regiones solapadas
        if (regionsCount > 1) {
          // Inicializando posiciones
          let leftStart = originalRegionIntervals[0][0];
          let leftEnd = originalRegionIntervals[0][1];
          let newStart = leftStart;
          let newEnd = leftEnd;
          let rightStart;
          let rightEnd;

          for (
            let leftRegion = 0, rightRegion = 1;
            rightRegion < regionsCount;
            rightRegion++
          ) {
            // eslint-disable-next-line prefer-destructuring
            rightStart = originalRegionIntervals[rightRegion][0];
            // eslint-disable-next-line prefer-destructuring
            rightEnd = originalRegionIntervals[rightRegion][1];

            if (leftEnd > rightStart) {
              newEnd = rightEnd;
            } else {
              selectedRegionsIntervals.push([newStart, newEnd]);
              leftRegion = rightRegion;
              // eslint-disable-next-line prefer-destructuring
              leftStart = originalRegionIntervals[leftRegion][0];
              // eslint-disable-next-line prefer-destructuring
              leftEnd = originalRegionIntervals[leftRegion][1];
              newStart = leftStart;
              newEnd = leftEnd;
            }
          }
          // Insertar última región
          selectedRegionsIntervals.push([newStart, newEnd]);
        } else {
          selectedRegionsIntervals = originalRegionIntervals;
        }

        // Determinando intervalos de regiones no seleccionadas
        for (
          let currentRegion = 0, start = 0, end = selectedRegionsIntervals[0][0];
          currentRegion < selectedRegionsIntervals.length;
          currentRegion++
        ) {
          if (selectedRegionsIntervals[currentRegion][0] > start) {
            end = selectedRegionsIntervals[currentRegion][0];
            nonSelectedRegionsIntervals.push([start, end]);
            start = selectedRegionsIntervals[currentRegion][1];
          } else {
            start = selectedRegionsIntervals[currentRegion][1];
          }

          // Insertar última región
          if (
            currentRegion === selectedRegionsIntervals.length - 1 && selectedRegionsIntervals[currentRegion][1] < clipDuration
          ) {
            nonSelectedRegionsIntervals.push([
              selectedRegionsIntervals[currentRegion][1],
              clipDuration,
            ]);
          }
        }

        const audioBlob = generatedClips[0].data;

        this.slice(this.getSoundClip(clipID), nonSelectedRegionsIntervals);
        this.showEditionBlock = false;
        this.finalBlock = true;
      };

      clearRegions.onclick = () => {
        editionWave.playPause();
        editionWave.clearRegions();
        this.disableEditionOnClear();
      };

      cancelEditing.onclick = () => {
        editionWave.playPause();
        this.exitEditionMode();
        this.disableEditionModeToggle();
        editionWave.destroy();
        appStatus = 'inactive';
      };
    },

    // Trim end
    ...mapActions({
      recordingListAction: 'fetchRecordingList',
      isShowRecordingAction: 'isShowRecording',
      isShowUploadRecordAction: 'isShowUploadRecord',
      isShowFilesAction: 'isShowFiles',
      inSecondStepAction: 'inSecondStep',
    }),
  },

  validations() {
    return {
      storyName: {
        required: helpers.withMessage('Enter a Story Name', required),
      },
    };
  },

  computed: {
    ...mapGetters({
      duration: 'listDuration',
      showRecording: 'isShowRecording',
    }),
    hours() {
      return Math.floor(this.diffTime / 1000 / 60 / 60);
    },

    minutes() {
      return Math.floor(this.diffTime / 1000 / 60) % 60;
    },
    seconds() {
      return Math.floor(this.diffTime / 1000) % 60;
    },
    milliSeconds() {
      return Math.floor(this.diffTime % 1000);
    },
  },
};
</script>

<style scoped lang="scss"></style>
