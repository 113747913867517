import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      storyShieldsList: {},
      storyPhoneList: {},
      playShieldList: {},
      zenShieldList: {},
      playlists: {},
      storyList: {},
      storyDetails: {},
      playlistsDetails: {},
      selectedShieldDetails: {},
      selectedShieldSku: {},
      storyShieldPrice: {},
      successMessage: '',
      errorMessage: '',
      wishlist: {},
      cartItemDetails: {},
      cartItemPrice: {},
      selectedStoryPhone: {},
      userWishlist: {},
      searchedItems: [],
      selectedPlayShield: {},
      playlistDetails: [],
      genresDetails: [],
      onanoffStoryPhones: [],
      disneyStoryPhones: [],
      lingoKidsStoryPhones: [],
      playShieldPlus: [],

    };
  },
  mutations,
  actions,
  getters,
};
