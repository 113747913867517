<template lang="html">
  <PageHeader />

  <section class="warranty-registration account-section mt-stickybar home">
    <div class="inner-page-banner full-width">
      <img src="@/assets/images/warranty-reg.jpg" alt="" />
    </div>
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-7 col-xl-6 m-auto">
            <div class="account-form-block general-form-block">
              <h2 class="mb-2">{{ $t('warranty') }}</h2>
              <p>{{ $t('warrantyInput') }}</p>
              <div class="form-group">
                <label>{{ $t('fullName') }}</label>
                <input type="text" v-model="registration.name" class="form-control" />
                <span class="text-danger" v-if="v$.name.$error">
                  {{ v$.name.$errors[0].$message }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('email') }}</label>
                <input type="email" v-model="registration.email" class="form-control" />
                <span class="text-danger" v-if="v$.email.$error">
                  {{ v$.email.$errors[0].$message }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('selectProduct') }}</label>
                <select v-model="registration.product" class="form-control">
                  <option>StoryPhones</option>
                  <option>DisneyPhones</option>
                  <option>StoryShield</option>
                  <option>PlayShield</option>
                  <option>PlayShield Plus</option>
                  <option>ZenShield</option>
                </select>
                <span class="text-danger" v-if="v$.product.$error">
                  {{ v$.product.$errors[0].$message }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('productSerialNumber') }}</label>
                <input type="text" v-model="registration.serialNumber" class="form-control" />
                <span class="text-danger" v-if="v$.serialNumber.$error">
                  {{ v$.serialNumber.$errors[0].$message }}*
                </span>
              </div>
              <div class="form-group">
                <label>{{ $t('productBroughtFrom') }}</label>
                <textarea class="form-control" v-model="registration.place" rows="3"></textarea>
                <span class="text-danger" v-if="v$.place.$error">
                  {{ v$.place.$errors[0].$message }}*
                </span>
              </div>
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck" />
                  <label class="form-check-label" for="gridCheck">{{
                    $t('promotionalOffers')
                  }}</label>
                </div>
              </div>
              <div class="wave-btn-block text-right">
                <button
                  type="submit"
                  class="blob-btn fill-bg btn-lg submit-btn mt-2"
                  @click="submitRegistration()"
                >
                  {{ $t('submit') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
              <div
                class="success-submit-box modal fade mt-3 d-none"
                :class="{ addsuccess: isAdded, show: isAdded }"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content border-0 p-3">
                    <div class="modal-body">
                      <div class="popup-msg-box">
                        <p>{{ $t('contactUsMessage') }}!</p>
                      </div>
                    </div>
                    <div class="text-center">
                      <a href="/" class="btn btn-primary btn-lg submit-btn mb-3">{{ $t('ok') }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5">
            <a href="/contact-us" class="p-0 border-0">
              <img src="@/assets/images/warranty-contact-us.jpg" alt="" />
            </a>
          </div>
        </div>

        <div class="serial-number mt-5">
          <h2>{{ $t('whereSerialNumber') }}</h2>
          <div class="row">
            <div class="col-md-6"><img src="@/assets/images/btm-serial.jpg" alt="" /></div>
            <div class="col-md-6"><img src="@/assets/images/top-serial.jpg" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, helpers, alpha,
} from '@vuelidate/validators';
import {
  ref, computed, reactive,
} from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';

const storyphoneService = new StoryphoneService();

export default {
  name: 'WarrantyRegistration',
  props: [],
  components: {
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const isAdded = ref(false);

    const registration = reactive({
      name: '',
      email: '',
      product: '',
      serialNumber: '',
      place: '',
    });

    const rules = {
      name: { required: helpers.withMessage('You must enter your name', required) },
      email: { required: helpers.withMessage('You must enter your email', required), email },
      product: { required: helpers.withMessage('You must select a product', required) },
      serialNumber: { required: helpers.withMessage('You must enter the serial number', required) },
      place: { required: helpers.withMessage('You must specify where you bought your product from', required) },
    };

    const v$ = useVuelidate(
      rules,
      registration,
    );

    const submitRegistration = async () => {
      const result = await v$.value.$validate();
      if (result) {
        const data = {
          customer_email: registration.email,
          customer_name: registration.name,
          product_title: registration.product,
          product_serial_number: registration.serialNumber,
          purchased_from: registration.place,
        };
        storyphoneService.warrantyRegistration(data)
          .then((res) => {
            isAdded.value = true;
          })
          .catch(() => {

          });
      } else {
        console.log('fail');
      }
    };

    return {
      v$, isAdded, registration, submitRegistration,
    };
  },
};
</script>

<style scoped lang="scss"></style>
