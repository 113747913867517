<template lang="html">
  <PageHeader />
  <MarketplaceAuthorProfile />
  <MarketPlaceSearchs />
  <AuthorCards />
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import MarketplaceAuthorProfile from '@/components/partials/MarketplaceAuthorProfile.vue';
import MarketPlaceSearchs from '@/components/partials/MarketPlaceSearchs.vue';
import AuthorCards from '@/components/partials/AuthorCards.vue';

export default {
  name: 'MarketplaceAuthorDetails',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    MarketplaceAuthorProfile,
    MarketPlaceSearchs,
    AuthorCards,
  },
  mounted() {

  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>
