<template lang="html">
  <PageHeader />

  <section class="mt-stickybar work-in-progress home" aria-label="work in progress">
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-12 m-auto text-center error-bg pt-5">
          <h1 class="">{{ $t('comingSoon') }}</h1>
          <h5>
            <!-- Let imagination be limitless, with  -->{{ homeAwards.title }}
            <span class="highlight-title">
              <!-- StoryPhones. -->
              {{ homeAwards.excerpt }}
            </span>
          </h5>

          <img src="@/assets/images/image.png" alt="" />
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Terms Of Service',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_awards_${langCode}`,
    };
    // store.dispatch('fetchCMS', Data);

    const homeAwards = computed(() => store.getters.getCMS);

    return {
      langCode,
      homeAwards,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
