<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="cart-details mt-stickybar home pt-5">
    <div class="container-fluid text-start mb-4">
      <a href="/shields">
        <img src="@/assets/images/1020.png" alt="" class="back-button" />
      </a>
    </div>
    <div v-if="productDetails" class="container mb-5">
      <div class="row align-items-center">
        <div class="col-md-6 m-auto">
          <div v-if="productDetails.brand" class="text-center px-5">
            <img :src="getImage(productDetails)" alt="" class="w-100" />
            <div v-if="productDetails?.brand?.name" class="col-md-12 mt-4">
              <img :src="productDetails?.brand?.image" alt="" width="200" />
            </div>
            <button
              v-if="productDetails?.sample_audio"
              type="button"
              class="blob-btn fill-bg play-btn mt-3 mb-2"
              data-bs-target="#shield-play-popup"
              data-bs-toggle="modal"
            >
              <span class="play-arrow me-1"
                ><i class="icon-right-arrow" aria-hidden="true"></i
              ></span>
              {{ $t('play') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <h1>{{ productDetails.title }}</h1>
          <h4 v-if="priceDetails[0]?.prices[0]">
            {{ price(priceDetails[0]?.prices[0]) }}
            <!-- {{ priceDetails[0]?.prices[0]?.formatted_amount.slice(0, 1) }}
              {{ priceDetails[0]?.prices[0]?.amount_float }} -->
          </h4>
          <div v-if="productDetails.title">
            <p v-if="productName(productDetails?.title)" class="mt-3">
              {{ productDetails.description }}
            </p>
            <p v-else class="mt-3">Product Details</p>
          </div>

          <ol v-if="productDetails?.informations" class="pl-3">
            <li
              class="w-100 p-0 m-0"
              v-for="storyList in productDetails?.informations"
              :key="storyList.id"
            >
              {{ storyList }}
            </li>
          </ol>
          <div v-if="productDetails?.age">
            <p>&copy;{{ productDetails?.brand?.name }}</p>
          </div>
          <span>
              <h6>目前该产品已在onanoff天猫旗舰店上线，点击此处购买</h6>
            </span>
            <span>
              <a href="https://m.tb.cn/h.gMgMxEzwGSaHSIn?tk=mKec35Jcdcz" target="_blank">
                <img class="me-3" src="@/assets/images/taobao.png" width="40"  alt="">
              </a>
             </span>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12 px-4 details-carousel mt-5">
        <h4>{{ $t('umaylike') }}</h4>
        <ShieldCarouselListing />
      </div> -->
  </section>
  <div
      class="modal fade"
      id="shield-play-popup"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @focusout="stopAudio()">
          <button
            type="button"
            class="btn-close close popup"
            data-bs-dismiss="modal"
            @click="stopAudio()"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <div v-if="productDetails" class="modal-body text-center py-5">
            <h5>{{ productDetails?.title }}</h5>
            <div class="audio audioStyle">
              <audio
                id="audio"
                controls="controls"
                class="audioStyle"
                ref="audio"
                :src="productDetails?.sample_audio"
              ></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import 'vue3-carousel/dist/carousel.css';
import { useCookies } from 'vue3-cookies';
import { ref, computed, reactive } from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import ShieldCarouselListing from '@/components/partials/ShieldCarouselListing.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';

import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';

const { cookies } = useCookies({ secure: true });
const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();

export default {
  name: 'ShieldsDetails',
  props: [],
  components: {
    // ShieldCarouselListing,
    PageHeader,
    PageFooter,
    Loader,
  },

  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');
    const route = useRoute();
    const isAdded = ref(false);
    const sku = ref('');
    const isLoading = ref(false);
    const priceObject = ref({});
    const finalArray = ref([]);

    sku.value = route.params.id;

    const Data = {
      sku: sku.value,
      locale: langCode,
    };
    isLoading.value = true;
    store.dispatch('fetchCartItemDetails', Data).then((details) => {
      store.dispatch('fetchCartItemPrice', sku.value)
        .then((prices) => {
          isLoading.value = false;
          prices.forEach((price) => {
            priceObject.value = { ...price, ...details };
            finalArray.value.push(priceObject.value);
          });
        });
    });

    const productDetails = computed(() => store.getters.cartItemDetails);
    const priceDetails = computed(() => store.getters.cartItemPrice);
    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    const getImage = (productDetail) => productDetail?.image;

    const productName = (name) => {
      if (name.includes('StoryPhones')) {
        return false;
      }
      return true;
    };
    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };
    const stopAudio = () => {
      document.getElementById('audio').pause();
    };
    return {
      productDetails,
      priceDetails,
      price,
      getImage,
      productName,
      isLoading,
      selectedShieldDetails,
      stopAudio,
      finalArray,
    };
  },

};
</script>

<style scoped lang="scss"></style>
