<template lang="html">
  <section class="marketplace-story">
    <div class="story-slider-block mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <h3>Top Rated</h3>
              <div class="wave-btn-block">
                <a href="#" class="blob-btn fill-bg btn-sm">
                  See All
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>

                <SvgButton />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of other-story-tellers -->

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="popular-trending py-5">
            <div class="d-flex justify-content-between">
              <h3>Popular and Trending</h3>
              <div class="wave-btn-block">
                <a href="#" class="blob-btn fill-bg btn-sm">
                  See All
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>

                <SvgButton />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>
            </div>
          </div>
          <!-- End of popular-trending -->

          <div class="popular-trending pb-5">
            <div class="d-flex justify-content-between">
              <h3>Audiance Choice</h3>
              <div class="wave-btn-block">
                <a href="#" class="blob-btn fill-bg btn-sm">
                  See All
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>

                <SvgButton />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>

              <div class="col-md-6 col-lg-4">
                <MarketplaceSingleStory />
              </div>
            </div>
          </div>
          <!-- End of Audiance Choice -->
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import MarketplaceSingleStory from '@/components/partials/MarketplaceSingleStory.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'marketplace-story',
  props: [],
  components: {
    MarketplaceSingleStory,
    SvgButton,

  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
