import PageService from '@/services/PageService';
import * as types from './mutation-types';

const ent = require('ent');

const pageService = new PageService();

export const fetchCMS = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS1 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then(async (response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM1, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS2 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM2, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS3 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM3, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS4 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM4, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS5 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM5, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS6 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM6, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS7 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM7, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS8 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM8, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS9 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM9, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCMS10 = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTENT_MANAGEMENT_SYSTEM10, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchLangCode = ({ commit }, Data) => {
  commit(types.LANG_CODE, Data);
};

export const fetchContactUs = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.CONTACT_US, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserManualIos = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.USER_MANUAL_IOS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchUserManualAndroid = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.USER_MANUAL_ANDROID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchApp = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    pageService
      .contentManagementSystem(Data)
      .then((response) => {
        commit(types.APP, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
