<template>
  <div class="modal-body px-5">
    <div class="row">
      <div class="col-sm-4">
        <div class="text-end">
          <button class="btn storyteller-btn">
            <img src="@/assets/images/heart.png" alt="star" class="" /> Follow
          </button>
        </div>
        <div class="card w-100 border-0 mp-footer text-center">
          <div class="card-body p-0">
            <div class="author-details">
              <div class="mt-4 author-img">
                <img src="@/assets/images/author1.png" alt="customer like also" />
              </div>
              <div class="d-flex text-center details-group">
                <img src="@/assets/images/leaf_left.png" alt="..." class="left-leaf" />

                <h6 class="mt-2">B. B. Alston</h6>
                <img src="@/assets/images/leaf_right.png" alt="..." class="right-leaf" />
              </div>
              <div class="mt-1 author-star">
                <img src="@/assets/images/Star31.png" alt="star" class="" />
                <img src="@/assets/images/Star31.png" alt="star" class="" />
                <img src="@/assets/images/Star31.png" alt="star" class="" />
                <img src="@/assets/images/Star31.png" alt="star" class="" />
                <img src="@/assets/images/Star35.png" alt="star" class="" />
              </div>
              <div class="border-0 mt-2 mb-4 author-star">15 Stories</div>
            </div>
          </div>
        </div>
        <div class="text-start">
          <h5>Author bio</h5>
          <p>
            B. B. Alston is the #1 New York Times and USA Today bestselling author of Amari and the
            Night Brothers and Amari and the Great Game. His books have been published in over 30
            countries and won numerous international awards. When not writing, he can be found
            eating too many sweets and exploring country roads to see where they lead.
          </p>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-6">
            <div class="ms-4 details-img">
              <img src="@/assets/images/Amari.png" alt="customer like also" class="" />
            </div>
            <div class="d-flex mt-4">
              <button class="btn btn-primary rounded-circle">
                <i class="fa fa-play" aria-hidden="true"></i>
              </button>
              <input type="range" class="ms-2 w-100" />
            </div>
          </div>
          <div class="col-sm-6">
            <h4>Amari and the Night Brothers</h4>
            <div class="">
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star31.png" alt="star" class="" />
              <img src="@/assets/images/Star35.png" alt="star" class="" />
            </div>
            <div class="mt-2 details-range">
              <h5>By: <span>B.B. Alston</span></h5>
            </div>
            <div class="row details-bg">
              <div class="col-sm-6">
                <div class="d-flex">
                  <img src="@/assets/images/play.png" alt="star" class="" />
                  <h6>DURATION:</h6>
                </div>
                <p>32:09</p>
              </div>
              <div class="col-sm-6">
                <div class="d-flex">
                  <img src="@/assets/images/book.png" alt="star" class="" />
                  <h6>CHAPTER:</h6>
                </div>
                <p>06</p>
              </div>
            </div>
            <div class="mt-3 details-btn">
              <button class="btn btn-primary rounded-circle">
                <i class="icon-cart" aria-hidden="true"></i>
              </button>
              €14,99
            </div>
          </div>
        </div>
        <div class="mt-3">
          <h4>Story description</h4>
          <p>
            New York Times best seller! Artemis Fowl meets Men in Black in this exhilarating debut
            middle-grade fantasy, the first in a trilogy filled with #blackgirlmagic. Perfect for
            fans of Tristan Strong Punches a Hole in the Sky, the Percy Jackson series, and
            Nevermoor. Amari Peters has never stopped believing her missing brother, Quinton, is
            alive. Not even when the police told her otherwise, or when she got in trouble for
            standing up to bullies who said he was gone for good.
          </p>
        </div>
        <hr class="hr-line" />
        <div class="d-flex justify-content-between">
          <h6>403 reviews</h6>
          <h6>More</h6>
        </div>
        <div>
          <div class="">
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
          </div>
          <p class="mt-2">LOVE THIS SO MUCH. The best story for my kids.</p>
          <div class="d-flex">
            <span class="me-3">Blah Blah Blah</span><span>31 Jan, 2021</span>
          </div>
        </div>
        <div>
          <div class="mt-3">
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
            <img src="@/assets/images/Star31.png" alt="star" class="" />
          </div>
          <p class="mt-2">
            Every night, my kids will always listen to this and sleep. I love this story so much.
          </p>
          <div class="d-flex">
            <span class="me-3">Blah Blah Blah</span><span>31 Jan, 2021</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketplaceDetails',
};
</script>

<style></style>
