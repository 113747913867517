<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="currency-page">
    <div class="currency-outer">
      <!-- {{userMarkets}} -->
      <div class="btn-group currency">
        <button
          type="button"
          class="dropdown-toggle p-0 border-0"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span>{{ market }}</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end animate__animated animate__fadeInUp">
          <li v-for="(market, index) in userMarkets" :key="index">
            <a class="dropdown-item" @click="changeCurrency(market, index)">{{
              market.price_list.name
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';

import CLayerBaseService from '@/services/CLayerBaseService';
import CLayerService from '@/services/CLayerService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const { cookies } = useCookies({ secure: true });
const cLayerBaseService = new CLayerBaseService();
const cLayerService = new CLayerService();

export default {
  name: 'currency',
  props: [],
  mounted() {

  },
  components: {
    Loader,
  },

  setup() {
    const store = useStore();
    const selectedCurrency = ref('');
    const isLoading = ref(false);
    const router = useRouter();
    const userMarkets = computed(() => store.getters.getUserMarkets);

    const market = localStorage.getItem('marketName');

    if (userMarkets?.value?.length === 0) {
      // store.dispatch('fetchMarkets');
    }

    const changeCurrency = (data, index) => {
      const storyToken = cookies.get('storyPhoneUserAuthCookie');
      router.push('/');
      isLoading.value = true;
      selectedCurrency.value = data.name;
      localStorage.removeItem('marketNumber');
      localStorage.removeItem('marketName');
      localStorage.setItem('marketNumber', '12501');
      localStorage.setItem('marketName', data.price_list.name);

      localStorage.removeItem('storyPhoneUser');
      localStorage.removeItem('cLayerUserToken');

      cookies.remove('clUserAuthCookie');

      cookies.remove('orderIdCookie');

      cookies.remove('clUserOwnerId');
      cookies.remove('clAuthCookie');
      cookies.remove('clUserAuthRefreshCookie');
      cookies.remove('storyPhoneUserRefreshCookie');
      cookies.remove('storyPhoneUserAuthCookie');

      cLayerBaseService.createToken()
        .then(() => {
          const pagePath = router.currentRoute.value.path;
          // router.push('/');

          if (pagePath === '/') {
            window.location.reload();
          }
          window.location.reload();
          isLoading.value = false;
        })
        .catch((err) => {
          isLoading.value = false;
        });
    };

    return {
      userMarkets, changeCurrency, market,
    };
  },
};
</script>

<style scoped lang="scss"></style>
