<template>
  <PageHeader />

  <section class="market-place-banner home">
    <div class="market-place-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="position-relative">
            <img
              src="@/assets/images/marketplace-banner.png"
              class="img-fluid"
              alt="marketplace-banner"
            />
            <div class="card market-banner-card p-3 position-absolute ms-5 pt-4 pb-3">
              <h3 class="text-black">
                Bring your book to life with StoryPhones! Create & distribute audio books that easy
                eay.
              </h3>
              <p class="text-black">
                Join us, be part of a community of storytellers. Contribute to the ever growing
                library of stories. Upload, promote and manage your own creations to a global
                network of children.
              </p>
              <div class="d-flex market-banner-btn">
                <button
                  class="btn btn-primary banner-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#marketPlaceModal"
                >
                  Join us
                </button>
                <button class="btn btn-secondary banner-btn2 mx-4">Learn more</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    class="modal fade"
    id="marketPlaceModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="marketPlaceModalLabel"
    aria-hidden="true"
  >
    <MarketPlaceLoginModal />
  </div>

  <MarketPlaceSearchs />
  <MarketPlaceAudioBook />
  <MarketplaceStoryteller />
  <MarketPlaceMedicationStories />

  <MarketPlaceFooter />

  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import MarketPlaceAudioBook from '@/components/partials/MarketPlaceAudioBook.vue';
import MarketPlaceMedicationStories from '@/components/partials/MarketPlaceMedicationStories.vue';
import MarketplaceStoryteller from '@/components/partials/MarketplaceStoryteller.vue';
import MarketPlaceFooter from '@/components/partials/MarketPlaceFooter.vue';
import MarketPlaceSearchs from '@/components/partials/MarketPlaceSearchs.vue';
import MarketPlaceLoginModal from '@/components/partials/MarketPlaceLoginModal.vue';

export default {
  name: 'marketplacehome',
  components: {
    PageHeader,
    PageFooter,
    MarketPlaceAudioBook,
    MarketPlaceMedicationStories,
    MarketplaceStoryteller,
    MarketPlaceFooter,
    MarketPlaceSearchs,
    MarketPlaceLoginModal,
  },
};
</script>

<style></style>
