<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="all-shields mt-stickybar home">
    <div class="inner-page-content py-5">
      <div class="container-fluid text-start mb-4">
        <a href="/shields">
          <img src="@/assets/images/1020.png" alt="" class="back-button" />
        </a>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ getGenresDetail?.title }} StoryShields</h1>
            <!-- <h1>{{ storyShieldList[0]?.fields?.genres[0]?.fields?.name }} StoryShields</h1> -->

            <ul class="story-categories list-unstyled" v-if="finalArray.length > 0">
              <li class="text-center" v-for="(shield, index) in finalArray" :key="shield.id">
                <a
                  class="p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#product-details-popup"
                  @click="shieldClick(shield)"
                >
                  <ShieldListingItem :shieldData="shield" :shieldSku="priceDetails[index]" />
                </a>
                <ShieldListingButton :shieldData="shield" :shieldSku="priceDetails[index]" />
              </li>
            </ul>

            <div
              class="modal fade"
              id="product-details-popup"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <button
                    type="button"
                    class="btn-close close-blue-btn ms-auto"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="icon-close" aria-hidden="true"></i>
                  </button>

                  <DetailsOfCategory
                    v-if="selectedShieldDetails && this.selectedShieldSku"
                    :shield-data="selectedShieldDetails"
                    :shield-sku="this.selectedShieldSku"
                  />
                  <div v-else><Loader /></div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-4">
              <VuejsPaginateNext
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageClick"
                :margin-pages="2"
                :page-range="5"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
              ></VuejsPaginateNext>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Toggle modals Second -->
    <div
      class="modal fade"
      id="shield-play-popup"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @focusout="stopAudio()">
          <button
            type="button"
            class="btn-close close popup"
            data-bs-target="#product-details-popup"
            data-bs-toggle="modal"
            @click="stopAudio()"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <div v-if="selectedShieldDetails" class="modal-body text-center py-5">
            <h5>{{ selectedShieldDetails?.title }}</h5>
            <div class="audio audioStyle">
              <audio
                id="audio"
                controls="controls"
                class="audioStyle"
                ref="audio"
                :src="selectedShieldDetails?.sample_audio"
              ></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';
import ShieldListingItem from '@/components/partials/ShieldListingItem.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'AllShields',
  components: {
    PageHeader,
    PageFooter,
    Loader,
    ShieldListingButton,
    ShieldListingItem,
    DetailsOfCategory,
    VuejsPaginateNext,
  },
  props: [
  ],
  setup() {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const shieldSku = ref({});
    const isAdded = ref(false);
    const storyShieldList = ref([]);
    const categoryId = ref('');
    const page = ref(1);
    const perPage = ref(10);
    const currentOffset = ref(0);
    const pageCount = ref(0);
    const storyShieldSkuList = ref([]);
    const priceDetails = ref([]);
    const priceObject = ref({});
    const finalArray = ref([]);

    categoryId.value = route.params.id;
    const details = {
      id: categoryId.value,
      locale: langCode,

    };
    store.dispatch('fetchGenresDetail', details);

    isLoading.value = true;
    newContentfulService.fetchShielsByGenres(categoryId.value, perPage.value, currentOffset.value, langCode)
      .then((response) => {
        storyShieldSkuList.value = [];
        priceDetails.value = [];
        pageCount.value = Math.ceil(response.total / perPage.value);
        storyShieldList.value = response.items;
        storyShieldList.value.forEach((element) => {
          storyShieldSkuList.value.push(element.sku);
        });

        cLayerService.getShieldPriceList(storyShieldSkuList.value)
          .then((prices) => {
            storyShieldList.value.forEach((shields) => {
              prices.forEach((price) => {
                if (shields.sku === price.code) {
                  priceObject.value = { ...price, ...shields };
                  finalArray.value.push(priceObject.value);
                }
              });
            });
            isLoading.value = false;
          })
          .catch((error) => {
            console.log('error');
          });
      }).catch((e) => {
        isLoading.value = false;
      });

    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    const selectedShieldSku = computed(() => store.getters.getSelectedShieldSku);

    const getGenresDetail = computed(() => store.getters.getGenresDetails);

    const shieldClick = (data) => {
      store.dispatch('fetchSelectedShieldSku', data.sku);
      store.dispatch('fetchPlaylistDetail', data.playlist);
      const items = {
        id: data.genre,
        locale: langCode,
      };
      store.dispatch('fetchGenresDetail', items);
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      newContentfulService.fetchShielsByGenres(categoryId.value, perPage.value, currentOffset.value, langCode)
        .then((response) => {
          storyShieldSkuList.value = [];
          finalArray.value = [];
          priceDetails.value = [];
          pageCount.value = Math.ceil(response.total / perPage.value);
          storyShieldList.value = response.items;
          storyShieldList.value.forEach((element) => {
            storyShieldSkuList.value.push(element.sku);
          });

          cLayerService.getShieldPriceList(storyShieldSkuList.value)
            .then((prices) => {
              storyShieldList.value.forEach((shields) => {
                prices.forEach((price) => {
                  if (shields.sku === price.code) {
                    priceObject.value = { ...price, ...shields };
                    finalArray.value.push(priceObject.value);
                  }
                });
              });
              isLoading.value = false;
            })
            .catch((error) => {
              console.log('error');
            });
        }).catch((e) => {
          isLoading.value = false;
        });
      page.value = pageNum;
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const addLineItem = (lineItem) => {
      isLoading.value = true;
      store.dispatch('updateLineItem', lineItem)
        .then((itemAdded) => {
          isAdded.value = true;
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      isLoading,
      storyShieldList,
      Loader,
      shieldSku,
      isAdded,
      selectedShieldDetails,
      selectedShieldSku,
      shieldClick,
      addLineItem,
      pageClick,
      pageCount,
      storyShieldSkuList,
      priceDetails,
      getGenresDetail,
      langCode,
      finalArray,
    };
  },
};
</script>
