<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="popup-change-password">
    <div
      class="modal fade"
      id="changePassword"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2">
          <button
            type="button"
            class="btn-close close-blue-btn popup"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closePopUp"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <h3 class="text-center mt-2">{{ $t('changePassword') }}</h3>
          <div class="modal-body">
            <div class="form-group">
              <label>{{ $t('oldPassword') }}</label>
              <input type="password" id="password" v-model="password.old" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.old.$error">
                <!-- {{ v$.old.$errors[0].$message }}* -->
                {{ $t('errorMessage', { text: $t('oldPassword') }) }}*
              </span>
              <div class="eye-pwd" @click="toggleShowPassword">
                <div v-if="showPassword">
                  <i class="icon-eye-Open" aria-hidden="true"></i>
                </div>
                <div v-if="!showPassword">
                  <i class="icon-eye-closed" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('newPassword') }}</label>
              <input
                type="password"
                id="passwordNew"
                name="passwordNew"
                v-model="password.new"
                class="form-control"
              />
              <span class="text-danger pl-3" v-if="v$.new.$error">
                <!-- {{ v$.new.$errors[0].$message }}* -->
                <span v-if="v$.new.required.$invalid">
                  {{ $t('errorMessage', { text: $t('newPassword') }) }}*
                </span>
                <span v-if="v$.new.min.$invalid"> {{ $t('minLength') }}*</span>
                <span
                  v-if="
                    v$.new.containsPasswordRequirement.$invalid &&
                    !v$.new.required.$invalid &&
                    !v$.new.min.$invalid
                  "
                >
                  {{ $t('passwordRequirement') }}*
                </span>
              </span>
              <div class="eye-pwd" @click="toggleShowPasswordNew()">
                <div v-if="showPasswordNew">
                  <i class="icon-eye-Open" aria-hidden="true"></i>
                </div>
                <div v-if="!showPasswordNew">
                  <i class="icon-eye-closed" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('confirmPassword') }} </label>
              <input
                type="password"
                id="passwordConfirm"
                v-model="password.confirm"
                class="form-control"
              />
              <span class="text-danger pl-3" v-if="v$.confirm.$error">
                <!-- {{ v$.confirm }}* -->
                <span v-if="v$.confirm.required.$invalid">
                  {{ $t('errorMessage', { text: $t('confirmPassword') }) }}*
                </span>
                <span
                  v-if="
                    v$.confirm.containsPasswordRequirement.$invalid && !v$.confirm.required.$invalid
                  "
                >
                  {{ $t('passwordRequirement') }}*
                </span>
              </span>
              <span class="text-danger pl-3" v-if="confirmError">{{ $t('PassDontMatch') }}* </span>
              <div class="eye-pwd" @click="toggleShowPasswordConfirm">
                <div v-if="showPasswordConfirm">
                  <i class="icon-eye-Open" aria-hidden="true"></i>
                </div>
                <div v-if="!showPasswordConfirm">
                  <i class="icon-eye-closed" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <span class="text-danger pl-3" v-if="error">
              {{ error }}
            </span>
            <span class="text-success pl-3" v-if="changeSuccess">
              {{ $t('changeSuccess') }}
            </span>
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-primary btn-lg submit-btn mt-2"
                @click="changePassword()"
              >
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import {
  ref, reactive,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required, minLength, helpers, sameAs,
} from '@vuelidate/validators';

import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';

const storyphoneService = new StoryphoneService();

export default {
  name: 'popup-change-password',
  props: [],
  components: {
    Loader,
  },
  setup() {
    const isLoading = ref(false);
    const error = ref('');
    const changeSuccess = ref('');
    const confirmError = ref(false);
    const showPassword = ref(false);
    const showPasswordNew = ref(false);
    const showPasswordConfirm = ref(false);
    const password = reactive({
      old: '',
      new: '',
      confirm: '',
    });

    const rules = {
      old: { required: helpers.withMessage('You must enter your old password', required) },
      new: {
        required: helpers.withMessage('You must enter a new password', required),
        min: minLength(8),
        containsPasswordRequirement: helpers.withMessage(
          () => 'The password requires an uppercase, lowercase, number',
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
        ),
      },
      confirm: {
        required: helpers.withMessage('You must confrim your new password', required),
        containsPasswordRequirement: helpers.withMessage(
          () => 'The password requires an uppercase, lowercase, number',
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
        ),
      },
    };

    const v$ = useVuelidate(rules, password);

    const closePopUp = () => {
      password.old = '';
      password.new = '';
      password.confirm = '';
      error.value = '';
      changeSuccess.value = '';
      v$.value.$reset();
    };

    const toggleShowPassword = () => {
      const password1 = document.querySelector('#password');
      if (showPassword.value) {
        showPassword.value = false;
        password1.setAttribute('type', 'password');
      } else {
        showPassword.value = true;
        password1.setAttribute('type', 'text');
      }
    };
    const toggleShowPasswordNew = () => {
      const password1 = document.querySelector('#passwordNew');
      if (showPasswordNew.value) {
        showPasswordNew.value = false;
        password1.setAttribute('type', 'password');
      } else {
        showPasswordNew.value = true;
        password1.setAttribute('type', 'text');
      }
    };
    const toggleShowPasswordConfirm = () => {
      const password1 = document.querySelector('#passwordConfirm');
      if (showPasswordConfirm.value) {
        showPasswordConfirm.value = false;
        password1.setAttribute('type', 'password');
      } else {
        showPasswordConfirm.value = true;
        password1.setAttribute('type', 'text');
      }
    };

    const changePassword = async () => {
      error.value = '';
      changeSuccess.value = '';
      confirmError.value = false;
      const result = await v$.value.$validate();
      if (result) {
        if (password.new === password.confirm) {
          isLoading.value = true;
          const credentials = {
            oldPassword: password.old,
            newPassword: password.new,
          };
          const clCredentials = {
            password: password.new,
          };
          storyphoneService.updatePassword(credentials)
            .then((passwordResult) => {
              // storyphoneService.updateClayerPassword(clCredentials)
              //   .then((changeClpassword) => {
              isLoading.value = false;
              changeSuccess.value = 'Password has been successfully changed';
              setTimeout(() => {
                changeSuccess.value = '';
              }, 4000);
              password.old = '';
              password.new = '';
              password.confirm = '';
              v$.value.$reset();
            })
          // .catch((err) => {
          //   isLoading.value = false;
          //   error.value = err[0].detail;
          // });
            // })
            .catch((response) => {
              isLoading.value = false;
              // eslint-disable-next-line prefer-destructuring
              error.value = response.response.data.errors[0];
            });
        } else {
          confirmError.value = true;
        }
      } else {
        console.log(v$);
      }
    };

    return {
      isLoading,
      error,
      changeSuccess,
      confirmError,
      showPassword,
      showPasswordNew,
      showPasswordConfirm,
      password,
      v$,
      closePopUp,
      toggleShowPassword,
      toggleShowPasswordNew,
      toggleShowPasswordConfirm,
      changePassword,
    };
  },
};
</script>

<style scoped lang="scss"></style>
