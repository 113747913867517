import * as types from './mutation-types';

const mutations = {
  [types.CONTENT_MANAGEMENT_SYSTEM](state, CMS) {
    state.CMS = CMS;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM1](state, CMS1) {
    state.CMS1 = CMS1;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM2](state, CMS2) {
    state.CMS2 = CMS2;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM3](state, CMS3) {
    state.CMS3 = CMS3;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM4](state, CMS4) {
    state.CMS4 = CMS4;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM5](state, CMS5) {
    state.CMS5 = CMS5;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM6](state, CMS6) {
    state.CMS6 = CMS6;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM7](state, CMS7) {
    state.CMS7 = CMS7;
  },

  [types.CONTENT_MANAGEMENT_SYSTEM8](state, CMS8) {
    state.CMS8 = CMS8;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM9](state, CMS9) {
    state.CMS9 = CMS9;
  },
  [types.CONTENT_MANAGEMENT_SYSTEM10](state, CMS10) {
    state.CMS10 = CMS10;
  },

  [types.LANG_CODE](state, LangCode) {
    state.LangCode = LangCode;
  },
  [types.CONTACT_US](state, contactUs) {
    state.contactUs = contactUs;
  },

  [types.USER_MANUAL_IOS](state, userManualIos) {
    state.userManualIos = userManualIos;
  },
  [types.USER_MANUAL_ANDROID](state, userManualAndroid) {
    state.userManualAndroid = userManualAndroid;
  },
  [types.APP](state, app) {
    state.app = app;
  },

};

export default mutations;
