<template lang="html">
  <section class="py-8 d-none d-sm-none d-md-block d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            id="carouselExampleIndicators"
            class="carousel slide app-workflow"
            data-bs-ride="carousel"
          >
            <ul
              class="app-carousel list-unstyled carousel-indicators"
              v-for="(item, index) in app.widgets"
              :key="index"
            >
              <span v-for="(details, index) in item.details" :key="index">
                <li
                  data-bs-target="#carouselExampleIndicators"
                  :data-bs-slide-to="index"
                  aria-current="true"
                  :aria-label="`Slide ${index + 1}`"
                  :class="{ active: index === 0 }"
                >
                  <span class="dot" :class="`dot-${index + 1}`"></span>
                  <div class="app-content">
                    <div class="app-icon">
                      <img :src="details.image" alt="" />
                    </div>
                    <div class="app-sentence">
                      <div class="mb-2" v-html="domSanitize(details.title)"></div>
                      <div v-html="domSanitize(details.description)"></div>

                      <!-- <h5 class="mb-2">{{ details.title }}</h5>
                      <p>
                        {{ details.description }}
                      </p> -->
                    </div>
                  </div>
                </li>
              </span>
            </ul>
            <div class="carousel-inner">
              <div
                class="carousel-item"
                v-for="(image, index) in app.images"
                :key="index"
                :class="{ active: index === 0 }"
              >
                <img class="d-block m-auto" :src="image.image_url" alt="First slide" width="300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-8 d-block d-sm-block d-md-none d-lg-none">
    <div class="container">
      <div class="row">
        <div v-for="(item, index) in app.widgets" :key="index" class="col-md-12">
          <Carousel class="product-slider">
            <Slide v-for="(details, index) in item.details" :key="index">
              <div class="carousel__item app-workflow-ph">
                <div class="mb-2" v-html="domSanitize(details.title)"></div>
                <div v-html="domSanitize(details.description)"></div>

                <img
                  class=""
                  :src="app?.images[index].image_url"
                  alt="storytelling image"
                  width="400"
                />
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import {
  Carousel, Pagination, Slide,
} from 'vue3-carousel';
import DOMPurify from 'dompurify';

export default {
  name: 'AppWorkflow',
  props: [],
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `app_${langCode}`,
    };
    store.dispatch('fetchApp', Data);

    const app = computed(() => store.getters.getApp);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      app, langCode, domSanitize,
    };
  },
  mounted() {
  },
  data() {
    return {
      slides: [
        {
          heading: 'Stories All Around the World',
          para: 'Bring screen-free audio stories, music and magic with you wherever you go.',
          image: 'app-one.png',
        },
        {
          heading: 'Record your own custom PlayShield',
          para: 'With StoryPhones it has never been easier to share stories from one generation to the next. This means that even though grandma lives in another country, a child can hear a bedtime story read in her voice.',
          image: 'app-two.png',
        },
        {
          heading: 'Create your own collection',
          para: 'Personalize your album to enjoy and listen whenever you want',
          image: 'app-three.png',
        },
        {
          heading: 'Parental-controlled device',
          para: 'This allows parents to relax and know they don‘t have to constantly monitor what their children are listening to. Settings can easily be configured in the companion parent mobile app.',
          image: 'app-four.png',
        },
      ],
    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
