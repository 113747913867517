import * as types from './mutation-types';

export const inFirstStep = ({ commit }, value) => {
  commit(types.FIRST_STEP, value);
};

export const inSecondStep = ({ commit }, value) => {
  commit(types.SECOND_STEP, value);
};

export const inThirdStep = ({ commit }, value) => {
  commit(types.THIRD_STEP, value);
};

export const inFourthStep = ({ commit }, value) => {
  commit(types.FOURTH_STEP, value);
};

export const inFifthStep = ({ commit }, value) => {
  commit(types.FIFTH_STEP, value);
};

export const inSixthStep = ({ commit }, value) => {
  commit(types.SIXTH_STEP, value);
};
