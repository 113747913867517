<template>
  <div>
    <div v-if="user" class="wishlist-success-block top-heart">
      <button
        type="button"
        class="root border-0 p-0 animate__animated animate__jackInTheBox"
        :class="{ active: isOnWishlist }"
        @click="
          isOnWishlist ? removeFromWishlist(shieldData) : addToWishlist(shieldData)
        "
      >
        <WishListSvgButton />
      </button>
    </div>
    <div v-if="shieldSku" class="d-flex justify-content-center align-items-center mt-2">
      <div v-if="shieldData.availability_status === 'coming_soon'" class="round-add-button">
        <button class="btn btn-primary cart-btn p-0 me-3" >
          <span><img src="@/assets/images/coming-soon.png"  alt=""></span>
        </button>
      </div>
        <div v-else-if="shieldSku?.stock_items[0]?.quantity > 0" class="round-add-button">
          <!-- <button
            class="button p-0 me-3"
            :class="{ loading: isAdded }"
            @click="addtoCart({ itemDetails: shieldData })"
          >
            <span><i class="icon-cart" aria-hidden="true"></i></span>
            <div class="cart">
              <svg viewBox="0 0 36 26">
                <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
              </svg>
            </div>
          </button> -->
        </div>
        <div v-else>
          <button class="btn btn-primary cart-btn p-0 me-3 disabled" disabled>
            <span><i class="icon-cart-close" aria-hidden="true"></i></span>
          </button>
        </div>

        <p class="text-blue mt-2" v-if="shieldSku">
          {{ price(shieldSku?.prices[0]) }}
        </p>

      <!-- <p class="text-blue mt-2" v-if="productPriceDetails[0]?.prices[0]">
        {{ price(productPriceDetails[0]?.prices[0]) }} -->
        <!-- {{ productPriceDetails[0]?.prices[0]?.formatted_amount.slice(0, 1) }}
        {{ productPriceDetails[0]?.prices[0]?.amount_float }} -->
      <!-- </p> -->
    </div>
  </div>
</template>

<script lang="js">

import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import CLayerBaseService from '@/services/CLayerBaseService';
import StoryphoneService from '@/services/StoryphoneService';
import WishListSvgButton from '@/components/partials/WishListSvgButton.vue';

const cLayerBaseService = new CLayerBaseService();
const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'ShieldCarouselItem',
  props: ['shieldData', 'shieldSku'],
  components: {
    WishListSvgButton,
  },
  setup(props) {
    const store = useStore();
    const isAdded = ref(false);
    const user = ref({});
    const productPriceDetails = ref({});
    const isActive = ref(false);
    const isOnWishlist = ref(false);
    // const allWishlist = computed(() => store.getters.getWishlist);
    const allWishlist = computed(() => store.getters.getUserWishlist?.data?.items);

    const quantity = ref(0);

    user.value = cookies.get('storyPhoneUserAuthCookie');
    if (cookies.isKey('storyPhoneUserAuthCookie')) {
      store.dispatch('fetchUserWishList', { limit: 0, offset: 0 })
        .then(() => {
          if (user.value) {
            if (allWishlist.value.length > 0) {
              allWishlist.value.forEach((element) => {
                if (element.sku === props.shieldData.sku) {
                  isOnWishlist.value = true;
                }
              });
            }
          }
        });
    }

    const addtoCart = (itemSku) => {
      isAdded.value = true;
      store.dispatch('updateLineItem', itemSku)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {

        });
    };

    const addToWishlist = (data) => {
      isOnWishlist.value = true;
      const sku = {
        sku: data.sku,
        title: data.name,
      };
      store.dispatch('addToWishlist', sku);
    };

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    const removeFromWishlist = (data) => {
      isOnWishlist.value = false;
      storyphoneService.deleteWishlist(data.sku)
        .then(() => {
          store.dispatch('fetchUserWishList', { limit: 0, offset: 0 });
        });
    };

    return {
      isAdded,
      user,
      isActive,
      productPriceDetails,
      allWishlist,
      addtoCart,
      addToWishlist,
      removeFromWishlist,
      isOnWishlist,
      quantity,
      price,
    };
  },
};
</script>
