<template lang="html">
  <PageHeader />
  <div>
    <section class="account-settings order-history mt-stickybar">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <!--  -->
            <section class="account-sidebar">
              <div class="accounts-left-block d-none d-md-block">
                <h2>Welcome Back!</h2>
                <!---->
                <section class="account-sidebar-menu">
                  <div class="accounts-sidebar">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <a href="#">My profile</a>
                      </li>
                      <li class="nav-item">
                        <a href="/orderhistory-payment">Order History</a>
                      </li>
                      <li class="nav-item">
                        <a href="/manageshop">Manage Shop</a>
                      </li>
                      <li class="nav-item">
                        <a href="/mystory-content">My story</a>
                      </li>
                    </ul>
                  </div>
                  <div class="text-center py-4">
                    <div class="wave-btn-block">
                      <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                        Log out
                        <span class="blob-btn__inner"
                          ><span class="blob-btn__blobs"
                            ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span></span
                        ></span>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              <div class="accounts-left-block mobile-sidebar d-md-none">
                <a
                  class="btn-profile-settings collapsed"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Profile settings
                </a>
                <div class="collapse" id="collapseExample" style="">
                  <!---->
                  <section class="account-sidebar-menu">
                    <div class="accounts-sidebar">
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <a href="#">My profile</a>
                        </li>
                        <li class="nav-item">
                          <a href="/orderhistory-payment">Order History</a>
                        </li>
                        <li class="nav-item">
                          <a href="/manageshop">Manage Shop</a>
                        </li>
                        <li class="nav-item">
                          <a href="/mystory-content">My story</a>
                        </li>
                      </ul>
                    </div>
                    <div class="text-center py-4">
                      <div class="wave-btn-block">
                        <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                          Log out
                          <span class="blob-btn__inner"
                            ><span class="blob-btn__blobs"
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <!--  -->
          </div>
          <div class="col-sm-8">
            <div class="accounts-content">
              <div class="border-block">
                <h3>My story</h3>
                <div class="container mb-5 pb-5 px-4">
                  <ul class="gd-container list-unstyled" id="mq-story">
                    <li class="gd-item border-0 rounded-4 bg-secondary">
                      <div class="round-add-button">
                        <button class="button bg-info float-end">
                          <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
                        </button>
                      </div>
                      <img
                        src="@/assets/images/marketplace/amari.png"
                        class="rounded-circle mx-auto d-block"
                        id="crd-img"
                        alt="amari"
                      />
                      <div class="card-body">
                        <ul class="d-flex justify-content-center mb-2 mt-2 list-unstyled">
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/white-Star.svg"
                              alt="white star"
                            />
                          </li>
                        </ul>
                        <h6 class="text-dark text-center">Amari and the Night Brothers</h6>
                        <h6 class="text-dark text-center">
                          By: <span class="colour">B.B. Alston</span>
                        </h6>
                      </div>
                    </li>

                    <li class="gd-item border-0 rounded-4 bg-secondary">
                      <div class="round-add-button">
                        <button class="button bg-info float-end">
                          <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
                        </button>
                      </div>
                      <img
                        src="@/assets/images/marketplace/lastfallenmoon.png"
                        class="rounded-circle mx-auto d-block"
                        id="crd-img"
                        alt="fallenmoon"
                      />
                      <div class="card-body">
                        <ul class="d-flex justify-content-center mb-2 mt-2 list-unstyled">
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                        </ul>
                        <h6 class="text-dark text-center">The Last Fallen Moon</h6>
                        <h6 class="text-dark text-center">
                          By: <span class="colour">B. B. Alston</span>
                        </h6>
                      </div>
                    </li>
                    <li class="gd-item border-0 rounded-4 bg-secondary">
                      <div class="round-add-button">
                        <button class="button bg-info float-end">
                          <img src="@/assets/images/marketplace/btn-arrow.svg" alt="blue arrow" />
                        </button>
                      </div>
                      <img
                        src="@/assets/images/marketplace/lionofmars.png"
                        class="rounded-circle mx-auto d-block"
                        id="crd-img"
                        alt="lionofmars"
                      />
                      <div class="card-body">
                        <ul class="d-flex justify-content-center mb-2 mt-2 list-unstyled">
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                          <li>
                            <img
                              src="@/assets/images/marketplace/yellow-Star.svg"
                              alt="yellow star"
                            />
                          </li>
                        </ul>
                        <h6 class="text-dark text-center">The Lion of Mars</h6>
                        <h6 class="text-dark text-center">
                          By: <span class="colour">Jennifer L. Holm</span>
                        </h6>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="wave-btn-block text-center pb-4">
                  <button type="submit" class="blob-btn fill-bg btn-lg mt-5">
                    Record my story
                    <span class="blob-btn__inner"
                      ><span class="blob-btn__blobs"
                        ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                        ><span class="blob-btn__blob"></span
                        ><span class="blob-btn__blob"></span></span
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <PageFooter />
</template>

<script lang="js">
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'MystoryContent',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>
