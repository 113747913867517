/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const isFirstStep = (state) => isObj(state, 'firstStep', true);

export const isSecondStep = (state) => isObj(state, 'secondStep', false);

export const isThirdStep = (state) => isObj(state, 'thirdStep', false);

export const isFourthStep = (state) => isObj(state, 'fourthStep', false);

export const isFifthStep = (state) => isObj(state, 'fifthStep', false);

export const isSixthStep = (state) => isObj(state, 'sixthStep', false);
