<template lang="html">
  <section class="register mt-stickybar home pt-5">
    <div
      class="library-block d-none"
      :class="{
        showLibrary: showLibrary,
      }"
    >
      <div class="inner-page-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="grey-outer-block py-4 mb-5">
                <div class="library-heading">
                  <div class="row">
                    <div class="col-md-4"><h2 class="m-0 p-0">Library</h2></div>
                  </div>
                </div>
                <div class="library-tab-content tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="library-recordings" role="tabpanel">
                    <div>
                      <section class="library-recording-list">
                        <div class="d-none showrecordingBlock">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="product-search library-search">
                                <input
                                  class="search-filed form-control"
                                  type="search"
                                  placeholder="Search Your Recordings"
                                  aria-label="Search"
                                /><button class="btn product-search-btn border-0" type="submit">
                                  <i class="icon-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-if="creativeStories" class="library-record-listing mt-5">
                            <ul
                              v-if="creativeStories?.results?.length > 0"
                              class="row list-unstyled"
                            >
                              <li
                                v-for="(story, index) in creativeStories.results"
                                :key="index"
                                class="col-md-6 recording-list"
                              >
                                <button
                                  type="button"
                                  class="ibrary-listing-btn"
                                  @click="selectedStory(story, index)"
                                  :class="{ active: recordings.includes(story.id) }"
                                >
                                  <span class="border-0 round-btn blue play me-2">
                                    <i
                                      class="icon-play3 ms-1 animate__animated animate__jackInTheBox"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {{ story.title }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="5"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg me-3" @click="gotToRecordUpload()">
            {{ $t('back') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg me-3" @click="goToList()">
            {{ $t('next') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { ref, computed, reactive } from 'vue';
import { mapActions, useStore } from 'vuex';
import Multiselect from 'vue-multiselect';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

export default {
  name: 'LibrarySelection',
  props: [],
  components: {
    VuejsPaginateNext,
    // Multiselect,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const search = ref('');
    const perPage = ref(10);
    const pageCount = ref(0);
    const currentOffset = ref(0);
    const currentIndex = ref('');
    const recordings = ref([]);
    const value = {
      offset: currentOffset.value,
      term: search.value,
    };
    isLoading.value = true;

    const selectedStory = (story, index) => {
      if (recordings.value.includes(story.id)) {
        const position = recordings.value.indexOf(story.id);
        if (index > -1) {
          recordings.value.splice(index, 1);
        }
      } else {
        recordings.value.push(story.id);
      }
      const audioFile = {
        file: story.file,
        name: story.title,
        id: story.id,
        duration: story.duration_in_seconds,
      };
      store.dispatch('fetchRecordingList', audioFile);
      currentIndex.value = index;
    };

    const recordingList = computed(() => store.getters.getRecordingList);

    const showLibrary = computed(() => store.getters.isShowLibrary);

    const creativeStories = computed(() => store.getters.getCreativeStories);

    // store.dispatch('fetchCreativeStories', value).then((stories) => {
    //   pageCount.value = Math.ceil(stories.data.count / perPage.value);
    //   isLoading.value = false;
    // });

    const goToList = () => {
      store.dispatch('isShowFiles', true);
      store.dispatch('isShowLibrary', false);
    };

    const gotToRecordUpload = () => {
      store.dispatch('isShowUploadRecord', true);
      store.dispatch('isShowLibrary', false);
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = (pageNum - 1) * 10;
      const data = {
        offset: currentOffset.value,
        term: search.value,
      };
      // store.dispatch('fetchCreativeStories', data).then(() => {
      //   isLoading.value = false;
      // });
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return {
      showLibrary, creativeStories, pageClick, pageCount, isLoading, goToList, selectedStory, gotToRecordUpload, currentIndex, recordingList, recordings,
    };
  },
};
</script>

<style scoped lang="scss"></style>
