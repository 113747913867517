<template lang="html">
  <section class="">
    <div class="container">
      <h1 class="mb-2 mt-5 ms-5">Sleeping medication stories</h1>
      <div class="">
        <MarketPlaceStoriesCarousal />
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import MarketPlaceStoriesCarousal from '@/components/partials/MarketPlaceStoriesCarousal.vue';

export default {
  name: 'MarketPlaceMedicationStories',
  props: [],
  components: {
    MarketPlaceStoriesCarousal,
  },
  setup() {
    return {
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
