<template lang="html">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable location-modal">
    <div class="modal-content">
      <div class="container mt-2 text-end">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-header border-0">
        <div>
          <h5 class="modal-title mb-3" id="languagePopModalLabel">Choose location</h5>
          <p>Be sure to select the location that matches your shipping address.</p>
        </div>
      </div>
      <div class="modal-body">
        <div
          v-for="(item, index) in europe"
          :key="index"
          :value="item"
          class="card border-0 me-3"
          :class="{ active: item.name === LocationName }"
        >
          <div class="card-body d-flex" data-bs-dismiss="modal" @click="changeLanguage(item)">
            <div style="width: 35px">
              <img :src="`/flag/${item.image}.png`" :alt="item.image" />
            </div>
            <div class="mt-1 mx-4">
              {{ item.name }}
            </div>
            <!-- <div class="container text-end">
              <img src="@/assets/images/Ellipse79.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';

import CLayerBaseService from '@/services/CLayerBaseService';

const { cookies } = useCookies({ secure: true });
const cLayerBaseService = new CLayerBaseService();

export default {
  name: 'loctaionModal',
  props: [],
  components: {

  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const router = useRouter();
    const market = localStorage.getItem('marketNumber');
    const LocationImg = localStorage.getItem('LocationImg');
    const LocationName = localStorage.getItem('LocationName');

    // if (cookies.isKey('orderIdCookie')) {
    //   const { orderId } = cookies.get('orderIdCookie');
    //   store.dispatch('fetchCartItems', orderId);
    // }
    // if ((cookies.isKey('storyPhoneUserAuthCookie'))) {
    //   // store.dispatch('fetchWishList');
    // }

    const changeLanguage = (data) => {
      isLoading.value = true;
      localStorage.removeItem('marketNumber');
      localStorage.removeItem('marketName');
      localStorage.removeItem('update');
      localStorage.setItem('marketNumber', data.currencyCode);
      localStorage.setItem('marketId', data.id);
      localStorage.setItem('marketName', data.currency);

      localStorage.removeItem('storyPhoneUser');
      localStorage.removeItem('cLayerUserToken');

      cookies.remove('clUserAuthCookie');

      cookies.remove('orderIdCookie');

      cookies.remove('clUserOwnerId');
      cookies.remove('clAuthCookie');
      cookies.remove('clUserAuthRefreshCookie');
      cookies.remove('storyPhoneUserRefreshCookie');
      cookies.remove('storyPhoneUserAuthCookie');

      cLayerBaseService.createToken()
        .then(() => {
          const pagePath = router.currentRoute.value.path;
          // router.push('/');

          if (pagePath === '/') {
            window.location.reload();
          }
          console.log('here');
          router.push('/');

          isLoading.value = false;
        })
        .catch((err) => {
          isLoading.value = false;
        });
      localStorage.setItem('LangCode', data.langCode);
      localStorage.setItem('LocationImg', data.image);
      localStorage.setItem('LocationName', data.name);

      window.location.reload();
    };
    const europe = computed(() => store.getters.getEurope);

    // localStorage.setItem('LangCode', 'en');
    // localStorage.setItem('LocationImg', 'global');
    // localStorage.setItem('LocationName', 'International');

    // const shippingMessage = computed(() => store.getters.shippingMessage);

    // const outOfStockMessage = computed(() => store.getters.getOutOfStockMessage);

    return {
      europe, changeLanguage, LocationImg, LocationName, isLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.activeImage {
  background: url('../../assets/images/activeIcon.png') right no-repeat;
  /* Other styles for your active class */
}
</style>
