<template lang="html">
  <section class="login-animation">
    <div class="signup-banner text-center">
      <!-- <div class="top-animarion-block">
        <div class="otter"></div>
        <div class="pajaro"></div>
      </div> -->
      <img src="@/assets/images/fly-birds.png" alt="" />
    </div>

    <div class="twingle-star">
      <div id="stars-group-1"></div>
      <div id="stars-group-2"></div>
      <div id="stars-group-3"></div>
      <div id="stars-group-4"></div>
      <div id="stars-group-5"></div>
      <div id="stars-group-6"></div>
    </div>

    <div id="clouds">
      <div class="cloud x1 text-end"></div>
      <div class="cloud x2"></div>
      <div class="cloud x3"></div>
      <div class="cloud x5"></div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'login-animation',
  props: [],
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
