<template>
  <div class="modal-body px-4">
    <div class="row">
      <div class="col-md-6 text-center">
        Hello
        <!-- <img
          v-if="this.shieldData.length > 0"
          :src="this.shieldData[0]?.fields?.image?.fields?.file?.url"
          alt=""
        /> -->
      </div>
      <div class="col-md-6 text-left">
        <!-- <h1>{{ this.shieldData[0]?.fields?.name }}</h1>
        <h1 v-if="this.shieldData[0]?.fields?.title">{{ this.shieldData[0]?.fields?.title }}</h1> -->

        <!-- <div v-if="this.shieldData[0]?.fields?.genres">
          <p class="mt-3">Stories</p>
          <ol v-if="this.shieldData[0]?.fields?.information" class="ps-3">
            <li
              class="w-100 p-0 m-0"
              v-for="(storyList, index) in this.shieldData[0]?.fields?.information"
              :key="index"
            >
              {{ storyList }}
            </li>
          </ol>
        </div> -->
      </div>
    </div>

    <hr />
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { useCookies } from 'vue3-cookies';

import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();

export default {
  name: 'DetailsOfCategory',
  props: ['shieldData'],

  components: {
  },

  setup(props) {
    const store = useStore();
    const isAdded = ref(false);
    const priceDetails = ref({});
    const quantity = ref(0);

    const toTime = (seconds) => {
      const date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substr(11, 8);
    };

    return {
      isAdded, priceDetails, toTime, quantity,
    };
  },
};
</script>

<style scoped lang="scss"></style>
