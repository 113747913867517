<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="search-result mt-stickybar home">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>{{ $t('Search') }}</h2>
            <div class="row mb-5">
              <div class="col-md-4">
                <div class="product-search mb-3">
                  <input
                    class="search-filed form-control"
                    v-model="searchText"
                    type="search"
                    :placeholder="$t('Search')"
                    aria-label="Search"
                    v-on:keyup.enter="onSubmit()"
                  />
                  <button class="btn product-search-btn border-0" type="submit" @click="onSubmit()">
                    <i class="icon-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="product-filter mb-3">
                  <div class="filter-by">
                    <span class="filter-icon">
                      <i class="icon-filter" aria-hidden="true"></i>
                    </span>
                    <multiselect
                      v-model="selected"
                      :options="options"
                      :multiple="true"
                      group-values="libs"
                      group-label="language"
                      :group-select="false"
                      placeholder="Filter"
                      track-by="name"
                      label="name"
                      selectLabel=""
                      selectedLabel=""
                      deselectLabel=""
                      @select="selectedOption"
                      @remove="removedOption"
                    ></multiselect>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ finalArray }} -->
            <div v-if="finalArray?.length > 0">
              <h3>{{ searchResults.total }} Results Found</h3>
              <div class="category-result">
                <ul class="story-categories list-unstyled">
                  <li v-for="(shield, index) in finalArray" :key="index" class="text-center">
                    <a
                      class="p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#product-details-popup"
                      @click="shieldClick(shield)"
                    >
                      <ShieldListingItem
                        :shieldData="shield"
                        :category="shield.product_category"
                        :shieldSku="priceDetails[index]"
                      />
                    </a>
                    <ShieldListingButton :shieldData="shield" :shieldSku="priceDetails[index]" />
                  </li>
                </ul>
              </div>
            </div>

            <div class="no-search-result" v-else>
              <h3>0 {{ $t('results') }}</h3>
              <p>{{ $t('notFindQuery') }}</p>
            </div>
          </div>

          <div
            class="modal fade"
            id="product-details-popup"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <button
                  type="button"
                  class="btn-close close-blue-btn ms-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="icon-close" aria-hidden="true"></i>
                </button>

                <DetailsOfCategory
                  v-if="selectedShieldDetails && this.selectedShieldSku"
                  :shield-data="selectedShieldDetails"
                  :shield-sku="selectedShieldSku"
                />
                <div v-else><Loader /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-4">
      <VuejsPaginateNext
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageClick"
        :margin-pages="1"
        :page-range="pageCount"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :prev-class="'prev-item'"
        :prev-link-class="'page-link prev-link-item'"
        :next-class="'next-item'"
        :next-link-class="'page-link next-link-item'"
        :break-view-class="'break-view'"
        :break-view-link-class="'break-view-link'"
      ></VuejsPaginateNext>
    </div>
  </section>
  <PageFooter />
  <!-- Toggle modals Second -->
  <div
    class="modal fade"
    id="shield-play-popup"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" @focusout="stopAudio()">
        <button
          type="button"
          class="btn-close close popup"
          data-bs-target="#product-details-popup"
          data-bs-toggle="modal"
          @click="stopAudio()"
        >
          <i class="icon-close" aria-hidden="true"></i>
        </button>
        <div v-if="selectedShieldDetails" class="modal-body text-center py-5">
          <h5>{{ selectedShieldDetails?.title }}</h5>
          <div class="audio audioStyle">
            <audio
              id="audio"
              controls="controls"
              class="audioStyle"
              ref="audio"
              :src="selectedShieldDetails?.sample_audio"
            ></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  watch, ref, computed, reactive, onUpdated,
} from 'vue';
import Multiselect from 'vue-multiselect';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import ShieldListingItem from '@/components/partials/ShieldListingItem.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';
import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

const cLayerService = new CLayerService();

export default {
  name: 'search-result',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    ShieldListingButton,
    ShieldListingItem,
    Loader,
    DetailsOfCategory,
    Multiselect,
    VuejsPaginateNext,
  },

  setup() {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const searchText = ref('');
    // const brands = ref([]);
    const categories = ref([]);
    const storyShieldSkuList = ref([]);
    const priceDetails = ref([]);
    const page = ref(1);
    const perPage = ref(10);
    const currentOffset = ref(0);
    const selectedShieldSku = ref('');
    const pageCount = ref(0);
    const priceObject = ref({});
    const finalArray = ref([]);
    let category = 'storyshield,zenshield,storyphones';
    // let brand = 'onanoff,disney,disney-pixar';

    const searchTerm = computed(() => store.getters.getsearchTerm);

    searchText.value = searchTerm.value;

    const searchResults = computed(() => store.getters.searchedItems);

    const getPrice = () => {
      isLoading.value = true;
      storyShieldSkuList.value = [];
      finalArray.value = [];
      priceObject.value = {};
      priceDetails.value = [];
      searchResults.value.items.forEach((element) => {
        storyShieldSkuList.value.push(element.sku);
      });

      cLayerService.getShieldPriceList(storyShieldSkuList.value)
        .then((prices) => {
          searchResults.value.items.forEach((shields) => {
            prices.forEach((price) => {
              if (shields.sku === price.code) {
                priceObject.value = { ...price, ...shields };
                finalArray.value.push(priceObject.value);
              }
            });
          });
          isLoading.value = false;
        })
        .catch((error) => {
          console.log('error');
        });
    };

    store.dispatch('fetchSearchedItems', {
      category, searchText, currentOffset, langCode,
    })
      .then(() => {
        pageCount.value = Math.ceil(searchResults.value.total / perPage.value);
        getPrice();
      });

    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    // const selectedShieldSku = computed(() => store.getters.getSelectedShieldSku);

    const onSubmit = () => {
      isLoading.value = true;
      const term = searchText.value;
      // window.location.reload();
      store.dispatch('fetchSearchedItems', {
        category, searchText, currentOffset, langCode,
      })
        .then(() => {
          pageCount.value = Math.ceil(searchResults.value.total / perPage.value);
          getPrice();
        });
    };

    const removedOption = (a) => {
      isLoading.value = true;
      if ('category' in a) {
        categories.value.forEach((element, index) => {
          if (element === a.value) {
            categories.value.splice(index, 1);
          }
        });
      }
      //  else {
      //   brands.value.forEach((element, index) => {
      //     if (element === a.value) {
      //       brands.value.splice(index, 1);
      //     }
      //   });
      // }
      category = categories.value.toString();
      // brand = brands.value.toString();
      const text = searchText.value;
      store.dispatch('fetchSearchedItems', {
        category, searchText, currentOffset, langCode,
      })
        .then(() => {
          pageCount.value = Math.ceil(searchResults.value.total / perPage.value);
          getPrice();
        });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      store.dispatch('fetchSearchedItems', {
        category, searchText, currentOffset, langCode,
      })
        .then((response) => {
          pageCount.value = Math.ceil(searchResults.value.total / perPage.value);
          getPrice();
        }).catch((e) => {
          isLoading.value = false;
        });
      page.value = pageNum;
      // if (pageNum > page.value) {
      //   currentOffset.value += 10;
      //   store.dispatch('fetchSearchedItems', {
      //     category, brand, searchText, currentOffset,
      //   })
      //     .then((response) => {
      //       getPrice();
      //       isLoading.value = false;
      //     }).catch((e) => {
      //       isLoading.value = false;
      //     });
      //   page.value = pageNum;
      // } else {
      //   currentOffset.value -= 10;
      //   store.dispatch('fetchSearchedItems', {
      //     category, brand, searchText, currentOffset,
      //   })
      //     .then((response) => {
      //       getPrice();
      //       isLoading.value = false;
      //     }).catch((e) => {
      //       isLoading.value = false;
      //     });
      //   page.value = pageNum;
      // }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const productDetails = computed(() => store.getters.cartItemDetails);

    const selectedOption = (a) => {
      isLoading.value = true;
      if ('category' in a) {
        categories.value.push(a.value);
      }
      // else {
      //   brands.value.push(a.value);
      // }
      category = categories.value.toString();
      // brand = brands.value.toString();
      const text = searchText.value;
      store.dispatch('fetchSearchedItems', {
        category, searchText, currentOffset, langCode,
      })
        .then(() => {
          pageCount.value = Math.ceil(searchResults.value.total / perPage.value);
          isLoading.value = false;
          getPrice();
        });
    };

    const shieldClick = (data) => {
      const details = {
        sku: data.sku,
        locale: langCode,
        category: data.product_category,
      };
      selectedShieldSku.value = data.sku;
      store.dispatch('fetchItemDetails', details);
      store.dispatch('fetchPlaylistDetail', data.playlist);
      const item = {
        id: data.genre,
        locale: langCode,
      };
      store.dispatch('fetchGenresDetail', item);
    };

    return {
      searchTerm,
      searchResults,
      shieldClick,
      selectedOption,
      priceDetails,
      removedOption,
      selectedShieldSku,
      selectedShieldDetails,
      searchText,
      onSubmit,
      isLoading,
      pageClick,
      productDetails,
      pageCount,
      finalArray,
    };
  },

  data() {
    return {
      isOnWishlist: false,
      added: false,
      selected: [],
      options: [
        {
          language: 'Categories',
          libs: [
            { name: 'Headphones', value: 'storyphones', category: 'Categories' },
            { name: 'StoryShields', value: 'storyshield', category: 'Categories' },
            { name: 'ZenShields', value: 'zenshield', category: 'Categories' },
          ],
        },
        // {
        //   language: 'Brands',
        //   libs: [
        //     { name: 'Disney', value: 'disney', brands: 'Brands' },
        //     { name: 'Disney Pixar', value: 'disney-pixar', brands: 'Brands' },
        //     { name: 'OnanOff', value: 'onanoff', brands: 'Brands' },
        //   ],
        // },
      ],

    };
  },

};
</script>

<style scoped lang="scss"></style>
