<template lang="html">
  <div v-if="cartItems" class="order-item" aria-label="order summery">
    <div class="text-center">
      <h6>{{ $t('orderSum') }}</h6>
      <hr />
    </div>

    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Subtotal') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code,cartItems.subtotal_amount_float) }}
          <!-- {{ cartItems.formatted_subtotal_amount.slice(0, 1) }}
          {{ cartItems.subtotal_amount_float }} -->
        </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <h6 class="">{{ $t('Shipping') }} :</h6>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code,cartItems.shipping_amount_float) }}
          <!-- {{ cartItems.formatted_shipping_amount.slice(0, 1) }}
          {{ cartItems.shipping_amount_float }} -->
        </p>
      </div>
    </div>
    <hr class="mt-4" />
    <div class="row order-total-price">
      <div class="col">
        <h5>{{ $t('totalCost') }} :</h5>
      </div>
      <div class="col">
        <p>
          {{ price(cartItems.currency_code,cartItems.total_amount_with_taxes_float) }}
          <!-- {{ cartItems.formatted_total_amount_with_taxes.slice(0, 1) }}
          {{ cartItems.total_amount_with_taxes_float }} -->
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import { computed } from 'vue';

const { cookies } = useCookies({ secure: true });

export default {
  name: 'OrderSummery',
  props: ['cartData'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const { orderId } = cookies.get('orderIdCookie');
    store.dispatch('fetchCartItems', orderId);
    const checkout = () => {
      router.push('/checkout');
    };

    const price = (currencyCode, value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      });
      return formatter.format(value);
    };

    const cartItems = computed(() => store.getters.getCartItems);

    return { cartItems, checkout, price };
  },
};
</script>

<style scoped lang="scss"></style>
