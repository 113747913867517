<template lang="html">
  <div>
    <section class="d-block d-sm-block d-md-block d-lg-block" id="autherbanner-container">
      <h1 class="text-center fw-light banner-heading">Storyteller customised cover image here</h1>
    </section>
    <section class="d-block d-sm-block d-md-block d-lg-block" id="autherbio-container">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 col-md-4 col-12">
            <div class="profile-txt">
              <img
                src="@/assets/images/marketplace/amy.png"
                class="rounded-circle mx-auto d-block"
                alt="amy.png"
              />
              <div class="double-btn mt-4 text-center">
                <a href="#" type="button" class="btn bg-light px-2 rounded-5 fs-6 border-0 mx-2">
                  <img
                    class="px-2 mt-n4"
                    src="@/assets/images/marketplace/like-btn.svg"
                    alt="like svg"
                  />Follow
                </a>
                <a
                  href="#"
                  type="button"
                  class="btn bg-light px-2 rounded-5 fs-6 border-0"
                  id="msg"
                >
                  <img
                    class="px-2"
                    src="@/assets/images/marketplace/masg.svg"
                    alt="masg svg"
                  />CONTACT
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-8 col-md-8 col-12">
            <div class="author-bio">
              <h3>
                <img src="@/assets/images/leaf_left.png" alt="leftleaf" />B. B. Alston<img
                  src="@/assets/images/leaf_right.png"
                  alt="rightleaf"
                />
              </h3>

              <div class="list">
                <ul>
                  <li>
                    <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
                  </li>
                  <li>
                    <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
                  </li>
                  <li>
                    <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
                  </li>
                  <li>
                    <img src="@/assets/images/marketplace/yellow-Star.svg" alt="yellow star" />
                  </li>
                  <li>
                    <img src="@/assets/images/marketplace/white-Star.svg" alt="white star" />
                  </li>
                </ul>
              </div>
              <h5>Author bio</h5>
              <p>
                B. B. Alston is the #1 New York Times and USA Today bestselling author of Amari and
                the Night Brothers and Amari and the Great Game. His books have been published in
                over 30 countries and won numerous international awards. When not writing, he can be
                found eating too many sweets and exploring country roads to see where they lead.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js">
export default {
  name: 'MarketplaceAuthorProfile',

};
</script>

<style scoped lang="scss"></style>
