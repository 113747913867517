<template lang="html">
  <section class="marketplace-single-story">
    <div class="single-story-box card h-100">
      <div class="story-header d-flex align-items-center">
        <div class="profile-pic">
          <a href="/marketplace-story-details"
            ><img src="@/assets/images/story-img11.png" alt="story-img11"
          /></a>
        </div>
        <div class="title-block">
          <h4>Pappa’s Princes</h4>
          <h5>Julia Wilson</h5>
          <div class="stars-list">
            <ul class="list-unstyled">
              <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
              <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
              <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
              <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
              <li><i class="icon-star" aria-hidden="true"></i></li>
            </ul>
          </div>
          <div class="marketplace-icons text-end">
            <ul class="list-unstyled">
              <li>
                <button class="marketplace-btn border-0">
                  <i class="icon-thumb-up" aria-hidden="true"></i>
                </button>
              </li>
              <li class="mx-2">
                <button class="marketplace-btn border-0">
                  <i class="icon-like" aria-hidden="true"></i>
                </button>
              </li>
              <li>
                <button class="marketplace-btn border-0">
                  <i class="icon-share" aria-hidden="true"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="preview-outer text-center d-flex justify-content-between mt-4 pt-1">
        <div class="story-preview">
          <h6>Story Preview</h6>
          <button class="btn btn-primary preview-btn p-0">
            <i class="icon-play3" aria-hidden="true"></i>
          </button>
        </div>
        <div class="categort-name">
          <h6>Category</h6>
          <span class="round-box">Fantasy</span>
        </div>
        <div class="categort-name">
          <h6>Suitable For</h6>
          <span class="round-box">Age 5+</span>
        </div>
      </div>

      <!-- <div class="card-mid-block mt-3">
        <div class="row">
          <div class="col-6">
            <div class="profile-rating">
              <h6 class="mb-1">Total ratings</h6>
              <div class="stars-list">
                <ul class="list-unstyled">
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li><i class="icon-star" aria-hidden="true"></i></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="marketplace-icons text-end">
              <ul class="list-unstyled">
                <li>
                  <button class="marketplace-btn border-0">
                    <i class="icon-thumb-up" aria-hidden="true"></i>
                  </button>
                </li>
                <li class="mx-2">
                  <button class="marketplace-btn border-0">
                    <i class="icon-like" aria-hidden="true"></i>
                  </button>
                </li>
                <li>
                  <button class="marketplace-btn border-0">
                    <i class="icon-share" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> -->

      <div class="card-body p-0 mt-4">
        <h6>Story Description</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et...
        </p>
        <a href="#" class="read-more">Read More</a>
      </div>
      <div class="card-footer p-0 border-0 mt-3">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-sm">
            Add to Wishlist
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>

          <button class="blob-btn fill-bg btn-sm float-end">
            Add to Cart
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>

          <SvgButton />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'marketplace-single-story',
  props: [],
  components: {

    SvgButton,

  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
