<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="account-settings order-history-details mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>
        <div v-if="orderDetails" class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('order') }} - #{{ orderDetails.number }}</h3>
              <div v-if="orderData" class="inner-box">
                <div v-for="order in orderData" :key="order.id" class="order-history-items">
                  <OrderHistoryItem :orderDetails="order" />
                </div>
                <div class="order-single-block mb-0">
                  <div v-if="orderDetails" class="order-details mt-4">
                    <div>
                      <div class="row">
                        <div class="col-md-6">
                          <h5>{{ $t('payment') }}</h5>
                          <p v-if="orderDetails.payment_source_details">
                            {{ orderDetails.payment_source_details.payment_method_details.brand }}
                            **{{ orderDetails.payment_source_details.payment_method_details.last4 }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <h5>{{ $t('deliveryAdress') }}</h5>
                          <p>
                            {{ orderAddress.line_1 }}<br />
                            {{ orderAddress.city }} {{ orderAddress.state_code }}({{
                              orderAddress.country_code
                            }})<br />
                            {{ orderAddress.phone }}
                          </p>
                        </div>
                        <div class="col-md-6 mt-2">
                          <h5>{{ $t('orderDate') }}</h5>
                          <p v-if="orderDetails.placed_at">
                            {{ orderDetails.placed_at.slice(0, 10) }}
                          </p>
                        </div>
                        <div v-if="orderDetails" class="col-md-6 mt-2">
                          <h5>{{ $t('orderSum') }}</h5>
                          <table class="w-100">
                            <tr>
                              <td>
                                <p>{{ $t('Subtotal') }}</p>
                              </td>
                              <td class="summary">
                                <p v-if="orderDetails.formatted_subtotal_amount">
                                  {{ orderDetails.formatted_subtotal_amount.slice(0, 1) }}
                                  {{ orderDetails.subtotal_amount_float }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>{{ $t('shippingCharges') }}</p>
                              </td>
                              <td class="summary">
                                <p v-if="orderDetails.formatted_shipping_amount">
                                  {{ orderDetails.formatted_shipping_amount.slice(0, 1) }}
                                  {{ orderDetails.shipping_amount_float }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div v-if="orderDetails" class="total-price">
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="col-md-6">
                            <table class="w-100">
                              <tr>
                                <td>
                                  <h4>{{ $t('totalCost') }}</h4>
                                </td>
                                <td class="summary">
                                  <h4 v-if="orderDetails.formatted_total_amount_with_taxes">
                                    {{ orderDetails.formatted_total_amount_with_taxes.slice(0, 1) }}
                                    {{ orderDetails.total_amount_with_taxes_float }}
                                  </h4>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <div class="wave-btn-block">
                          <button
                            type="submit"
                            class="blob-btn fill-bg btn-lg submit-btn mt-5"
                            @click="goBack()"
                          >
                            {{ $t('back') }}
                            <span class="blob-btn__inner">
                              <span class="blob-btn__blobs">
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                              </span>
                            </span>
                          </button>
                          <SvgButton />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end of order-single-block -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import {
  ref, computed, watch,
} from 'vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';
import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';
import OrderHistoryItem from '@/components/partials/OrderHistoryItem.vue';
import Loader from '@/components/commons/Loader.vue';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'order-history-details',
  props: [],
  components: {
    AccountSidebar,
    SvgButton,
    Loader,
    OrderHistoryItem,
    PageHeader,
    PageFooter,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const orderId = ref('');
    const orderData = ref([]);
    const orderAddress = ref({});
    const orderDetails = ref({});
    const isLoading = ref(true);
    orderId.value = route.params.id;
    const userId = cookies.get('clUserOwnerId');
    // store.dispatch('fetchOrderHistory', userId.value);

    cLayerService.fetchCartItems(orderId.value)
      .then((data) => {
        data.line_items.forEach((element) => {
          if (element.sku_code != null) {
            orderData.value.push(element);
          }
        });
        // isLoading.value = false;
      });

    cLayerService.getOrderShippingAddress(orderId.value)
      .then((address) => {
        orderAddress.value = address;
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
        console.log(error);
      });
    const orderHistory = computed(() => store.getters.getOrderHistory);
    orderHistory.value.forEach((x) => {
      if (x.id === orderId.value) {
        orderDetails.value = x;
      }
    });

    const goBack = () => {
      router.push('/order-history');
    };

    return {
      isLoading, orderAddress, orderData, orderDetails, goBack,
    };
  },
};
</script>

<style scoped lang="scss"></style>
