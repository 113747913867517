import { createRouter, createWebHistory } from 'vue-router';
import frontendRoutes from './FrontendRoutes';

const routes = createRouter({
  routes: [
    ...frontendRoutes,
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ top: 0, left: 0, behavior: 'smooth' });
  },
  history: createWebHistory(),
});

export default routes;
