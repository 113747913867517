<template lang="html">
  <section class="account-sidebar">
    <div class="profile-sidebar p-0">
      <div class="accounts-left-block d-none d-md-block">
        <h2>Welcome Back!</h2>
        <MarketplaceAccountSidebarMenu />
      </div>

      <div class="accounts-left-block mobile-sidebar d-md-none">
        <a
          class="btn-profile-settings collapsed"
          data-bs-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Profile settings
        </a>

        <div class="collapse" id="collapseExample">
          <MarketplaceAccountSidebarMenu />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import MarketplaceAccountSidebarMenu from '@/components/commons/MarketplaceAccountSidebarMenu.vue';

export default {
  name: 'account-sidebar',
  props: [],
  components: {
    MarketplaceAccountSidebarMenu,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
