import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      hideShipping: false,
      showBilling: false,
      showPreview: false,
      showPaynow: false,
      hideButton: false,
      shippingAddress: {},
      billingAddress: {},
      paymentSource: {},
      hideCartButton: false,
      successMessage: '',
    };
  },
  mutations,
  actions,
  getters,
};
