<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="shipping-details" :class="{ hideShipping: hideShipping }">
    <h5 class="mt-4 mb-3">{{ $t('shipAds') }}</h5>
    <div v-if="user">
      <h6>
        <!-- Please select an address or enter new address -->
        {{ $t('plzSelectAds') }}
      </h6>
      <div class="address-list-outer">
        <div class="address-list" v-for="(address, index) in userAddress" :key="address.id">
          <a
            @click="selectAddress(address.address, index)"
            :class="{ active: currentIndex === index }"
          >
            <span class="address-icon"><i class="icon-location" aria-hidden="true"></i></span>
            <p>
              {{ address.address.notes }}<br />
              {{ address.address.line_1 }} <br />
              {{ address.address.city }} {{ address.address.state_code }} ({{
                address.address.country_code
              }})<br />
              {{ address.address.phone }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <h5 class="mb-3">{{ $t('contactInf') }}</h5>
    <div class="form-floating mb-3">
      <input
        type="email"
        class="form-control"
        v-model="shippingAddress.email"
        :placeholder="$t('email')"
      />
      <label for="floatingInput">{{ $t('email') }} </label>
      <span class="text-danger pl-3" v-if="v$.email.$error">
        {{ $t('errorMessage', { text: $t('emailAddress') }) }}*
      </span>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckNews" />
      <label class="form-check-label" for="flexCheckNews"> {{ $t('emailNews') }} </label>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            v-model="shippingAddress.firstName"
            :placeholder="$t('firstName')"
          />
          <label for="floatingInput">{{ $t('firstName') }}</label>
          <span class="text-danger pl-3" v-if="v$.firstName.$error">
            {{ $t('errorMessage', { text: $t('firstName') }) }}*
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            v-model="shippingAddress.lastName"
            :placeholder="$t('lastName')"
          />
          <label for="floatingInput">{{ $t('lastName') }}</label>
          <span class="text-danger pl-3" v-if="v$.lastName.$error">
            {{ $t('errorMessage', { text: $t('lastName') }) }}*
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          v-model="shippingAddress.address1"
          :placeholder="$t('address')"
        />
        <label for="floatingInput">{{ $t('address') }}1</label>
        <span class="text-danger pl-3" v-if="v$.address1.$error">
          {{ $t('errorMessage', { text: $t('address') }) }}*
        </span>
      </div>
    </div>

    <div class="form-group">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          v-model="shippingAddress.address2"
          :placeholder="$t('address')"
        />
        <label for="floatingInput">{{ $t('address') }}2</label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <!-- <div class="form-selection-outer">
          <div class="form-selection" v-if="!shippingAddress.country">Select a Country</div>
        </div> -->
        <select
          class="form-select mb-3"
          v-model="shippingAddress.country"
          aria-label="Default select example"
          @change="countrySelected()"
        >
          <option value="">{{ $t('selectCountry') }}</option>
          <option
            v-for="(country, index) in countryList?.countryList?.data.items"
            :key="index"
            :value="country.country_code"
          >
            {{ country.name }}
          </option>
        </select>
        <span class="text-danger pl-3" v-if="v$.country.$error">
          {{ $t('selectMessage', { text: $t('country') }) }}*
        </span>
      </div>
      <div class="col-md-6">
        <select
          class="form-select mb-3"
          v-model="shippingAddress.state"
          aria-label="Default select example"
        >
          <option value="">{{ $t('selectState') }}</option>
          <option v-for="state in stateList" :key="state.code" :value="state.state_code">
            {{ state.name }}
          </option>
        </select>
        <span class="text-danger pl-3" v-if="v$.state.$error">
          {{ $t('selectMessage', { text: $t('state') }) }}*
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            v-model="shippingAddress.city"
            :placeholder="$t('city')"
          />
          <label for="floatingInput">{{ $t('city') }}</label>
          <span class="text-danger pl-3" v-if="v$.city.$error">
            {{ $t('errorMessage', { text: $t('city') }) }}*
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            v-model="shippingAddress.zipCode"
            :placeholder="$t('zipCode')"
          />
          <label for="floatingInput">{{ $t('zipCode') }}</label>
          <span class="text-danger pl-3" v-if="v$.zipCode.$error">
            {{ $t('errorMessage', { text: $t('zipCode') }) }}*
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          v-model="shippingAddress.phoneNumber"
          :placeholder="$t('phoneNumber')"
        />
        <label for="floatingInput">{{ $t('phoneNumber') }}</label>
        <span class="text-danger pl-3" v-if="v$.phoneNumber.$error">
          {{ $t('errorMessage', { text: $t('phoneNumber') }) }}*
        </span>
      </div>
    </div>
    <div class="text-end p-0">
      <button class="blob-btn fill-bg btn-lg" @click="updateShippingAddress">
        {{ $t('continue') }}
        <span class="blob-btn__inner">
          <span class="blob-btn__blobs">
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
            <span class="blob-btn__blob"></span>
          </span>
        </span>
      </button>
      <SvgButton />
    </div>
  </section>
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, helpers, alpha,
} from '@vuelidate/validators';
import { useCookies } from 'vue3-cookies';

import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';

const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'shipping-details',
  props: [],
  components: {
    Loader,
    SvgButton,
  },
  setup() {
    const store = useStore();
    const state = ref('');
    const currentIndex = ref('');
    const country = ref('');
    const stateList = ref([]);
    const isLoading = ref(false);
    const user = ref({});
    store.dispatch('fetchAvailableCountries');

    user.value = cookies.get('clUserAuthCookie');
    if (user.value) {
      store.dispatch('fetchUserAddress');
      store.dispatch('fetchUserDetails');
    }

    const userAddress = computed(() => store.getters.getUserAddress);

    const hideShipping = computed(() => store.getters.isHideShipping);

    const shippingAddress = reactive({
      email: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      phoneNumber: '',
    });
    const countryList = computed(() => store.getters.getCountryList);

    const rules = {
      email: { required: helpers.withMessage('You must enter your email address', required), email },
      firstName: { required: helpers.withMessage('You must enter your first name', required) },
      lastName: { required: helpers.withMessage('You must enter your last name', required) },
      address1: { required: helpers.withMessage('You must enter your address', required) },
      city: { required: helpers.withMessage('You must enter your city', required) },
      state: { required: helpers.withMessage('You must select your state', required) },
      country: { required: helpers.withMessage('You must select your country', required) },
      zipCode: { required: helpers.withMessage('You must enter your zipCode', required) },
      phoneNumber: { required: helpers.withMessage('You must enter your phone number', required) },
    };

    const v$ = useVuelidate(rules, shippingAddress);

    const stateSelected = () => {
      state.value = '';
    };

    const countrySelected = () => {
      storyphoneService.fetchAvailableStates(shippingAddress.country)
        .then((states) => {
          stateList.value = states.data.items;
        }).catch((stateError) => {
          stateList.value = {};
        });
    };

    const selectAddress = (address, index) => {
      currentIndex.value = index;
      const userDetails = computed(() => store.getters.getUserDetails);
      storyphoneService.fetchStateByCode(address.country_code, address.state_code)
        .then((result) => {
          state.value = result.data.name;
        });
      shippingAddress.email = userDetails.value.email;
      shippingAddress.firstName = address.first_name;
      shippingAddress.lastName = address.last_name;
      shippingAddress.address1 = address.line_1;
      shippingAddress.address2 = address.line_2;
      shippingAddress.city = address.city;
      shippingAddress.state = address.state_code;
      shippingAddress.country = address.country_code;
      shippingAddress.zipCode = address.zip_code;
      shippingAddress.phoneNumber = address.phone;
      countrySelected();
    };

    const updateShippingAddress = async () => {
      const { orderId } = cookies.get('orderIdCookie');
      const shippingAddressParam = {
        first_name: shippingAddress.firstName,
        last_name: shippingAddress.lastName,
        line_1: shippingAddress.address1,
        line_2: shippingAddress.address2,
        city: shippingAddress.city,
        country_code: shippingAddress.country,
        state_code: shippingAddress.state,
        zip_code: shippingAddress.zipCode,
        phone: shippingAddress.phoneNumber,
      };
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        if (!cookies.isKey('clUserAuthCookie')) {
          cLayerService.createGuestOrder(orderId, shippingAddress.email);
        }
        cLayerService.createAddress(shippingAddressParam)
          .then((addressCreated) => {
            cookies.set('shippingAddressId', {
              shippingAddressId: addressCreated.id,
            }, addressCreated.expires);
            store.dispatch('fetchShippingAddress', shippingAddressParam);
            store.dispatch('isHideShipping', true);
            store.dispatch('isShowBilling', true)
              .then(() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              });
            store.dispatch('inFirstStep', true);
            store.dispatch('buttonHide', true);
            isLoading.value = false;
          });
      } else {
        console.log(v$);
      }
    };

    return {
      state, stateList, isLoading, hideShipping, currentIndex, shippingAddress, user, v$, userAddress, countryList, selectAddress, countrySelected, updateShippingAddress,
    };
  },
};
</script>

<style scoped lang="scss"></style>
