import * as types from './mutation-types';

const mutations = {
  [types.HIDE_SHIPPING](state, value) {
    state.hideShipping = value;
  },
  [types.SHOW_BILLING](state, value) {
    state.showBilling = value;
  },
  [types.SHOW_PREVIEW](state, value) {
    state.showPreview = value;
  },
  [types.SHOW_PAYNOW](state, value) {
    state.showPaynow = value;
  },
  [types.HIDE_BUTTON](state, value) {
    state.hideButton = value;
  },
  [types.SHIPPING_ADDRESS](state, value) {
    state.shippingAddress = value;
  },
  [types.BILLING_ADDRESS](state, value) {
    state.billingAddress = value;
  },
  [types.PAYMENT_SOURCE](state, value) {
    state.paymentSource = value;
  },
  [types.HIDE_CARTBUTTONS](state, value) {
    state.hideCartButton = value;
  },
  [types.GET_SUCCESS_MESSAGE](state, successMessage) {
    state.successMessage = successMessage;
  },
};

export default mutations;
