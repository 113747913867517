<template lang="html">
  <PageHeader />

  <div v-if="Loading"><Loader /></div>
  <section class="success-password account-section mt-stickybar mb-5 home">
    <div class="account-banner text-center"><img src="@/assets/images/signup.png" alt="" /></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block active-box mt-5">
            <h2 class="text-center mb-3">{{ $t('successPassword') }}</h2>
            <div v-if="isLoading && !successMessage && !failureMessage" class="text-center mb-3">
              <img src="../assets/images/loader.gif" alt="" />
            </div>
            <div>
              <h6 class="mt-3 text-success text-center">
                <!-- Your password reset is successfully completed. -->
                {{ $t('resetIsSuccessfully') }}
              </h6>
              <div class="text-center">
                <div class="wave-btn-block">
                  <a v-if = "this.isMobile()"
                    href="https://storyphones.page.link/start"
                    class="blob-btn fill-bg btn-lg submit-btn me-2"
                  >
                    <!-- Open App -->Open App On Phone

                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </a>
                  <a v-else href="/" class="blob-btn fill-bg btn-lg submit-btn">
                    <!-- Go To Home -->{{ $t('goToHome') }}

                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </a>

                  <SvgButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'success-password',
  props: [],
  components: {
    SvgButton,
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {
    isMobile() {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    },
  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
