<template lang="html">
  <PageHeader />
  <section class="mt-stickybar bg-red py-8 home">
    <div class="container">
      <div class="row">
        <div class="col-md-7 bg-red py-5 m-auto text-center">
          <img src="@/assets/images/success-payment.jpg" alt="" />
          <h3 class="highlight-heading mt-4">{{ $t('Congrats') }}</h3>
          <p class="mt-2">
            {{ $t('paidSucces') }}
            <span class="highlight-heading">
              {{ orderAddress.formatted_total_amount_with_taxes?.slice(0, 1) }}
              {{ orderAddress.total_amount_with_taxes_float }}
            </span>
          </p>
          <p class="px-3">
            {{ $t('paidSuccesMsg') }}

            <span class="highlight-heading">{{ orderAddress.number }}</span>
          </p>
          <button class="btn btn-primary btn-sm mt-4" @click="goToHome()">{{ $t('done') }}</button>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';
import CLayerService from '@/services/CLayerService';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

const { cookies } = useCookies({ secure: true });
const cLayerService = new CLayerService();

export default {
  name: 'PaymentSuccess',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getOrderAddress(this.$route.params.id);
    const { orderId } = cookies.get('orderIdCookie');
    this.fetchCartItemsAction(orderId)
      .then(() => {
        cookies.remove('orderIdCookie');
        this.fetchCartItemsAction(cookies.get('orderIdCookie'));
      });
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
    },
    orderAddress: {
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      id: '',
      orderAddress: {},
    };
  },
  methods: {
    getOrderAddress(id) {
      cLayerService.getOrderAddress(id)
        .then((result) => {
          this.orderAddress = result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToHome() {
      this.$router.push('/shields');
    },
    ...mapActions({
      fetchCartItemsAction: 'fetchCartItems',

    }),

  },
  computed: {
    ...mapGetters({
      cartItems: 'getCartItems',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
