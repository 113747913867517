/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import CLayerBaseService from '../services/CLayerBaseService';

const cLayerBaseService = new CLayerBaseService();

export default function IsLoggedIn(to, from, next) {
    const { cookies } = useCookies({ secure: true });
    const token = cookies.get('storyPhoneUserAuthCookie');
    if (!token) {
        next('/login');
    }

    next();
}
