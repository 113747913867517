<template lang="html">
  <div v-show="isLoading"><Loader /></div>
  <section class="country-selection">
    <div class="country-selection-card">
      <div class="row">
        <h1>{{ next }} - {{ back }}</h1>
        <!-- <div class="country-selection-card"> -->
        <div class="col-6">
          <div v-for="(item, index) in europe" :key="index" :value="item" class="card border-0">
            <div class="card-body d-flex" @click="clickNext(item)">
              <div style="width: 30px">
                <img :src="`/flag/${item.image}.png`" :alt="item.image" />
              </div>
              <div class="mt-1 mx-4">
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- <div class="country-selection-card"> -->
        <div class="col-6">
          <div v-for="(item, index) in language" :key="index" :value="item" class="card border-0">
            <div class="card-body d-flex" @click="clickBack(item)">
              <!-- <div style="width: 30px">
                <img :src="`/flag/${item.image}.png`" :alt="item.image" />
              </div> -->
              <div class="mt-1 mx-4">
                {{ item.lang }}
                <!-- {{ item.language }} -->
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <button @click="selectCountry" class="btn btn-primary">Continue</button>
    </div>
    <!-- <div class="container"></div> -->
    <!-- <div class="country-selection-card container">
      <div class="row">
        <div class="col-sm-6">
          <button @click="clickBack()" class="btn btn-primary">back</button>
        </div>
        <div class="col-sm-6">
          <button @click="clickNext()" class="btn btn-primary">next</button>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import CLayerBaseService from '@/services/CLayerBaseService';
import Loader from '@/components/commons/Loader.vue';

const cLayerBaseService = new CLayerBaseService();

export default {
  name: 'CountySelection',
  props: [],
  components: {
    Loader,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const next = ref('');
    const back = ref('');
    const isLoading = ref(false);
    const curCode = ref('');
    const langCode = ref('');

    const language = ref([]);
    language.value = [{ lang: 'english', code: 'en' }, { lang: 'german', code: 'de' }, { lang: 'spanish', code: 'es' }, { lang: 'french', code: 'fr' }, { lang: 'italian', code: 'it' }];

    const europe = computed(() => store.getters.getEurope);

    const clickNext = (value) => {
      next.value = value.name;
      curCode.value = value.currencyCode;
    };

    const clickBack = (value) => {
      back.value = value.lang;
      langCode.value = value.code;
    };
    const selectCountry = (data) => {
      isLoading.value = true;
      localStorage.removeItem('marketNumber');
      // localStorage.removeItem('marketName');
      localStorage.removeItem('LangCode');
      localStorage.setItem('marketNumber', '12501');
      // localStorage.setItem('marketName', data.currency);
      localStorage.setItem('LangCode', langCode.value);
      cLayerBaseService.createToken()
        .then(() => {
          isLoading.value = false;
          router.push('/').then(() => { router.go(); });
        });
    };
    return {
      europe, language, next, back, clickNext, clickBack, isLoading, selectCountry, curCode, langCode,
    };
  },

};
</script>

<style></style>
