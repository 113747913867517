<template lang="html">
  <PageHeader />
  <DownloadApp class="mt-stickybar home" />
  <AppWorkflow />
  <section class="py-8 adv-storyphone">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="row h-100 align-items-center">
            <div class="col-md-10">
              <div class="heading-with-highlight" v-html="domSanitize(setUp.html_description)"></div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="easy-outer-block">
            <div class="mini-video-block">
              <button class="video-play-btn" @click="playAdventureVideo()"></button>
              <video id="adventure" class="w-100" width="100%" autoplay="autoplay" loop="loop">
                <source src="@/assets/videos/s1.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="middle-img text-right">
              <img src="@/assets/images/puffin.png" alt="" width="180" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 p-0" v-for="(item, index) in record.widgets" :key="index">
          <span v-for="(image, index) in item.images" :key="index">
            <img :src="image.image_url" alt="" class="w-100" />
          </span>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row justify-content-center align-items-center mt-s-3">
        <div class="col-md-6 text-center" v-for="(image, index) in record.images" :key="index">
          <img :src="image.image_url" alt="" width="400" />
        </div>
        <div class="col-md-6 py-sm-8">
          <div class="row">
            <div class="col-md-10">
              <div class="heading-with-highlight" v-html="domSanitize(record.html_description)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="manage-headphone">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 py-sm-8 order-2 order-lg-1 order-sm-1">
          <div class="row">
            <div class="col-md-10 offset-lg-2">
              <div class="heading-with-highlight" v-html="domSanitize(headphones.html_description)"></div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 p-0 order-1 order-lg-2 order-sm-2"
          v-for="(image, index) in headphones.images"
          :key="index"
        >
          <img :src="image.image_url" alt="" class="w-100" />
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import DownloadApp from '@/components/partials/DownloadApp.vue';
import AppWorkflow from '@/components/partials/AppWorkflow.vue';
import DOMPurify from 'dompurify';

let adventureVideo;

export default {
  name: 'Product',
  props: [],
  components: {
    DownloadApp,
    AppWorkflow,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data1 = {
      code: langCode,
      slug: `app_setup_${langCode}`,
    };
    store.dispatch('fetchCMS2', Data1);

    const Data2 = {
      code: langCode,
      slug: `app_record_${langCode}`,
    };
    store.dispatch('fetchCMS3', Data2);

    const Data3 = {
      code: langCode,
      slug: `app_headphones_${langCode}`,
    };
    store.dispatch('fetchCMS4', Data3);

    const setUp = computed(() => store.getters.getCMS2);
    const record = computed(() => store.getters.getCMS3);
    const headphones = computed(() => store.getters.getCMS4);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      langCode, setUp, record, headphones, domSanitize,
    };
  },
  mounted() {
    adventureVideo = document.getElementById('adventure');
  },
  data() {
    return {

    };
  },
  methods: {
    playAdventureVideo() {
      adventureVideo.play();
    },
  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
