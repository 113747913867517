<template lang="html">
  <section class="main-menu-content">
    <ul class="nav">
      <li class="nav-item d-lg-none">
        <router-link :to="{ name: 'home' }" class="nav-link txt-bdr-link white-txt">
          Home
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'ProductCollections' }" class="nav-link txt-bdr-link white-txt">
          {{ $t('storyPhonesHeadphones') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'shields' }" class="nav-link txt-bdr-link white-txt">
          {{ $t('storyShields') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'appStoryphones' }" class="nav-link txt-bdr-link white-txt">
          {{ $t('storyPhonesAPP') }}
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link :to="{ name: 'marketplace-home' }" class="nav-link txt-bdr-link white-txt">
          Marketplace
        </router-link>
      </li> -->

      <!-- <li class="nav-item">
        <a href="/marketplace-home" class="nav-link txt-bdr-link white-txt"> Marketplace </a>
      </li> -->

      <!-- <li class="nav-item">
        <a
          class="nav-link txt-bdr-link white-txt"
          data-bs-toggle="modal"
          data-bs-target="#marketPlaceLogin"
        >
          <img
            src="@/assets/images/marketplace-login.png"
            alt=""
            style="width: 30px"
            class="d-none d-lg-block"
          />
          <span class="d-lg-none">Marketplace Login</span>
        </a>
      </li> -->
      <li class="nav-item">
        <router-link :to="{ name: 'login' }" class="nav-link txt-bdr-link white-txt">
          <!-- <i class="icon-User d-none d-lg-block" aria-hidden="true"></i
          > -->
          <span class="d-lg-none">{{ $t('myAccount') }}</span>
        </router-link>
      </li>
    </ul>
  </section>
</template>

<script lang="js">
export default {
  name: 'main-menu-content',
  props: [],
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
