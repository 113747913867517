<template>
  <PageHeader />
  <section class="blog">
    <div class="container">
      <div class="row">
        <div class="col-md-10 m-auto">
          <div class="container text-center">
            <img src="@/assets/images/lingokids-StoryPhones-2.jpg" alt="lingokids-StoryPhones" />
          </div>
          <h5 class="mt-4 mb-3">
            Screen-Free FUN: StoryPhones partners with Lingokids for engaging learning.
          </h5>
          <p class="blog-p">
            In today&#39;s digital age, it&#39;s no secret that screens play a significant role in
            children&#39;s lives. However, striking a balance between screen time and other forms of
            engagement is crucial for their holistic development. That is why StoryPhones have
            teamed up with leading early learning platform
            <a href="https://lingokids.com/" class="">Lingokids</a> to offer captivating
            learning experience for young children in the form of new StoryShields. This partnership
            combines the power of technology with the magic of music and sing-a-longs, promising an
            educational journey like no other.
          </p>
          <p class="blog-p">
            We’re very excited to unveil not just one, but six Lingokids shields exclusively on the
            StoryPhones platform throughout 2023! That’s right, we’re taking our mission of making
            learning fun to a whole new level. Two StoryShields from Lingokids are already
            available:
          </p>
          <ul class="ps-5 mb-3">
            <li>
              Learn to Read with Elliot –Elliot is a playful panda that uses music and sing-a-longs
              to introduce kids to the world of reading.
            </li>
            <li>
              Learn Math and Science with Billy –Billy is a little bird with big brain power that
              guides kids through math and science with catchy tunes that make learning an
              adventure.
            </li>
          </ul>
          <div class="container text-center mt-5">
            <img src="@/assets/images/lingokids-StoryPhones-1.jpg" alt="lingokids-StoryPhones" />
          </div>
          <h5 class="mt-4 mb-3">Lingokids: A Pioneer in Early Language Learning</h5>
          <p class="blog-p">
            Lingokids has earned its reputation as a pioneer in early language learning through its
            interactive and engaging digital platform. The Lingokids universe provides original,
            award- winning, interactive content for kids: games, songs, podcasts, videos, and
            activities that blend educational subjects with modern life skills to spark curiosity,
            imagination, and success.With a wide range of educational material designed for kids
            aged 2 and 8, Lingokids recognizes the need to provide children with diverse learning
            experiences. The partnership between Lingokids and StoryPhones is a testament to the
            power of collaboration. By integrating Lingokids&#39; learning expertise with
            StoryPhones, the two companies are encouraging early childhood education. This
            partnership not only addresses concerns about excessive screen time, but also harnesses
            technology to enrich children&#39;s skills and ignite their imagination.
          </p>
          <h5 class="mt-4 mb-3">StoryPhones: Where Technology Meets Traditional Storytelling</h5>
          <p class="blog-p">
            For those of you not yet familiar with StoryPhones, they are award winning SafeAudio®
            headphones for kids. StoryPhones are the first smart, wireless headphones for children
            that can acquire, store, and play content by simply attaching a magnetic StoryShield to
            the headphones. It&#39;s an independent device offering screen-free entertainment that
            doesn&#39;t need to connect to any device for playback. StoryPhones can easily be
            operated by anyone, and work as a standard wireless headphone.
          </p>
          <h5 class="mt-4 mb-3">The Rise of Screen Time and its Implications</h5>
          <p class="blog-p">
            As smartphones, tablets and computers become ubiquitous, children are increasingly
            exposed to screens from an early age. While technology offers numerous benefits,
            excessive screen time can have adverse effects on children&#39;s cognitive, social, and
            physical development. This concern has prompted educators and parents to seek
            alternative ways to engage children in meaningful learning experiences that go beyond
            screens. In a world where technology is both a tool and a challenge, the Lingokids and
            StoryPhones partnership offers a refreshing approach to early childhood education. By
            weaving together technology and traditional storytelling, these two innovators have
            created a screen-free learning solution that not only enriches language and mathematical
            skills but also nurtures imagination and cognitive development. As parents and educators
            continue to seek meaningful learning experiences for children, the collaboration between
            LingoKids and StoryPhones stands as an example of how innovation can transform
            education.
          </p>
          <div class="container">
            <ul class="" style="list-style: none;">
              <li class="mt-4 mb-4">
                <a href="https://www.storyphones.com/products/lingoKids"
                  >StoryPhones + 2 Lingokids Shields Bundle</a
                >
              </li>
              <li class="">
                <a href="https://www.storyphones.com/shields-details/4897111742990"
                  >Lingokids Billy &amp; Elliot StoryShield Bundle</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid text-center mt-4 mb-5">
      <div class="wave-btn-block">
        <router-link to="/blog" class="blob-btn fill-bg btn-lg">
          Back to Blog
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Blog',

  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style></style>
