<template lang="html">
  <section class="home">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-5 m-auto">
          <div id="logo" class="text-center mb-4">
            <img src="../assets/images/verification-bg.jpg" alt="" width="300" />
            <img src="../assets/images/logo-black.png" alt="" width="400" />
          </div>
          <div class="col-md-12 active-box text-center">
            <div v-if="!successMessage && !failureMessage">
              <img src="../assets/images/loading.gif" alt="" />
              <h5>{{ $t('plzWait') }}</h5>
            </div>
            <h5 v-if="successMessage">{{ this.successMessage }}</h5>
            <h5 v-if="failureMessage" class="text-danger">{{ this.failureMessage }}</h5>
            <!-- <button v-if="successMessage" class="btn btn-primary mt-4" @click="emailVerify()">
              Login
            </button> -->
            <div class="text-center">
              <div class="wave-btn-block">
                <a v-if = "this.isMobile()"
                  href="https://storyphones.page.link/start"
                  class="blob-btn fill-bg btn-lg submit-btn me-2"
                  >Open App On Phone

                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <a v-else href="/" class="blob-btn fill-bg btn-lg submit-btn"
                  >{{ $t('goToHome') }}

                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>

                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Verification',
  props: [],
  components: {
  },
  mounted() {
    this.uid = this.$route.params.uid;
    this.token = this.$route.params.token;
    const userActiveData = {
      uid: this.$route.params.uid,
      token: this.$route.params.token,
    };

    this.userActivationAction(userActiveData)
      .then((response) => {
        this.isLoading = false;
        this.successMessage = 'Your account has been verified successfully';
      })
      .catch(() => {
        this.isLoading = false;
        this.failureMessage = 'Sorry, we were not able to verify your account! Please try again later';
      });
  },
  data() {
    return {
      uid: '',
      token: '',
      successMessage: '',
      failureMessage: '',
      isLoading: true,
      userActivationError: '',
    };
  },
  methods: {
    ...mapActions({
      userActivationAction: 'userActivation',
    }),
    userResetPassword() {

    },

    isMobile() {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    },

    emailVerify() {
      this.$router.push('/');
    },
  },
  computed: {
    ...mapGetters({
      userActivationErrors: 'getUserActivationErrors',
      userActivationSuccess: 'getUserActivationSuccess',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
