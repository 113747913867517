<template>
  <router-view></router-view>
</template>

<script>
import 'bootstrap';
import 'animate.css';
import './assets/css/icomoon.css';
import './themes/storyphones/style.scss';
import './assets/css/fontface.css';
import './assets/css/font-awesome.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: 'App',
  components: {},
};
</script>

<style></style>
