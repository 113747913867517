/* eslint-disable import/extensions */
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  state() {
    return {
      CMS: [],
      CMS1: [],
      CMS2: [],
      CMS3: [],
      CMS4: [],
      CMS5: [],
      CMS6: [],
      CMS7: [],
      CMS8: [],
      CMS9: [],
      CMS10: [],
      LangCode: [],
      contactUs: [],
      userManualIos: [],
      userManualAndroid: [],
      app: [],

    };
  },
  mutations,
  actions,
  getters,
};
