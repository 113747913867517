import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      isLoading: true,
      scChannelToken: {},
      productDetails: {},
      cartItems: {},
      shippingMethods: {},
      deliveryLeadTime: {},
      countryList: {},
      stateList: {},
      orders: {},
      outOfStockMessage: '',
    };
  },
  mutations,
  actions,
  getters,
};
