<template>
  <div class="container ms-3">
    <div class="row">
      <div class="col-sm-12">
        <div class="mt-3">
          <h2>Register</h2>
          <p class="mb-4">Simple steps to register now to become our storyteller team!</p>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ $t('firstName') }}</label>
                <input type="input" class="form-control" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{ $t('lastName') }}</label>
                <input type="input" class="form-control" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('email') }}</label>
            <input type="input" class="form-control" />
          </div>
          <div class="form-group">
            <label>{{ $t('password') }}</label>
            <input type="password" id="marketplace-password" class="form-control" />
            <div
              class="eye-pwd"
              style="cursor: pointer; text-align: right; margin-top: -30px"
              @click="toggleShowPassword()"
            >
              <div v-if="showPassword">
                <i class="icon-eye-Open" aria-hidden="true"></i>
              </div>
              <div v-if="!showPassword">
                <i class="icon-eye-closed" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1"
              >{{ $t('agreement') }}
              <router-link to="/licence-agreement" class="text-link">{{
                $t('licenceAgreement')
              }}</router-link
              >.
            </label>
          </div>

          <div class="text-center">
            <div class="wave-btn-block">
              <button type="submit" class="blob-btn fill-bg btn-lg submit-btn w-100">
                NEXT
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </button>
              <SvgButton />
            </div>
          </div>
          <!-- <SocialAuthentication /> -->
          <p class="small-text text-center mt-4">
            {{ $t('logInQuestion') }}
            <router-link to="/login" class="txt-bdr-link">{{ $t('logIn') }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import {
  ref, computed, reactive,
} from 'vue';

export default {
  name: 'MarketPlaceSignUpSection',
  props: [],
  components: {},
  setup() {
    const showPassword = ref(false);

    const toggleShowPassword = () => {
      const password = document.querySelector('#marketplace-password');
      if (showPassword.value) {
        showPassword.value = false;
        password.setAttribute('type', 'password');
      } else {
        showPassword.value = true;
        password.setAttribute('type', 'text');
      }
    };

    return { showPassword, toggleShowPassword };
  },
};
</script>

<style></style>
