<template lang="html">
  <PageHeader />
  <section class="register mt-stickybar home" aria-label="register">
    <div class="inner-page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav aria-label="breadcrumb" class="mt-3">
              <ol class="breadcrumb px-0">
                <li class="breadcrumb-item">
                  <router-link to="/my-account">{{ $t('myAccount') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('library') }}</li>
              </ol>
            </nav>
            <div class="grey-outer-block py-4 mb-5">
              <div class="library-heading">
                <div class="row">
                  <div class="col-md-4">
                    <h2 class="m-0 p-0">{{ $t('library') }}</h2>
                  </div>
                  <div class="col-md-8">
                    <div class="library-tab-list d-flex justify-content-end">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="library-recordings-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#library-recordings"
                            type="button"
                            role="tab"
                            aria-controls="library-recordings"
                            aria-selected="false"
                          >
                            {{ $t('yourRecordings') }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="library-storyshields-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#library-storyshields"
                            type="button"
                            role="tab"
                            aria-controls="library-storyshields"
                            aria-selected="true"
                          >
                            {{ $t('storyShields') }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="library-playshields-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#library-playshields"
                            type="button"
                            role="tab"
                            aria-controls="library-playshields"
                            aria-selected="false"
                          >
                            PlayShields
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="library-tab-content tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="library-recordings"
                  role="tabpanel"
                  aria-labelledby="library-recordings-tab"
                >
                  <LibraryRecordingList />
                </div>
                <div
                  class="tab-pane fade"
                  id="library-storyshields"
                  role="tabpanel"
                  aria-labelledby="library-storyshields-tab"
                >
                  <LibraryStoryShields />
                </div>
                <div
                  class="tab-pane fade"
                  id="library-playshields"
                  role="tabpanel"
                  aria-labelledby="library-playshields-tab"
                >
                  <LibraryPlayShields />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { mapActions, useStore } from 'vuex';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import LibraryPlayShields from '@/components/partials/LibraryPlayShields.vue';
import LibraryStoryShields from '@/components/partials/LibraryStoryShields.vue';
import LibraryRecordingList from '@/components/partials/LibraryRecordingList.vue';

export default {
  name: 'library',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    LibraryStoryShields,
    LibraryPlayShields,
    LibraryRecordingList,
  },
  setup() {
    const store = useStore();
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions({
      recordingListAction: 'fetchRecordingList',
    }),
  },

  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
