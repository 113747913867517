/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const isHideTitle = (state) => isObj(state, 'hideTitle', true);

export const isShowUploadRecord = (state) => isObj(state, 'showUploadRecord', false);

export const isShowRecording = (state) => isObj(state, 'showRecording', false);

export const isShowFiles = (state) => isObj(state, 'showFiles', false);

export const isShowArtwork = (state) => isObj(state, 'showArtwork', false);

export const isShowOverview = (state) => isObj(state, 'showOverview', false);

export const isShowLibrary = (state) => isObj(state, 'showLibrary', false);
