<template lang="html">
  <section class="marketplace-single-tellers">
    <div class="single-story-box card h-100">
      <div class="story-header">
        <div class="card-wishlist-outer">
          <span class="card-flag me-2"
            ><img src="@/assets/images/flag-us-new.jpg" alt="flag-us-new"
          /></span>
          <button class="card-wishlist-btn border-0 p-0">
            <i class="icon-like" aria-hidden="true"></i>
          </button>
        </div>
        <div class="row align-items-center">
          <div class="col-5">
            <div class="profile-pic">
              <a href="/marketplace-profile"
                ><img src="@/assets/images/profile-pic.jpg" alt="profile-pic"
              /></a>
            </div>
          </div>
          <div class="col-7">
            <div class="title-block">
              <h4>Alexandra Niya</h4>
              <h5>Playlists : 5</h5>
              <div class="stars-list">
                <ul class="list-unstyled">
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="icon-star" aria-hidden="true"></i></li>
                  <li><i class="icon-star" aria-hidden="true"></i></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-mid-block mt-3">
        <div class="card-language mt-1">
          <h6 class="mb-2">Language Profficiency</h6>
          <ul class="nav nav-pills">
            <li class="nav-item">English</li>
            <li class="nav-item">Japaneese</li>
            <li class="nav-item">German</li>
          </ul>
        </div>
      </div>

      <div class="card-body p-0 mt-3">
        <h6>Author bio</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
      <div class="card-footer p-0 border-0 mt-2">
        <div class="wave-btn-block text-center">
          <a href="/marketplace-profile" class="blob-btn fill-bg">
            Read More
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </a>
          <SvgButton />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'marketplace-single-tellers',
  props: [],
  components: {
    SvgButton,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
