import { createApp } from 'vue';
import VueCookies from 'vue3-cookies';

import translation from './translation/translation';
import titleMixin from './mixins/titleMixin';
import App from './App.vue';
import routes from './routes';
import store from './store';

const vueApp = createApp(App);

vueApp.use(translation);
vueApp.use(routes);
vueApp.use(store);
vueApp.use(VueCookies);
vueApp.use(translation);

vueApp.mixin(titleMixin);

vueApp.mount('#app');
