<template lang="html">
  <PageHeader />
  <section class="account-settings order-history mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="account-sidebar">
            <div class="accounts-left-block d-none d-md-block">
              <h2>Welcome Back!</h2>
              <div class="account-sidebar-menu">
                <div class="accounts-sidebar">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a href="#" class="">My Profile</a>
                    </li>
                    <li class="nav-item">
                      <a href="/orderhistory-payment" class="">Order History</a>
                    </li>
                    <li class="nav-item">
                      <a href="/manageshop" class="">ManageShop</a>
                    </li>
                    <li class="nav-item">
                      <a href="/mystory-content" class="">My story</a>
                    </li>
                  </ul>
                </div>
                <div class="text-center py-4">
                  <div class="wave-btn-block">
                    <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                      Log out
                      <span class="blob-btn__inner"
                        ><span class="blob-btn__blobs"
                          ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                          ><span class="blob-btn__blob"></span
                          ><span class="blob-btn__blob"></span></span
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="accounts-left-block mobile-sidebar d-md-none">
              <a
                class="btn-profile-settings collapsed"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Profile settings
              </a>
              <div class="collapse" id="collapseExample" style="">
                <!--v-if-->
                <div class="account-sidebar-menu">
                  <div class="accounts-sidebar">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <a href="#" class="">My Profile</a>
                      </li>
                      <li class="nav-item">
                        <a href="/orderhistory-payment" class="">Order History</a>
                      </li>
                      <li class="nav-item">
                        <a href="/manageshop" class="">ManageShop</a>
                      </li>
                      <li class="nav-item">
                        <a href="/mystory-content" class="">My story</a>
                      </li>
                    </ul>
                  </div>
                  <div class="text-center py-4">
                    <div class="wave-btn-block">
                      <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                        Log out
                        <span class="blob-btn__inner"
                          ><span class="blob-btn__blobs"
                            ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span></span
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <div class="header">
                <h3>
                  <div class="row">
                    <div class="col-sm-7" id="mq-heading">My payment</div>
                    <div class="col-sm-5 px-4" id="mq-heading">
                      Total Earnings
                      <button
                        type="button"
                        class="btn bg-white border-dark rounded-5 px-3 pt-1 pb-0 fs-6"
                      >
                        $5003
                      </button>
                    </div>
                  </div>
                </h3>
              </div>
              <button type="button" class="btn bg-light mt-4 mx-3 rounded-circle border-0">
                <img src="@/assets/images/marketplace/leftarrow.svg" alt="leftarrow" />
              </button>
              <div class="container mb-5 pb-5 px-4">
                <div class="block rounded-5 mt-4">
                  <h3 class="bg-warning rounded-top">ORDER DATE: 20 JULY 2022</h3>
                  <div class="order-detail p-5" id="mq-heading">
                    <div class="row">
                      <div class="col-sm-6">
                        <button
                          type="button"
                          class="btn bg-info text-white mb-3 px-lg-5 px-md-3 fs-6"
                        >
                          ORDER DETAILS
                        </button>
                        <ul class="list-unstyled">
                          <li>Order no: 12345678</li>
                          <li>Order Date: 26 March 2020</li>
                          <li>Status: Waiting for payment</li>
                          <li>Customer name: Marie</li>
                          <li>Customer email: Marie@gmail.com</li>
                        </ul>
                      </div>
                      <div class="col-sm-6">
                        <button type="button" class="btn bg-info text-white mb-3 px-lg-5 fs-6">
                          SHIPPING DETAILS
                        </button>
                        <h6>JACKSON'S HOUSE</h6>
                        <p>home sweet home HK , 76991 12345678</p>
                      </div>
                      <div class="col-sm-12 bg-info mt-3 rounded text-center">
                        <button type="button" class="btn text-white border-0">ITEMS</button>
                      </div>
                      <div class="story-cards">
                        <ul class="list-unstyled mt-4">
                          <li class="mb-4 mq-card">
                            <div class="card w-100 bg-secondary border-0">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <img
                                      class="around rounded-circle p-3"
                                      src="@/assets/images/marketplace/amari.png"
                                      alt="amari"
                                    />
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="pt-lg-5 pt-md-4" id="mq-cd">
                                      <h4>Amari and the Night Brothers</h4>
                                      <p class="text-blue">€14,99</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="mq-card">
                            <!-- <div class="card w-100 bg-secondary border-0">
                              <div class="row">
                                <div class="col-sm-4 d-flex">
                                  <img
                                    class="around rounded-circle p-3"
                                    src="@/assets/images/marketplace/lastfallenmoon.png"
                                    id="mq-img"
                                    alt="lastfallenmoon"
                                  />
                                </div>
                                <div class="col-sm-8 pt-5">
                                  <h4>The Last Fallen Moon</h4>
                                  <p class="text-blue">€14,99</p>
                                </div>
                              </div>
                            </div> -->
                            <div class="card w-100 bg-secondary border-0">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <img
                                      class="around rounded-circle p-3"
                                      src="@/assets/images/marketplace/lastfallenmoon.png"
                                      alt="lastfallenmoon"
                                    />
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="pt-lg-5 pt-md-4" id="mq-cd">
                                      <h4>The Last Fallen Moon</h4>
                                      <p class="text-blue">€14,99</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="footer-content mt-5 pt-5">
                        <div class="row">
                          <div class="col-sm-6"></div>
                          <div class="col-sm-6" id="mq">
                            <hr class="style3" />
                            <ul class="list-unstyled">
                              <li>
                                <div class="row">
                                  <div class="col-sm-8 px-4">SUBTOTAL:</div>
                                  <div class="col-sm-4 px-4">€14.99</div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-8 px-4">SHIPPING CHARGES:</div>
                                  <div class="col-sm-4 px-4">€0.00</div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-sm-8 px-4">TOTAL:</div>
                                  <div class="col-sm-4 px-4">€14.99</div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'ManageShop',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>
