import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      firstStep: false,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      fifthStep: false,
      sixthStep: false,
    };
  },
  mutations,
  actions,
  getters,
};
