<template lang="html">
  <PageHeader />
  <section class="reviews-and-ratings mt-stickybar home">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>Reviews And Ratings</h2>
            <div class="review-outer-block">
              <div class="row">
                <div class="col-md-4">
                  <div class="review-pic-block mb-3">
                    <img src="@/assets/images/story-img5.png" alt="" />
                  </div>
                  <h5>Disney Mickey Mouse Christmas Collection</h5>
                  <div class="progressbar-outer-block rating-only mt-4">
                    <div class="progress-single-block">
                      <span class="progress-star-num">
                        <div class="color-box">5<span>★</span></div>
                      </span>
                      <div class="progress">
                        <div
                          class="progress-bar orange"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 100%"
                        ></div>
                      </div>
                      <span class="progress-num-person">
                        <p>154</p>
                      </span>
                    </div>
                    <!--progress-single-block-->

                    <div class="progress-single-block">
                      <span class="progress-star-num">
                        <div class="color-box">4<span>★</span></div>
                      </span>
                      <div class="progress">
                        <div
                          class="progress-bar orange"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 60%"
                        ></div>
                      </div>
                      <span class="progress-num-person">
                        <p>60</p>
                      </span>
                    </div>
                    <!--progress-single-block-->

                    <div class="progress-single-block">
                      <span class="progress-star-num">
                        <div class="color-box">3<span>★</span></div>
                      </span>
                      <div class="progress">
                        <div
                          class="progress-bar orange"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 40%"
                        ></div>
                      </div>
                      <span class="progress-num-person">
                        <p>268</p>
                      </span>
                    </div>
                    <!--progress-single-block-->

                    <div class="progress-single-block">
                      <span class="progress-star-num">
                        <div class="color-box">2<span>★</span></div>
                      </span>
                      <div class="progress">
                        <div
                          class="progress-bar orange"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 20%"
                        ></div>
                      </div>
                      <span class="progress-num-person">
                        <p>73</p>
                      </span>
                    </div>
                    <!--progress-single-block-->

                    <div class="progress-single-block">
                      <span class="progress-star-num">
                        <div class="color-box">1<span>★</span></div>
                      </span>
                      <div class="progress">
                        <div
                          class="progress-bar orange"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 34%"
                        ></div>
                      </div>
                      <span class="progress-num-person">
                        <p>110</p>
                      </span>
                    </div>
                    <!--progress-single-block-->
                  </div>
                  <!--progressbar-outer-block-->
                  <div class="wave-btn-block">
                    <button
                      class="blob-btn fill-bg btn-lg mt-3"
                      data-bs-toggle="modal"
                      data-bs-target="#write-review"
                    >
                      Write a Review
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                    </button>
                    <SvgButton />
                  </div>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="write-review"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content">
                        <button
                          type="button"
                          class="btn-close close popup"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i class="icon-close" aria-hidden="true"></i>
                        </button>

                        <div class="modal-body p-4">
                          <h2 class="text-center">My Review</h2>
                          <div class="review-form">
                            <h5>Rate this product</h5>
                            <div id="half-stars-example">
                              <div class="rating-group">
                                <input
                                  class="rating__input rating__input--none"
                                  checked
                                  name="rating2"
                                  id="rating2-0"
                                  value="0"
                                  type="radio"
                                />
                                <label aria-label="0 stars" class="rating__label" for="rating2-0"
                                  >&nbsp;</label
                                >
                                <label
                                  aria-label="0.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-05"
                                  ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-05"
                                  value="0.5"
                                  type="radio"
                                />
                                <label aria-label="1 star" class="rating__label" for="rating2-10"
                                  ><i class="rating__icon rating__icon--star fa fa-star"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-10"
                                  value="1"
                                  type="radio"
                                />
                                <label
                                  aria-label="1.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-15"
                                  ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-15"
                                  value="1.5"
                                  type="radio"
                                />
                                <label aria-label="2 stars" class="rating__label" for="rating2-20"
                                  ><i class="rating__icon rating__icon--star fa fa-star"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-20"
                                  value="2"
                                  type="radio"
                                />
                                <label
                                  aria-label="2.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-25"
                                  ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-25"
                                  value="2.5"
                                  type="radio"
                                  checked
                                />
                                <label aria-label="3 stars" class="rating__label" for="rating2-30"
                                  ><i class="rating__icon rating__icon--star fa fa-star"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-30"
                                  value="3"
                                  type="radio"
                                />
                                <label
                                  aria-label="3.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-35"
                                  ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-35"
                                  value="3.5"
                                  type="radio"
                                />
                                <label aria-label="4 stars" class="rating__label" for="rating2-40"
                                  ><i class="rating__icon rating__icon--star fa fa-star"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-40"
                                  value="4"
                                  type="radio"
                                />
                                <label
                                  aria-label="4.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-45"
                                  ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-45"
                                  value="4.5"
                                  type="radio"
                                />
                                <label aria-label="5 stars" class="rating__label" for="rating2-50"
                                  ><i class="rating__icon rating__icon--star fa fa-star"></i
                                ></label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-50"
                                  value="5"
                                  type="radio"
                                />
                              </div>
                            </div>
                            <h5>Write Your reviews</h5>
                            <div class="form-floating mb-3">
                              <input
                                type="text"
                                class="form-control"
                                id="floatingInput"
                                placeholder="Name"
                              />
                              <label for="floatingInput">Name</label>
                            </div>
                            <div class="form-floating mb-3">
                              <input
                                type="text"
                                class="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                              />
                              <label for="floatingInput">Email</label>
                            </div>
                            <div class="form-floating mb-3">
                              <input
                                type="text"
                                class="form-control"
                                id="floatingInput"
                                placeholder="Review Title*"
                              />
                              <label for="floatingInput">Review Title</label>
                            </div>
                            <div class="form-floating">
                              <textarea
                                class="form-control review-comment"
                                placeholder="Leave a comment here"
                                id="floatingTextarea"
                              ></textarea>
                              <label for="floatingTextarea">Comments</label>
                            </div>
                            <div class="photo-video-btn-block mt-2">
                              <button type="button" class="btn btn-outline-primary btn-sm me-2">
                                Add Photo
                              </button>
                              <button type="button" class="btn btn-outline-primary btn-sm">
                                Add Video
                              </button>
                            </div>

                            <div class="form-check mt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label class="form-check-label" for="flexCheckDefault">
                                I agree to the
                                <a href="#" class="txt-bdr-link">terms &amp; conditions</a>
                              </label>
                            </div>
                            <p>
                              You may receive emails regarding this submission. Any emails will
                              include the ability to opt-out of future communications.
                            </p>
                            <div class="wave-btn-block text-center">
                              <button type="submit" class="blob-btn fill-bg btn-lg mt-3">
                                Post Review
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End of Modal -->
                </div>
                <div class="col-md-8">
                  <div class="review-listing-outer-block">
                    <div class="product-review-listing">
                      <ul>
                        <li>
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div id="half-stars-example">
                            <div class="rating-group">
                              <input
                                class="rating__input rating__input--none"
                                checked
                                name="rating2"
                                id="rating2-0"
                                value="0"
                                type="radio"
                              />
                              <label aria-label="0 stars" class="rating__label" for="rating2-0"
                                >&nbsp;</label
                              >
                              <label
                                aria-label="0.5 stars"
                                class="rating__label rating__label--half"
                                for="rating2-05"
                                ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-05"
                                value="0.5"
                                type="radio"
                              />
                              <label aria-label="1 star" class="rating__label" for="rating2-10"
                                ><i class="rating__icon rating__icon--star fa fa-star"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-10"
                                value="1"
                                type="radio"
                              />
                              <label
                                aria-label="1.5 stars"
                                class="rating__label rating__label--half"
                                for="rating2-15"
                                ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-15"
                                value="1.5"
                                type="radio"
                              />
                              <label aria-label="2 stars" class="rating__label" for="rating2-20"
                                ><i class="rating__icon rating__icon--star fa fa-star"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-20"
                                value="2"
                                type="radio"
                              />
                              <label
                                aria-label="2.5 stars"
                                class="rating__label rating__label--half"
                                for="rating2-25"
                                ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-25"
                                value="2.5"
                                type="radio"
                                checked
                              />
                              <label aria-label="3 stars" class="rating__label" for="rating2-30"
                                ><i class="rating__icon rating__icon--star fa fa-star"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-30"
                                value="3"
                                type="radio"
                              />
                              <label
                                aria-label="3.5 stars"
                                class="rating__label rating__label--half"
                                for="rating2-35"
                                ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-35"
                                value="3.5"
                                type="radio"
                              />
                              <label aria-label="4 stars" class="rating__label" for="rating2-40"
                                ><i class="rating__icon rating__icon--star fa fa-star"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-40"
                                value="4"
                                type="radio"
                              />
                              <label
                                aria-label="4.5 stars"
                                class="rating__label rating__label--half"
                                for="rating2-45"
                                ><i class="rating__icon rating__icon--star fa fa-star-half"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-45"
                                value="4.5"
                                type="radio"
                              />
                              <label aria-label="5 stars" class="rating__label" for="rating2-50"
                                ><i class="rating__icon rating__icon--star fa fa-star"></i
                              ></label>
                              <input
                                class="rating__input"
                                name="rating2"
                                id="rating2-50"
                                value="5"
                                type="radio"
                              />
                            </div>
                            Good Buy!
                          </div>

                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                          <div class="helpful-block d-flex justify-content-start">
                            Helpful?
                            <div class="like-dislike-btn">
                              <a href="#" class="like"
                                ><i class="icon-thumb-up" aria-hidden="true"></i>74</a
                              >
                              <a href="#" class="dislike"
                                ><i class="icon-thumb-down" aria-hidden="true"></i>10</a
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <h4><span>★★★★★</span> Nice Product &amp; Value for Money</h4>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            It snuggled in well in the Type C and the USB went in like better.
                            Smooth plug b play. No nonsense..
                          </p>
                          <div class="helpful-block d-flex justify-content-start">
                            Helpful?
                            <div class="like-dislike-btn">
                              <a href="#" class="like"
                                ><i class="icon-thumb-up" aria-hidden="true"></i>74</a
                              >
                              <a href="#" class="dislike"
                                ><i class="icon-thumb-down" aria-hidden="true"></i>10</a
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <h4><span>★★★★★</span> Good Buy!</h4>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                          <div class="helpful-block d-flex justify-content-start">
                            Helpful?
                            <div class="like-dislike-btn">
                              <a href="#" class="like"
                                ><i class="icon-thumb-up" aria-hidden="true"></i>74</a
                              >
                              <a href="#" class="dislike"
                                ><i class="icon-thumb-down" aria-hidden="true"></i>10</a
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> Thomas Mathew
                          </div>
                          <h4><span>★★★★★</span> Good Buy!</h4>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            It snuggled in well in the Type C and the USB went in like better.
                            Smooth plug b play. No nonsense..
                          </p>
                          <div class="helpful-block d-flex justify-content-start">
                            Helpful?
                            <div class="like-dislike-btn">
                              <a href="#" class="like"
                                ><i class="icon-thumb-up" aria-hidden="true"></i>74</a
                              >
                              <a href="#" class="dislike"
                                ><i class="icon-thumb-down" aria-hidden="true"></i>10</a
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'reviews-and-ratings',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    SvgButton,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss">
.reviews-and-ratings {
}
</style>
