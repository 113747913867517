<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="account-sidebar-menu">
    <div class="accounts-sidebar">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link :to="{ name: 'my-account' }">{{ $t('myAccount') }}</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'order-history' }">{{ $t('orderHistory') }}</router-link>
        </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'Wishlist' }">{{ $t('wishlist') }}</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'custom-playlist' }">{{ $t('playShieldPlus') }}</router-link>
        </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'recording' }">{{ $t('recording') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'library' }">{{ $t('library') }}</router-link>
        </li>
      </ul>
    </div>
    <div class="text-center py-4">
      <div class="wave-btn-block">
        <button type="submit" class="blob-btn fill-bg btn-lg submit-btn" @click="logout">
          {{ $t('logOut') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';

import CLayerBaseService from '@/services/CLayerBaseService';
import CLayerService from '@/services/CLayerService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const { cookies } = useCookies({ secure: true });
const cLayerBaseService = new CLayerBaseService();
const cLayerService = new CLayerService();

export default {
  name: 'account-sidebar',
  props: [],
  components: {
    Loader,
    SvgButton,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const id = cookies.get('clUserOwnerId');
    if (id) {
      store.dispatch('fetchOrderHistory', id.owner_id);
    }
    store.dispatch('fetchUserAddress');

    store.dispatch('fetchUserWishList', { limit: 10, offset: 0 });

    // store.dispatch('fetchCreativeStories', 0);

    // store.dispatch('fetchUserStoryShields');

    // store.dispatch('fetchUserPlayShields');

    const logout = () => {
      isLoading.value = true;
      localStorage.removeItem('storyPhoneUser');
      localStorage.removeItem('cLayerUserToken');
      cookies.remove('clUserAuthCookie');
      cookies.remove('storyPhoneUserAuthCookie');
      cookies.remove('orderIdCookie');
      cookies.remove('clUserOwnerId');
      cookies.remove('clAuthCookie');
      cookies.remove('orderIdCookie');
      cookies.remove('clUserAuthRefreshCookie');
      cookies.remove('storyPhoneUserRefreshCookie');
      cLayerBaseService.createToken()
        .then(() => {
          cLayerService.createOrderService()
            .then((createdOrder) => {
              router.push('/login');
              cookies.set('orderIdCookie', {
                orderId: createdOrder.id,
              });
              store.dispatch('fetchCartItems', createdOrder.id);
              isLoading.value = false;
            })
            .catch(() => {
              isLoading.value = false;
            });
        })
        .catch((err) => {
          isLoading.value = false;
        });
    };

    return { isLoading, logout };
  },
};
</script>

<style scoped lang="scss">
.account-sidebar-menu {
}
</style>
