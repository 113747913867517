import * as types from './mutation-types';

const mutations = {
  [types.HIDE_TITLE](state, value) {
    state.hideTitle = value;
  },
  [types.SHOW_UPLOAD_RECORD](state, value) {
    state.showUploadRecord = value;
  },
  [types.SHOW_RECORDING](state, value) {
    state.showRecording = value;
  },
  [types.SHOW_FILES](state, value) {
    state.showFiles = value;
  },
  [types.SHOW_ARTWORK](state, value) {
    state.showArtwork = value;
  },
  [types.SHOW_OVERVIEW](state, value) {
    state.showOverview = value;
  },
  [types.SHOW_LIBRARY](state, value) {
    state.showLibrary = value;
  },
};

export default mutations;
