<template lang="html">
  <PageHeader />

  <div v-if="Loading"><Loader /></div>
  <section class="reset-password account-section mt-stickybar mb-5 home">
    <div class="account-banner text-center"><img src="@/assets/images/signup.png" alt="" /></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block active-box mt-5">
            <h2 class="text-center mb-3">{{ $t('resetUrPass') }}</h2>
            <p class="text-center mb-4">{{ $t('newPasswordToReset') }}</p>
            <!-- <div v-if="isLoading && !successMessage && !failureMessage" class="text-center mb-3">
              <img src="../assets/images/loader.gif" alt="" />
            </div> -->
            <div>
              <h6 v-if="successMessage" class="mt-3 text-success">{{ this.successMessage }}</h6>
              <h6 v-if="failureMessage" class="mt-3 text-danger">
                {{ $t('failureMessage') }}
                <!-- The password reset link expired OR the password you have entered doesn't satisfy the
                validation rules mentioned below. -->
              </h6>
              <form class="mt-4">
                <div class="form-group">
                  <label for="password" class="text-muted">{{ $t('newPassword') }}</label>
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    aria-describedby="passwordHelp"
                    v-model="newPassword"
                  />
                  <div class="eye-pwd" @click="toggleShowPassword()">
                    <div v-if="showPassword">
                      <i class="icon-eye-Open" aria-hidden="true"></i>
                    </div>
                    <div v-if="!showPassword">
                      <i class="icon-eye-closed" aria-hidden="true"></i>
                    </div>
                  </div>
                  <small id="passwordHelp" class="form-text text-success">
                    {{ $t('passwordHelp') }}
                    <!-- Password should have minimum 8 characters, must also have alphabets and digits,
                    and should not be a common password like abcd123, 1234 etc. -->
                  </small>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-primary btn-lg submit-btn"
                    type="button"
                    @click="userResetPassword()"
                  >
                    {{ $t('done') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { mapActions, mapGetters } from 'vuex';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

const storyphoneService = new StoryphoneService();

export default {
  name: 'Verification',
  components: {
    Loader,
    PageHeader,
    PageFooter,
  },
  mounted() {
    this.uid = this.$route.params.uid;
    this.token = this.$route.params.token;
  },
  data() {
    return {
      isLoading: false,
      Loading: false,
      uid: '',
      token: '',
      newPassword: '',
      successMessage: '',
      failureMessage: '',
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      userActivationAction: 'userActivation',
    }),
    userResetPassword() {
      this.resetValues();
      this.Loading = true;
      const resetPasswordData = {
        newPassword: this.newPassword,
        uid: this.uid,
        token: this.token,
      };
      const failureMessage = 'Password validation rules not satisifed, Please check the help text below!';
      if (this.newPassword.length > 7) {
        storyphoneService.resetPassword(resetPasswordData)
          .then((result) => {
            this.Loading = false;
            this.resetValues();
            this.$router.push('/success-password');
            // this.successMessage = successMessage;
          })
          .catch((errors) => {
            this.Loading = false;
            this.resetValues();
            this.failureMessage = failureMessage;
          });
      } else {
        this.resetValues();
        this.failureMessage = failureMessage;
      }
    },
    toggleShowPassword() {
      const password = document.querySelector('#password');
      if (this.showPassword) {
        this.showPassword = false;
        password.setAttribute('type', 'password');
      } else {
        this.showPassword = true;
        password.setAttribute('type', 'text');
      }
    },
    resetValues() {
      this.isLoading = false;
      this.successMessage = false;
      this.failureMessage = false;
    },
  },
};
</script>
