import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class PageService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',

    };
  }

  getRefundPolicyContent() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/refundpolicy/`;
      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserManual() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/usermanual-en/`;
      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLicenseAgreementContent() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/licenseagreement-en/`;
      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAboutContent() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/about-en/`;
      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  contentManagementSystem(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/by-slug/${Data.slug}/widgets`;
      // const apiEndPoint = `${this.baseURL}v1/cms/widgets/${Data.code}/${Data.slug}/`;
      // const apiEndPoint = `${this.baseURL}v1/cms/pages/${Data.slug}/`;

      this.http.get(apiEndPoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'guest-token': this.guestToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
