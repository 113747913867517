<template lang="html">
  <PageHeader />
  <section class="marketplace-my-playlist mt-stickybar">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 col-lg-7">
                <MarketplaceSearch />
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="wave-btn-block text-end">
                  <button class="blob-btn fill-bg">
                    Edit Profile
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>

            <div class="marketplace-profile-outerblock">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <MarketplaceAccountSidebar />
                </div>
                <div class="col-md-8 col-lg-8">
                  <div class="row align-items-center mb-4">
                    <div class="col-md-4 col-lg-3">
                      <div class="profile-photo">
                        <img src="@/assets/images/profile-pic.jpg" alt="profile-pic" />
                      </div>
                    </div>
                    <div class="col-md-8 col-lg-9">
                      <div class="profile-brief">
                        <h3>
                          Naughty Luttappi
                          <span class="d-block">Born on February 07, 1992, Italy</span>
                        </h3>
                        <h5>Language proficiency</h5>
                        <ul class="list-unstyled">
                          <li>English,</li>
                          <li>Japaneese,</li>
                          <li>German,</li>
                          <li>Italik</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    Luttāppi, is a cute, innocent red coloured little imp who works as an aide of
                    Küttoosen. One of the primary sources of humor in the comic, Luttāppi is noted
                    for his cheekiness and cowardice. His stupidity and over excitedness often lands
                    everyone in trouble.
                  </p>
                  <p>
                    Luttāppi, is a cute, innocent red coloured little imp who works as an aide of
                    Küttoosen. One of the primary sources of humor in the comic, Luttāppi is noted
                    for his cheekiness and cowardice. His stupidity and over excitedness often lands
                    everyone in trouble.
                  </p>
                </div>
              </div>
            </div>
            <!-- marketplace-profile-outerblock -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import MarketplaceSearch from '@/components/partials/MarketplaceSearch.vue';
import MarketplaceAccountSidebar from '@/components/commons/MarketplaceAccountSidebar.vue';

export default {
  name: 'marketplace-creater-profile',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    SvgButton,
    MarketplaceSearch,
    MarketplaceAccountSidebar,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
