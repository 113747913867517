<template lang="html">
  <PageHeader />

  <section class="reset-password-email account-section mt-stickybar mb-5 home">
    <div class="account-banner text-center">
      <img src="@/assets/images/check-mail.png" alt="" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block mt-5">
            <h2 class="text-center mb-3">{{ $t('resetEmail') }}</h2>
            <p class="text-center mb-4">{{ $t('resetEmailMessage') }}.</p>

            <div class="text-center">
              <div class="wave-btn-block">
                <button
                  type="submit"
                  class="blob-btn fill-bg btn-lg submit-btn"
                  @click="backTologin"
                >
                  {{ $t('back') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import SvgButton from '@/components/partials/SvgButton.vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'reset-password-email',
  props: [],
  components: {
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const router = useRouter();
    const backTologin = () => {
      router.push('/login');
    };

    return { backTologin };
  },
};
</script>

<style scoped lang="scss"></style>
