<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <div v-if="this.orderData">
    <a @click="gotToDetails(this.orderDetails.id)" class="d-block">
      <div class="row align-items-center">
        <div class="col-md-3 col-lg-2">
          <div class="order-pic">
            <img :src="getImage(this.orderData[0])" alt="" />
          </div>
        </div>
        <div class="col-md-9 ol-lg-10 m-auto">
          <div class="row">
            <div class="col-8">
              <h6>{{ $t('order') }}: #{{ this.orderDetails.number }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <p>
                {{ $t('status') }}:
                {{ getStatus(this.orderDetails?.status, this.orderDetails?.fulfillment_status) }}
              </p>
            </div>
            <div class="col-8">
              <p>{{ $t('orderDate') }}: {{ this.orderDetails?.placed_at?.slice(0, 10) }}</p>
            </div>
            <div class="col-4">
              <p class="text-end">{{ $t('items') }}: {{ this.orderData.length }}</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script lang="js">

import { useRouter } from 'vue-router';
import { ref } from 'vue';
import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();

export default {
  name: 'OrderHistoryList',
  props: ['orderDetails'],
  components: {
    Loader,
  },

  setup(props) {
    const isLoading = ref(true);
    const router = useRouter();

    const orderData = ref([]);

    cLayerService.fetchCartItems(props.orderDetails.id)
      .then((data) => {
        isLoading.value = false;
        data.line_items.forEach((element) => {
          if (element.sku_code != null) {
            orderData.value.push(element);
          }
        });
      })
      .catch((error) => {
        isLoading.value = false;
      });

    const getImage = (order) => order?.image_url;

    const getStatus = (status, fulfillment) => {
      if (status === 'placed' && fulfillment === 'unfulfilled') {
        return 'Placed';
      }
      if (status === 'approved' && fulfillment === 'in_progress') {
        return 'Approved';
      }
      if (status === 'approved' && fulfillment === 'unfulfilled') {
        return 'Approved';
      }
      if (status === 'approved' && fulfillment === 'fulfilled') {
        return 'Dispatched';
      }
      return 'Cancelled';
    };

    const gotToDetails = (orderId) => {
      router.push({ name: 'order-history-details', params: { id: orderId } });
    };

    return {
      isLoading, orderData, getImage, getStatus, gotToDetails,
    };
  },

};
</script>

<style scoped lang="scss"></style>
