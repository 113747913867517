<template lang="html">
  <section class="story-bg">
    <div class="stroke-left-top">
      <div class="stroke-right-top">
        <div class="stroke-left-bottom">
          <div class="stroke-right-bottom">
            <div class="img-top">
              <img src="@/assets/images/disney.png" alt="Disney logo" width="130" />
            </div>
            <div class="disney-details">
              <h6>
                ©Disney <br />
                ©Disney/Pixar
              </h6>
            </div>
            <div class="container">
              <div class="row py-5">
                <div class="col-md-10 text-center m-auto">
                  <div class="heading-with-highlight" v-html="Details"></div>
                </div>
                <div class="magical-carousal-outer">
                  <ShieldCarouselListing />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';
import ShieldCarouselListing from '@/components/partials/ShieldCarouselListing.vue';

export default {
  name: 'StoriesCarousel',
  props: [],
  components: {
    ShieldCarouselListing,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_magical_world_story_${langCode}`,
    };
    store.dispatch('fetchCMS4', Data);

    const magicalWorld = computed(() => store.getters.getCMS4);
    const Details = computed(() => DOMPurify.sanitize(magicalWorld.value.html_description));

    return {
      magicalWorld, langCode, Details,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
