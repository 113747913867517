export default class RecorderTimer {
  constructor(callback, delay) {
    this.remaining = delay;
    this.callback = callback;
    this.timerId = undefined;
    this.start = undefined;
    this.resume();
  }

  pause() {
    window.clearTimeout(this.timerId);
    this.timerId = null;
    this.remaining -= Date.now() - this.start;
  }

  cancel() {
    window.clearTimeout(this.timerId);
  }

  resume() {
    if (this.timerId) {
      return;
    }

    this.start = Date.now();
    this.timerId = window.setTimeout(this.callback, this.remaining);
  }
}
