<template lang="html">
  <section class="marketplace-search-component">
    <div class="product-search library-search">
      <input
        class="search-filed form-control"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <button class="btn product-search-btn border-0" type="submit" @click="onSubmit()">
        <i class="icon-search" aria-hidden="true"></i>
      </button>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'marketplace-search',
  props: [],
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
