import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

export default {
  state() {
    return {
      hideTitle: false,
      showUploadRecord: false,
      showRecording: false,
      showFiles: false,
      showArtwork: false,
      showOverview: false,
      showLibrary: false,
    };
  },
  mutations,
  actions,
  getters,
};
