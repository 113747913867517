<template lang="html">
  <PageHeader />
  <section class="playshield-title mt-stickybar mb-5 home">
    <div class="inner-page-content pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="grey-outer-block">
              <ul id="wizard-progressbar">
                <li id="shield-title" class="active"><span>1</span>{{ $t('title') }}</li>
                <li id="recording" :class="{ active: firstStep }">
                  <span>2</span>{{ $t('uploadRecord') }}
                </li>
                <li id="recording" :class="{ active: secondStep }">
                  <span>3</span>{{ $t('audioList') }}
                </li>
                <li id="artwork" :class="{ active: thirdStep }">
                  <span>4</span>{{ $t('selectArtwork') }}
                </li>
                <li id="overview" :class="{ active: fourthStep }">
                  <span>5</span>{{ $t('Overview') }}
                </li>
              </ul>

              <div class="playshield-title-block" :class="{ hideTitle: hideTitle }">
                <h4 class="text-center">{{ $t('PlayShieldLable') }}</h4>
                <div class="row">
                  <div class="col-md-8 m-auto">
                    <div class="form-group">
                      <input
                        type="text"
                        id="shieldName"
                        v-model="form.playShieldName"
                        class="form-control"
                      />
                      <span class="text-danger pl-3" v-if="v$.playShieldName.$error">
                        <!-- {{ v$.playShieldName.$errors[0].$message }}* -->
                        {{ $t('errorMessage', { text: $t('title') }) }}*
                      </span>
                    </div>
                    <div class="d-flex justify-content-between mt-4">
                      <div class="wave-btn-block">
                        <router-link to="/custom-playlist" class="blob-btn fill-bg btn-lg">
                          {{ $t('back') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                        </router-link>
                        <SvgButton />
                      </div>
                      <div class="wave-btn-block">
                        <button class="blob-btn fill-bg btn-lg" @click="goToNext">
                          {{ $t('next') }}
                          <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                              <span class="blob-btn__blob"></span>
                            </span>
                          </span>
                        </button>
                        <SvgButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RecordingAndUpload />
              <LibrarySelection />
              <RecordingList />
              <Recording />
              <SelectArtwork />
              <Overview v-if="form.playShieldName" :shield-name="form.playShieldName" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import {
  required, minLength, helpers, sameAs,
} from '@vuelidate/validators';
import { useCookies } from 'vue3-cookies';
import {
  ref, computed, reactive, onUnmounted,
} from 'vue';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import Recording from '@/components/partials/Recording.vue';
import SelectArtwork from '@/components/partials/SelectArtwork.vue';
import Overview from '@/components/partials/Overview.vue';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import RecordingAndUpload from '@/components/partials/RecordingAndUpload.vue';
import RecordingList from '@/components/partials/RecordingList.vue';
import LibrarySelection from '@/components/partials/LibrarySelection.vue';

const { cookies } = useCookies({ secure: true });
const storyphoneService = new StoryphoneService();

let realFileBtn;
let realFileBtn2;

export default {
  name: 'playshield-plus',
  props: [],
  components: {
    Recording,
    SelectArtwork,
    Overview,
    PageHeader,
    PageFooter,
    SvgButton,
    RecordingAndUpload,
    RecordingList,
    LibrarySelection,
  },
  setup() {
    const store = useStore();

    const form = reactive({
      playShieldName: '',
    });

    const hideTitle = computed(() => store.getters.isHideTitle);

    const firstStep = computed(() => store.getters.isFirstStep);

    const secondStep = computed(() => store.getters.isSecondStep);

    const thirdStep = computed(() => store.getters.isThirdStep);

    const fourthStep = computed(() => store.getters.isFourthStep);

    const rules = {
      playShieldName: {
        required: helpers.withMessage('You must enter a title', required),
      },
    };

    const v$ = useVuelidate(rules, form);

    onUnmounted(() => {
      store.dispatch('isHideTitle', false);
      store.dispatch('inFirstStep', false);
      store.dispatch('inSecondStep', false);
      store.dispatch('inThirdStep', false);
      store.dispatch('inFourthStep', false);
      store.dispatch('isShowUploadRecord', false);
      store.dispatch('isShowRecording', false);
      store.dispatch('isShowFiles', false);
      store.dispatch('isShowArtwork', false);
      store.dispatch('isShowOverview', false);
    });

    const goToNext = async () => {
      const result = await v$.value.playShieldName.$validate();
      if (result) {
        store.dispatch('isHideTitle', true);
        store.dispatch('isShowUploadRecord', true);
        store.dispatch('inFirstStep', true);
      } else {
        console.log(v$);
      }
    };

    return {
      form, goToNext, hideTitle, v$, firstStep, secondStep, thirdStep, fourthStep,
    };
  },

};
</script>

<style scoped lang="scss"></style>
