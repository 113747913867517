<template lang="html">
  <div v-if="userManualAndroid?.widgets" class="row">
    <div class="col-md-9 col-lg-7 col-xl-6 m-auto">
      <div id="userManualAndroid" class="carousel slide" data-bs-ride="carousel">
        <div
          class="carousel-inner"
          v-for="(item, index) in userManualAndroid?.widgets"
          :key="index"
        >
          <div
            class="carousel-item"
            v-for="(details, index) in item.details"
            :key="index"
            :class="{ active: index === 0 }"
          >
            <div class="">
              <!-- <h5 class="mb-3">{{ details.title }}</h5> -->
              <div class="mb-3" v-html="domSanitize(details.title)"></div>

              <a
                v-if="index === 0"
                href="https://play.google.com/store/apps/details?id=com.onanoff.storyphones"
                target="_blank"
              >
                <img class="" :src="details.image" alt="storytelling image" width="250" />
              </a>
              <img v-else class="" :src="details.image" alt="storytelling image" width="250" />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#userManualAndroid"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#userManualAndroid"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="6"
            aria-label="Slide 7"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="7"
            aria-label="Slide 8"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="8"
            aria-label="Slide 9"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="9"
            aria-label="Slide 10"
          ></button>
          <button
            type="button"
            data-bs-target="#userManualAndroid"
            data-bs-slide-to="10"
            aria-label="Slide 11"
          ></button>
        </div>
      </div>
      <!-- <div class="container"> -->
      <!-- <div class="row"> -->
      <!-- <div
        v-for="(item, index) in userManualAndroid?.widget_details"
        :key="index"
        class="col-sm-12"
      >
        <Carousel :wrap-around="true" :touchDrag="true" class="user-manual-slider">
          <Slide v-for="(details, index) in item.meta_information" :key="index">
            <div class="carousel__item app-workflow-ph">
              <h5 class="mb-3">{{ details.title }}</h5>
              <a
                v-if="index === 0"
                href="https://play.google.com/store/apps/details?id=com.onanoff.storyphones"
                target="_blank"
              >
                <img class="" :src="details.image" alt="storytelling image" width="250" />
              </a>
              <img v-else class="" :src="details.image" alt="storytelling image" width="250" />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div> -->
    </div>
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import {
  Carousel, Pagination, Slide, Navigation,
} from 'vue3-carousel';
import DOMPurify from 'dompurify';

export default {
  name: 'user-manual-android',

  components: {
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  setup() {
    const store = useStore();
    const LangCode = localStorage.getItem('LangCode');
    const initialSlide = ref(0);
    const android = {
      code: LangCode,
      slug: `usermanual_android_${LangCode}`,
    };
    store.dispatch('fetchUserManualAndroid', android);

    const userManualAndroid = computed(() => store.getters.getUserManualAndroid);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return { userManualAndroid, domSanitize };
  },
};
</script>

<style></style>
