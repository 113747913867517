<template lang="html">
  <PageHeader />
  <section class="account-settings order-history mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="account-sidebar">
            <div class="accounts-left-block d-none d-md-block">
              <h2>Welcome Back!</h2>
              <div class="account-sidebar-menu">
                <div class="accounts-sidebar">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a href="#" class="">My Profile</a>
                    </li>
                    <li class="nav-item">
                      <a href="/orderhistory-payment" class="active">Order History</a>
                    </li>
                    <li class="nav-item">
                      <a href="/manageshop">ManageShop</a>
                    </li>
                    <li class="nav-item">
                      <a href="/mystory-content" class="">My story</a>
                    </li>
                  </ul>
                </div>
                <div class="text-center py-4">
                  <div class="wave-btn-block">
                    <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                      Log out
                      <span class="blob-btn__inner"
                        ><span class="blob-btn__blobs"
                          ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                          ><span class="blob-btn__blob"></span
                          ><span class="blob-btn__blob"></span></span
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="accounts-left-block mobile-sidebar d-md-none">
              <a
                class="btn-profile-settings collapsed"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Profile settings
              </a>
              <div class="collapse" id="collapseExample" style="">
                <!--v-if-->
                <div class="account-sidebar-menu">
                  <div class="accounts-sidebar">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <a href="#" class="">My Profile</a>
                      </li>
                      <li class="nav-item">
                        <a href="/manageshop" class="active">Order History</a>
                      </li>
                      <li class="nav-item">
                        <a href="/manageshop" class="">ManageShop</a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="">My story</a>
                      </li>
                    </ul>
                  </div>
                  <div class="text-center py-4">
                    <div class="wave-btn-block">
                      <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                        Log out
                        <span class="blob-btn__inner"
                          ><span class="blob-btn__blobs"
                            ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span></span
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <div class="header">
                <h3>
                  <div class="row">
                    <div class="col-sm-7" id="mq-heading">My payment</div>
                    <div class="col-sm-5 px-4" id="mq-heading">
                      Total Earnings
                      <button
                        type="button"
                        class="btn bg-white border-dark rounded-5 px-3 pt-1 pb-0 fs-6"
                      >
                        $5003
                      </button>
                    </div>
                  </div>
                </h3>
              </div>
              <div class="container mb-5 pb-5 px-4">
                <div class="row">
                  <div class="col-sm-10 d-flex">
                    <button type="button" class="btn bg-info px-3 text-white mt-5">
                      PAST ORDER
                    </button>
                    <button type="button" class="btn bg-light px-3 border-0 mt-5">
                      UPCOMING ORDER
                    </button>
                  </div>
                </div>
                <div class="border-block rounded-5 bg-lite mt-4">
                  <h3 class="nd bg-warning fs-5">ORDER DATE: 20 JULY 2022</h3>
                  <div class="slider text-center">
                    <ul class="slider-img d-md-flex d-lg-flex list-unstyled px-sm-3">
                      <li class="justify-content-center d-flex">
                        <img
                          class="around rounded-circle p-3"
                          src="@/assets/images/marketplace/amari.png"
                          alt="amari"
                        />
                      </li>

                      <li class="justify-content-center d-flex">
                        <img
                          class="around rounded-circle p-3"
                          src="@/assets/images/marketplace/lastfallenmoon.png"
                          alt="lastfallenmoon"
                        />
                      </li>
                      <li class="justify-content-center d-flex">
                        <img
                          class="around rounded-circle p-3"
                          src="@/assets/images/marketplace/lionofmars.png"
                          alt="lionofmars"
                        />
                      </li>
                      <li class="justify-content-center d-flex">
                        <img
                          class="around rounded-circle p-3"
                          src="@/assets/images/marketplace/stella.png"
                          alt="stella"
                        />
                      </li>
                      <button type="button" class="btn bg-body border-0 m-4 p-2">
                        <img
                          class="rounded-circle"
                          src="@/assets/images/marketplace/rightarrow.svg"
                          alt="r-arrow"
                        />
                      </button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </section>

  <!-- <div>
    <section class="account-settings order-history mt-stickybar">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 col-12">

            <section class="account-sidebar">
              <div class="accounts-left-block d-none d-md-block">
                <h2>Welcome Back!</h2>

                <section class="account-sidebar-menu">
                  <div class="accounts-sidebar">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <a href="#">My profile</a>
                      </li>
                      <li class="nav-item">
                        <a href="/orderhistory-payment">Order History</a>
                      </li>
                      <li class="nav-item">
                        <a href="/manageshop">Manage Shop</a>
                      </li>
                      <li class="nav-item">
                        <a href="/mystory-content">My story</a>
                      </li>
                    </ul>
                  </div>
                  <div class="text-center py-4">
                    <div class="wave-btn-block">
                      <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                        Log out
                        <span class="blob-btn__inner"
                          ><span class="blob-btn__blobs"
                            ><span class="blob-btn__blob"></span><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span
                            ><span class="blob-btn__blob"></span></span
                        ></span>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="accounts-left-block mobile-sidebar d-md-none d-lg-none"
                id="profile-setting"
              >
                <a
                  class="btn-profile-settings collapsed"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Profile settings
                </a>
                <div class="collapse" id="collapseExample" style="">

                  <section class="account-sidebar-menu">
                    <div class="accounts-sidebar">
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <a href="#">My profile</a>
                        </li>
                        <li class="nav-item">
                          <a href="/orderhistory-payment">Order History</a>
                        </li>
                        <li class="nav-item">
                          <a href="/manageshop">Manage Shop</a>
                        </li>
                        <li class="nav-item">
                          <a href="/mystory-content">My story</a>
                        </li>
                      </ul>
                    </div>
                    <div class="text-center py-4">
                      <div class="wave-btn-block">
                        <button type="submit" class="blob-btn fill-bg btn-lg submit-btn">
                          Log out
                          <span class="blob-btn__inner"
                            ><span class="blob-btn__blobs"
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span
                              ><span class="blob-btn__blob"></span></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

          </div>
          <div class="col-sm-8 col-12" id="mq-txt">
            <div class="accounts-content">
              <div class="toppoints">
                <div class="row">
                  <div class="col-sm-6 bg-secondary pt-3 fs-3">
                    My payment
                     <h3 class="d-flex">
                  My payment
                  <p class="fs-5 te">Total Earnings</p>

                </h3>
                  </div>
                  <div class="col-sm-6 bg-secondary pt-2 px-4 fs-4">
                    Total Earnings
                    <button
                      type="button"
                      class="btn bg-white border-dark rounded-5 px-4 mx-3 pb-0 mb-2"
                    >
                      $5003
                    </button>
                  </div>
                </div>

                <div class="container mb-5 pb-5 px-4">
                  <div class="row">
                    <div class="col-sm-10 d-flex">
                      <button type="button" class="btn bg-info px-3 text-white mt-5">
                        PAST ORDER
                      </button>
                      <button type="button" class="btn bg-light px-3 border-0 mt-5">
                        UPCOMING ORDER
                      </button>
                    </div>
                  </div>
                  <div class="border-block rounded-5 bg-lite mt-4">
                    <h3 class="nd bg-warning fs-5">ORDER DATE: 20 JULY 2022</h3>
                    <div class="slider">
                      <ul class="slider-img d-md-flex d-lg-flex list-unstyled px-sm-3">
                        <li>
                          <img
                            class="around rounded-circle p-3"
                            src="@/assets/images/marketplace/amari.png"
                            alt="amari"
                          />
                        </li>

                        <li>
                          <img
                            class="around rounded-circle p-3"
                            src="@/assets/images/marketplace/lastfallenmoon.png"
                            alt="lastfallenmoon"
                          />
                        </li>
                        <li>
                          <img
                            class="around rounded-circle p-3"
                            src="@/assets/images/marketplace/lionofmars.png"
                            alt="lionofmars"
                          />
                        </li>
                        <li>
                          <img
                            class="around rounded-circle p-3"
                            src="@/assets/images/marketplace/stella.png"
                            alt="stella"
                          />
                        </li>
                        <button type="button" class="btn bg-body border-0 m-4 p-2">
                          <img
                            class="rounded-circle"
                            src="@/assets/images/marketplace/rightarrow.svg"
                            alt="r-arrow"
                          />
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   </div>  -->
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'OrderHistoryMypayment',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  mounted() {

  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
  },
};
</script>
