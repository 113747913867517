export const HIDE_SHIPPING = 'HIDE_SHIPPING';
export const SHOW_BILLING = 'SHOW_BILLING';
export const SHOW_PREVIEW = 'SHOW_PREVIEW';
export const SHOW_PAYNOW = 'SHOW_PAYNOW';
export const HIDE_BUTTON = 'HIDE_BUTTON';
export const SHIPPING_ADDRESS = 'SHIPPING_ADDRESS';
export const BILLING_ADDRESS = 'BILLING_ADDRESS';
export const PAYMENT_SOURCE = 'PAYMENT_SOURCE';
export const HIDE_CARTBUTTONS = 'HIDE_CARTBUTTONS';
export const GET_SUCCESS_MESSAGE = 'GET_SUCCESS_MESSAGE';
