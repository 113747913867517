<template lang="html">
  <PageHeader />
  <section class="mt-stickybar home">
    <div class="shield-banner">
      <carousel :items-to-show="1" :autoplay="4000" :wrap-around="true">
        <slide :key="1">
          <div class="disney-logo-outer">
            <div class="disney-logo">
              <img src="@/assets/images/disney.png" alt="Disney logo" />
            </div>
            <div class="disney-details">
              <h6>
                ©Disney <br />
                ©Disney/Pixar
              </h6>
            </div>
            <img
              src="@/assets/images/product-banner.png"
              alt="product-banner"
              class=" w-100"
            />
          </div>
        </slide>
        <slide :key="2">
          <img
            src="@/assets/images/banners-pinna-2.jpeg"
            class=" d-block w-100"
            alt="banners-pinna"
          />
        </slide>

        <template #addons>
          <!-- <navigation /> -->
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>
    <!-- <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="disney-logo-outer">
            <div class="disney-logo">
              <img src="@/assets/images/disney.png" alt="Disney logo" />
            </div>
            <div class="disney-details">
              <h6>
                ©Disney <br />
                ©Disney/Pixar
              </h6>
            </div>
            <img src="@/assets/images/product-banner.png" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div> -->
  </section>
  <section class="mb-5">
    <div class="container">
      <div class="row">
        <div>
        <div class="col-md-12 mt-5">
          <div class="category-heading mb-3">
            <h3>{{ $t('whatNew') }}</h3>
          </div>
          <ShieldListing category-id="" class="mt-4" />
        </div>
        <!-- <div class="col-md-12 mt-5 ">
          <div class="category-heading mb-3">
            <h3 >Coming Soon...</h3>
          </div>
          <ComingSoonShields class="mt-4" />
        </div> -->
      </div>
        <div class="col-md-12">
          <div v-if="categoryList.items">
            <div v-for="category in categoryList.items" :key="category.id" class="mt-5">
              <div class="category-heading">
                <h3>{{ category.title }}</h3>
                <div class="wave-btn-block see-all">
                  <a
                    class="blob-btn btn-lg submit-btn"
                    @click="gotToSeeAll(category.parent ? category.parent : category.id)"
                    >{{ $t('seeAll') }}
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </a>
                  <SvgButton />
                </div>
              </div>
              <ShieldListing :category-id="category.parent ? category.parent : category.id" class="mt-4" />
            </div>
          </div>
          <div v-if="page === pageCount" class="mt-5">
            <div class="category-heading">
              <h3>ZenShield</h3>
            </div>
            <ul class="list-unstyled story-categories">
              <li v-for="(item, index) in finalArray" :key="index" class="text-center">
                <a
                  class="p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#product-details-popup"
                  @click="shieldClick(item)"

                >
                  <ShieldListingItem
                    :shieldData="item"
                    :shieldSku="priceDetails[index]"

                  />
                </a>
                <ShieldListingButton
                  :shieldData="item"
                  :shieldSku="priceDetails[index]"

                />
              </li>
            </ul>
          </div>
          <section class="shields-banner">
            <div class="text-center">
              <!-- href="/shields-playShields" -->
              <a data-bs-toggle="modal" data-bs-target="#popupplayshield">
                <!-- <img :src="shieldBanner?.images[1]?.image_url" alt="" class="w-100" /> -->
              </a>
            </div>
          </section>
          <PopupPlayShield />
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="5"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="shields-banner">
    <div class="w-100">
      <a href="/shields-playShields">
        <img src="@/assets/images/shieldFooter.png" alt="" />
      </a>
    </div>
  </section> -->
  <PageFooter />
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useCookies } from 'vue3-cookies';
import {
  Carousel, Navigation, Slide,
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import DownloadApp from '@/components/partials/DownloadApp.vue';
import CategoryCarousel from '@/components/partials/CategoryCarousel.vue';
import ShieldListing from '@/components/partials/ShieldListing.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import ShieldListingItem from '@/components/partials/ShieldListingItem.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';
import PopupPlayShield from '@/components/partials/PopupPlayShield.vue';
import NewContentfulService from '@/services/NewContentfulService';
import ComingSoonShields from '@/components/partials/ComingSoonShields.vue';
import CLayerService from '@/services/CLayerService';

const { cookies } = useCookies({ secure: true });
const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'Shields',
  components: {
    ShieldListing,
    PageHeader,
    PageFooter,
    SvgButton,
    VuejsPaginateNext,
    ShieldListingItem,
    ShieldListingButton,
    PopupPlayShield,
    // ComingSoonShields,
    Carousel,
    Slide,
  },
  setup() {
    const langCode = localStorage.getItem('LangCode');
    const categoryList = ref({});
    const user = ref({});
    const store = useStore();
    const router = useRouter();
    const page = ref(1);
    const perPage = ref(2);
    const currentOffset = ref(0);
    const pageCount = ref(0);
    const zenShield = ref([]);
    const playShield = ref({});
    const priceDetails = ref([]);
    const priceObject = ref({});
    const finalArray = ref([]);

    newContentfulService.fetchAllGenresIds(perPage.value, currentOffset.value, langCode).then((categories) => {
      categoryList.value = categories;
      newContentfulService.fetchZenShields().then((response) => {
        // eslint-disable-next-line prefer-destructuring
        zenShield.value = response.items;
        cLayerService.getShieldPriceList(response?.items[0]?.sku)
          .then((prices) => {
            zenShield.value.forEach((shield) => {
              priceObject.value = { ...prices[0], ...shield };
              finalArray.value.push(priceObject.value);
            });
            // priceDetails.value.push(prices[0]);
            pageCount.value = Math.ceil((categories.total + (finalArray.value[0].language === langCode ? 1 : 0)) / perPage.value);
          })
          .catch((error) => {
            console.log('error');
          });
      });
    });

    const zenShieldList = computed(() => store.getters.getZenShieldList);

    const shieldClick = (Data) => {
      store.dispatch('fetchSelectedShieldSku', Data.sku);
      // store.dispatch('fetchPlaylistDetail', Data.playlist);
      // store.dispatch('fetchGenresDetail', Data.genre);
    };

    const pageClick = (pageNum) => {
      currentOffset.value = perPage.value * (pageNum - 1);
      newContentfulService.fetchAllGenresIds(perPage.value, currentOffset.value, langCode).then((category) => {
        categoryList.value = category;
      });
      page.value = pageNum;

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    user.value = cookies.get('storyPhoneUserAuthCookie');
    if (user.value) {
      store.dispatch('fetchUserWishList', { limit: 0, offset: 0 });
    }

    const gotToSeeAll = (categoryId) => {
      router.push({ name: 'all-shields', params: { id: categoryId } });
    };
    const Data = {
      code: langCode,
      slug: `shield_footer_banner_${langCode}`,
    };
    store.dispatch('fetchCMS6', Data);

    const shieldBanner = computed(() => store.getters.getCMS6);

    const allCategoryList = computed(() => store.getters.getAllCategoryList);

    return {
      categoryList,
      user,
      gotToSeeAll,
      pageClick,
      pageCount,
      zenShieldList,
      shieldClick,
      zenShield,
      playShield,
      shieldBanner,
      allCategoryList,
      priceDetails,
      finalArray,
    };
  },

};
</script>
