<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="billing-details" :class="{ showBilling: showBilling }">
    <h5 class="mt-4 mb-3">{{ $t('billAds') }}</h5>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        v-model="useShippingAddress"
        value="yes"
        checked
        name="flexRadioDefault"
        id="flexRadioDefault1"
      />
      <label class="form-check-label" for="flexRadioDefault1"> {{ $t('sameShipAdd') }} </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        v-model="useShippingAddress"
        value="no"
        id="flexRadioDefault2"
      />
      <label class="form-check-label" for="flexRadioDefault2"> {{ $t('diffAdd') }} </label>
    </div>
    <div v-if="useShippingAddress == 'no'" class="">
      <h6>
        <!-- Please select an address or enter new address -->
        {{ $t('plzSelectAds') }}
      </h6>
      <div v-if="user">
        <div class="address-list-outer">
          <div class="address-list" v-for="(address, index) in userAddress" :key="address.id">
            <a
              @click="selectAddress(address.address, index)"
              :class="{ active: currentIndex === index }"
            >
              <span class="address-icon"><i class="icon-location" aria-hidden="true"></i></span>
              <p>
                {{ address.address.notes }}<br />
                {{ address.address.line_1 }} <br />
                {{ address.address.city }} {{ address.address.state_code }} ({{
                  address.address.country_code
                }})<br />
                {{ address.address.phone }}
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              v-model="billingAddress.firstName"
              :placeholder="$t('firstName')"
            />
            <label for="floatingInput">{{ $t('firstName') }}</label>
            <span class="text-danger pl-3" v-if="v$.firstName.$error">
              {{ $t('errorMessage', { text: $t('firstName') }) }}*
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              v-model="billingAddress.lastName"
              :placeholder="$t('lastName')"
            />
            <label for="floatingInput">{{ $t('lastName') }}</label>
            <span class="text-danger pl-3" v-if="v$.lastName.$error">
              {{ $t('errorMessage', { text: $t('lastName') }) }}*
            </span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            v-model="billingAddress.address1"
            :placeholder="$t('address')"
          />
          <label for="floatingInput">{{ $t('address') }}1</label>
          <span class="text-danger pl-3" v-if="v$.address1.$error">
            {{ $t('errorMessage', { text: $t('address') }) }}*
          </span>
        </div>
      </div>

      <div class="form-group">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            v-model="billingAddress.address2"
            :placeholder="$t('address')"
          />
          <label for="floatingInput">{{ $t('address') }}2</label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <!-- <div class="form-selection-outer">
            <div class="form-selection" v-if="!billingAddress.country">Select a Country</div>
          </div> -->
          <select
            class="form-select mb-3"
            v-model="billingAddress.country"
            aria-label="Default select example"
            @change="countrySelected()"
          >
            <option value="" v-if="!billingAddress.country">{{ $t('selectCountry') }}</option>
            <option
              v-for="(country, index) in countryList?.countryList?.data.items"
              :key="index"
              :value="country.country_code"
            >
              {{ country.name }}
            </option>
          </select>
          <span class="text-danger pl-3" v-if="v$.country.$error">
            {{ $t('selectMessage', { text: $t('country') }) }}*
          </span>
        </div>
        <div class="col-md-6">
          <select
            class="form-select mb-3"
            v-model="billingAddress.state"
            aria-label="Default select example"
          >
            <option value="" v-if="!billingAddress.state">{{ $t('selectState') }}</option>
            <option v-for="state in stateList" :key="state.code" :value="state.state_code">
              {{ state.name }}
            </option>
          </select>
          <span class="text-danger pl-3" v-if="v$.state.$error">
            {{ $t('selectMessage', { text: $t('state') }) }}*
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              v-model="billingAddress.city"
              :placeholder="$t('city')"
            />
            <label for="floatingInput">{{ $t('city') }}</label>
            <span class="text-danger pl-3" v-if="v$.city.$error">
              {{ $t('errorMessage', { text: $t('city') }) }}*
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              v-model="billingAddress.zipCode"
              :placeholder="$t('zipCode')"
            />
            <label for="floatingInput">{{ $t('zipCode') }}</label>
            <span class="text-danger pl-3" v-if="v$.zipCode.$error">
              {{ $t('errorMessage', { text: $t('zipCode') }) }}*
            </span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            v-model="billingAddress.phoneNumber"
            :placeholder="$t('phoneNumber')"
          />
          <label for="floatingInput">{{ $t('phoneNumber') }}</label>
          <span class="text-danger pl-3" v-if="v$.phoneNumber.$error">
            {{ $t('errorMessage', { text: $t('phoneNumber') }) }}*
          </span>
        </div>
      </div>
    </div>

    <div
      class="success-submit-box modal fade mt-3 d-none"
      :class="{ addsuccess: isAdded, show: isAdded }"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 p-3">
          <div class="modal-body">
            <div class="popup-msg-box">
              <p>
                <!-- Oops! We currently do not ship to your location. Please go back and provide another
                shipping address. -->
                {{ $t('oopsLocation') }}
              </p>
            </div>
          </div>
          <div class="text-center">
            <button
              class="btn btn-primary btn-lg submit-btn mb-3"
              data-dismiss="modal"
              @click="closePopup()"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="goToShipping">
          {{ $t('back') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="updateBillingAddress">
          {{ $t('continue') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  watch, ref, computed, reactive,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, helpers, alpha,
} from '@vuelidate/validators';
import { useCookies } from 'vue3-cookies';

import CLayerService from '@/services/CLayerService';
import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';

const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'billing-details',
  props: [],
  components: {
    Loader,
    SvgButton,
  },
  setup() {
    const store = useStore();
    const state = ref('');
    const currentIndex = ref('');
    const country = ref('');
    const stateList = ref([]);
    const isLoading = ref(false);
    const user = ref({});
    const isAdded = ref(false);
    const useShippingAddress = ref('yes');
    const updateShippingMethod = reactive({
      shipmentId: '',
      shippingMethodId: '',
    });

    const paymentSource = ref({});
    const availableShippingMethods = ref({});
    const shippingMethodAndLeadTimes = ref([]);

    store.dispatch('fetchAvailableCountries');

    const { orderId } = cookies.get('orderIdCookie');

    const showBilling = computed(() => store.getters.isShowBilling);

    watch(useShippingAddress.value);

    user.value = cookies.get('clUserAuthCookie');
    if (user.value) {
      store.dispatch('fetchUserAddress');
      store.dispatch('fetchUserDetails');
    }
    const userAddress = computed(() => store.getters.getUserAddress);

    const billingAddress = reactive({
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      phoneNumber: '',
    });
    const countryList = computed(() => store.getters.getCountryList);
    const shippingMethods = computed(() => store.getters.getshippingMethods);
    const deliveryLeadTime = computed(() => store.getters.getdeliveryLeadTime);

    const rules = {
      email: { required: helpers.withMessage('You must enter your email address', required), email },
      firstName: { required: helpers.withMessage('You must enter your first name', required) },
      lastName: { required: helpers.withMessage('You must enter your last name', required) },
      address1: { required: helpers.withMessage('You must enter your address', required) },
      city: { required: helpers.withMessage('You must enter your city', required) },
      state: { required: helpers.withMessage('You must select your state', required) },
      country: { required: helpers.withMessage('You must select your country', required) },
      zipCode: { required: helpers.withMessage('You must enter your zipCode', required) },
      phoneNumber: { required: helpers.withMessage('You must enter your phone number', required) },
    };

    const v$ = useVuelidate(rules, billingAddress);

    const stateSelected = () => {
      state.value = '';
    };

    const countrySelected = () => {
      storyphoneService.fetchAvailableStates(billingAddress.country)
        .then((states) => {
          stateList.value = states.data.items;
        }).catch((stateError) => {
          stateList.value = {};
        });
    };

    const selectAddress = (address, index) => {
      currentIndex.value = index;
      const userDetails = computed(() => store.getters.getUserDetails);
      storyphoneService.fetchStateByCode(address.country_code, address.state_code)
        .then((result) => {
          state.value = result.data.name;
        });
      billingAddress.email = userDetails.value.email;
      billingAddress.firstName = address.first_name;
      billingAddress.lastName = address.last_name;
      billingAddress.address1 = address.line_1;
      billingAddress.address2 = address.line_2;
      billingAddress.city = address.city;
      billingAddress.state = address.state_code;
      billingAddress.country = address.country_code;
      billingAddress.zipCode = address.zip_code;
      billingAddress.phoneNumber = address.phone;
      countrySelected();
    };

    const createPaymentSource = () => {
      cLayerService.getPaymentMethods(orderId)
        .then((paymentMethods) => {
          const paymentMethodId = paymentMethods[0].id;
          cLayerService.updatePaymentMethods(orderId, paymentMethodId)
            .then((updatedPaymentMethods) => {
              cLayerService.createPaymentSource(orderId)
                .then((payment) => {
                  store.dispatch('isShowBilling', false);
                  store.dispatch('isShowPreview', true)
                    .then(() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    });
                  store.dispatch('inSecondStep', true);
                  paymentSource.value = payment;
                  store.dispatch('fetchPaymentSource', paymentSource.value);
                  isLoading.value = false;
                })
                .catch(() => {
                  isLoading.value = false;
                });
            })
            .catch(() => {
              isLoading.value = false;
            });
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const selectedShippingMethod = (id) => {
      cLayerService.updateShippingMethod(updateShippingMethod.shipmentId, id)
        .then((shippingMethod) => {
          store.dispatch('fetchCartItems', orderId);
          createPaymentSource();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchShippingMethod = () => {
      store.dispatch('fetchShippingMethods', orderId)
        .then((shipments) => {
          store.dispatch('fetchDeliveryLeadTime')
            .then((deliveryTime) => {
              availableShippingMethods.value = shippingMethods.value.shippingMethod[0].available_shipping_methods;
              shippingMethods.value.shippingMethod.forEach((stockLocation) => {
                deliveryLeadTime.value.deliveryLeadTime.forEach((leadTime) => {
                  if (stockLocation.stock_location.id === leadTime.stock_location.id) {
                    if (availableShippingMethods.value.length === 0) {
                      isAdded.value = true;
                    } else {
                      availableShippingMethods.value.forEach((methods) => {
                        const shippingMethodsTemp = {};
                        if (methods.id === leadTime.shipping_method.id) {
                          shippingMethodsTemp.shipment_id = stockLocation.id;
                          updateShippingMethod.shipmentId = stockLocation.id;
                          shippingMethodsTemp.shipping_method_id = methods.id;
                          shippingMethodsTemp.shipping_method_name = methods.name;
                          shippingMethodsTemp.min_hours = leadTime.min_hours;
                          shippingMethodsTemp.max_hours = leadTime.max_hours;
                          shippingMethodsTemp.min_days = leadTime.min_days;
                          shippingMethodsTemp.max_days = leadTime.max_days;
                          shippingMethodsTemp.price = `${methods.formatted_price_amount.slice(0, 1)} ${methods.price_amount_float}`;
                          shippingMethodAndLeadTimes.value.push(shippingMethodsTemp);
                        }
                      });
                    }
                  }
                });
              });
              selectedShippingMethod(shippingMethodAndLeadTimes.value[0].shipping_method_id);
            })
            .catch(() => {

            });
        });
    };

    const updateBillingAddress = async () => {
      shippingMethodAndLeadTimes.value = [];
      if (useShippingAddress.value === 'yes') {
        isLoading.value = true;
        const { shippingAddressId } = cookies.get('shippingAddressId');
        cLayerService.addAddressToOrder(orderId, shippingAddressId, shippingAddressId)
          .then((addressAdded) => {
            const shippingAddress = computed(() => store.getters.getShippingAddress);
            store.dispatch('fetchBillingAddress', shippingAddress.value);
            fetchShippingMethod();
          })
          .catch(() => {

          });
      } else {
        const billingAddressParam = {
          first_name: billingAddress.firstName,
          last_name: billingAddress.lastName,
          line_1: billingAddress.address1,
          line_2: billingAddress.address2,
          city: billingAddress.city,
          country_code: billingAddress.country,
          state_code: billingAddress.state,
          phone: billingAddress.phoneNumber,
          zip_code: billingAddress.zipCode,
        };
        const result = await v$.value.$validate();
        if (result) {
          isLoading.value = true;
          store.dispatch('fetchBillingAddress', billingAddressParam);
          cLayerService.createAddress(billingAddressParam)
            .then((response) => {
              const { shippingAddressId } = cookies.get('shippingAddressId');
              cLayerService.addAddressToOrder(orderId, shippingAddressId, response.id)
                .then((addressAdded) => {
                  fetchShippingMethod();
                })
                .catch(() => {

                });
            })
            .catch(() => {

            })
            .finally(() => {

            });
        } else {
          console.log(v$);
        }
      }
    };

    const closePopup = () => {
      isAdded.value = false;
    };

    const goToShipping = () => {
      store.dispatch('isHideShipping', false);
      store.dispatch('isShowBilling', false);
      store.dispatch('buttonHide', false);
      store.dispatch('inFirstStep', false);
    };

    return {
      state, stateList, isLoading, closePopup, isAdded, paymentSource, updateShippingMethod, useShippingAddress, shippingMethodAndLeadTimes, availableShippingMethods, billingAddress, goToShipping, showBilling, currentIndex, user, v$, userAddress, countryList, selectAddress, countrySelected, updateBillingAddress,
    };
  },
};
</script>

<style scoped lang="scss"></style>
