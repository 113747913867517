<template lang="html">
  <section class="py-8 adv-storyphone">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="heading-with-highlight" v-html="Details"></div>
        </div>
        <div class="col-md-6">
          <div class="adventure-main">
            <div v-for="(video, index) in adventures?.videos" :key="index" class="mini-video-block">
              <button class="video-play-btn" @click="playAdventureVideo(video.video_url)"></button>
              <video id="adventure" class="w-100" width="100%" autoplay="autoplay" loop="loop">
                <source :src="video.video_url" type="video/mp4" />
              </video>
            </div>
            <div class="adventure-video-pic">
              <img src="@/assets/images/aduntitled.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

let adventureVideo;

export default {
  name: 'StoryphonesAdventure',
  components: {},
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_adventure_${langCode}`,
      // slug: 'homeadventureen',
    };
    store.dispatch('fetchCMS9', Data);

    const adventures = computed(() => store.getters.getCMS9);
    const Details = computed(() => DOMPurify.sanitize(adventures.value.html_description));

    return {
      adventures,
      langCode,
      Details,
    };
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    playAdventureVideo(src) {
      adventureVideo = document.getElementById('adventure');
      adventureVideo.play();
    },
  },
  computed: {},
};
</script>

<style></style>
