<template lang="html">
  <section class="order-preview" :class="{ showPreview: showPreview }">
    <h5 class="mb-3">{{ $t('shipAds') }}</h5>
    <div class="row">
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('firstName') }}</h6>
        <p>{{ shippingAddress.first_name }}</p>
      </div>
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('lastName') }}</h6>
        <p>{{ shippingAddress.last_name }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('address') }}</h6>
        <p>
          {{ shippingAddress.line_1 }}
          {{ shippingAddress.city }} {{ shippingAddress.state_code }} ({{
            shippingAddress.country_code
          }})
        </p>
      </div>
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('phoneNumber') }}</h6>
        <p>{{ shippingAddress.phone }}</p>
      </div>
    </div>
    <h5 class="mb-3">{{ $t('billInf') }}</h5>
    <div class="row">
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('firstName') }}</h6>
        <p>{{ billingAddress.first_name }}</p>
      </div>
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('lastName') }}</h6>
        <p>{{ billingAddress.last_name }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('address') }}</h6>
        <p>
          {{ billingAddress.line_1 }} {{ billingAddress.city }} {{ billingAddress.state_code }} ({{
            billingAddress.country_code
          }})
        </p>
      </div>
      <div class="col-md-6">
        <h6 class="mb-1">{{ $t('phoneNumber') }}</h6>
        <p>{{ billingAddress.phone }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="goToBilling">
          {{ $t('back') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="goToPayment">
          {{ $t('ConfirmOrder') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import {
  watch, ref, computed, reactive,
} from 'vue';

import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'order-preview',
  props: [],
  components: {
    SvgButton,
  },
  setup() {
    const store = useStore();

    store.dispatch('fetchShippingAddress');
    store.dispatch('fetchBillingAddress');

    const showPreview = computed(() => store.getters.isShowPreview);
    const shippingAddress = computed(() => store.getters.getShippingAddress);
    const billingAddress = computed(() => store.getters.getBillingAddress);

    const goToBilling = () => {
      store.dispatch('isShowBilling', true);
      store.dispatch('isShowPreview', false);
      store.dispatch('inSecondStep', false);
    };

    const goToPayment = () => {
      store.dispatch('isShowPreview', false);
      store.dispatch('isShowPaynow', true)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
      store.dispatch('inThirdStep', true);
    };

    return {
      goToBilling, showPreview, goToPayment, shippingAddress, billingAddress,
    };
  },
};
</script>

<style scoped lang="scss"></style>
