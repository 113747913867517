<template lang="html">
  <div>
    <section class="library-playshields home" aria-label="library-playshields">
      <h4>PlayShields</h4>
      <div class="library-story-listing">
        <div
          v-if="playShields?.items?.length > 0"
          class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4"
        >
          <div v-for="(shield, index) in playShields?.items" :key="index" class="col">
            <div class="card h-100 text-center">
              <!-- <div v-if="shield?.playlist?.image" class="card-body p-0">
                <img :src="shield?.playlist?.image" class="card-img-top" alt="" />
              </div> -->
              <div class="card-body p-0">
                <img src="@/assets/images/playshield-white.png" class="card-img-top" alt="" />
              </div>
              <div class="card-footer border-0 p-0">
                <h5 class="card-title m-0">{{ shield?.title }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert empty-cart" role="alert">
          <img src="@/assets/images/empty-cart.png" alt="Empty image" />
          <h2>
            {{ $t('oopsMessage', { text: 'PlayShields' }) }}
          </h2>
          <p>
            {{ $t('attachMessage', { text: 'PlayShield' }) }}
          </p>
          <div class="wave-btn-block">
            <button
              type="submit"
              class="blob-btn fill-bg btn-lg mt-3"
              @click="library"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {{ $t('shopNow') }}
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <SvgButton />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

import SvgButton from '@/components/partials/SvgButton.vue';

export default {
  name: 'LibraryPlayShields',
  props: [],
  components: {
    SvgButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    store.dispatch('fetchUserPlayShields');

    const playShields = computed(() => store.getters.getUserPlayShields);

    const library = () => {
      router.push('/shields');
    };

    return { playShields, library };
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
