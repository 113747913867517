<template lang="html">
  <section class="side-menu-content">
    <ul class="side-content">
      <li>
        <div class="header-search d-none d-lg-block">
          <button
            type="button"
            class="header-serch-icon btn border-0"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i class="icon-search" aria-hidden="true"></i>
          </button>
        </div>
      </li>
      <li class="d-none d-lg-block">
        <router-link :to="{ name: 'login' }" class="nav-link txt-bdr-link white-txt">
          <i class="icon-User" aria-hidden="true"></i
          ><span class="d-lg-none">{{ $t('myAccount') }}</span>
        </router-link>
      </li>
      <li>
        <!-- <Cart /> -->
      </li>
    </ul>
  </section>
</template>

<script lang="js">
// import Cart from '@/pages/Cart.vue';

export default {
  name: 'main-menu-content',
  props: [],
  components: {
    // Cart,

  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
