<template>
  <div>
    <section class="mt-stickybar work-in-progress bg-secondary" aria-label="work in progress">
      <div class="container">
        <div class="row mt-5">
          <div class="col-md-12 text-center mt-5">
            <h1 class="">{{ $t('comingSoon') }}</h1>
          </div>
          <div class="col-md-8 m-auto text-center error-bg">
            <h5>
              <!-- Let imagination be limitless, with -->
              <span class="highlight-title">
                <!-- StoryPhones. -->
                {{ homeAwards.excerpt }}
              </span>
            </h5>
            <img src="@/assets/images/image.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

export default {
  name: 'ComingSoon',
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `home_awards_${langCode}`,
    };
    // store.dispatch('fetchCMS', Data);

    const homeAwards = computed(() => store.getters.getCMS);

    return {
      langCode,
      homeAwards,
    };
  },
};
</script>

<style></style>
