<template lang="html">
  <section class="p-0 box-shadow-bg mt-stickybar">
    <div class="container">
      <div class="row">
        <Carousel
          :settings="settings"
          :breakpoints="breakpoints"
          class="py-4 stories-carousel"
          aria-label="category carousel"
        >
          <Slide v-for="slide in slides" :key="slide">
            <div class="carousel__item">
              <img
                :src="require(`@/assets/images/${slide.image}`)"
                alt="category image"
                width="200"
              />
              <h6 class="mt-4">{{ slide.title }}</h6>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'CategoryCarousel',
  props: [],
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  mounted() {
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
      slides: [
        {
          image: 'category1.png',
          title: 'Adventure',
        },
        {
          image: 'category2.png',
          title: 'History',
        },
        {
          image: 'category3.png',
          title: 'Spooky',
        },
        {
          image: 'category4.png',
          title: 'Fantasy',
        },
        {
          image: 'category2.png',
          title: 'Adventure',
        },
        {
          image: 'category1.png',
          title: 'History',
        },
        {
          image: 'category3.png',
          title: 'Spooky',
        },
      ],
    };
  },
  methods: {

  },
  computed: {

  },
};
</script>
<style scoped></style>
