<template>
  <PageHeader />
  <section class="blog">
    <div class="container">
      <!-- <div class="row">
          <div class="col-sm-6">
            <img src="@/assets/images/Disney100_social_post_1.jpg" alt="Disney100social" />
          </div>
          <div class="col-sm-6">
            <div class="blog-content">
              <h3 class=" mt-5">Disney 100 Journeys of Wonder</h3>
              <p class="blog-p">
                Disney 100 Journeys of Wonder is a StoryShield for StoryPhones offering an enchanting
                collection of six relaxing looping sounds compiled from the magical worlds of some of
                the best Disney stories. It&#39;s an immersive audio experience with a subtle
                narrative element offering a relaxing ambient audio journey into Disney and
                Pixar&#39;s character worlds....
              </p>
              <div class="wave-btn-block">
                <button class="blob-btn fill-bg btn-lg">
                  Continue reading...
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div> -->
      <div class="row">
        <!-- <div class="col-sm-6 m-auto mt-2 mb-4">
            <img src="@/assets/images/Disney100_social_post_1.jpg" alt="Disney100social" />
          </div> -->
        <h3 class="text-center mt-5">Disney 100 Journeys of Wonder</h3>
        <p class="blog-p">
          Disney 100 Journeys of Wonder is a StoryShield for StoryPhones offering an enchanting
          collection of six relaxing looping sounds compiled from the magical worlds of some of the
          best Disney stories. It&#39;s an immersive audio experience with a subtle narrative
          element offering a relaxing ambient audio journey into Disney and Pixar&#39;s character
          worlds.
        </p>
        <p class="blog-p">
          With these soothing soundbites children can sit back and enjoy relaxing sounds from such
          adventures as Finding Nemo, featuring soft piano music, gentle violin, bubbling water and
          the deep hum of the ocean. Gentle flute and piano music combined with sounds of the
          forest, bird singing, horses neighing, rain falling, and a gentle stream trickling make up
          the comforting sound bite from Frozen, while the world of Lion King offers a wonderful
          orchestra with noises of the African plains where exotic animals sing along with heavy
          rainfall in a relaxing sound bite. You can also get lost in the tranquil sounds of the
          ocean from Moana. Listen to waves softly lapping the shore, wind rustling the sails and
          floorboards of a boat creaking. You&#39;ll also hear the occasional croak from Hei Hei,
          Moana&#39;s lovable rooster.
        </p>
      </div>
      <div class="row mt-3">
        <div class="col-md-5 m-auto">
          <img src="@/assets/images/Disney100_social_post_2.jpg" alt="Disney100social" />
        </div>
        <div class="col-md-5 m-auto">
          <img src="@/assets/images/Disney100_social_post_3.jpg" alt="Disney100social" />
        </div>
        <p class="mt-4 blog-p">
          From the world of Peter Pan, you can enjoy gentle violin music, hear church bells chiming
          softly in distance along with gentle pan pipes and flutes. You&#39;ll also hear a clock
          ticking far away, birds singing and crickets chirping in this compilation of sounds that
          offer a celebration for the senses. In the sound bite from Princess and the Frog you can
          get lost in a mishmash of water gently lapping the shore, frogs croaking a tender tune,
          alligators wading through a swamp, crickets chirping and an owl hooting in a distance in
          this relaxing feast for the ears.
        </p>
        <p class="mb-4 blog-p">
          Disney 100 Journeys of Wonder is ideal for when you want your kids to relax and wind down.
          As parents we know how difficult it can be when our kids get over stimulated and
          unsettled. This can happen when kids are swamped with more sensations, experiences, and
          activity than they can cope with. Disney&#39;s clever combination of white and pink noises
          made up from a compilation of background sounds from different adventures will surely do
          the trick. They are also great to help children focus or to keep them occupied in a gentle
          way. This collection is perfect for the car ride, and you can even create a game out of it
          during a road trip. Ask the child to listen to a soundbite from one adventure and then ask
          them to recount some of the sounds they just heard and see if they can guess which Disney
          adventure they are from.
        </p>
      </div>
    </div>
    <div class="container-fluid text-center mt-4 mb-5">
      <div class="wave-btn-block">
        <router-link to="/blog" class="blob-btn fill-bg btn-lg">
          Back to Blog
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Blog',

  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style></style>
