<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="cloud-bg login account-section mt-stickybar mb-5 pad">
    <LoginAnimation />
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6 col-xl-5 m-auto">
          <div class="account-form-block mt-5">
            <h2 class="text-center mb-4">{{ $t('logIn') }}</h2>
            <div class="form-group">
              <label class="form-label">{{ $t('email') }}</label>
              <input type="input" v-model="userLogin.email" class="form-control" />
              <span class="text-danger pl-3" v-if="v$.email.$error">
                {{ $t('errorMessage', { text: $t('mail') }) }}*
              </span>
            </div>
            <div class="form-group">
              <label>{{ $t('password') }}</label>
              <input
                type="password"
                id="password"
                v-model="userLogin.password"
                class="form-control"
              />

              <div class="eye-pwd" @click="toggleShowPassword()">
                <div v-if="showPassword">
                  <i class="icon-eye-Open" aria-hidden="true"></i>
                </div>
                <div v-if="!showPassword">
                  <i class="icon-eye-closed" aria-hidden="true"></i>
                </div>
              </div>
              <span class="text-danger pl-3" v-if="v$.password.$error">
                {{ $t('errorMessage', { text: $t('password') }) }}*
              </span>
            </div>

            <!-- <div class="form-group">
              <label class="form-label">Currency</label>
              <select
                class="form-select"
                placeholder
                name=""
                v-model="userLogin.selectedMarket"
                id=""
                aria-label="Default select example"
              >
                <option disabled value="">Select Currency</option>
                <option v-for="(market, index) in userMarkets" :key="index" :value="market">
                  {{ market.price_list.name }}
                </option>
              </select>
              <span class="text-danger pl-3" v-if="v$.selectedMarket.$error">
                {{ $t('selectMessage', { text: $t('currency') }) }}*
              </span>
            </div> -->

            <p>
              <router-link to="/forget-password" class="txt-bdr-link">
                {{ $t('forgotPassword') }}</router-link
              >
            </p>
            <div class="text-center">
              <span class="text-danger" v-if="error?.length > 0">
                {{ error }}
              </span>

              <div class="wave-btn-block">
                <button type="submit" class="blob-btn fill-bg btn-lg submit-btn" @click="login">
                  {{ $t('logIn') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
            <p class="small-text text-center mt-4">
              {{ $t('signUpQuestion') }}
              <router-link to="/signup" class="txt-bdr-link">{{ $t('signUp') }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import {
  required, numeric, email, minLength, helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import CLayerBaseService from '@/services/CLayerBaseService';
import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';
import LoginAnimation from '@/components/partials/LoginAnimation.vue';
import Loader from '@/components/commons/Loader.vue';

const cLayerBaseService = new CLayerBaseService();
const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });
const storyphoneService = new StoryphoneService();

export default {
  name: 'login',
  props: [],
  components: {
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
    LoginAnimation,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const showPassword = ref(false);
    // const selectedMarket = reactive('');
    const userMarkets = computed(() => store.getters.getUserMarkets);

    // store.dispatch('fetchMarkets');

    const isLoading = ref(false);
    const error = ref([]);
    const userLogin = reactive({
      email: '',
      password: '',
      selectedMarket: '',
    });

    const rules = {
      email: { required: helpers.withMessage('You must enter your email', required), email },
      password: {
        required: helpers.withMessage('You must enter your password', required),
      },
      // selectedMarket: { required: helpers.withMessage('You must select a currrency', required) },
    };

    const v$ = useVuelidate(rules, userLogin);

    const toggleShowPassword = () => {
      const password = document.querySelector('#password');
      if (showPassword.value) {
        showPassword.value = false;
        password.setAttribute('type', 'password');
      } else {
        showPassword.value = true;
        password.setAttribute('type', 'text');
      }
    };

    const login = async () => {
      const result = await v$.value.$validate();
      if (result) {
        const credentials = {
          email: userLogin.email,
          password: userLogin.password,
        };
        cookies.remove('orderIdCookie');
        isLoading.value = true;
        storyphoneService.login(credentials)
          .then((user) => {
            localStorage.setItem('storyPhoneUser', user.data.access);
            store.dispatch('fetchUserDetails');
            cLayerBaseService.createUserToken(credentials)
              .then((cLayerToken) => {
                isLoading.value = false;
                localStorage.setItem('cLayerUserToken', cLayerToken);
                const id = cookies.get('clUserOwnerId');
                cookies.remove('clAuthCookie');
                cookies.remove('orderIdCookie');
                cLayerService.checkPendingOrder(id.owner_id)
                  .then((order) => {
                    if (order.length > 0) {
                      cookies.set('orderIdCookie', {
                        orderId: order[0].id,
                      });
                    }
                    const { orderId } = cookies.get('orderIdCookie');
                    store.dispatch('fetchCartItems', orderId);
                    // if ((cookies.isKey('orderIdCookie'))) {
                    //   cLayerService.changeOrder(orderId);
                    // }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                router.push('/my-account');
              })
              .catch((err) => {
                isLoading.value = false;
                error.value = err.errors[0].detail;
              });
          })
          .catch((err) => {
            isLoading.value = false;
            // eslint-disable-next-line prefer-destructuring
            error.value = err?.response?.data?.errors[0];
          });
      } else {
        console.log(v$);
      }
    };

    return {
      showPassword, v$, isLoading, error, userLogin, toggleShowPassword, login, userMarkets,
    };
  },
};
</script>

<style scoped lang="scss">
// .login {
// }
</style>
