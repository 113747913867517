import NewContentfulService from '@/services/NewContentfulService';
import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';
import * as types from './mutation-types';

const newContentfulService = new NewContentfulService();
const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();

// export const fetchStoryshieldsList = ({ commit }, limit, category = false) => new Promise(
//   (resolve, reject) => {
//     contentfulService
//       .getStoryShieldList(limit, category)
//       .then((response) => {
//         commit(types.SET_STORYSHIELDS_LIST, response);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchPlaylists = ({ commit }, limit, playlistID = false) => new Promise(
//   (resolve, reject) => {
//     contentfulService
//       .getEntityList('playlists', limit, playlistID)
//       .then((response) => {
//         commit(types.SET_PLAYLISTS_LIST, response);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

export const fetchPlayShields = ({ commit }, locale, playlistID = false) => new Promise(
  (resolve, reject) => {
    newContentfulService.fetchPlayShieldPlus(locale)
      .then((response) => {
        commit(types.SET_PLAYSHIELD_LIST, response);
        // commit(types.SELECTED_PLAYSHIELD, response.items[0]);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },

);

// export const fetchZenShields = ({ commit }, limit, playlistID = false) => new Promise(
//   (resolve, reject) => {
//     contentfulService
//       .getEntityList('ZenShield', limit, playlistID)
//       .then((response) => {
//         commit(types.SET_ZENSHIELD_LIST, response);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchStories = ({ commit }, limit) => new Promise((resolve, reject) => {
//   contentfulService
//     .getEntityList('story', limit)
//     .then((response) => {
//       commit(types.SET_STORY_LIST, response);
//       resolve(response);
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });

// export const fetchStoryDetails = ({ commit }, entityID) => new Promise((resolve, reject) => {
//   contentfulService
//     .getEntity('story', entityID)
//     .then((response) => {
//       commit(types.SET_STORY_DETAILS, response);
//       resolve(response);
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });

// export const fetchPlaylistDetails = ({ commit }, entityID) => new Promise((resolve, reject) => {
//   contentfulService
//     .getEntity('playlists', entityID)
//     .then((response) => {
//       commit(types.SET_PLAYLISTS_DETAILS, response);
//       resolve(response);
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });

export const fetchSelectedShieldDetails = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    commit(types.SET_SELECTED_SHIELD_DETAILS, '');
    // contentfulService
    //   .getShieldDetails(data.id, data.type)
    newContentfulService
      .fetchStoryshieldsDetails(data)
      // .getEntity('playlists', entityID)
      .then((response) => {
        commit(types.SET_SELECTED_SHIELD_DETAILS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchSelectedZenShieldDetails = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    commit(types.SET_SELECTED_SHIELD_DETAILS, '');
    // contentfulService
    //   .getShieldDetails(data.id, data.type)
    newContentfulService
      .fetchZenShieldsById(data)
      // .getEntity('playlists', entityID)
      .then((response) => {
        commit(types.SET_SELECTED_SHIELD_DETAILS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCartItemDetails = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    newContentfulService
      .fetchStoryshieldBySku(data)
      .then((response) => {
        commit(types.SET_CARTITEM_DETAILS, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    // }
  },
);

export const fetchCartItemPrice = ({ commit }, entityID) => new Promise(
  (resolve, reject) => {
    cLayerService
      .getShieldPrice(entityID)
      .then((response) => {
        commit(types.SET_CARTITEM_PRICE, response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchSelectedShieldSku = ({ commit }, skuCode) => {
  commit(types.SET_SELECTED_SHIELD_SKU, skuCode);
};

export const fetchStoryShieldPrice = ({ commit }, productSKU) => new Promise((resolve, reject) => {
  cLayerService.getShieldPrice(productSKU)
    .then((response) => {
      commit(types.SET_SHIELD_PRICE, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

// export const fetchStoryPhones = ({ commit }, limit) => new Promise((resolve, reject) => {
//   contentfulService
//     .getEntityList('storyPhones', limit)
//     .then((response) => {
//       commit(types.SET_STORYPHONE_LIST, response);
//       resolve(response);
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });

export const addToWishlist = ({ commit, dispatch }, itemDetail) => new Promise((resolve, reject) => {
  storyphoneService
    .addToWishlist(itemDetail)
    .then((response) => {
      dispatch('fetchWishList');
      resolve(response);
    })
    .catch((error) => {
      dispatch('fetchWishList');
      reject(error);
    });
});

export const fetchWishList = ({ commit }) => new Promise((resolve, reject) => {
  storyphoneService
    .getAllWishlist()
    .then((response) => {
      commit(types.GET_WISHLIST, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchUserWishList = ({ commit }, data) => new Promise((resolve, reject) => {
  storyphoneService
    .getWishlist(data.limit, data.offset)
    .then((response) => {
      commit(types.GET_USER_WISHLIST, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const selectStoryPhone = ({ commit }, value) => {
  commit(types.SELECTED_STORYPHONE, value);
};

export const selectPlayShield = ({ commit }, value) => {
  commit(types.SELECTED_PLAYSHIELD, value);
};

export const fetchSearchedItems = ({ commit }, value) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchSearchResults(value)
    .then((response) => {
      commit(types.GET_SEARCH_RESULT, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

// export const fetchFilteredResults = ({ commit }, value) => new Promise((resolve, reject) => {
//   contentfulService
//     .getFilterResults(value)
//     .then((response) => {
//       commit(types.GET_SEARCH_RESULT, response);
//       resolve(response);
//     })
//     .catch((error) => {
//       reject(error);
//     });
// });

export const fetchPlaylistDetail = ({ commit }, id) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchPlaylistDetails(id)
    .then((response) => {
      commit(types.GET_PLAYLIST_DETAILS, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchGenresDetail = ({ commit }, Data) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchGenresDetails(Data)
    .then((response) => {
      commit(types.GET_GENRES_DETAILS, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchOnanoffStoryPhones = ({ commit }, Data) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchStoryPhonesBySku(Data)
    .then((response) => {
      commit(types.ONANOFF_STORYPHONES, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchDisneyStoryPhones = ({ commit }, id) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchStoryPhonesBySku(id)
    .then((response) => {
      commit(types.DISNEY_STORYPHONES, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchLingoKidsStoryPhones = ({ commit }, id) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchStoryPhonesBySku(id)
    .then((response) => {
      commit(types.LINGOKIDS_STORYPHONES, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchPlayShieldPlusByLineItems = ({ commit }, Data) => new Promise((resolve, reject) => {
  newContentfulService
    .fetchPlayShieldPlusByLineItems(Data)
    .then((response) => {
      commit(types.PLAY_SHEILD_PLUS, response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const fetchItemDetails = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    if (data.category === 'storyphones') {
      newContentfulService
        .fetchStoryphoneBySku(data)
        .then((response) => {
          commit(types.SET_SELECTED_SHIELD_DETAILS, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else if (data.category === 'storyshield') {
      newContentfulService
        .fetchStoryshieldBySku(data)
        .then((response) => {
          commit(types.SET_SELECTED_SHIELD_DETAILS, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else if (data.category === 'zenshield') {
      newContentfulService
        .fetchZenShieldsById(data)
        .then((response) => {
          commit(types.SET_SELECTED_SHIELD_DETAILS, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  },
);
