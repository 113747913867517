<template>
  <div class="btn-group">
    <div class="play-pause-block">
      <button
        @click="play(this.elementData.file)"
        type="button"
        class="border-0 round-btn blue play me-2"
        :class="{ active: isPlayActive }"
      >
        <i class="icon-play3 ms-1 animate__animated animate__jackInTheBox" aria-hidden="true"></i>
      </button>
      <button
        @click="pause(this.elementData.file)"
        type="button"
        class="border-0 round-btn blue pause me-2"
        :class="{ active: isPauseActive }"
      >
        <i class="icon-pause2 animate__animated animate__jackInTheBox" aria-hidden="true"></i>
      </button>
    </div>
    <button type="button" class="border-0 round-btn red" @click="deleteAudio()">
      <i class="icon-delete" aria-hidden="true"></i>
    </button>
    <div
      class="success-submit-box modal fade mt-3 d-none"
      :class="{ addsuccess: isAdded, show: isAdded }"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 p-3">
          <div class="modal-body">
            <div class="popup-msg-box">
              <p>{{ $t('ruSure') }}</p>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-primary btn-lg submit-btn mb-3 me-3"
              data-dismiss="modal"
              @click="popupYesClick(this.elementData)"
            >
              {{ $t('yes') }}
            </button>
            <button
              class="btn btn-primary btn-lg submit-btn mb-3"
              data-dismiss="modal"
              @click="popupNoClick()"
            >
              {{ $t('no') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import { onUnmounted } from 'vue';

import StoryphoneService from '@/services/StoryphoneService';

const storyphoneService = new StoryphoneService();

export default {
  name: 'RecordListingButton',
  props: ['elementData'],
  components: { },
  data() {
    return {
      isPauseActive: false,
      isPlayActive: true,
      audio: null,
      isAdded: false,
    };
  },
  mounted() {

  },
  onUnmounted() {
    this.audio.pause();
  },
  watch: {
    // showFiles: {
    //   handler() {
    //     if (!this.showFiles) {
    //       this.pause();
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  methods: {
    play(file) {
      this.isPauseActive = true;
      this.isPlayActive = false;
      this.audio = new Audio(file);
      this.audio.play();
    },

    pause(file) {
      this.isPauseActive = false;
      this.isPlayActive = true;
      this.audio.pause();
    },

    deleteAudio() {
      this.isAdded = true;
    },

    popupYesClick(data) {
      this.deleteRecordingAction(data.name).then(() => {
      });
      this.isAdded = false;
      storyphoneService.storyDelete(data.id);
    },

    popupNoClick() {
      this.isAdded = false;
    },
    ...mapActions({
      deleteRecordingAction: 'deleteRecording',
      recordingListAction: 'fetchRecordingList',
    }),
  },
  computed: {
    ...mapGetters({
      recordingList: 'getRecordingList',
      showFiles: 'isShowFiles',
    }),
  },
};
</script>
