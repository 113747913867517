<template lang="html">
  <div>
    <!-- {{ shieldData }} -->
    <div @click="getProductDetails(shieldData)" style="cursor: pointer">
      <div v-if="shieldData.is_new" class="category-new">
        <p>{{ $t('new') }}</p>
      </div>
      <div class="storylist-img">
        <img :src="shieldData.image" alt="playlist image" class="" />
      </div>
      <div class="category-body">
        <h6 class="mt-3 carousel-title">{{ shieldData.title }}</h6>
      </div>
      <!-- {{ shieldData }} -->
      <!-- {{ priceDetails }} -->
      <span v-if="shieldData">
        <h6 class="mb-3">
          {{ price(shieldData?.prices[0]) }}
        </h6>
      </span>
      <!-- <span v-if="priceDetails[0]?.prices[0]">
        <h6 class="mb-3">
          {{ price(priceDetails[0]?.prices[0]) }}
        </h6>
      </span> -->
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import {
  ref, computed, watch, toRefs,
} from 'vue';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();

export default {
  name: 'ShieldListingItem',
  props: ['shieldData', 'shieldSku', 'zenShield'],
  components: { },
  setup(props) {
    // const { zenShield } = toRefs(props);
    // console.log(zenShield.value);
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const priceDetails = ref([]);
    const storyShieldImage = computed(() => store.getters.getStoryShieldImage);

    if (props.zenShield) {
      cLayerService.getShieldPrice(props.zenShield)
        .then((response) => {
          priceDetails.value = response;
        })
        .catch((error) => {
          console.log('error');
        });
    }

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    const getProductDetails = (Data, type = 'storyShields') => {
      if (Data.brand === 'onanoff') {
        const details = {
          sku: Data.sku,
          locale: langCode,
        };
        store.dispatch('fetchSelectedZenShieldDetails', details);
      } else {
        const details = {
          id: Data.id,
          locale: langCode,
        };
        store.dispatch('fetchSelectedShieldDetails', details);
      }
    };

    return {
      priceDetails, getProductDetails, storyShieldImage, price,
    };
  },
};
</script>
