<template lang="html">
  <div v-show="isLoading"><Loader /></div>
  <section class="py-5 mt-stickybar" aria-label="product">
    <div class="container-fluid text-start mb-4">
      <a href="/product-collections">
        <img src="@/assets/images/1020.png" alt="" class="back-button" />
      </a>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 m-auto mb-4">
          <!-- carousel -->
          <Carousel class="product-slider" :autoplay="2000" :wrap-around="true">
            <Slide v-for="slide in slides" :key="slide">
              <div v-if="disneyStoryPhones.color_name === ' Light Blue '" class="carousel__item">
                <img
                  class=""
                  :src="require(`@/assets/images/disneyblue/${slide.image}`)"
                  alt="storytelling image"
                />
              </div>
              <div v-else class="carousel__item">
                <img
                  class=""
                  :src="require(`@/assets/images/disneypink/${slide.image}`)"
                  alt="storytelling image"
                />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination class="mt-4" />
            </template>
          </Carousel>
          <!-- carousel end -->
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-9 m-auto">
              <p class="letter-space">{{ $t('storytellingHp') }}</p>
              <div class="row justify-content-between py-3">
                <h4>{{ disneyStoryPhones.name }}</h4>
                <p class="highlight-heading" v-if="priceDetails[0]?.prices[0]">
                  {{ price(priceDetails[0]?.prices[0]) }}
                  <!-- {{ priceDetails[0]?.prices[0]?.formatted_amount.slice(0, 1) }}
                  {{ priceDetails[0]?.prices[0]?.amount_float }} -->
                </p>
              </div>
              <p>Disney StoryPhones</p>
              <hr />
              <p class="highlight-heading">
                {{ $t('color') }} - {{ disneyStoryPhones.color_name }}
              </p>

              <!-- <button
                v-for="(phone, index) in disneyPhone"
                :key="phone.id"
                class="btn p-0 border-0"
              >
                <div
                  class="product-white"
                  @click="selectedColour(phone.fields, index)"
                  :class="{ active: currentIndex === index }"
                >
                  <div
                    class="product-color"
                    :style="{ 'background-color': phone.fields.colour_value }"
                  >
                    <span
                      class="left-dot"
                      :style="{ 'background-color': phone.fields.colour_value }"
                    ></span>
                    <span
                      class="right-dot"
                      :style="{ 'background-color': phone.fields.colour_value }"
                    ></span>
                  </div>
                </div>
              </button> -->
              <button
                v-for="(phone, index) in storyPhonesDetails.colorValue"
                :key="index"
                class="btn p-0 border-0"
              >
                <div
                  class="product-white"
                  @click="selectedColour(phone, index)"
                  :class="{ active: currentIndex === index }"
                >
                  <div class="product-color" :style="{ 'background-color': phone }">
                    <span class="left-dot" :style="{ 'background-color': phone }"></span>
                    <span class="right-dot" :style="{ 'background-color': phone }"></span>
                  </div>
                </div>
              </button>

              <br />
              <div class="col-md-12 mt-5">
                <div class="row">
                  <div
                    class="accordion accordion-flush w-100 product-accordion"
                    id="accordionExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed px-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          {{ $t('description') }}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body px-0">
                          <p>
                            {{ disneyStoryPhones.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed px-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {{ $t('whatsInclude') }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body px-0">
                          <ul class="ms-4">
                            <li v-for="lists in disneyStoryPhones?.included" :key="lists.id">
                              {{ lists }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed px-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {{ $t('productDetails') }}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body px-0">
                          <ul class="ms-4">
                            <li
                              v-for="(lists, index) in disneyStoryPhones?.informations"
                              :key="index"
                            >
                              {{ lists }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--  -->

              <!--  -->

              <div class="btn-main-box d-flex mt-5">
                <div v-if="quantity > 0" class="wave-btn-block">
                  <!-- <button
                    class="add-to-cart blob-btn fill-bg"
                    :class="{ added: isAdded }"
                    @click="addToCart(selectedStoryPhone)"
                  >
                    <div class="default">
                      <i class="icon-cart" aria-hidden="true"></i> {{ $t('AddToCart') }}
                    </div>
                    <div class="success">{{ $t('added') }}</div>
                    <div class="cart">
                      <div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div class="dots"></div>
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button> -->
                  <SvgButton />
                </div>
                <div v-else>
                  <button class="add-to-cart disabled" disabled>
                    <span class="default"
                      ><i class="icon-cart-close me-1" aria-hidden="true"></i>
                      {{ $t('outOfStock') }}</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { useStore, mapActions, mapGetters } from 'vuex';
import {
  ref, computed, reactive, onBeforeMount,
} from 'vue';
import {
  Carousel, Navigation, Pagination, Slide,
} from 'vue3-carousel';
import { useCookies } from 'vue3-cookies';

import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';
import NewContentfulService from '@/services/NewContentfulService';
import Loader from '@/components/commons/Loader.vue';

const { cookies } = useCookies({ secure: true });
const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'ProductOfStoryphones',
  props: [],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    SvgButton,
    Loader,
  },

  setup() {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const isAdded = ref(false);
    const skuValue = ref('');
    const currentIndex = ref(0);
    const priceDetails = ref({});
    const quantity = ref(0);
    const isLoading = ref(false);
    const storyPhonesDetails = reactive({
      sku: [],
      colorValue: [],
    });

    const questions = ref([
      {
        id: 1,
        title: 'Description',
      },
      {
        id: 2,
        title: 'What\'s included?',
        list: [
          {
            items: 'StoryPhones',
          },
          {
            items: 'ZenShield - travel and relax',
          },
          {
            items: 'PlayShield - create and record',
          },
          {
            items: 'USB-C charging cable',
          },
          {
            items: 'Audio cable',
          },
          {
            items: 'Carry bag',
          },
        ],
      },
      {
        id: 3,
        title: 'Product details',
      },
    ]);
    const slides = ref([
      {
        image: '1.png',
      },
      {
        image: '2.png',
      },
      {
        image: '3.png',
      },
      {
        image: '4.png',
      },
      {
        image: '5.png',
      },
    ]);

    const disneyPhone = computed(() => store.getters.getDisneyStoryPhone);

    // const selectedStoryPhone = computed(() => store.getters.selectedStoryPhone);

    const getPrice = (sku) => {
      cLayerService.getShieldPrice(sku)
        .then((response) => {
          priceDetails.value = response;
          quantity.value = response[0]?.stock_items[0]?.quantity;
        })
        .catch((error) => {
        });
    };
    const product = async () => {
      isLoading.value = true;
      await newContentfulService.fetchDisneyStoryPhones(langCode).then(async (res) => {
        res.items.forEach((element) => {
          storyPhonesDetails.sku.push(element.sku);
          storyPhonesDetails.colorValue.push(element.color_value);
        });
        // eslint-disable-next-line prefer-destructuring
        skuValue.value = storyPhonesDetails.sku[0];

        const Data = {
          id: storyPhonesDetails.sku[0],
          locale: langCode,
        };

        await store.dispatch('fetchDisneyStoryPhones', Data)
          .then(() => {
            isLoading.value = false;
          });
        getPrice(storyPhonesDetails.sku[0]);
      });
    };
    product();

    const disneyStoryPhones = computed(() => store.getters.getDisneyStoryPhones);

    // const func = async () => {
    //   await store.dispatch('fetchStoryPhones')
    //     .then(async () => {
    //       await store.dispatch('selectStoryPhone', disneyPhone.value[0].fields);
    //       getPrice(selectedStoryPhone.value.sku);
    //     });
    // };
    // func();

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    const selectedColour = (phone, index) => {
      isLoading.value = true;
      currentIndex.value = index;
      skuValue.value = storyPhonesDetails.sku[index];
      const Data = {
        id: storyPhonesDetails.sku[index],
        locale: langCode,
      };
      store.dispatch('fetchDisneyStoryPhones', Data)
        .then(() => {
          isLoading.value = false;
        });
      getPrice(storyPhonesDetails.sku[index]);
      // store.dispatch('selectStoryPhone', phone);
      // getPrice(phone.sku);
    };

    const addToCart = async (lineItem) => {
      isAdded.value = true;
      const storyPhone = { itemDetails: { sku: skuValue.value } };
      await store.dispatch('updateLineItem', storyPhone)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {
        });
    };

    return {
      isAdded,
      currentIndex,
      priceDetails,
      questions,
      slides,
      disneyPhone,
      // selectedStoryPhone,
      selectedColour,
      getPrice,
      addToCart,
      quantity,
      price,
      storyPhonesDetails,
      disneyStoryPhones,
      skuValue,
      isLoading,
    };
  },

};
</script>

<style scoped lang="scss"></style>
