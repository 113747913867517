<template lang="html">
  <PageHeader />
  <section class="mt-stickybar home mb-5" aria-label="cart">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5">
          <h1>{{ $t('shopCart') }}</h1>
        </div>
        <div class="col-md-8">
          <div v-if="isLoading"><Loader /></div>
          <div class="table-responsive mt-3 cartlist">
            <div v-if="lineItems.length > 0">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="border-bottom-0">{{ $t('ProductDetails') }}</th>
                    <th class="price-box border-bottom-0">{{ $t('Price') }}</th>
                    <th class="border-bottom-0 qty-main-block">{{ $t('quantity') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lineItem in lineItems" :key="lineItem.id">
                    <td class="align-middle">
                      <div class="update-details d-flex align-items-center">
                        <img :src="lineItem.image_url" alt="cart image" width="50" class="me-3" />
                        <h6>{{ lineItem.name }}</h6>
                      </div>
                    </td>
                    <td class="price-box align-middle">
                      {{ price(lineItem.currency_code,lineItem.total_amount_float ) }}
                      <!-- {{ lineItem.formatted_unit_amount.slice(0, 1) }}
                      {{ lineItem.total_amount_float }} -->
                    </td>
                    <td class="align-middle qty-main-block">
                      <CartItemButton :cartData="lineItem" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="wave-btn-block">
                <button class="blob-btn fill-bg btn-lg mt-5" @click="checkout()">
                  {{ $t('checkOut') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
            <div v-else class="alert empty-cart" role="alert">
              <img src="@/assets/images/empty-cart.png" alt="Empty image" />
              <h2>{{ $t('oopsCart') }}</h2>
              <!-- <p>It is really surprising that you haven't added any stories to the cart yet.</p> -->
              <p>{{ $t('oopsCartMeg') }}</p>

              <div class="wave-btn-block">
                <button class="blob-btn fill-bg btn-lg my-3" @click="library()">
                  {{ $t('shopNow') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-none d-sm-none d-md-block d-lg-block">
          <OrderSummery />
        </div>
      </div>
    </div>
  </section>

  <PageFooter class="mt-5" />
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import CLayerService from '@/services/CLayerService';
import Loader from '@/components/commons/Loader.vue';
import OrderSummery from '@/components/partials/OrderSummery.vue';
import CartItemButton from '@/components/partials/CartItemButton.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'Cart',

  components: {
    Loader,
    PageHeader,
    PageFooter,
    OrderSummery,
    CartItemButton,
    SvgButton,
  },

  mounted() {
    const { orderId } = cookies.get('orderIdCookie');
    this.fetchCartItemsAction(orderId)
      .then((res) => {
        this.isLoading = false;
      });
  },

  data() {
    return {
      isLoading: true,
      slideCart: false,
    };
  },
  watch: {
  },
  methods: {
    checkout() {
      this.$router.push('/checkout');
    },
    clearCart() {
      if (cookies.isKey('orderIdCookie')) {
        const { orderId } = cookies.get('orderIdCookie');
        cLayerService.clearCartItems(orderId);
      }
    },

    price(currencyCode, value) {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
      });
      return formatter.format(value);
    },

    library() {
      this.$router.push('/shields');
    },

    ...mapActions({
      fetchCartItemsAction: 'fetchCartItems',
    }),

  },
  computed: {
    ...mapGetters({
      lineItems: 'getLineItems',
      cartItemCount: 'getCartItemCount',
      subtotal: 'getSubtotalPrice',
    }),
  },
};
</script>
