import * as types from './mutation-types';

const mutations = {
  [types.SET_STORYSHIELDS_LIST](state, storyShieldsList) {
    state.storyShieldsList = storyShieldsList;
  },
  [types.SET_STORYPHONE_LIST](state, storyPhoneList) {
    state.storyPhoneList = storyPhoneList;
  },
  [types.SET_PLAYLISTS_LIST](state, playlists) {
    state.playlists = playlists;
  },
  [types.SET_PLAYSHIELD_LIST](state, playShieldList) {
    state.playShieldList = playShieldList;
  },
  [types.SET_ZENSHIELD_LIST](state, zenShieldList) {
    state.zenShieldList = zenShieldList;
  },
  [types.SET_STORY_LIST](state, storyList) {
    state.storyList = storyList;
  },
  [types.SET_STORY_DETAILS](state, storyDetails) {
    state.storyDetails = storyDetails;
  },
  [types.SET_PLAYLISTS_DETAILS](state, playlistsDetails) {
    state.playlistsDetails = playlistsDetails;
  },
  [types.SET_SELECTED_SHIELD_DETAILS](state, shieldDetails) {
    state.selectedShieldDetails = shieldDetails;
  },
  [types.GET_SUCCESS_MESSAGE](state, successMessage) {
    state.successMessage = successMessage;
  },
  [types.GET_ERROR_MESSAGE](state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  [types.SET_SELECTED_SHIELD_SKU](state, selectedShieldSku) {
    state.selectedShieldSku = selectedShieldSku;
  },
  [types.SET_SHIELD_PRICE](state, storyShieldPrice) {
    state.storyShieldPrice = storyShieldPrice;
  },
  [types.GET_WISHLIST](state, wishlist) {
    state.wishlist = wishlist;
  },
  [types.SET_CARTITEM_DETAILS](state, cartItemDetails) {
    state.cartItemDetails = cartItemDetails;
  },
  [types.SET_CARTITEM_PRICE](state, cartItemPrice) {
    state.cartItemPrice = cartItemPrice;
  },
  [types.SELECTED_STORYPHONE](state, value) {
    state.selectedStoryPhone = value;
  },
  [types.SELECTED_PLAYSHIELD](state, value) {
    state.selectedPlayShield = value;
  },
  [types.GET_USER_WISHLIST](state, wishlist) {
    state.userWishlist = wishlist;
  },
  [types.GET_SEARCH_RESULT](state, value) {
    state.searchedItems = value;
  },
  [types.GET_PLAYLIST_DETAILS](state, value) {
    state.playlistDetails = value;
  },
  [types.GET_GENRES_DETAILS](state, value) {
    state.genresDetails = value;
  },
  [types.ONANOFF_STORYPHONES](state, value) {
    state.onanoffStoryPhones = value;
  },
  [types.DISNEY_STORYPHONES](state, value) {
    state.disneyStoryPhones = value;
  },
  [types.LINGOKIDS_STORYPHONES](state, value) {
    state.lingoKidsStoryPhones = value;
  },
  [types.PLAY_SHEILD_PLUS](state, value) {
    state.playShieldPlus = value;
  },
};

export default mutations;
