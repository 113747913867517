<template lang="html">
  <PageHeader />
  <div v-if="isLoading"><Loader /></div>
  <section class="account-settings edit-address mt-stickybar home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('personalInformation') }}</h3>
              <div class="inner-box">
                <div class="form-group">
                  <label>{{ $t('addressNickname') }} </label>
                  <input type="text" v-model="editAddress.title" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.title.$error">
                    <!-- {{ v$.title.$errors[0].$message }}* -->
                    {{ $t('errorMessage', { text: $t('title') }) }}*
                  </span>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label>{{ $t('firstName') }}</label>
                    <input type="text" v-model="editAddress.firstName" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.firstName.$error">
                      <!-- {{ v$.firstName.$errors[0].$message }}* -->
                      {{ $t('errorMessage', { text: $t('firstName') }) }}*
                    </span>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ $t('lastName') }}</label>
                    <input type="text" v-model="editAddress.lastName" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.lastName.$error">
                      <!-- {{ v$.lastName.$errors[0].$message }}* -->
                      {{ $t('errorMessage', { text: $t('lastName') }) }}*
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ $t('address') }} 1 </label>
                  <input type="text" v-model="editAddress.address1" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.address1.$error">
                    <!-- {{ v$.address1.$errors[0].$message }}* -->
                    {{ $t('errorMessage', { text: $t('address') }) }}*
                  </span>
                </div>
                <div class="form-group">
                  <label>{{ $t('address') }} 2 </label>
                  <input type="text" v-model="editAddress.address2" class="form-control" />
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>{{ $t('selectCountry') }}</label>
                    <div class="select-outer">
                      <div class="form-selection" v-if="!editAddress.country">
                        {{ country }}
                      </div>
                      <select
                        class="form-select"
                        v-model="editAddress.country"
                        @change="countrySelected()"
                      >
                        <option
                          v-for="(country, index) in countryList?.countryList?.data.items"
                          :key="index"
                          :value="country.country_code"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ $t('selectState') }}</label>
                    <div class="select-outer">
                      <div class="form-selection" v-if="!editAddress.state">
                        {{ state }}
                      </div>
                      <select class="form-select" v-model="editAddress.state">
                        <option
                          v-for="state in stateList"
                          :key="state.code"
                          :value="state.state_code"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                      <span class="text-danger pl-3" v-if="v$.state.$error">
                        <!-- {{ v$.city.$errors[0].$message }}* -->
                        {{ $t('errorMessage', { text: $t('state') }) }}*
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label> {{ $t('city') }}</label>
                    <input type="text" v-model="editAddress.city" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.city.$error">
                      <!-- {{ v$.city.$errors[0].$message }}* -->
                      {{ $t('errorMessage', { text: $t('city') }) }}*
                    </span>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ $t('zipCode') }}</label>
                    <input type="text" v-model="editAddress.zipCode" class="form-control" />
                    <span class="text-danger pl-3" v-if="v$.zipCode.$error">
                      <!-- {{ v$.zipCode.$errors[0].$message }}* -->
                      {{ $t('errorMessage', { text: $t('zipCode') }) }}*
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ $t('phoneNumber') }}</label>
                  <input type="text" v-model="editAddress.phoneNumber" class="form-control" />
                  <span class="text-danger pl-3" v-if="v$.phoneNumber.$error">
                    <!-- {{ v$.phoneNumber.$errors[0].$message }}* -->
                    {{ $t('errorMessage', { text: $t('phoneNumber') }) }}*
                  </span>
                </div>
                <div class="text-center">
                  <div class="wave-btn-block">
                    <button
                      type="submit"
                      class="blob-btn fill-bg btn-lg submit-btn mt-2"
                      @click="saveAddress()"
                    >
                      {{ $t('update') }}
                      <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                          <span class="blob-btn__blob"></span>
                        </span>
                      </span>
                    </button>
                    <SvgButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, alpha, helpers,
} from '@vuelidate/validators';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';
import Loader from '@/components/commons/Loader.vue';
import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';

const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();

export default {
  name: 'add-new-address',
  props: ['data'],
  components: {
    AccountSidebar,
    Loader,
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const stateList = ref({});
    const id = ref('');
    const country = ref('');
    const state = ref('');
    const countryCode = ref('');
    const stateCode = ref('');
    const isLoading = ref(false);
    const address = ref({});

    const editAddress = reactive({
      title: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      phoneNumber: '',
    });

    store.dispatch('fetchUserAddress');

    const countryList = computed(() => store.getters.getCountryList);

    const userAddress = computed(() => store.getters.getUserAddress);

    id.value = route.params.id;
    userAddress.value.forEach((element) => {
      if (element.address.id === id.value) {
        address.value = element.address;
      }
    });
    storyphoneService.fetchStateByCode(address.value.country_code, address.value.state_code)
      .then((result) => {
        stateCode.value = result.data.state_code;
        state.value = result.data.name;
        storyphoneService.fetchAvailableStates(address.value.country_code)
          .then((stateLists) => {
            stateList.value = stateLists.data.items;
          }).catch((stateError) => {
            stateList.value = {};
          });
      });
    storyphoneService.fetchCountryByCode(address.value.country_code)
      .then((result) => {
        countryCode.value = result.data.country_code;
        country.value = result.data.name;
      });

    store.dispatch('fetchAvailableCountries');
    editAddress.title = address.value.notes;
    editAddress.firstName = address.value.first_name;
    editAddress.lastName = address.value.last_name;
    editAddress.address1 = address.value.line_1;
    editAddress.address2 = address.value.line_2;
    editAddress.city = address.value.city;
    editAddress.zipCode = address.value.zip_code;
    editAddress.phoneNumber = address.value.phone;

    const rules = {
      title: { required: helpers.withMessage('You must enter a title', required) },
      firstName: { required: helpers.withMessage('You must enter your first name', required) },
      lastName: { required: helpers.withMessage('You must enter your last name', required) },
      address1: { required: helpers.withMessage('You must enter your address', required) },
      city: { required: helpers.withMessage('You must enter your city', required) },
      state: { required: helpers.withMessage('You must select your state', required) },
      country: { required: helpers.withMessage('You must select your country', required) },
      zipCode: { required: helpers.withMessage('You must enter your zipCode', required) },
      phoneNumber: { required: helpers.withMessage('You must enter your phone number', required) },
    };

    const v$ = useVuelidate(rules, editAddress);

    const countrySelected = () => {
      state.value = '';
      editAddress.state = '';
      stateCode.value = '';
      storyphoneService.fetchAvailableStates(editAddress.country)
        .then((stateLists) => {
          stateList.value = stateLists.data.items;
        }).catch((stateError) => {
          stateList.value = {};
        });
    };

    const saveAddress = async () => {
      if (editAddress.country === '') {
        editAddress.country = countryCode.value;
      }
      if (editAddress.state === '') {
        editAddress.state = stateCode.value;
      }

      const userAddressParam = {
        notes: editAddress.title,
        first_name: editAddress.firstName,
        last_name: editAddress.lastName,
        line_1: editAddress.address1,
        line_2: editAddress.address2,
        city: editAddress.city,
        country_code: editAddress.country,
        state_code: editAddress.state,
        zip_code: editAddress.zipCode,
        phone: editAddress.phoneNumber,
      };
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        cLayerService.updateAddress(id.value, userAddressParam)
          .then((addressUpdated) => {
            isLoading.value = false;
            store.dispatch('fetchUserAddress');
            router.push('/my-account');
          })
          .catch((err) => {
            isLoading.value = false;
          })
          .finally(() => {
          });
      } else {
        console.log(v$);
      }
    };

    return {
      stateList,
      id,
      country,
      state,
      countryCode,
      stateCode,
      isLoading,
      address,
      editAddress,
      countryList,
      userAddress,
      countrySelected,
      saveAddress,
      v$,
    };
  },
};
</script>

<style scoped lang="scss"></style>
