<template lang="html">
  <div class="">
    <div v-if="!isLoading">
      <ul class="story-categories list-unstyled" v-if="finalArray.length > 0">
        <li class="text-center" v-for="(shield, index) in finalArray" :key="shield.id">
          <a
            class="p-0"
            data-bs-toggle="modal"
            data-bs-target="#product-details-popup"
            @click="shieldClick(shield)"
          >
            <ShieldListingItem :shieldData="shield" :shieldSku="priceDetails[index]" />
          </a>
          <ShieldListingButton :shieldData="shield" :shieldSku="priceDetails[index]" />
        </li>
      </ul>
      <ul v-else>
        <li>{{ $t('noItems') }}</li>
      </ul>
    </div>
    <div v-else>
      <Loader />
    </div>

    <div
      class="modal fade"
      id="product-details-popup"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close close-blue-btn ms-auto"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>

          <DetailsOfCategory
            v-if="selectedShieldDetails?.items?.length > 0 && this.selectedShieldSku"
            :shield-data="selectedShieldDetails?.items"
            :shield-sku="this.selectedShieldSku"
          />
          <div v-else><Loader /></div>
        </div>
      </div>
    </div>

    <!-- Toggle modals Second -->
    <div
      class="modal fade"
      id="shield-play-popup"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @focusout="stopAudio()">
          <button
            type="button"
            class="btn-close close popup"
            data-bs-target="#product-details-popup"
            data-bs-toggle="modal"
            @click="stopAudio()"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>
          <div v-if="selectedShieldDetails?.items" class="modal-body text-center py-5">
            <h5>{{ selectedShieldDetails?.items[0]?.fields?.name }}</h5>
            <div class="audio audioStyle">
              <audio
                id="audio"
                controls="controls"
                class="audioStyle"
                ref="audio"
                :src="selectedShieldDetails?.items[0]?.fields?.sampleAudio"
              ></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>
<script lang="js">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import NewContentfulService from '@/services/NewContentfulService';
import ShieldListingItem from '@/components/partials/ShieldListingItem.vue';
import ShieldListingButton from '@/components/partials/ShieldListingButton.vue';
import Loader from '@/components/commons/Loader.vue';
import DetailsOfCategory from '@/components/partials/DetailsOfCategory.vue';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();

const newContentfulService = new NewContentfulService();

export default {
  components: {
    ShieldListingItem,
    // DetailsOfCategory,
    Loader,
    ShieldListingButton,
  },
  // setup() {
  //   const isLoading = ref(true);

  //   const code = localStorage.getItem('LangCode');
  //   contentfulService.getComingSoon().then((res) => {
  //     console.log(res);
  //   });
  //   return { code };
  // },
  setup(props) {
    const store = useStore();
    const shieldSku = ref({});
    const storyShieldList = ref({});
    const storyShieldSkuList = ref([]);
    const shieldsList = ref([]);
    const priceDetails = ref([]);
    const isLoading = ref(true);
    const isAdded = ref(false);
    const priceObject = ref({});
    const finalArray = ref([]);

    isLoading.value = true;

    newContentfulService.getComingSoon()
      .then((response) => {
        storyShieldSkuList.value = [];
        priceDetails.value = [];
        storyShieldList.value = response.items;
        storyShieldList.value.forEach((element) => {
          storyShieldSkuList.value.push(element.sku);
        });

        cLayerService.getShieldPriceList(storyShieldSkuList.value)
          .then((prices) => {
            storyShieldList.value.forEach((shields, index) => {
              prices.forEach((price) => {
                if (shields.sku === price.code) {
                  // priceDetails.value.push(price);
                  priceObject.value = { ...price, ...shields };
                  finalArray.value.push(priceObject.value);
                }
              });
            });
          })
          .catch((error) => {
            console.log('error');
          });
      }).catch((e) => {

      })
      .finally(() => {
        isLoading.value = false;
      });

    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    const selectedShieldSku = computed(() => store.getters.getSelectedShieldSku);

    const stopAudio = () => {
      document.getElementById('audio').pause();
    };

    const shieldClick = (data) => {
      store.dispatch('fetchSelectedShieldSku', data.sku);
      store.dispatch('fetchPlaylistDetail', data.playlist);
      const details = {
        id: data.genre,
      };
      store.dispatch('fetchGenresDetail', details);
    };

    return {
      shieldSku,
      storyShieldList,
      isAdded,
      isLoading,
      selectedShieldDetails,
      selectedShieldSku,
      shieldClick,
      stopAudio,
      storyShieldSkuList,
      priceDetails,
      finalArray,
    };
  },
};
</script>
