<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="recording-list">
    <div
      class="recording-list-main-block d-none"
      :class="{
        showFiles: showFiles,
      }"
    >
      <div class="text-center">
        <h4 class="mb-1">{{ $t('recordingList') }}</h4>
        <h6 v-if="duration" class="text-center">
          {{ $t('recordTimeDuration', { time: toTime(duration) }) }}
        </h6>
        <h6 v-else class="text-center">
          {{ $t('recordTimeDuration', { time: '120' }) }}
        </h6>
        <div class="white-box mt-3">
          <div v-if="recordingList">
            <div v-if="recordingList.length > 0" class="recording-list">
              <ul>
                <li v-for="(element, index) in recordingList" :key="index">
                  <div class="d-flex justify-content-between">
                    <h4 class="m-0">{{ element.name }}</h4>
                    <RecordListingButton v-if="element" :element-data="element" />
                  </div>
                </li>
              </ul>
            </div>
            <span v-else>{{ $t('noAudio') }}</span>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <div class="wave-btn-block">
              <button class="blob-btn fill-bg btn-lg me-3" @click="listRecordButton()">
                {{ $t('record') }}
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </button>
              <SvgButton />
            </div>
            <div class="upload-block">
              <input
                type="file"
                class="form-control-file"
                id="real-recording-file"
                accept="audio/*"
                hidden="hidden"
              />
              <div class="wave-btn-block">
                <button
                  type="button"
                  id="custom-button"
                  @click="openFile2()"
                  class="blob-btn fill-bg btn-lg"
                >
                  {{ $t('upload') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg me-3" @click="gotToRecordUpload()">
            {{ $t('back') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg me-3" @click="goToArtwork()">
            {{ $t('next') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
      </div>
      <div
        class="success-submit-box modal fade mt-3 d-none"
        :class="{ addsuccess: isAdded, show: isAdded }"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0 p-3">
            <div class="modal-body">
              <div class="popup-msg-box">
                <p>{{ $t('youMust') }}!</p>
              </div>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary btn-lg submit-btn mb-3"
                data-dismiss="modal"
                @click="closePopup()"
              >
                {{ $t('close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="success-submit-box modal fade mt-3 d-none"
        :class="{ addsuccess: isVerified, show: isVerified }"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0 p-3">
            <div class="modal-body">
              <div class="popup-msg-box">
                <p>{{ $t('storyMoreThan') }}!</p>
              </div>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary btn-lg submit-btn mb-3"
                data-dismiss="modal"
                @click="closeVerfied()"
              >
                {{ $t('close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { ref, computed, reactive } from 'vue';
import { mapActions, useStore } from 'vuex';

import SvgButton from '@/components/partials/SvgButton.vue';
import RecordListingButton from '@/components/partials/RecordListingButton.vue';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';

const storyphoneService = new StoryphoneService();
let realFileBtn2;

export default {
  name: 'recording-list',
  props: [],
  components: {
    SvgButton,
    RecordListingButton,
    Loader,
  },
  setup() {
    const store = useStore();
    const isVerified = ref(false);
    const isAdded = ref(false);

    const showFiles = computed(() => store.getters.isShowFiles);

    const duration = computed(() => store.getters.listDuration);

    const recordingList = computed(() => store.getters.getRecordingList);

    const toTime = (seconds) => {
      const date = new Date(null);
      if (seconds > 7200) {
        return 0;
      }
      date.setSeconds(7200 - seconds);
      return date.toISOString().substr(11, 8);
    };

    const listRecordButton = () => {
      store.dispatch('isShowFiles', false);
      store.dispatch('isShowRecording', true);
    };

    const gotToRecordUpload = () => {
      store.dispatch('isShowFiles', false);
      store.dispatch('isShowUploadRecord', true);
      store.dispatch('inSecondStep', false);
    };

    const goToArtwork = () => {
      if (recordingList.value.length > 0) {
        if (duration.value < 7200) {
          store.dispatch('isShowFiles', false);
          store.dispatch('isShowArtwork', true);
          store.dispatch('inThirdStep', true);
        } else {
          isVerified.value = true;
        }
      } else {
        isAdded.value = true;
      }
    };

    const closePopup = () => {
      isAdded.value = false;
    };

    const closeVerfied = () => {
      isVerified.value = false;
    };

    return {
      showFiles, isVerified, isAdded, toTime, duration, recordingList, listRecordButton, gotToRecordUpload, goToArtwork, closePopup, closeVerfied,
    };
  },
  mounted() {
    realFileBtn2 = document.getElementById('real-recording-file');
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    openFile2() {
      realFileBtn2.click();
      realFileBtn2.addEventListener('change', this.listener2);
    },
    listener2(evt) {
      if (realFileBtn2.value) {
        this.isLoading = true;

        const file = realFileBtn2.files[0];
        const formData = new FormData();
        formData.append('title', realFileBtn2.files[0].name);
        formData.append('file', realFileBtn2.files[0]);
        formData.append('type', 'playshield-story');
        storyphoneService.storyUpload(formData).then((story) => {
          const audioFile = {
            file: URL.createObjectURL(realFileBtn2.files[0]),
            name: realFileBtn2.files[0].name,
            id: story.data.id,
            duration: story.data.duration_in_seconds,
          };
          this.recordingListAction(audioFile);
          this.isLoading = false;
        });
      } else {
        console.log('no file selected');
      }
      realFileBtn2.removeEventListener('change', this.listener);
    },
    ...mapActions({
      recordingListAction: 'fetchRecordingList',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
