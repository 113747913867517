/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class StoryphoneService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  signUp(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/register`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/login/`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          cookies.set('storyPhoneUserAuthCookie', {
            access_token: response.data.accessToken,
          });
          cookies.set('storyPhoneUserRefreshCookie', {
            refresh_token: response.data.refreshToken,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendOTP(credentials, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/add-email`;
      this.http
        .post(apiEndPoint, credentials, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  verifyOTP(credentials, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/verify-email-otp`;
      this.http
        .post(apiEndPoint, credentials, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPasswordEmail(resetPasswordData) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/reset-password/`;
      this.http
        .post(apiEndPoint, resetPasswordData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPassword(resetPasswordData) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/reset-password-confirm/`;
      this.http
        .post(apiEndPoint, resetPasswordData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePassword(updatePasswordData) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/set-password`;
      this.http
        .post(apiEndPoint, updatePasswordData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateClayerPassword(updatePasswordData) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/commerce-layer/change-password/`;
      this.http
        .post(apiEndPoint, updatePasswordData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserDetails() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/details/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateUserDetails(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/update/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchAvailableCountries() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/countries/?limit=0&orderBy=name`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchAvailableStates(stateCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/states/country/${stateCode}?limit=0&orderBy=name`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStateByCode(countryCode, stateCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/states/${stateCode}/country/${countryCode}`;
      this.http
        .get(`${apiEndPoint}?limit=500`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchCountryByCode(countryCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/countries/by-code/`;
      this.http
        .get(apiEndPoint + countryCode, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addToWishlist(item) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/wishlist/`;
      this.http
        .post(apiEndPoint, item, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllWishlist() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/wishlist/me`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getWishlist(limit, offset) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/wishlist?limit=${limit}&offset=${offset}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteWishlist(sku) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/wishlist/by-sku/`;
      this.http
        .delete(apiEndPoint + sku, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getArtWork() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/artworks/?limit=20`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // storyUpload(data, token) {
  //   return new Promise((resolve, reject) => {
  //     const apiEndPoint = `${this.baseURL}v1/creative-stories/`;
  //     if (token) {
  //       this.http
  //         .post(apiEndPoint, data, {
  //           headers: {
  //             // eslint-disable-next-line no-underscore-dangle
  //             'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     } else {
  //       this.http
  //         .post(apiEndPoint, data, {
  //           headers: {
  //             // eslint-disable-next-line no-underscore-dangle
  //             'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  //             Authorization: `Bearer ${this.getUserToken()}`,
  //           },
  //         })
  //         .then((response) => {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     }
  //   });
  // }

  storyUpload(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/story/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  storyUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  storyDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // playListCreation(data) {
  //   return new Promise((resolve, reject) => {
  //     const apiEndPoint = `${this.baseURL}v1/creative-stories/playlists/`;
  //     this.http
  //       .post(apiEndPoint, data, {
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${this.getUserToken()}`,
  //         },
  //       })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  customizeShields(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/customize-shields/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  imageUpload(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/image-upload/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  contactUsEmail(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/query/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  warrantyRegistration(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/warranty-registration/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getCreativeStories(offset, search) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/stories?limit=10&offset=${offset}&search=${search}`;

      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserStoryShields() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/shields/?type=storyshield`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserPlayShields() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/shields/?type=playshield`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  playListCreation(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/users/me/playlist/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPlayShieldPlus(reference, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/orders/with-reference-id/${reference}`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  facebookLogin(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/facebook`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  twitterLogin(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/twitter`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  googleLogin(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/google`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
