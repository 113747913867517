<template lang="html">
  <section class="marketplace-story-tellers">
    <div class="story-slider-block mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>Top Rated</h3>
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col">
                <MarketplaceSingleTellers />
              </div>

              <div class="col">
                <MarketplaceSingleTellers />
              </div>

              <div class="col">
                <MarketplaceSingleTellers />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of story-slider-block -->

    <div class="other-story-tellers py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>Others</h3>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-4">
                <MarketplaceSingleTellers />
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <MarketplaceSingleTellers />
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <MarketplaceSingleTellers />
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <MarketplaceSingleTellers />
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <MarketplaceSingleTellers />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of other-story-tellers -->
  </section>
</template>

<script lang="js">
import MarketplaceSingleTellers from '@/components/partials/MarketplaceSingleTellers.vue';

export default {
  name: 'marketplace-story-tellers',
  props: [],
  components: {
    MarketplaceSingleTellers,

  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
