<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="pay-now" :class="{ showPaynow: showPaynow }">
    <h5 class="mb-3">{{ $t('payInf') }}</h5>
    <div class="row">
      <div class="col-6">{{ $t('cardNo') }}</div>
      <div class="col-6"><div id="card-number-element" class="field form-control" /></div>
    </div>

    <div class="row">
      <div class="col-6">{{ $t('ExpiryDate') }}</div>
      <div class="col-6"><div id="card-expiry-element" class="field" /></div>
    </div>

    <div class="row">
      <div class="col-6">{{ $t('CVC') }}</div>
      <div class="col-6"><div id="card-cvc-element" class="field"></div></div>
    </div>

    <div
      class="success-submit-box modal fade mt-3 d-none"
      :class="{ addsuccess: isAdded, show: isAdded }"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 p-3">
          <div class="modal-body">
            <div class="popup-msg-box">
              <p>{{ paymentError }}</p>
            </div>
          </div>
          <div class="text-center">
            <button
              class="btn btn-primary btn-lg submit-btn mb-3"
              data-dismiss="modal"
              @click="closePopup()"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="backToPreview">
          {{ $t('back') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>

      <div class="wave-btn-block">
        <button class="blob-btn fill-bg btn-lg" @click="handlePayment">
          {{ $t('PayNow') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
        <SvgButton />
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { useStore, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import { loadStripe } from '@stripe/stripe-js';
import {
  watch, ref, computed, reactive, onBeforeMount, onMounted,
} from 'vue';
import { useCookies } from 'vue3-cookies';

import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });
const isObj = require('lodash.get');

let stripe = null;
let cardElement;
let cardNumberElement;

const formInputStyle = {
  classes: {
    complete: 'form-control',
    empty: 'form-control',
    focus: '',
    invalid: '',
    base: 'form-group',
  },
};

const customStyle = {
  base: {
    iconColor: '#666EE8',
    color: '#31325F',
    lineHeight: '40px',
    fontWeight: 300,
    fontSize: '15px',

    '::placeholder': {
      color: '#222',
    },
  },
};

export default {
  name: 'pay-now',
  props: [],
  components: {
    Loader,
    SvgButton,
  },
  setup() {
    onBeforeMount(async () => {
      stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      const confirmOrder = async () => {
        let cardExpiryElement;
        let cardCvcElement;
        // Init card element
        const elements = await stripe.elements({});
        cardNumberElement = elements.create('cardNumber', formInputStyle);
        cardNumberElement.mount('#card-number-element');

        // eslint-disable-next-line prefer-const
        cardExpiryElement = elements.create('cardExpiry', formInputStyle);
        cardExpiryElement.mount('#card-expiry-element');

        // eslint-disable-next-line prefer-const
        cardCvcElement = elements.create('cardCvc', formInputStyle);
        cardCvcElement.mount('#card-cvc-element');
      };
      confirmOrder();
    });
    const store = useStore();
    const backToPreview = () => {
      store.dispatch('isShowPreview', true);
      store.dispatch('isShowPaynow', false);
      store.dispatch('inThirdStep', false);
    };
    return { backToPreview };
  },
  data() {
    return {
      paymentSuccess: '',
      paymentError: '',
      isLoading: false,
      isAdded: false,
    };
  },
  watch: {
    stripe: {
      immediate: true,
      deep: true,
    },
    cardNumberElement: {
      immediate: true,
      deep: true,
    },
  },
  methods: {

    handlePayment() {
      this.isLoading = true;
      const { orderId } = cookies.get('orderIdCookie');
      stripe
        .confirmCardPayment(this.paymentSource.client_secret, {
          payment_method: {
            card: cardNumberElement,
          },
        })
        .then((paymentStatus) => {
          this.isLoading = false;
          if (isObj(paymentStatus, 'error', false)) {
            this.isAdded = true;
            this.paymentError = paymentStatus.error.message;
          } else {
            cLayerService.updateOrder(orderId);
            this.$router.push({ name: 'paymentSuccess', params: { id: orderId } });
          }
        }).catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.paymentError = 'Sorry we couldn\'t process your payment at this time.';
        });
    },
    closePopup() {
      this.isAdded = false;
    },

  },

  computed: {
    ...mapGetters({
      paymentSource: 'getPaymentSource',
      showPaynow: 'isShowPaynow',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
