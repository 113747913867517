<template lang="html">
  <PageHeader />

  <div class="banner-section mt-stickybar home" aria-label="about banner">
    <!-- <div id="about-page"></div> -->
    <div class="banner">
      <img src="@/assets/images/about.png" alt="about image" />
    </div>
  </div>
  <section class="bg-gray py-9" aria-label="onanoff the makers of storyphones">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <!-- <p>{{ onAnOff.title }}</p>
          <h2 class="mb-3">{{ onAnOff.excerpt }}</h2>
          <div class="row">
            <div class="col-md-10">
              <p>
                {{ onAnOff.description }}
              </p>
            </div>
          </div> -->
          <div v-html="domSanitize(onAnOff.html_description)"></div>
        </div>
        <div class="col-md-6" v-for="(image, index) in onAnOff.images" :key="index">
          <img :src="image.image_url" alt="StoryPhones image" width="450" />
        </div>
      </div>
    </div>
  </section>

  <section aria-label="Pétur Ólafsson" class="py-9">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6" v-for="(image, index) in person.images" :key="index">
          <img :src="image.image_url" alt="Pétur Ólafsson image" width="450" />
        </div>
        <div class="col-md-6">
          <div v-html="domSanitize(person.html_description)"></div>
          <!-- <p>{{ person.excerpt }}</p>
          <h2 class="mb-3">{{ person.title }}</h2>
          <div class="row">
            <div class="col-md-10" v-for="(content, index) in person.details" :key="index">
              <p>{{ content.description }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="bg-gray py-9" aria-label="Bjarki Viðar Garðarsson">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <!-- <p>{{ finance.excerpt }}</p>
          <h2 class="mb-3">{{ finance.title }}</h2>
          <div class="row">
            <div
              class="col-md-10"
              v-for="(content, index) in finance.details"
              :key="index"
            >
              <p>{{ content.description }}</p>
            </div>
          </div> -->
          <div v-html="domSanitize(finance.html_description)"></div>
        </div>
        <div class="col-md-6" v-for="(image, index) in finance.images" :key="index">
          <img :src="image.image_url" alt="Bjarki Viðar Garðarsson" width="450" />
        </div>
      </div>
    </div>
  </section>

  <section class="py-5" aria-label="Meet our teamates">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="meeting-top-bg">
            <div class="meeting-btm-bg">
              <h2>{{ team.title }}</h2>
              <span v-for="(image, index) in team.images" :key="index">
                <img :src="image.image_url" alt="" class="my-4" />
                <!-- <img src="@/assets/images/meeting.png" alt="" class="my-4" /> -->
              </span>
              <div class="col-md-10 m-auto">
                <div v-html="domSanitize(team.html_description)"></div>
                <!-- <h5>
                  {{ team.description }}
                </h5> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-gray about-card py-9" aria-label="Tradeshows">
    <div class="container">
      <div
        class="row row-cols-1 row-cols-md-3 g-4"
        v-for="(item, index) in events.widgets"
        :key="index"
      >
        <div class="col" v-for="(team, index) in item.details" :key="index">
          <div class="card h-100 single-card">
            <img class="card-img-top" :src="team.image" :alt="team.title" />
            <div class="card-body p-4">
              <div v-html="domSanitize(team.title)"></div>
              <div v-html="domSanitize(team.description)"></div>
              <!-- <h5 class="card-title">{{ team.title }}</h5>
              <p class="card-text">
                {{ team.description }}
              </p> -->
            </div>
          </div>
        </div>
        <!-- <div class="col">
          <div class="card h-100 single-card">
            <img class="card-img-top" src="@/assets/images/about-img4.png" alt="Tradeshows" />
            <div class="card-body p-4">
              <h5 class="card-title">Tradeshows</h5>
              <p class="card-text">
                We take part in key tradeshows within Consumer Electronics like CES in Las Vegas and
                IFA in Berlin. Within education you can finds at BETT in London and ISTE in Anaheim.
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="col">
          <div class="card h-100 single-card">
            <img class="card-img-top" src="@/assets/images/about-img5.png" alt="Awards" />
            <div class="card-body p-4">
              <h5 class="card-title">Awards</h5>
              <p class="card-text">
                StoryPhones are award winning SafeAudio® headphones for kids. They have received two
                CES innovation awards in no less than two categories; Headphones &amp; Personal
                Audio, and Health &amp; Wellness. Having StoryPhones selected as a winner of two CES
                Awards, amidst a great number of submitted designs, is truly an honor and a
                brilliant acknowledgment.
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="col">
          <div class="card h-100 single-card">
            <img class="card-img-top" src="@/assets/images/about-img6.png" alt="Social Programs" />
            <div class="card-body p-4">
              <h5 class="card-title">Social Programs</h5>
              <p class="card-text">
                As educational equipment makers and kids audio experts we understand the additional
                challenges facing underprivileged kids due to home learning. If you know of cases
                where we can help, pls. get in touch with us.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

import PageService from '@/services/PageService';
import WidgetService from '@/services/WidgetService';

const pageService = new PageService();
const widgetService = new WidgetService();

export default {
  name: 'About',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const Data = {
      code: langCode,
      slug: `our_story_onanoff_${langCode}`,
    };
    store.dispatch('fetchCMS', Data);

    const Data1 = {
      code: langCode,
      slug: `our_story_person_${langCode}`,
    };
    store.dispatch('fetchCMS2', Data1);

    const Data2 = {
      code: langCode,
      slug: `our_story_finance_${langCode}`,
    };
    store.dispatch('fetchCMS3', Data2);

    const Data3 = {
      code: langCode,
      slug: `our_story_team_${langCode}`,
    };
    store.dispatch('fetchCMS4', Data3);

    const Data4 = {
      code: langCode,
      slug: `our_story_event_${langCode}`,
    };
    store.dispatch('fetchCMS5', Data4);

    const onAnOff = computed(() => store.getters.getCMS);
    const person = computed(() => store.getters.getCMS2);
    const finance = computed(() => store.getters.getCMS3);
    const team = computed(() => store.getters.getCMS4);
    const events = computed(() => store.getters.getCMS5);

    const domSanitize = (html) => DOMPurify.sanitize(html);

    return {
      onAnOff, langCode, person, finance, team, events, domSanitize,
    };
  },
  mounted() {
    pageService.getAboutContent()
      .then((res) => {
        this.text = res.data.description;
        document.getElementById('about-page').innerHTML = this.decodeHtml(this.text);
      });

    widgetService.getAboutMakersContent()
      .then((res) => {
        this.text = res.data.content;
        document.getElementById('makers').innerHTML = this.decodeHtml(this.text);
      });
  },
  data() {
    return {
      text: '',
    };
  },
  methods: {
    decodeHtml(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
