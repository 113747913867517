<template lang="html">
  <PageHeader />
  <section class="marketplace-story-details mt-stickybar">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 col-lg-7">
                <MarketplaceSearch />
              </div>
              <div class="col-md-6 col-lg-5">
                <MarketplaceIcons />
              </div>
            </div>

            <div class="marketplace-profile-outerblock">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div class="profile-sidebar">
                    <div class="story-header">
                      <div class="card-wishlist-outer">
                        <span class="card-flag me-2"
                          ><img src="@/assets/images/flag-us-new.jpg" alt="flag-us-new"
                        /></span>
                        <button class="card-wishlist-btn border-0 p-0">
                          <i class="icon-like" aria-hidden="true"></i>
                        </button>
                      </div>
                      <div class="text-center">
                        <div class="profile-pic">
                          <a href="/marketplace-profile"
                            ><img src="@/assets/images/profile-pic.jpg" alt="profile-pic"
                          /></a>
                        </div>

                        <div class="title-block">
                          <h4>Julia Wilson</h4>
                          <h5>Playlists : 5</h5>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-mid-block text-center mt-4">
                      <div class="card-language mt-1">
                        <h6 class="mb-2">Language Profficiency</h6>
                        <ul class="nav nav-pills">
                          <li class="nav-item">English</li>
                          <li class="nav-item">Japaneese</li>
                          <li class="nav-item">German</li>
                        </ul>
                      </div>
                    </div>

                    <div class="card-body text-center p-0 mt-4">
                      <h6>Author bio</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-lg-8">
                  <div class="profile-content-block">
                    <div class="profiile-single-content">
                      <div class="star-heading-block">
                        <h3>Pappa’s Princes</h3>
                        <div class="stars-list mb-2">
                          <ul class="list-unstyled">
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li class="yellow">
                              <i class="icon-star" aria-hidden="true"></i>
                            </li>
                            <li><i class="icon-star" aria-hidden="true"></i></li>
                          </ul>
                        </div>
                        <div class="preview-outer text-center d-flex justify-content-between my-3">
                          <div class="categort-name">
                            <h6>Category</h6>
                            <span class="round-box">Fantasy</span>
                          </div>
                          <div class="categort-name">
                            <h6>Suitable For</h6>
                            <span class="round-box">Age 5+</span>
                          </div>
                        </div>
                        <div class="top-btn-block">
                          <button class="shadow-btn border-0">
                            <i class="icon-share" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>

                      <p>
                        Pappa’s Princes, is a cute, innocent red coloured little imp who works as an
                        aide of Küttoosen. One of the primary sources of humor in the comic, it is
                        noted for his cheekiness and cowardice. His stupidity and over excitedness
                        often lands everyone in trouble.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat.
                      </p>
                      <p>
                        Innocent red coloured little imp who works as an aide of Küttoosen. One of
                        the primary sources of humor in the comic, Pappa’s Princes is noted for his
                        cheekiness and cowardice. His stupidity and over excitedness often lands
                        everyone in trouble.
                      </p>
                      <div class="single-video-outer d-block my-3">
                        <div class="single-video">
                          <img src="@/assets/images/story-video.png" alt="story-video" />
                        </div>
                      </div>

                      <div class="btn-block pt-3">
                        <div class="row">
                          <div class="col-md-6 col-lg-6">
                            <span class="profile-like-dislike">
                              <button class="like">
                                <i class="icon-thumb-up" aria-hidden="true"></i>74
                              </button>
                              <button href="#" class="dislike">
                                <i class="icon-thumb-down" aria-hidden="true"></i>10
                              </button>
                            </span>
                            <span class="wave-btn-block">
                              <button
                                type="button"
                                class="blob-btn btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#eviews-popup"
                              >
                                Reviews
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </span>
                          </div>

                          <div class="col-md-6 col-lg-6">
                            <div class="wave-btn-block text-end">
                              <button class="blob-btn fill-bg btn-sm me-2">
                                Add to cart
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <button class="blob-btn fill-bg btn-sm">
                                Add To Wishlist
                                <span class="blob-btn__inner">
                                  <span class="blob-btn__blobs">
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                    <span class="blob-btn__blob"></span>
                                  </span>
                                </span>
                              </button>
                              <SvgButton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- marketplace-profile-outerblock -->

            <!-- Modal -->
            <div
              class="modal fade"
              id="eviews-popup"
              tabindex="-1"
              aria-labelledby="eviewsPopupLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                  <button
                    type="button"
                    class="btn-close close-blue-btn ms-auto 45"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="icon-close" aria-hidden="true"></i>
                  </button>
                  <div class="modal-body">
                    <h3 class="text-center">Reviews</h3>
                    <div class="marketplace-review-listing">
                      <ul class="row">
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                        <li class="col-lg-6">
                          <div class="review-person mb-2">
                            <span><i class="icon-User" aria-hidden="true"></i></span> D'yer Mak'er
                          </div>
                          <div class="stars-list">
                            <ul class="list-unstyled">
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li class="yellow">
                                <i class="icon-star" aria-hidden="true"></i>
                              </li>
                              <li><i class="icon-star" aria-hidden="true"></i></li>
                            </ul>
                          </div>
                          <h6>Reviewed on 17 August 2022</h6>
                          <p>
                            Definitely a good buy. The only issue one faces with the budget
                            electronics like this is how they fit.It snuggled in well in the Type C
                            and the USB went in like better. Smooth plug b play. No nonsense..
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import MarketplaceSearch from '@/components/partials/MarketplaceSearch.vue';
import MarketplaceIcons from '@/components/partials/MarketplaceIcons.vue';

export default {
  name: 'marketplace-story-details',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    SvgButton,
    MarketplaceSearch,
    MarketplaceIcons,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
