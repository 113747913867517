<template lang="html">
  <PageHeader />

  <section class="user-manual mt-stickybar home">
    <div class="inner-page-content py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <div id="user-manual-link" v-html="Details"></div>
              <!-- <h2>{{ userManualIos.title }}</h2>
              <p>{{ userManualIos.excerpt }}</p> -->
              <!-- <h4 class="mt-4 pb-3">
                <a
                  download="StoryPhones-manual-v3.2.pdf"
                  href="/userManual/StoryPhones-manual-v3.2.pdf"
                  class="download-manual"
                >
                  <span class="icon-wrap">
                    <i class="icon-download"></i>
                  </span>

                  Click here to download the user manual
                  {{ $t('userManualLink') }}
                </a>
              </h4> -->
              <!-- <hr /> -->
              <!-- <h2 class="mt-5">{{ userManualIos.description }}</h2> -->

              <div class="user-manual-list mt-5">
                <ul class="nav nav-pills justify-content-center mb-4" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      <!-- iOS Version -->
                      {{ $t('ios') }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      <!-- Android Version -->
                      {{ $t('android') }}
                    </button>
                  </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <UserManualIos />
                  </div>

                  <div
                    class="tab-pane fade show"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <UserManualAndroid />
                  </div>
                </div>
              </div>

              <div class="pt-4">
                <hr />
                <!-- <p class="pt-4">
                  {{ userManualAndroid.description }}
                </p> -->
                <div v-html="Details2"></div>
                <p>
                  <a href="/contact-us" class="download-manual txt-bdr-link p-0">{{
                    $t('clickToContact')
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import DOMPurify from 'dompurify';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import UserManualIos from '@/components/partials/UserManualIos.vue';
import UserManualAndroid from '@/components/partials/UserManualAndroid.vue';

import PageService from '@/services/PageService';

const pageService = new PageService();

export default {
  name: 'user-manual',
  props: [],
  components: {
    PageHeader,
    PageFooter,
    UserManualAndroid,
    UserManualIos,

  },
  setup() {
    const store = useStore();
    const LangCode = localStorage.getItem('LangCode');
    const ios = {
      code: LangCode,
      slug: `usermanual_ios_${LangCode}`,
    };
    const android = {
      code: LangCode,
      slug: `usermanual_android_${LangCode}`,
    };

    store.dispatch('fetchCMS', ios);

    store.dispatch('fetchCMS1', android);

    const userManualIos = computed(() => store.getters.getCMS);

    const userManualAndroid = computed(() => store.getters.getCMS1);

    const Details = computed(() => DOMPurify.sanitize(userManualIos.value.html_description));
    const Details2 = computed(() => DOMPurify.sanitize(userManualAndroid.value.html_description));

    const demo = () => {
      const element = document.querySelector('#user-manual-link h4 a');
      const spanElement = document.createElement('span');
      spanElement.className = 'icon-wrap';

      const iElement = document.createElement('i');
      iElement.className = 'icon-download';

      spanElement.appendChild(iElement);

      element.appendChild(spanElement);
    };
    return {
      userManualIos, userManualAndroid, Details, demo, Details2,
    };
  },
};
</script>

<style scoped lang="scss"></style>
