import * as types from './mutation-types';

const mutations = {
  [types.SET_USER_ACTIVATION_SUCCESS](state, userActivationSuccess) {
    state.userActivationSuccess = userActivationSuccess;
  },
  [types.SET_USER_ACTIVATION_ERRORS](state, userActivationErrors) {
    state.userActivationErrors = userActivationErrors;
  },
};

export default mutations;
