<template lang="html">
  <PageHeader />

  <section class="account-settings wishlist mt-stickybar home">
    <div v-if="isLoading"><Loader /></div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <AccountSidebar />
        </div>
        <!-- {{ wishListItems }} -->
        <div class="col-md-8">
          <div class="accounts-content">
            <div class="border-block">
              <h3>{{ $t('wishlist') }}</h3>
              <div v-if="finalArray.length > 0" class="inner-box">
                <div
                  v-for="(wishlist, index) in finalArray"
                  :key="wishlist.id"
                  class="order-single-block"
                >
                  <WishlistItem :wishListItems="wishlist" :shieldSku="priceDetails[index]" />
                </div>
              </div>
              <div v-else class="alert empty-cart" role="alert">
                <img src="@/assets/images/empty-cart.png" alt="Empty image" />
                <h2>{{ $t('oopsWishlist') }}</h2>
                <div class="wave-btn-block">
                  <button type="submit" class="blob-btn fill-bg btn-lg" @click="library()">
                    {{ $t('shopNow') }}
                    <span class="blob-btn__inner">
                      <span class="blob-btn__blobs">
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                        <span class="blob-btn__blob"></span>
                      </span>
                    </span>
                  </button>
                  <SvgButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="5"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import AccountSidebar from '@/components/commons/AccountSidebar.vue';
import WishlistItem from '@/components/partials/WishlistItem.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import CLayerService from '@/services/CLayerService';
import Loader from '@/components/commons/Loader.vue';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'wishlist',
  props: [],
  components: {
    AccountSidebar,
    WishlistItem,
    SvgButton,
    PageHeader,
    PageFooter,
    Loader,
    VuejsPaginateNext,
  },

  setup() {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const router = useRouter();
    const page = ref(1);
    const perPage = ref(5);
    const currentOffset = ref(0);
    const storyShieldSkuList = ref([]);
    const priceDetails = ref([]);
    const wishListItems = ref([]);
    const pageCount = ref('');
    const isLoading = ref(false);
    const priceObject = ref({});
    const finalArray = ref([]);

    const allWishlist = computed(() => store.getters.getUserWishlist?.data?.items);

    store.dispatch('fetchUserWishList', { limit: perPage.value, offset: currentOffset.value })
      .then((res) => {
        isLoading.value = true;
        pageCount.value = Math.ceil(res.data.total / perPage.value);
        storyShieldSkuList.value = [];
        priceDetails.value = [];
        finalArray.value = [];
        priceObject.value = {};
        allWishlist.value?.forEach((element) => {
          storyShieldSkuList.value.push(element.sku);
        });

        newContentfulService.fetchProductsBySku(storyShieldSkuList.value, langCode)
          .then((response) => {
            wishListItems.value = response.data.items;
            cLayerService.getShieldPriceList(storyShieldSkuList.value)
              .then((prices) => {
                wishListItems.value?.forEach((shields) => {
                  prices.forEach((price) => {
                    if (shields.sku === price.code) {
                      isLoading.value = false;
                      // priceDetails.value.push(price);
                      priceObject.value = { ...shields, ...price };
                      finalArray.value.push(priceObject.value);
                    }
                  });
                });
              })
              .catch((error) => {
                isLoading.value = false;
                console.log('error');
              });
          })
          .catch((error) => {
            isLoading.value = false;
            console.log('error');
          });
      })
      .catch(() => {
        isLoading.value = false;
      });

    const wishlistCount = computed(() => store.getters.getUserWishlist?.data?.count);

    const library = () => {
      router.push('/shields');
    };

    // const pageClick = (pageNum) => {
    //   currentOffset.value = (pageNum - 1) * 10;
    //   store.dispatch('fetchUserWishList', { limit: 10, offset: currentOffset.value });
    //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // };
    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      store.dispatch('fetchUserWishList', { limit: perPage.value, offset: currentOffset.value }).then((category) => {
        pageCount.value = Math.ceil(category.data.total / perPage.value);
        storyShieldSkuList.value = [];
        priceDetails.value = [];
        finalArray.value = [];
        priceObject.value = {};
        allWishlist.value?.forEach((element) => {
          storyShieldSkuList.value.push(element.sku);
        });

        newContentfulService.fetchProductsBySku(storyShieldSkuList.value, langCode)
          .then((response) => {
            wishListItems.value = response.data.items;
            cLayerService.getShieldPriceList(storyShieldSkuList.value)
              .then((prices) => {
                wishListItems.value?.forEach((shields) => {
                  prices.forEach((price) => {
                    if (shields.sku === price.code) {
                      priceObject.value = { ...price, ...shields };
                      finalArray.value.push(priceObject.value);
                    }
                  });
                });
                isLoading.value = false;
              })
              .catch((error) => {
                console.log('error');
              });
          })
          .catch((error) => {
            console.log('error');
          });
      });
      page.value = pageNum;

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return {
      library, allWishlist, pageClick, wishlistCount, pageCount, priceDetails, wishListItems, isLoading, finalArray,
    };
  },

};
</script>

<style scoped lang="scss"></style>
