import * as types from './mutation-types';

export const isHideTitle = ({ commit }, value) => {
  commit(types.HIDE_TITLE, value);
};

export const isShowUploadRecord = ({ commit }, value) => {
  commit(types.SHOW_UPLOAD_RECORD, value);
};

export const isShowRecording = ({ commit }, value) => {
  commit(types.SHOW_RECORDING, value);
};

export const isShowFiles = ({ commit }, value) => {
  commit(types.SHOW_FILES, value);
};

export const isShowArtwork = ({ commit }, value) => {
  commit(types.SHOW_ARTWORK, value);
};

export const isShowOverview = ({ commit }, value) => {
  commit(types.SHOW_OVERVIEW, value);
};

export const isShowLibrary = ({ commit }, value) => {
  commit(types.SHOW_LIBRARY, value);
};
